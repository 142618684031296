import { Component, OnInit } from "@angular/core";

import { UserService } from "../user.service";
import { AuthService } from "../auth.service";
import { Role } from "../role";
import { Permission } from "../permission";

@Component({
  selector: "app-role-list",
  templateUrl: "./role-list.component.html",
  styleUrls: ["./role-list.component.css"],
})
export class RoleListComponent implements OnInit {
  roles: Role[];
  permissionsList: Permission[];

  success: boolean;
  updatedRoles: string;
  updatedPermissions: string;
  hasAccess: boolean;
  accessRoles = ["Admin", "Production"];
  edit = false;

  constructor(private userService: UserService, private authService: AuthService) {}

  ngOnInit() {
    //this.accessRoles = ["Admin", "Production"];
    const userRole = this.authService.getUserRole();

    this.hasAccess = this.accessRoles.includes(userRole);

    this.getPermissions();
    this.getRoles();
  }

  getRoles() {
    this.userService.getRoles().subscribe((roles) => {
      this.roles = roles;
    });
  }

  getPermissions() {
    //get ALL permissions list
    this.userService.getPermissionsList().subscribe((permissions) => {
      this.permissionsList = permissions;
    });
  }

  setChanges(permissionsUpdate: { role: string; permissions: string[] }) {
    if (!this.hasAccess) {
      return;
    }

    const role = permissionsUpdate["role"];
    const permissionsCode = permissionsUpdate["permissions"].toString();

    if (role) {
      this.success = true;
      this.updatedRoles = role;
      this.updatedPermissions = permissionsCode.split(",").join(", ");
      return;
    }
  }

  setNewRole(newRole: Role) {
    this.roles.push(newRole);
  }
}
