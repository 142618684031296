<div class="container-fluid">
  <div class="row">
    <div *ngFor="let hdri of hdris; let i = index" class="card">
      <div>
        <img
          [src]="getThumbnail(hdri)"
          class="img-thumbnail img-fluid"
          loading="lazy"
          (click)="showLargeImage(hdri)" />
        <div><b>Name: </b> {{ hdri["hdri_name"] }} <br /></div>
        <div><b>Description: </b> {{ hdri["hdri_description"] }} <br /></div>
        <div><b>Drive: </b> {{ hdri["hdri_drive"] }} <br /></div>
        <div><b>File Name: </b> {{ hdri["hdri_image_path"].split("/").pop() }}</div>
      </div>
    </div>
  </div>
</div>

<!-- Bigger view of thumbnail image-->
<ng-container *ngIf="willShowLargeImage">
  <div
    class="fade show d-block modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="imageModal"
    aria-hidden="true"
    (exited)="willShowLargeImage = !$event"
    appModalExit>
    <button
      type="button"
      class="close-button"
      data-dismiss="modal"
      aria-label="Close"
      (click)="$event.stopPropagation(); willShowLargeImage = false">
      <i class="material-icons md-24 align-text-bottom font-weight-bold">close</i>
    </button>
    <div class="container d-flex justify-content-center align-items-center" style="height: 100%">
      <img class="img-fluid" [src]="displayImageSrc" />
    </div>
  </div>
  <div class="fade show d-block modal-backdrop"></div>
</ng-container>
