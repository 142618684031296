import { Component, OnInit } from "@angular/core";
import { UserService } from "../user.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";

@Component({
  selector: "app-activate-account",
  templateUrl: "./activate-account.component.html",
  styleUrls: ["./activate-account.component.css"],
})
export class ActivateAccountComponent implements OnInit {
  token: string;
  activated: boolean;
  validated: boolean;
  confirmed: boolean;
  title: string;
  subtitle: string;
  message: string;
  context: string;

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.token = params.token;
    });

    this.activatedRoute.url.subscribe((urls) => {
      if (urls[0].toString() === "activate") {
        this.context = "activate";
        this.title = "Activate Account";
        this.subtitle = "Setup your password";
        this.message = "Account successfully activated.";
      } else if (urls[0].toString() === "reset-password") {
        this.context = "reset-password";
        this.title = "Reset your password";
        this.message = "Password successfully reset.";
      }
    });
  }

  validate(password: string) {
    this.validated = password.length >= 8;
  }

  validateConfirmPassword(password: string, confirmPass: string) {
    this.confirmed = this.validated && confirmPass.length >= 8 && password === confirmPass;
  }

  activate(password: string) {
    if (this.confirmed && this.validated) {
      if (this.context === "activate") {
        this.userService.activateAccount(password, this.token).subscribe(() => {
          this.activated = true;
          setTimeout(() => this.router.navigate(["login"]), 3000);
        });
      } else if (this.context === "reset-password") {
        this.userService.resetPassword(password, this.token).subscribe(() => {
          this.activated = true;
          setTimeout(() => this.router.navigate(["login"]), 3000);
        });
      }
    }
  }
}
