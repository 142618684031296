<div *ngIf="showColorPicker" class="d-flex card position-absolute" style="top: 0; right: 0">
  <canvas
    #canvas
    (mousedown)="onMouseDown($event)"
    (mousemove)="onMouseMove($event)"
    (mouseup)="onMouseUp($event)"></canvas>
  <div class="text-right">
    <button type="button" class="btn btn-primary btn-sm" (click)="ok()">OK</button>
  </div>
</div>
