import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
} from "@angular/core";
import { Subscription, Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";

import { Context, Entity, IMedia, IStatus } from "../entity";
import { EntityService } from "../entity.service";
import { TableConfigColumnItem } from "../../types/table-config";
import { QueryParamService } from "../query-param.service";
import { NoteService } from "../note.service";
import { EpisodeService } from "../episode.service";
import { FileBrowserService } from "../file-browser.service";
import { User } from "../user";
import { Note } from "../notes";
import { validateInput, formatDateTime, HttpProgress } from "../utils";

@Component({
  selector: "app-entity-card-view",
  templateUrl: "./entity-card-view.component.html",
  styleUrls: ["./entity-card-view.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityCardViewComponent implements OnInit, OnDestroy {
  @Input() primarySoftware: string;
  @Input() allowUploadInFileBrowser: boolean;
  @Input() projectCode: string;
  @Input() context: Context;
  @Input() entity: Entity;
  @Input() editMode: { [key: string]: Set<string> };
  @Input() statuses: IStatus[];
  @Input() users: { [key: string]: User[] };
  @Input() usersInProject: User[];
  @Input() columns: TableConfigColumnItem[];
  @Input() showStatusColors: boolean;
  @Input() inChildView?: boolean;
  // @Output() turnOffPlaySubscription = new EventEmitter<boolean>();
  updatedNote$ = new Subject<Note>();

  episodeName: string;
  notes: Note[];
  status = "";
  progress = 0;

  validateInput = validateInput;
  formatDateTime = formatDateTime;
  hasInvalidInput: boolean;
  fieldNameOfInvalidInput: string;

  subs: Subscription[] = [];
  openUpload: boolean;
  openFileBrowser: boolean;
  openAddChild: boolean;
  selectedEntity: Entity | boolean;


  constructor(
    private entityService: EntityService,
    private queryParamService: QueryParamService,
    private noteService: NoteService,
    private cdr: ChangeDetectorRef,
    private episodeService: EpisodeService,
    private fileBrowserService: FileBrowserService
  ) {}

  ngOnInit() {
    this.noteService.getNotes(this.entity).subscribe((notes) => {
      this.notes = [...(notes || [])];
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  getEpisodeName() {
    if (this.entity.context !== "assets") {
      this.episodeService
        .getOneEpisode(this.entity.projectCode, this.entity.group)
        .subscribe((episode) => (this.episodeName = episode["episode_name"]));
    }
  }

  toggleViewChildren() {
    if (this.entity.children === undefined) {
      return;
    }

    if (this.entity.children.length === 0) {
      return;
    }

    // if (!this.inChildView) {
    //   this.turnOffPlaySubscription.emit(true);
    // }
    this.showChildView(this.entity);
  }

  showChildView(entity: Entity) {
    this.entityService.showChildView.next(entity);
  }

  onShowChildren() {
    this.subs.push(
      this.entityService.showChildView$.subscribe((entity) => (this.selectedEntity = entity))
    );
  }

  inEditMode(key: string, value: string) {
    return this.editMode[key].has(value);
  }

  startEditMode(key: string, value: string) {
    this.editMode[key].add(value);
  }

  endEditMode(key: string, value: string) {
    if ((value === "frame_out" || value === "frame_in") && this.inEditMode(key, "frames")) {
      this.editMode[key].delete("frames");
      return;
    }

    this.editMode[key].delete(value);
  }

  onSave(
    entity: Entity,
    field: string,
    value: any,
    validItems$: Observable<Array<any>> | Array<any> = []
  ) {
    if (!entity) {
      return;
    }

    if (validItems$ instanceof Observable) {
      validItems$.subscribe((validItems) => {
        const sub = this.save(entity, field, validItems)(value);
        if (!sub) {
          return;
        }

        sub.subscribe();
      });
    } else {
      const sub = this.save(entity, field, validItems$)(value);
      if (!sub) {
        return;
      }

      sub.subscribe();
    }
  }

  save(entity: Entity, field: string, validItems: Array<any> = []) {
    return (value: any) => {
      if (field.endsWith("_assigned") && value === "-- unassigned --") {
        value = "";
      }

      if (
        entity["plate"] &&
        entity["plate"]["is_locked"] &&
        ["plate.plate_code", "plate.frame_range"].includes(field)
      ) {
        alert("Locked plate: Cannot change the plate code or frame range for this shot's plate.");
        this.endEditMode(entity.toString(), field);
        return;
      }

      let _value = [];
      if (field === "children" && this.context === "plates") {
        for (let element of value as string[]) {
          const plate = validItems.find((item) => item["plate_code"] === element);
          _value.push(plate);
        }

        validItems = validItems.map((item) => item["plate_code"]);
      }

      const validInput = this.validateInput(value, field, validItems);
      if (!validInput.isValid) {
        this.hasInvalidInput = true;
        this.fieldNameOfInvalidInput = validInput.fieldName;
        this.endEditMode(entity.toString(), field);
        this.cdr.markForCheck();
        return;
      }

      if (field === "children" && this.context === "plates" && _value.length > 0) {
        value = [..._value];
      }

      const json = { [field]: value };
      return this.entityService.updateOne(entity, json).pipe(
        tap((result) => {
          this.endEditMode(entity.toString(), field);

          const key = Object.keys(result);
          if (field.includes(".")) {
            if (key.length > 0) {
              // merge results with client side value if the field to be updated is nested
              // e.g. plate.plate_code
              entity[field.split(".")[0]] = { ...entity[field.split(".")[0]], ...result[key[0]] };
            } else {
              entity[field.split(".")[0]] = {};
            }
          } else {
            // Updating Plate's shot_code
            if (entity.context === "plates" && field === "shot_code") {
              let plateShotCodes = [];
              result.forEach((element: any) => {
                if (element["plate_code"] == entity.entityCode) {
                  entity["shot_code"] = element["shot_code"];
                } else {
                  plateShotCodes.push(element);
                }
              });
              if (plateShotCodes.length > 0) {
                //TODO!: this.updatedShotCode.emit(plateShotCodes);
              }
            } else {
              entity[key[0]] = result[key[0]];
            }
          }

          this.cdr.markForCheck();
        })
      );
    };
  }

  play(entity: Entity, notes: Note[], src: "MEDIA" | "CATALOG", selectedVersionId?: string) {
    this.entityService.playMedia.next({
      notes: notes,
      media: this.entityService
        .findOne(
          this.projectCode,
          this.context as Context,
          entity.group,
          entity.entityCode,
          "fields=media,catalog,plate&exclude_fields=_id"
        )
        .pipe(
          map((entity) => {
            const m = entity[src.toLowerCase()] || [];
            if (m) {
              //play=e020_s0030&version=5d4307df0d17bf2e64dfbd2c&play-ctx=shots&play-src=MEDIA
              if (entity["plate"] && entity["plate"]["media"] && src === "MEDIA") {
                // inject the entity's plate media into the entity's media. do not inject it into the catalog
                m.splice(0, 0, entity["plate"]["media"]);
                this.queryParamService.updateQueryParams({
                  play: entity.toString(),
                  version: selectedVersionId || m[m.length === 1 ? 0 : 1]["_id"]["$oid"],
                  "play-ctx": this.context,
                  "play-src": src,
                });
              } else {
                if (this.context === "plates") {
                  this.queryParamService.updateQueryParams({
                    play: entity.toString(),
                    version: selectedVersionId || m["_id"]["$oid"],
                    "play-ctx": this.context,
                    "play-src": src,
                  });
                  return [m as IMedia];
                } else if (this.context === "assets") {
                  this.queryParamService.updateQueryParams({
                    play: `${entity.group}_${entity.toString()}`,
                    version: selectedVersionId || m[0]["_id"]["$oid"],
                    "play-ctx": this.context,
                    "play-src": src,
                  });
                } else {
                  if (m.length > 0) {
                    this.queryParamService.updateQueryParams({
                      play: entity.toString(),
                      version: selectedVersionId || m[0]["_id"]["$oid"],
                      "play-ctx": this.context,
                      "play-src": src,
                    });
                  }
                }
              }
            }
            return m as IMedia[];
          })
        ),
      src: src,
      entity: entity,
      compare: "",
      audio: "left",
      selectedVersion: selectedVersionId || "",
      defaultThumbnail: entity["default_thumbnail"],
      statuses: [],
    });
  }

  reload() {
    location.reload();
  }

  delete() {
    if (
      window.confirm(
        `Are you sure you want to delete ${this.entity.toString()}? This will delete the files, notes and database entry. This operation is not undoable.`
      )
    ) {
      this.entityService.deleteEntity(this.entity).subscribe(
        (_) => {
          alert(`${this.entity.toString()} was deleted.`);
          location.reload();
        },
        (error) => console.error(error)
      );
    }
  }

  downloadRenders() {
    const showProgress = (status: HttpProgress) => {
      if (this.status === "Failed") {
        this.progress = 0;
        this.status = "";
      } else {
        this.progress = status["progress"];
        this.status = status["status"];
      }
      this.cdr.markForCheck();
    };

    const hasRenders = (this.entity["media"] || []).length > 0;
    if (!hasRenders) {
      alert(`${this.entity.toString()} has no downloadable renders`);
      return;
    }

    const latestMedia = this.entity["media"][0];

    this.fileBrowserService
      .downloadRenders(this.entity as Entity, latestMedia["dept"], showProgress)
      .subscribe(
        (_data) => {
          this.status = "";
          this.progress = 100;

          const data = _data?.body;
          if (data === undefined || data === null) {
            return;
          }
          const blob = new Blob([data], { type: data.type });
          const url = window.URL.createObjectURL(blob);
          const location = document.createElement("a");

          location.download = `${this.entity.projectCode}_${this.entity.group}_${this.entity.entityCode}_${latestMedia.dept}_renders.zip`;
          location.href = url;
          location.dispatchEvent(new MouseEvent("click"));

          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          }, 1000);

          this.cdr.markForCheck();
        },
        (_) => {
          this.status = "";
          this.progress = 0;
          this.cdr.markForCheck();
        }
      );
  }
}
