import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UntypedFormArray, UntypedFormControl } from "@angular/forms";

import { Observable, of, forkJoin, Subject, zip } from "rxjs";
import { map, tap } from "rxjs/operators";

import { EntityService } from "../entity.service";
import { TableConfigColumnItem } from "../../types/table-config";
import { NoteService } from "../note.service";
import { Entity, Context, IEntity, IStatus, IMedia } from "../entity";
import { QueryParamService } from "../query-param.service";
import { User } from "../user";
import {
  validateInput,
  difference,
  findStatusColor,
  flattenAssemblyLeicaFields,
  compareUploadDate,
} from "../utils";
import { TypeaheadItemsPipe } from "../utils.pipe";
import { Batch } from "../farm-status/farm-status.component";
import { Note } from "../notes";

@Component({
  selector: "[app-entity-table-view]",
  templateUrl: "./entity-table-view.component.html",
  styleUrls: ["./entity-table-view.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityTableViewComponent implements OnInit, OnChanges {
  @Input() allowUploadInFileBrowser: boolean;
  @Input() columns: TableConfigColumnItem[];
  @Input() statuses: IStatus[];
  @Input() users: { [key: string]: User[] };
  @Input() usersInProject: User[];
  @Input() _entity: IEntity;
  @Input() isIntersecting: boolean;
  @Input() showStatusColors: boolean;
  @Input() __updates?: { entity: Entity };
  @Input() inShotsByEpisodePage: boolean;

  @Output() toggledOOP = new EventEmitter<{ isOOP: boolean; children: number }>();
  @Output() toggledOOP2 = new EventEmitter<{ field: string; value: string }>();
  @Output() updatedEntity = new EventEmitter<{ entity: { toString(): string } }>();

  entityNotes$: Observable<[IEntity, Note[] | null]>;
  entityNotes: { entity: Entity; notes: Note[] };
  mediaCount$: Observable<number>;
  updatedNote$ = new Subject<Note>();

  projectCode: string;
  context: Context;

  editMode: string = "";
  openUpload: boolean;
  uploadDirectory: string;
  openFileBrowser: boolean;

  validateInput = validateInput;
  hasInvalidInput: boolean;
  fieldNameOfInvalidInput: string;

  offsetForm: { array: UntypedFormArray; title: string };
  sameAsCameraChildren$: Observable<IEntity[]>;
  showOffsetForm: boolean;

  findStatusColor = findStatusColor;

  constructor(
    private entityService: EntityService,
    private activatedRoute: ActivatedRoute,
    private noteService: NoteService,
    private queryParamService: QueryParamService,
    private cdr: ChangeDetectorRef,
    public typeaheadItemsPipe: TypeaheadItemsPipe
  ) {
    const parentURL = this.activatedRoute.parent?.snapshot.url;
    const urlSegments = this.activatedRoute.snapshot.url;
    this.projectCode = parentURL ? parentURL[1].path : "";
    this.context = urlSegments[0].path as Context;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["_entity"] && !changes["_entity"].firstChange && this.entityNotes !== undefined) {
      this.entityNotes.entity["out_of_picture"] = changes["_entity"].currentValue["out_of_picture"];
    }

    if ("__updates" in changes) {
      this.ngOnInit();
    }
  }

  ngOnInit() {
    const entity = {
      entityCode:
        this._entity["entityCode"] ||
        this._entity["plate_code"] ||
        this._entity["shot_code"] ||
        this._entity["asset_code"],
      group:
        this._entity["group"] ||
        this._entity["episode_code"] ||
        this._entity["asset_type"] ||
        this._entity["drive"],
      projectCode: this.projectCode,
      context: this.context as Context,
    } as IEntity;

    const entity$ = this.entityService.findOne(
      this.projectCode,
      this.context as Context,
      entity.group,
      entity.entityCode,
      "exclude_fields=media,brand_info,job_ids&latest_media=1"
    );
    const notes$ = entity.context !== "plates" ? this.noteService.getNotes(entity) : of([]);
    this.entityNotes$ = forkJoin([entity$, notes$]).pipe(
      tap((values) => {
        this.entityNotes = {
          entity: new Entity({
            ...values[0],
            projectCode: this.projectCode,
            context: this.context as Context,
          } as IEntity),
          notes: values[1] || [],
        };
      })
    );
  }

  onSave(
    entity: Entity,
    field: string,
    value: any,
    validItems$: Observable<Array<any>> | Array<any> = []
  ) {
    if (!this.editMode) {
      return;
    }

    if (!entity) {
      return;
    }

    if (validItems$ instanceof Observable) {
      validItems$.subscribe((validItems) => {
        const sub = this.save(entity, field, validItems)(value);
        if (!sub) {
          return;
        }

        sub.subscribe();
      });
    } else {
      const sub = this.save(entity, field, validItems$)(value);
      if (!sub) {
        return;
      }

      sub.subscribe();
    }
  }

  save(entity: Entity, field: string, validItems: Array<any> = []) {
    return (value: any) => {
      if (field.endsWith("_assigned") && value === "-- unassigned --") {
        value = "";
      }

      if (entity?.plate?.is_locked && ["plate.plate_code", "plate.frame_range"].includes(field)) {
        alert("Locked plate: Cannot change the plate code or frame range for this shot's plate.");
        this.editMode = "";
        return;
      }

      let _value = [];
      if (field === "children" && this.context === "plates") {
        for (let element of value as string[]) {
          const plate = validItems.find((item) => item["plate_code"] === element);
          _value.push(plate);
        }

        validItems = validItems.map((item) => item["plate_code"]);
      }

      const validInput = this.validateInput(value, field, validItems);
      if (!validInput.isValid) {
        this.hasInvalidInput = true;
        this.fieldNameOfInvalidInput = validInput.fieldName;
        this.editMode = "";
        this.cdr.markForCheck();
        return;
      }

      if (field === "children" && this.context === "plates" && _value.length > 0) {
        value = [..._value];
      }

      const json = { [field]: value };
      const updateChildren = field === "out_of_picture" && this.context === "plates";

      // if user sets the same_as_camera
      if (field === "same_as_camera" && (value as string).trim() !== "") {
        this.entityService
          .findOne(
            this.projectCode,
            this.context,
            (value as string).split("_")[0],
            (value as string).split("_")[1],
            "plate"
          )
          .subscribe((sameAsCameraShot) => {
            // if they are not the same plate, ask user if they want to change the plate
            const isSamePlate =
              (entity["plate"] || {})["plate_code"] ===
              (sameAsCameraShot["plate"] || {})["plate_code"];
            if (!isSamePlate) {
              this.sameAsCameraChildren$ = of([
                { ...entity, plate: sameAsCameraShot["plate"] },
              ]).pipe(
                tap((children: IEntity[]) =>
                  this.addOffsetForm(
                    entity,
                    children,
                    `Would you like to assign ${
                      sameAsCameraShot["plate"]["plate_code"]
                    } to this shot ${entity.toString()}?`
                  )
                )
              );
            }
          });
      }

      let before: string[] | string;
      if (
        ["same_as_camera_children", "lgt_children", "cmp_children"].includes(field) ||
        (this.context === "plates" && field === "shot_code")
      ) {
        before = [...(entity[field] || [])];
      } else if (["same_as_camera", "lgt_parent", "cmp_parent"].includes(field)) {
        before = (entity[field] || "").slice(0);
      }

      return this.entityService.updateOne(entity, json, updateChildren).pipe(
        tap(
          (result) => {
            this.editMode = "";

            // Updating Plate's shot_code
            if (entity.context === "plates" && field === "shot_code") {
              result.forEach((element: any) => {
                if (element["plate_code"] === entity.entityCode) {
                  entity["shot_code"] = element["shot_code"];
                } else {
                  this.updatedEntity.emit({
                    entity: {
                      toString: () => {
                        return element["plate_code"];
                      },
                    },
                  });
                }
              });
            } else {
              const keys = Object.keys(result);

              if (keys.length === 0) {
                entity[field.split(".")[0]] = {};
              } else {
                entity = Object.assign(entity, result);
                if (["same_as_camera_children", "lgt_children", "cmp_children"].includes(field)) {
                  // added to list - ask to update plate of new shots
                  const added = Array.from(
                    difference(new Set(result[field] as string[]), new Set(before))
                  );

                  if (field === "same_as_camera_children" && added.length > 0) {
                    let entities$ = [];
                    for (const e of added as string[]) {
                      entities$.push(
                        this.entityService
                          .findOne(
                            this.projectCode,
                            this.context,
                            e.split("_")[0],
                            e.split("_")[1],
                            "frame_out"
                          )
                          .pipe(
                            map((shot) => {
                              return {
                                ...shot,
                                group: e.split("_")[0],
                                entityCode: e.split("_")[1],
                              };
                            })
                          )
                      );
                    }
                    zip(...entities$).subscribe((v) => {
                      this.sameAsCameraChildren$ = of(v).pipe(
                        tap((children: IEntity[]) =>
                          this.addOffsetForm(
                            entity,
                            children,
                            `Would you like to assign ${entity["plate"]["plate_code"]} to these shots?`
                          )
                        )
                      );
                      this.cdr.markForCheck();
                    });
                  } else {
                    added.forEach((e) =>
                      this.updatedEntity.emit({
                        entity: {
                          toString: () => {
                            return e;
                          },
                        },
                      })
                    );
                  }

                  // removed from list - do not update plate of removed shot
                  difference(new Set(before), new Set(result[field] as string[])).forEach((e) => {
                    this.updatedEntity.emit({
                      entity: {
                        toString: () => {
                          return e;
                        },
                      },
                    });
                  });
                } else if (["same_as_camera", "lgt_parent", "cmp_parent"].includes(field)) {
                  this.updatedEntity.emit({
                    entity: {
                      toString: () => {
                        return before as string;
                      },
                    },
                  });
                  this.updatedEntity.emit({
                    entity: {
                      toString: () => {
                        return result[field];
                      },
                    },
                  });
                }
              }
            }

            if (field === "out_of_picture") {
              this.toggledOOP.emit({ isOOP: value, children: (entity["children"] || []).length });
            } else if (
              ["shot_status", "art_status", "mdl_status", "rig_status", "srf_status"].includes(
                field
              )
            ) {
              this.toggledOOP2.emit({ field: field, value: value });
            }

            // if user assigns a plate to a shot with same_as_camera_children
            if (
              (field === "plate.plate_code" || field === "plate.frame_range") &&
              (entity["same_as_camera_children"] || []).length > 0
            ) {
              this.sameAsCameraChildren$ = this.entityService
                .filterEntities(
                  this.projectCode,
                  this.context,
                  `same_as_camera=is:${entity.toString()}`,
                  `episode_code,shot_code,frame_in,frame_out,plate.plate_code`
                )
                .pipe(
                  tap((children: IEntity[]) => {
                    const title =
                      field === "plate.plate_code"
                        ? `Would you like to assign ${entity["plate"]["plate_code"]} to these children shots?`
                        : `Parent camera frame range changed. Would you like to update the frame range of the children shots?`;
                    this.addOffsetForm(entity, children, title);
                  })
                );
            }

            if (field === "spp.is_full_cg") {
              this.updatedEntity.emit({ entity: entity });
            }

            this.cdr.markForCheck();
          },
          /*error handler*/ () => (this.editMode = "")
        )
      );
    };
  }

  getBatches(dept: string): { [batchType: string]: Batch } {
    /**
     * Get the batches recorded in the database for the given department.
     */
    if (this.entityNotes.entity.jobs2 && this.entityNotes.entity.jobs2[dept]) {
      // console.log("this._entity.jobs2[dept] found");
      // console.log(this.entityNotes.entity.jobs2[dept]);
      return this.entityNotes.entity.jobs2[dept];
    } else {
      return {}; // return an empty object if there are no batches
    }
  }

  getSrc($event: IMedia): "MEDIA" | "CATALOG" | "assembly" | "leica" | "stages" | "storyboards" {
    if ($event.res === "assembly" && this.context === "scripts") {
      return "assembly";
    }
    if ($event.pdf_file?.file_path && $event.pdf_file?.file_path.includes("/scripts/")) {
      return "stages";
    }
    if ($event.pdf_file?.file_path && $event.pdf_file?.file_path.includes("/storyboards/")) {
      return "storyboards";
    }
    if ($event.movie_file?.file_path) {
      return "leica";
    }
    return "MEDIA";
  }

  play(
    entity: Entity,
    notes: Note[],
    src: "MEDIA" | "CATALOG" | "assembly" | "leica" | "stages" | "storyboards",
    selectedVersionId?: string
  ) {
    this.entityService.playMedia.next({
      notes: notes,
      media: this.entityService
        .findOne(
          this.projectCode,
          this.context as Context,
          entity.group,
          entity.entityCode,
          "fields=media,assembly,leica,catalog,plate,stages,storyboards&exclude_fields=_id"
        )
        .pipe(
          map((media) => {
            let m;
            if (
              src === "assembly" ||
              src === "leica" ||
              src === "stages" ||
              src === "storyboards"
            ) {
              m = flattenAssemblyLeicaFields(media, src);
              m.sort(compareUploadDate);
            } else {
              m = media[src.toLowerCase()] || [];
            }

            let sppMedia;
            try {
              sppMedia = entity["spp"]["plate"]["media"];
            } catch {}

            let plateMedia;
            try {
              plateMedia = entity["plate"]["media"];
            } catch {}

            if (m) {
              //play=e020_s0030&version=5d4307df0d17bf2e64dfbd2c&play-ctx=shots&play-src=MEDIA
              if (sppMedia && src === "MEDIA") {
                // inject the entity's spp media into the entity's media. do not inject it into the catalog
                m.splice(0, 0, sppMedia);
              }

              if (plateMedia && src === "MEDIA") {
                // inject the entity's plate media into the entity's media. do not inject it into the catalog
                m.splice(0, 0, plateMedia);
              }

              if (this.context === "plates") {
                this.queryParamService.updateQueryParams({
                  play: entity.toString(),
                  version: selectedVersionId || m["_id"]["$oid"],
                  "play-ctx": this.context,
                  "play-src": src,
                });
                return [m];
              }

              if (this.context === "assets") {
                this.queryParamService.updateQueryParams({
                  play: `${entity.group}_${entity.toString()}`,
                  version: selectedVersionId || m[0]["_id"]["$oid"],
                  "play-ctx": this.context,
                  "play-src": src,
                });
                return m;
              }

              if (m.length > 0) {
                let i = 0;
                if (m.length === 1) {
                  i = 0;
                } else if (plateMedia && sppMedia) {
                  i = 2;
                } else if (plateMedia) {
                  i = 1;
                } else if (sppMedia) {
                  i = 1;
                }

                this.queryParamService.updateQueryParams({
                  play: entity.toString(),
                  version: selectedVersionId || m[i]["_id"]["$oid"],
                  "play-ctx": this.context,
                  "play-src": src,
                });
              }
            }
            return m;
          })
        ),
      src: src,
      audio: "left",
      entity: entity,
      compare: "",
      selectedVersion: selectedVersionId || "",
      defaultThumbnail: entity["default_thumbnail"],
    });
  }

  assignPlate(entity: Entity) {
    this.offsetForm.array.value.forEach(
      (value: { entity: Entity; offset: number; startFrame: number; maxOffset: number }) => {
        if (entity.toString() !== (value["entity"] as Entity).toString()) {
          value["entity"]["plate"] = { ...entity["plate"] };
        }

        this.entityService
          .updateOne(
            value["entity"] as Entity,
            this.updatePlateFrameRange(value["entity"] as Entity, value["offset"])
          )
          .subscribe((_) => {
            this.updatedEntity.emit({ entity: value["entity"] as Entity });
          });
      }
    );

    this.showOffsetForm = false;
    this.sameAsCameraChildren$ = of([]);
  }

  cancelAssignPlate() {
    this.offsetForm.array.value.forEach(
      (value: { entity: Entity; offset: number; startFrame: number; maxOffset: number }) => {
        this.updatedEntity.emit({ entity: value["entity"] as Entity });
      }
    );

    this.showOffsetForm = false;
    this.sameAsCameraChildren$ = of([]);
  }

  updatePlateFrameRange(entity: Entity, offset: number) {
    let plate = { plate: { ...entity["plate"] } };
    const regexp = /[0-9]+-[0-9]+/;
    let frameOut = parseInt(entity["frame_out"] || 0);

    if (plate["plate"]["frame_range"] && regexp.test(plate["plate"]["frame_range"]) && frameOut) {
      const startFrame = parseInt(plate["plate"]["frame_range"].split("-")[0]);
      const endFrame = parseInt(plate["plate"]["frame_range"].split("-")[1]);
      if (endFrame - (startFrame + offset) > 2) {
        plate["plate"]["frame_range"] = `${startFrame + offset}-${
          startFrame + offset + frameOut - 1
        }`;
      }
    } else {
      plate["plate"]["frame_range"] = "";
    }

    return plate;
  }

  addOffsetForm(entity: Entity, children: IEntity[], title: string) {
    this.offsetForm = { array: new UntypedFormArray([]), title: title };

    for (const child of children) {
      const e = new Entity({
        ...child,
        context: this.context,
        projectCode: this.projectCode,
      } as IEntity);

      let startFrame = 0;
      let endFrame = 0;

      if (entity.toString() !== e.toString()) {
        startFrame = parseInt(entity["plate"]["frame_range"].split("-")[0] || 0);
        endFrame = parseInt(entity["plate"]["frame_range"].split("-")[1] || 0);
      } else {
        startFrame = parseInt(e["plate"]["frame_range"].split("-")[0] || 0);
        endFrame = parseInt(e["plate"]["frame_range"].split("-")[1] || 0);
      }

      if (
        title.includes("frame range") &&
        e["plate"]["plate_code"] !== entity["plate"]["plate_code"]
      ) {
        continue;
      }

      this.offsetForm.array.push(
        new UntypedFormControl({
          entity: e,
          offset: 0,
          startFrame: startFrame,
          maxOffset: endFrame - startFrame,
        })
      );
    }

    this.showOffsetForm = true;
    this.cdr.markForCheck();
  }

  getEventTargetAsHTMLInputElement($event: Event) {
    return $event.target as HTMLInputElement;
  }
}
