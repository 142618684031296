import { Component, OnInit, OnDestroy, ChangeDetectorRef, OnChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";

import { ISchedule, ScheduleService } from "../schedule.service";
import { UserToken } from "../user";
import { formatDateTime } from "../utils";

@Component({
  selector: "app-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.css"],
})
export class ScheduleComponent implements OnInit, OnChanges, OnDestroy {
  projectCode: string;
  currentUser: string;
  willShowAddSchedule: boolean;
  schedules: ISchedule[];
  subs: Subscription[] = [];
  initialized: boolean = false;
  openScheduleViewer: boolean;
  scheduleLink: string;
  toBeDeleted: { schedule_id?: string };

  editMode: { [index: number]: string } = {};
  willShowConfirmDelete: boolean;
  willShowBackdrop: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private scheduleService: ScheduleService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // TODO: Probably good to add default params or something here. Might help with storybook, testing etc.
    const parentParams = (this.activatedRoute.snapshot.parent as ActivatedRouteSnapshot).params;

    this.currentUser = UserToken.getUsername();
    this.projectCode = parentParams.projectCode;
    this.getSchedules();
  }

  ngOnChanges() {
    //Perform initialization steps that depend on valid input.
    //This can't be done in ngOnInit because sometimes the input is not ready yet.
    if (this.initialized == false && this.schedules) {
      this.initialized = true;
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  getSchedules() {
    this.subs.push(
      this.scheduleService.getAllSchedules(this.projectCode).subscribe((scheds) => {
        this.schedules = scheds;
        this.schedules.sort(this.compareDate);
        this.initialized = true;
        this.cdr.markForCheck();
      })
    );
  }

  add(scheduleName: string, scheduleLink: string) {
    if (scheduleName.trim() === "") {
      alert("Cannot add a schedule without a name.");
      return;
    }
    if (scheduleLink.trim() === "") {
      alert("Cannot add a schedule without link.");
      return;
    }

    let scheduleObj = {
      schedule_name: scheduleName,
      schedule_link: scheduleLink,
      created_by: this.currentUser,
    };
    this.scheduleService.addSchedule(this.projectCode, scheduleObj).subscribe((newSchedule) => {
      scheduleObj = { ...scheduleObj, ...newSchedule };
      this.schedules.splice(0, 0, newSchedule);
      this.willShowAddSchedule = false;
      this.onClose("Submitted");
    });
  }

  delete() {
    if (!this.toBeDeleted) {
      this.willShowConfirmDelete = false;
      this.willShowBackdrop = false;
      return;
    }

    if (this.toBeDeleted["schedule_id"]) {
      this.scheduleService
        .deleteSchedule(this.projectCode, this.toBeDeleted["schedule_id"])
        .subscribe(() => {
          setTimeout(() => {
            const selectedSchedule = this.schedules.find(
              (sched) => sched["_id"]["$oid"] === this.toBeDeleted["schedule_id"]
            );
            if (selectedSchedule) {
              const i = this.schedules.indexOf(selectedSchedule);
              this.schedules.splice(i, 1);
              this.toBeDeleted = {};
              this.willShowConfirmDelete = false;
              this.willShowBackdrop = false;
            }
          }, 500);
        });
    }
  }

  updateSchedule(
    newValue: string,
    scheduleId: string,
    field: "schedule_name" | "schedule_link" | "created_by",
    index: number
  ) {
    /**
     * Note: this will currently only update string valued fields of ISchedule.
     * Can be modified to support the datetime and _id fields.
     */
    if (this.editMode[index] !== field) {
      return;
    }
    let toBeUpdated: Partial<ISchedule> = {};
    toBeUpdated[field] = newValue;
    this.scheduleService.update(this.projectCode, scheduleId, toBeUpdated).subscribe(() => {
      this.schedules[index][field] = newValue;
      this.editMode[index] = "";
      this.cdr.markForCheck();
    });
  }

  startEditMode(index: number, value: string) {
    this.editMode[index] = value;
  }

  getDate(theSchedule: ISchedule): string {
    return formatDateTime(theSchedule["datetime"]["$date"]);
  }

  onClose($event: string | boolean) {
    if ($event === "Submitted" || $event === "ReloadOnClose") {
      location.reload();
    }
    this.openScheduleViewer = false;
    this.scheduleLink = "";
  }

  compareDate(obj1: ISchedule, obj2: ISchedule) {
    // TODO: This should be totally broken.
    let date1 = obj1["datetime"]["$date"];
    let date2 = obj2["datetime"]["$date"];

    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
  }
}
