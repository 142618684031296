<div *ngIf="project$ | async as project">
  <ul class="nav nav-tabs" role="tablist">
    <li class="nav-item">
      <a class="nav-link" id="project-name" role="tab" aria-selected="false">{{ project.name }}</a>
    </li>
    <li class="nav-item">
      <a
        [class.active]="isActive('bundles')"
        class="nav-link"
        id="bundles-tab"
        data-toggle="tab"
        [routerLink]="['./bundles']"
        role="tab"
        aria-controls="bundles"
        aria-selected="false"
        >Bundles</a
      >
    </li>
    <li class="nav-item">
      <a
        [class.active]="isActive('episodes')"
        class="nav-link"
        id="episodes-tab"
        data-toggle="tab"
        [routerLink]="['./episodes']"
        role="tab"
        aria-controls="episodes"
        aria-selected="false"
        >Episodes</a
      >
    </li>
    <li *ngIf="!shouldDefaultRedirectToNewUI" class="nav-item">
      <a
        [class.active]="isActive('shots')"
        class="nav-link"
        id="shots-tab"
        data-toggle="tab"
        [routerLink]="['./shots']"
        role="tab"
        aria-controls="shots"
        aria-selected="false"
        >Shots</a>
    </li>
    <ng-container *ngIf="shouldDefaultRedirectToNewUI">

    </ng-container>
    <li *ngIf="shouldDefaultRedirectToNewUI" class="nav-item">
      <a
        [class.active]="isActive('shots')"
        class="nav-link"
        id="shots-tab"
        href="{{cuiDomain}}/projects/{{project.code}}/shots"
        role="tab"
        aria-controls="shots"
        aria-selected="false">Shots</a>
    </li>
    <li *ngIf="!shouldDefaultRedirectToNewUI" class="nav-item">
      <a
        [class.active]="isActive('assets')"
        class="nav-link"
        id="assets-tab"
        data-toggle="tab"
        [routerLink]="['./assets']"
        role="tab"
        aria-controls="assets"
        aria-selected="false"
        >Assets</a>
    </li>
    <li *ngIf="shouldDefaultRedirectToNewUI" class="nav-item">
      <a
        [class.active]="isActive('assets')"
        class="nav-link"
        id="assets-tab"
        href="{{cuiDomain}}/projects/{{project.code}}/assets"
        role="tab"
        aria-controls="assets"
        aria-selected="false"
      >Assets</a>
    </li>
    <li class="nav-item">
      <a
        [class.active]="isActive('plates')"
        class="nav-link"
        id="plates-tab"
        data-toggle="tab"
        [routerLink]="['./plates']"
        role="tab"
        aria-controls="plates"
        aria-selected="false"
        >Plates</a
      >
    </li>
    <li class="nav-item">
      <a
        [class.active]="isActive('scripts')"
        class="nav-link"
        id="story-tab"
        data-toggle="tab"
        [routerLink]="['./scripts']"
        role="tab"
        aria-controls="story"
        aria-selected="false"
        >Story</a
      >
    </li>
    <li class="nav-item">
      <a
        [class.active]="isActive('hdri')"
        class="nav-link"
        id="hdri-tab"
        data-toggle="tab"
        [routerLink]="['./hdri']"
        role="tab"
        aria-controls="hdri"
        aria-selected="false"
        >HDRI</a
      >
    </li>
    <li class="nav-item">
      <a
        [class.active]="isActive('details')"
        class="nav-link"
        id="details-tab"
        data-toggle="tab"
        [routerLink]="['./details']"
        role="tab"
        aria-controls="details"
        aria-selected="false"
        >Details</a
      >
    </li>
    <li class="nav-item">
      <a
        [class.active]="isActive('schedule')"
        class="nav-link"
        id="schedule-tab"
        data-toggle="tab"
        [routerLink]="['./schedule']"
        role="tab"
        aria-controls="schedule"
        aria-selected="false"
        >Schedule</a
      >
    </li>
    <li class="nav-item">
      <a
        [class.active]="isActive('meeting')"
        class="nav-link"
        id="meeting-tab"
        data-toggle="tab"
        [routerLink]="['./meeting']"
        role="tab"
        aria-controls="meeting"
        aria-selected="false"
        >Meeting Notes</a
      >
    </li>
    <li class="nav-item">
      <a
        [class.active]="isActive('vault-logs')"
        class="nav-link"
        id="vault-logs-tab"
        data-toggle="tab"
        [routerLink]="['./vault-logs']"
        role="tab"
        aria-controls="vault-logs"
        aria-selected="false"
        >Vault Logs</a
      >
    </li>
    <li class="nav-item">
      <a
        [class.active]="isActive('documentation-links')"
        class="nav-link"
        id="vault-logs-tab"
        data-toggle="tab"
        [routerLink]="['./documentation-links']"
        role="tab"
        aria-controls="documentation-links"
        aria-selected="false"
        >Documentation Links</a>
    </li>
    <div *ngIf="showNewUILinkButton(project)" class="d-flex flex-grow-1 justify-content-end align-items-center">
      <a class="btn btn-primary mr-1" (click)="setNewUILocalStorage()" [href]="linkToNewUI(project.code)">New UI</a>
    </div>
  </ul>
  <div
    class="tab-pane active show fade"
    id="dynamic-tab"
    role="tabpanel"
    aria-labelledby="dynamic-tab">
    <router-outlet></router-outlet>
  </div>
</div>
