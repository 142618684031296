import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";

import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

import { Entity, MediaType } from "../entity";
import { EntityService, IVaultedInfo } from "../entity.service";

@Component({
  selector: "app-vault-logs",
  templateUrl: "./vault-logs.component.html",
  styleUrls: ["./vault-logs.component.css"],
})
export class VaultLogsComponent implements OnInit {
  @ViewChild("startDateRef", { static: true }) startDateRef: ElementRef<HTMLInputElement>;
  @ViewChild("endDateRef", { static: true }) endDateRef: ElementRef<HTMLInputElement>;
  @ViewChild("is24hrRange", { static: true }) is24HrRange: ElementRef<HTMLInputElement>;

  startDate: Date;
  endDate: Date;
  now: Date;
  log: string;
  vaultedInfo$: Observable<IVaultedInfo>;
  projectCode: string;
  showFetchingData: boolean = false;

  constructor(
    private datePipe: DatePipe,
    private entityService: EntityService,
    private activatedRoute: ActivatedRoute
  ) {
    const parentURL = this.activatedRoute.parent!.snapshot.url;
    this.projectCode = parentURL[1].path;
  }

  ngOnInit() {
    this.now = new Date();
    this.startDate = new Date(this.now);
    this.startDate.setHours(0, 0);
    this.endDate = new Date(this.now);
    this.endDate.setDate(this.endDate.getDate() + 1);
    this.endDate.setHours(0, 0);

    const newMinForEndDate = new Date(this.endDate);
    newMinForEndDate.setMinutes(newMinForEndDate.getMinutes() + 1);
    this.endDateRef.nativeElement.min = this.datePipe.transform(
      newMinForEndDate,
      "yyyy-MM-ddTHH:mm"
    ) as string;
  }

  onChangeStartDate($event: Event) {
    this.startDate = new Date(($event.target as HTMLInputElement).value);
    const newMinForEndDate = new Date(this.startDate);
    newMinForEndDate.setMinutes(newMinForEndDate.getMinutes() + 1);
    this.endDateRef.nativeElement.min = this.datePipe.transform(
      newMinForEndDate,
      "yyyy-MM-ddTHH:mm"
    ) as string;

    if (this.is24HrRange.nativeElement.checked) {
      this.endDate = new Date(($event.target as HTMLInputElement).value);
      this.endDate.setHours(this.endDate.getHours() + 24);
    }
  }

  onChangeEndDate($event: Event) {
    this.endDate = new Date(($event.target as HTMLInputElement).value);
  }

  getVaultedAssets() {
    this.showFetchingData = true;
    this.vaultedInfo$ = this.entityService
      .getVaultedInfo(
        this.projectCode,
        new Date(this.startDateRef.nativeElement.value).toISOString(),
        new Date(this.endDateRef.nativeElement.value).toISOString()
      )
      .pipe(finalize(() => (this.showFetchingData = false)));
  }

  getThumbnailURL(assetCode: string) {
    const entity = new Entity({
      projectCode: this.projectCode,
      context: "assets",
      group: assetCode.split("_")[0],
      entityCode: assetCode.split("_")[1],
    });
    return this.entityService.getMediaURL(entity, MediaType.THUMBNAIL);
  }

  onToggle24hrRange($event: Event) {
    this.endDateRef.nativeElement.disabled = ($event.target as HTMLInputElement).checked;
  }
}
