import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from "@angular/core";

import { Observable, Subscription, fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, filter } from "rxjs/operators";

import { DropdownArrowSelect } from "../dropdown-arrow-select";
import { applyMixins } from "../utils";

// NOTE: string fulfills TypeaheadItem, so you can use string with this component. See Docstring on class.
type TypeaheadItem = {
  toString: () => string;
  class?: string;
};

@Component({
  selector: "app-typeahead",
  templateUrl: "./typeahead.component.html",
  styleUrls: ["./typeahead.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TypeaheadComponent
  implements OnInit, DropdownArrowSelect, OnDestroy, OnChanges, AfterViewInit
{
  /**
   * This class works on an array of any object that implements the toString() method. The string type implements
   * toString() so an array of strings works fine. Entity also implements toString()
   * */

  @Input() items$: string[] | TypeaheadItem[] | Observable<string[] | TypeaheadItem[]>;
  @Input() idString: string;
  @Input() callback?: Function;
  @Input() labelString?: string = "";
  @Input() preDeterminedValue?: string;
  @Input() isAutofocused?: boolean;
  @Input() showDropdownWhenAutofocused: boolean = true;
  @Input() isSingleSelection: boolean = false;
  @Input() minWidth?: string = "100";
  @Input() placeholder?: string = "";
  @Input() applyBorderWarningOnBlur?: boolean = true; // todo: this is totally unused. Delete and remove from calls.
  @Input() emitStringArrayAsString: boolean = false;
  @Input() emitOnBlur?: boolean = false; // useful to capture the users text input when user clicks outside the dropdown
  @Input() customToString?: (item: any) => string;

  @ViewChild("textbox", { static: true }) textbox: ElementRef<HTMLInputElement>;

  @Output() added = new EventEmitter<string | string[] | undefined>();

  // DropdownArrowSelect mixin
  arrowSelect: (i: number, choices: number) => number;
  dropdown: ElementRef;
  @ViewChild("dropdown") set setDropdown(ref: ElementRef) {
    this.dropdown = ref;
  }
  focusedItem: number = -1;
  step: number = 1;
  maxItemsShown = 10;
  liHeight = 27; // or this.dropdown.nativeElement.children[0].children[0].offsetHeight;
  maxHeight = this.liHeight * this.maxItemsShown; // or this.dropdown.nativeElement.offsetHeight;
  currentScroll = 0;

  showDropdown: boolean;
  inputString = "";
  filteredItems: TypeaheadItem[] = [];
  items: TypeaheadItem[] = [];
  subs: Subscription[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["items$"] && !changes["items$"].firstChange) {
      this.ngOnInit();
    }

    if (
      changes["preDeterminedValue"] &&
      changes["preDeterminedValue"].currentValue !== changes["preDeterminedValue"].previousValue
    ) {
      this.inputString = this.preDeterminedValue ?? this.inputString; // TODO: when fixing type error, not sure if this should be ?? this.inputString or ?? ""
    }
  }

  ngAfterViewInit() {
    this.subs.push(
      fromEvent<KeyboardEvent>(this.textbox.nativeElement, "keyup")
        .pipe(
          filter(
            (event: KeyboardEvent) =>
              !["ArrowDown", "ArrowUp", "", "Enter", "Escape", "AltLeft", "AltRight"].includes(
                event.code
              )
          ),
          debounceTime(200),
          distinctUntilChanged()
        )
        .subscribe((event) => this.search(this.textbox.nativeElement.value, event))
    );
  }

  ngOnInit() {
    if (this.items$ instanceof Observable) {
      this.subs.push(
        this.items$.subscribe((items) => {
          this.items = items;
          this.showDropdown = this.showDropdownWhenAutofocused;
          this.cdr.markForCheck();
        })
      );
    } else {
      this.items = this.items$;
      this.showDropdown = this.showDropdownWhenAutofocused;
    }

    if (this.preDeterminedValue && this.preDeterminedValue.length > 0) {
      this.inputString = this.preDeterminedValue;
    }

    if (this.isAutofocused) {
      setTimeout(() => this.textbox.nativeElement.focus(), 0);
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  add(item: string) {
    if (this.isSingleSelection) {
      this.inputString = item;
      this.added.emit(this.inputString);
      return;
    }

    if (
      this.inputString
        .trim()
        .split(/[+,]/g)
        .map((t) => t.trim())
        .includes(item)
    ) {
      return; // do not add since text is a duplicate
    }

    if (this.textbox.nativeElement.selectionStart === null) {
      return;
    }

    if (
      this.textbox.nativeElement.selectionStart === this.inputString.length &&
      (this.inputString.trim().endsWith("+") || this.inputString.trim().endsWith(","))
    ) {
      this.inputString = this.inputString.trim().concat(` ${item}`);
    } else {
      // find the word
      const p = this.findDelimeter(
        this.inputString,
        this.textbox.nativeElement.selectionStart - 1,
        false
      );
      const n = this.findDelimeter(this.inputString, this.textbox.nativeElement.selectionStart);
      const wordToReplace = this.inputString.slice(p + 1, n).trim();
      if (wordToReplace) {
        this.inputString =
          this.inputString.slice(0, p + 1) +
          " " +
          item +
          this.inputString.slice(n, this.inputString.length);
      } else {
        this.inputString =
          this.inputString.slice(0, p + 1) + " " + item + this.inputString.slice(n);
      }
      this.inputString = this.inputString.trim();
    }
  }

  onBlur2() {
    this.filteredItems = [];
    this.showDropdown = false;

    this.added.emit(undefined); // signify to the caller we dont intend to use the value

    this.textbox.nativeElement.blur();
    this.cdr.markForCheck();
  }

  onBlur($event: Event) {
    $event.preventDefault();
    // user typed in text and did not select from dropdown
    if (this.emitOnBlur && this.focusedItem === -1) {
      if (this.isSingleSelection || this.emitStringArrayAsString) {
        this.added.emit(this.textbox.nativeElement.value);
      } else {
        this.added.emit(
          this.textbox.nativeElement.value
            .split(/[+,]/g)
            .filter((s) => s !== "")
            .map((s) => s.trim())
        );
      }
    }

    if (!this.showDropdown) {
      // pressing escape will trigger this as well
      // no need to process anything at this point
      // if (this.applyBorderWarningOnBlur && this.focusedItem === -1) {
      //   this.textbox.nativeElement.classList.add('border-blink');
      //   this.saveMsg.nativeElement.classList.remove('d-none');
      // }
      return;
    }

    const items = this.filteredItems.length > 0 ? this.filteredItems : this.items;
    if (items[this.focusedItem]) {
      if (typeof items[this.focusedItem] === "object") {
        this.add(items[this.focusedItem].toString());
      } else {
        this.add(items[this.focusedItem].toString());
      }
      this.isAutofocused = true;
      setTimeout(() => this.textbox.nativeElement.focus(), 0);
    } else {
      //if (this.applyBorderWarningOnBlur) {
      //  this.textbox.nativeElement.classList.add('border-blink');
      //  this.saveMsg.nativeElement.classList.remove('d-none');
      //}
    }

    this.showDropdown = false;
    this.focusedItem = -1;
    this.filteredItems = [];
  }

  findDelimeter(text: string, position: number, forward = true) {
    while (
      text[position] !== "," &&
      text[position] !== "+" &&
      position < text.length &&
      position >= 0
    ) {
      if (forward) {
        position++;
      } else {
        position--;
      }
    }
    return position;
  }

  onKeyDown(event: KeyboardEvent) {
    if (
      (event.code === "ArrowDown" || event.code === "ArrowUp" || event.key === "Alt") &&
      event.type === "keydown"
    ) {
      event.preventDefault();
      return;
    }

    this.maxHeight = this.liHeight * this.maxItemsShown;
  }

  onKeyEnter(text: string) {
    if (this.isSingleSelection) {
      if (this.focusedItem !== -1) {
        if (typeof this.items[this.focusedItem] === "object") {
          this.add(
            this.filteredItems.length > 0
              ? this.filteredItems[this.focusedItem].toString()
              : this.items[this.focusedItem].toString()
          );
        } else {
          this.add(
            this.filteredItems.length > 0
              ? this.filteredItems[this.focusedItem].toString()
              : this.items[this.focusedItem].toString()
          );
        }
      } else {
        this.add(text);
      }
    } else {
      if (this.focusedItem !== -1) {
        if (typeof this.items[this.focusedItem] === "object") {
          this.add(
            this.filteredItems.length > 0
              ? this.filteredItems[this.focusedItem].toString()
              : this.items[this.focusedItem].toString()
          );
        } else {
          this.add(
            this.filteredItems.length > 0
              ? (this.filteredItems[this.focusedItem] as string)
              : (this.items[this.focusedItem] as string)
          );
        }
      } else {
        if (this.emitStringArrayAsString) {
          this.added.emit(this.inputString);
        } else {
          this.added.emit(
            this.inputString
              .split(/[+,]/g)
              .filter((s) => s !== "")
              .map((s) => s.trim())
          );
        }
      }
    }

    this.showDropdown = false;
    this.focusedItem = -1;
    this.step = 1;
    if (this.dropdown) {
      this.dropdown.nativeElement.scrollTop = 0;
    }
    return;
  }

  search(text: string, event: KeyboardEvent) {
    this.showDropdown = true;
    this.filteredItems = [];
    this.focusedItem = -1;
    this.step = 1;
    if (this.dropdown) {
      this.dropdown.nativeElement.scrollTop = 0;
    }

    if (this.isSingleSelection) {
      if (event.key === "Backspace" && text.length == 0) {
        this.cdr.markForCheck();
        return;
      }
    } else {
      // find xxxx: abc,xxxx|,def so we can filter using this text instead of the whole text
      //               ^     ^
      //               |     |
      //               p     n
      // @ts-ignore
      let p = this.findDelimeter(text, this.textbox.nativeElement.selectionStart - 1, false);
      // @ts-ignore
      let n = this.findDelimeter(text, this.textbox.nativeElement.selectionStart - 1);
      text = text.slice(p + 1, n).trim();

      if (text.endsWith(",") || text.endsWith("+")) {
        // @ts-ignore
        p = this.findDelimeter(text, this.textbox.nativeElement.selectionStart - 2, false);
        // @ts-ignore
        n = this.findDelimeter(text, this.textbox.nativeElement.selectionStart - 2);
        this.add(text.slice(p + 1, n));
      }

      if (text[text.length - 2] === "," || text[text.length - 2] === "+") {
        // if the previous char is ',' we strip out the chars until the current text input
        text = text.slice(0, text.length - 2);
      }
    }

    for (const item of this.items) {
      if (!item) {
        continue;
      }
      if (
        item.toString().toLowerCase().includes(text.toLowerCase()) &&
        !this.includes(item.toString(), this.filteredItems)
      ) {
        this.filteredItems.push(item);
      }
    }

    this.cdr.markForCheck();
  }

  includes(needle: TypeaheadItem | string, haystack: TypeaheadItem[] | string[]) {
    for (const item in haystack) {
      if (item.toString().toLowerCase() === needle.toString().toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  getStyle(item: TypeaheadItem): string {
    if (typeof item === "object" && "class" in item) {
      return item.class?.toString() || "";
    }
    return "";
  }
}
applyMixins(TypeaheadComponent, [DropdownArrowSelect]);
