import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";

import { TokenService } from "./token.service";

@Injectable({
  providedIn: "root",
})
export class TokenGuard implements CanActivate { // todo: fix this depricated token guard!
  constructor(private tokenService: TokenService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const subject = new Subject<boolean>();
    const urlParams = next.params;
    const token = urlParams["token"];

    this.tokenService.checkTokenValidity(token).subscribe(
      () => {
        subject.next(true);
      },
      (_) => {
        if (state.url.startsWith("/activate/")) {
          alert(
            "This link to activate your account has expired. " +
              "If you have not activated your account yet, please contact the tech department to receive a new link."
          );
        } else if (state.url.startsWith("/reset-password/")) {
          alert(
            "This link to reset your password has expired. Please obtain a new link and try again."
          );
        } else {
          alert("This link is not valid. Please check the url.");
        }
        this.router.navigate(["login"]);
        subject.next(false);
      }
    );

    return subject.asObservable();
  }
}
