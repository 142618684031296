<button type="submit" class="btn btn-sm btn-outline-primary mb-4" (click)="add()">
  Create New Bundle
</button>
<div class="mb-4" *ngFor="let bundle of bundles; index as i">
  <h5>Bundle #{{ bundle["bundle_code"] }}:</h5>
  <ng-container *ngIf="editMode !== bundle['bundle_code']">
    <span
      *ngFor="let episode of bundle['episodes'] || []"
      class="badge badge-pill badge-light border border-secondary mx-1"
      >{{ episodesToNiceNames[episode] }}</span
    >
  </ng-container>
  <span *ngIf="editMode !== bundle['bundle_code']" (click)="editMode = bundle['bundle_code']"
    ><i class="material-icons-outlined md-18 align-text-bottom hover-pointer">edit</i></span
  >
  <app-typeahead
    *ngIf="editMode === bundle['bundle_code']"
    [placeholder]="
      'Search and select from dropdown and separate each episode by a comma then press Enter to save'
    "
    [items$]="episodes"
    [preDeterminedValue]="transform(bundle['episodes'] || [])"
    [isSingleSelection]="false"
    (added)="updateBundleEpisodes(i, $event, bundle['bundle_code'])"
    [isAutofocused]="true">
  </app-typeahead>
  <hr />
</div>
