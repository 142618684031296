<div class="container-fluid">
  <div *ngIf="success" class="alert alert-success alert-dismissible" role="alert">
    <div class="text-center"><strong>Update summary:</strong></div>
    <div><strong>Users:</strong> {{ updatedUsers }}</div>
    <div><strong>Projects:</strong> {{ updatedProjects }}</div>
    <div><strong>Deptartments:</strong> {{ updatedDepts }}</div>
    <div><strong>Role:</strong> {{ updatedRoles }}</div>
    <div><strong>Account Status:</strong> {{ updatedAccountStatus }}</div>
    <button
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
      (click)="success = false">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="container select-all d-flex flex-row flex-wrap mb-2 text-center" *ngIf="users">
    <div class="w-100 align-self-center">
      <div class="d-inline-block m-2" style="width: 10.25rem">
        <app-typeahead
          #searchTypeahead
          [placeholder]="searchByUsername ? 'Search by username↵' : 'Search by full name↵'"
          [items$]="activeTypeAheadItems$"
          [isSingleSelection]="true"
          (added)="onUpdatedSearch($event)"
          [isAutofocused]="true"
          [showDropdownWhenAutofocused]="false"
          [applyBorderWarningOnBlur]="false">
        </app-typeahead>
      </div>
      <div class="d-inline-block">
        <input
          type="checkbox"
          name="search-username"
          id="search-username"
          [checked]="searchByUsername"
          (change)="searchByUsername = !searchByUsername; getSearchItems()" />
        <label class="mr-2" for="search-username">Search Username</label>
      </div>
      <app-filters
        [filters$]="filters$"
        [context]="context"
        [projectCode]="projectCode"
        (toggledFiltersOnOrOff)="onToggledFilters($event)"
        (filtered)="searchAndFilter()"></app-filters>
    </div>
  </div>

  <div *ngIf="hasAccess" class="container select-all d-flex flex-row flex-wrap mb-2 text-center">
    <div class="w-100 align-self-center">
      <input type="checkbox" id="select-all" [(ngModel)]="selectAll" />
      <label class="mr-2" for="select-all">Select all</label>
      <input type="checkbox" id="show-inactive-users" [(ngModel)]="showInactiveUsers" />
      <label class="mr-2" for="show-inactive-users">Show inactive users</label>
      <a routerLink="new" class="btn btn btn-outline-primary">Add user</a>&nbsp;
      <a routerLink="manageRole" class="btn btn btn-outline-primary">Manage Roles</a>
    </div>
  </div>
  <div *ngIf="filteredUsers && filteredUsers.length > 0; else placeholder">
    <ng-container *ngFor="let user of filteredUsers">
      <div class="card w-50" *ngIf="showInactiveUsers || user.is_active">
        <div class="card-body">
          <span *ngIf="hasAccess" class="check-beside-acct"
            ><input
              class="form-check-input user-selected"
              type="checkbox"
              [checked]="selectAll"
              [value]="user.username"
          /></span>
          <app-users
            [projects]="projects"
            [departments]="departments"
            [user]="user"
            [hasAccess]="hasAccess"
            [roles]="roles"
            (updatedUsersProjectsDepts)="confirmChanges($event)"
            (updatedOneUser)="confirmChanges($event)"></app-users>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-template #placeholder>
    <!-- this will show Fetching data after the headings are rendered -->
    <div class="card w-50">
      {{
        filteredUsers === undefined ? "Fetching data..." : "No users were found given the filters."
      }}
    </div>
  </ng-template>
</div>

<ng-container *ngIf="confirming">
  <div
    class="show d-block modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="confirm-changes"
    aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirm-changes">Confirm changes</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="confirming = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <strong>Users:</strong>
            {{ confirmChangesData["users"] ? confirmChangesData["users"].join(",  ") : "" }}
          </div>
          <div>
            <strong>Projects:</strong>
            {{
              confirmChangesData["projectsNiceNames"]
                ? confirmChangesData["projectsNiceNames"].join(",  ")
                : ""
            }}
          </div>
          <div>
            <strong>Deptartments:</strong>
            {{ confirmChangesData["depts"] ? confirmChangesData["depts"].join(",  ") : "" }}
          </div>
          <div>
            <strong>Role:</strong>
            {{ confirmChangesData["roles"] ? confirmChangesData["roles"] : "" }}
          </div>
          <div>
            <strong>Account Status:</strong>
            {{ confirmChangesData["active"] ? "Active" : "Deactivated" }}
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-sm" (click)="confirming = false">
            Cancel
          </button>
          <button type="button" class="btn btn-primary btn-sm" (click)="updateToSelectedUsers()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="fade show modal-backdrop"></div>
</ng-container>
