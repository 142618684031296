<div
  class="fade show d-block modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mediaPlayer"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Duplicate/Migrate {{ context | titlecase }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closed.emit()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body row">
        <div *ngIf="submitted" class="container alert alert-success alert-dismissible" role="alert">
          {{ submitted }}
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            (click)="submitted = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form class="container">
          <div class="row">
            <div class="col">
              <label>Source project:</label>
              <div class="mb-3">
                <button type="button" class="btn btn-outline-secondary" disabled>
                  {{ projectCode }}
                </button>
              </div>

              <label>{{ context === "assets" ? "Asset type" : "Episode code" }}:</label>
              <div class="mb-3">
                <app-dropdown
                  *ngIf="groups$ | async as groups"
                  (selectionChanged)="onChangeGroup($event)"
                  [selectedItem]="groups[0] || ''"
                  [choices]="groups"
                  [applyBorder]="true"
                  [showDropArrow]="true"></app-dropdown>
              </div>

              <label>{{ context.slice(0, -1) | titlecase }} code:</label>
              <app-dropdown
                *ngIf="entities$ | async as entities"
                (selectionChanged)="onChangeEntityCode($event)"
                [selectedItem]="entities[0] || ''"
                [choices]="entities"
                [applyBorder]="true"
                [showDropArrow]="true"></app-dropdown>
            </div>
            <div class="col border-left">
              <label>Destination project:</label>
              <div class="mb-3">
                <app-dropdown
                  *ngIf="projects$ | async as projects"
                  (selectionChanged)="dstProjectCode = $event"
                  [selectedItem]="dstProjectCode"
                  [choices]="projects"
                  [applyBorder]="true"
                  [showDropArrow]="true"></app-dropdown>
              </div>

              <div class="form-group">
                <label for="group"
                  >{{ context === "assets" ? "Asset type" : "Episode code" }}:</label
                >
                <input
                  #dstGroup
                  class="form-control"
                  type="text"
                  id="group"
                  [attr.disabled]="context === 'assets' ? true : null"
                  [value]="srcGroup" />
              </div>
              <div class="form-group">
                <label for="entity">{{ context.slice(0, -1) | titlecase }} code:</label>
                <input
                  #dstEntityCode
                  class="form-control"
                  type="text"
                  id="entity"
                  [value]="srcEntityCode" />
              </div>
              <div class="form-check">
                <input #notes class="form-check-input" type="checkbox" id="notes" />
                <label class="form-check-label" for="notes">Copy Notes</label>
              </div>
              <div class="form-check">
                <input #media class="form-check-input" type="checkbox" id="media" />
                <label class="form-check-label" for="media">Copy Media</label>
              </div>
              <div class="form-check">
                <input #versions class="form-check-input" type="checkbox" id="versions" />
                <label class="form-check-label" for="versions">Copy Versions</label>
              </div>
              <ng-container *ngIf="context === 'shots'">
                <div class="form-check">
                  <input #caches class="form-check-input" type="checkbox" id="caches" checked />
                  <label class="form-check-label" for="caches">Copy Caches</label>
                </div>
                <div class="form-check">
                  <input #plates class="form-check-input" type="checkbox" id="plates" checked />
                  <label class="form-check-label" for="plates">Copy Plates</label>
                </div>
              </ng-container>
              <div class="text-right mt-4">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="
                    duplicate(
                      dstGroup.value,
                      dstEntityCode.value,
                      notes.checked,
                      media.checked,
                      versions.checked
                    )
                  ">
                  Duplicate
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="fade show d-block modal-backdrop"></div>
