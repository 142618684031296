<div *ngIf="hasImgList" class="w-100 text-center">
  <ng-container *ngFor="let img of imgList">
    <img
      class="img-thumb"
      [src]="entity | refImgURL : img['_id']['$oid']"
      width="114"
      height="64"
      title="Right click img to see more options." />
    <span class="hover-pointer" title="Delete ref img" (click)="delete(img['_id']['$oid'])"
      ><i class="material-icons md-24 align-text-bottom">delete</i></span
    >
  </ng-container>
</div>

<div class="input-group font-smaller">
  <div class="custom-file">
    <input
      #file
      class="custom-file-input"
      id="custom-file"
      type="file"
      name="file"
      [accept]="['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml']"
      (change)="onChangeAttachedFile($event)" />
    <label
      class="custom-file-label text-left"
      [ngClass]="{
        'font-weight-bold': file.files?.length === 1,
        'font-weight-normal': !file.files?.length
      }"
      for="custom-file"
      >{{
        file.files?.length === 1 ? file.files?.item(0)?.name : "Click Browse or drag-n-drop a file"
      }}</label
    >
  </div>
</div>

<div class="text-right mt-1">
  <button type="button" class="btn btn-sm btn-outline-dark" (click)="upload()">Upload</button>
</div>
