import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription, Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";

import { UserService } from "../user.service";
import { EntityService } from "../entity.service";
import { ProjectService } from "../project.service";
import { Entity, IEntity, Context, IStatus } from "../entity";
//import { QueryParamService } from '../query-param.service';
import { DisplayType } from "../project";
import { User, UserMixin, AddEmptyUser, GroupByDept } from "../user";
import { applyMixins } from "../utils";
import { NoteService } from "../note.service";

export enum Page {
  Entity,
  EntityDetail,
  Episode,
  Scripts,
  Hdri,
}

export enum TriggerSearchFrom {
  OnInit,
  Typeahead,
}

@Component({
  selector: "app-entity",
  templateUrl: "./entity-container.component.html",
  styleUrls: ["./entity-container.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityContainerComponent implements OnInit, UserMixin, OnDestroy {
  projectCode: string;
  context: Context;
  group: string;
  entityCode: string;
  entities$: Observable<Entity[]>;
  episodes$: Observable<IEntity[]>;
  episodes: Entity[];
  displayType: DisplayType;
  inShotsByEpisodePage: boolean;
  allowUploadInFileBrowser: boolean;
  allowDownloadWorkfileInMediaPlayer: boolean;
  primarySoftware: string;

  editMode: { [key: string]: Set<string> } = {};
  subs: Subscription[] = [];

  page: Page;
  Page = Page;
  DisplayType = DisplayType;

  // UsersMixin
  users: User[];
  groupedUsers: { [key: string]: User[] };
  usersInProject: User[];
  filteredUsers: User[];
  userService: UserService;
  getUsers: () => void;
  getUsersByProject: (
    projectCode: string,
    context: string,
    groupByDept: GroupByDept,
    addEmptyUser: AddEmptyUser
  ) => Observable<User[] | { [key: string]: User[] }>;

  users$: Observable<User[]>;
  usersInProject$: Observable<User[]>;

  statuses$: Observable<IStatus[]>;
  statuses: IStatus[];

  constructor(
    private entityService: EntityService,
    protected _userService: UserService,
    private projectService: ProjectService,
    //private queryParamService: QueryParamService,
    private activatedRoute: ActivatedRoute,
    private noteService: NoteService,
    private cdr: ChangeDetectorRef
  ) {
    this.userService = _userService;
  }

  ngOnInit() {
    const parentParams = this.activatedRoute.snapshot.parent?.params;
    const childParams = this.activatedRoute.snapshot.params;
    const urlSegments = this.activatedRoute.snapshot.url;

    this.projectCode = parentParams?.projectCode;
    this.context = urlSegments[0].path as Context;

    this.group = childParams.group;
    this.entityCode = childParams.entityCode;

    if (!["assets", "shots", "plates"].includes(this.context)) {
      this.statuses$ = this.entityService
        .getStatuses(this.projectCode, this.context === "scripts" ? "episodes" : this.context)
        .pipe(map((statuses) => (this.statuses = statuses)));
      this.users$ = this.getUsersByProject(
        this.projectCode,
        this.context,
        GroupByDept.no,
        AddEmptyUser.yes
      ) as Observable<User[]>;
      this.usersInProject$ = this.getUsersByProject(
        this.projectCode,
        "",
        GroupByDept.no,
        AddEmptyUser.no
      ) as Observable<User[]>;
    }

    // scripts page
    if (urlSegments.length === 1 && this.context === "scripts") {
      this.page = Page.Scripts;
      this.displayType = DisplayType.TABLE;
      this.getAllEpisodes();
      return;
    }

    // HDRI page
    if (urlSegments.length === 1 && this.context === "hdri") {
      this.page = Page.Hdri;
      this.displayType = DisplayType.TABLE;
      this.getUnGroupedEntities();
      return;
    }

    if (urlSegments.length === 1 && this.context === "episodes") {
      this.page = Page.Episode;
      this.displayType = DisplayType.TABLE;
      return;
    }

    this.projectService
      .getProjectByCode(
        this.projectCode,
        "display_type,primary_software,allow_upload_in_file_browser,allow_download_workfile_in_media_player"
      )
      .subscribe((result) => {
        if (result.display_type === "cui_card") {
          this.displayType = DisplayType.CARD;
        } else {
          this.displayType = result["display_type"] as DisplayType;
        }
        this.primarySoftware = result["primary_software"];
        this.allowUploadInFileBrowser = result["allow_upload_in_file_browser"] || false;
        this.allowDownloadWorkfileInMediaPlayer =
          result["allow_download_workfile_in_media_player"] || false;

        // in shot detail page
        if (this.context === "shots" && urlSegments.length > 1) {
          this.page = Page.EntityDetail;

          // in shots page
        } else if (urlSegments.length === 1 && this.context === "shots") {
          this.page = Page.Entity;
        } else if (this.context === "assets" && urlSegments.length > 1) {
          this.page = Page.EntityDetail;
        } else if (urlSegments.length === 1 && this.context === "assets") {
          this.page = Page.Entity;
        } else if (urlSegments.length === 1 && this.context === "plates") {
          this.page = Page.Entity;
          // shots per episode page
        } else if (this.context === "episodes" && urlSegments.length > 1) {
          this.page = Page.Entity;
          this.inShotsByEpisodePage = true;
        }

        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  getScriptNotesAll(theEntity: Entity) {
    let episodeCode = "";
    if ("episode_code" in theEntity) {
      episodeCode = theEntity["episode_code"];
    }
    this.noteService.getNotesForScript(theEntity, episodeCode).subscribe((notes) => {
      theEntity.notesBuffer?.next(notes);
    });
  }

  getUnGroupedEntities(last: string = "", calledByEvent = false): void {
    if (calledByEvent) {
      //this.queryParamService.resetQueryParams();
      this.entities$ = of([]);
    }

    this.entities$ = this.entityService
      .getUnGroupedEntities(this.projectCode, this.context, last)
      .pipe(
        map((entities) => {
          return (entities as IEntity[]).map((entity) => {
            entity.projectCode = this.projectCode;
            entity.context = this.context;
            const e = new Entity(entity);
            this.editMode[e.toString()] = new Set<string>();
            return e;
          });
        })
      );
  }

  getAllEpisodes() {
    this.episodes$ = this.entityService.getUnGroupedEntities(this.projectCode, "episodes", "").pipe(
      tap((episodes) => {
        this.episodes = (episodes as IEntity[]).map((entity) => {
          entity.projectCode = this.projectCode;
          entity.context = "episodes";
          const e = new Entity(entity);
          return e;
        });
      })
    );
  }
}
applyMixins(EntityContainerComponent, [UserMixin]);
