<div class="dropdown d-inline-block">
  <button
    class="btn btn-secondary dropdown-toggle"
    (click)="showColumns = !showColumns"
    type="button"
    id="dropdownMenuButton"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    (blur)="showColumns = false">
    Show/Hide Columns
  </button>
  <div
    *ngIf="showColumns"
    class="show dropdown-menu"
    aria-labelledby="dropdownMenuButton"
    (mousedown)="$event.preventDefault()">
    <ng-container *ngFor="let column of columns; let i = index">
      <div
        *ngIf="column.name"
        (click)="$event.stopPropagation(); toggleVisibility(i, column)"
        class="dropdown-item">
        <input
          type="checkbox"
          class="form-check-input"
          [id]="column.name"
          [ngModel]="columns[i].visible"
          (change)="toggleVisibility(i, column)" />
        <label class="form-check-label" [for]="column.name">{{ column.name }}</label>
      </div>
    </ng-container>
    <div class="container text-right">
      <button
        type="button"
        class="btn btn-primary"
        (click)="saved.emit(columns); showColumns = false">
        Save
      </button>
    </div>
  </div>
</div>
