import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";

import { Project } from "../project";
import { ProjectService } from "../project.service";

@Component({
  selector: "app-project-detail-tab",
  templateUrl: "./project-detail-tab.component.html",
  styleUrls: ["./project-detail-tab.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDetailTabComponent implements OnInit {
  projectCode: string;
  project$: Observable<Project>;
  requiredFields = ["name", "code", "_id"];
  openFileBrowser: boolean;

  constructor(private activatedRoute: ActivatedRoute, private projectService: ProjectService) {}

  ngOnInit() {
    this.getProject();
  }

  getProject() {
    // I am assuming this is never null.
    this.projectCode = this.activatedRoute.parent?.snapshot.paramMap.get("projectCode") as string;
    this.project$ = this.projectService.getProjectByCode(this.projectCode);
  }
}
