import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";

import { Entity } from "../entity";
import { EntityService } from "../entity.service";
import { Status, HttpProgress } from "../utils";
import type MongoDocument from "../mongo-document";

@Component({
  selector: "app-img-list-with-upload",
  templateUrl: "./img-list-with-upload.component.html",
  styleUrls: ["./img-list-with-upload.component.css"],
})
export class ImgListWithUploadComponent implements OnInit {
  @Input() entity: Entity;
  @Input() field: string;
  @Input() subfield?: string;

  @ViewChild("file", { static: true }) inputFile: ElementRef<HTMLInputElement>;

  attachedFile: File;
  hasImgList: boolean;
  imgList: ({ path: string } & MongoDocument)[] = [];

  processStatus: {
    status: Status;
    msg: string;
    progress?: number;
  } = { status: "Idle", msg: "", progress: 0 };
  showProgress: (msg: HttpProgress) => any;

  constructor(private entityService: EntityService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.setImgList();

    this.showProgress = (status: HttpProgress) => {
      this.processStatus = { ...status, msg: "" };
      if (this.processStatus.status === "Submitted") {
        this.hasImgList = true;
      }
      this.cdr.markForCheck();
    };
  }

  setImgList() {
    this.subfield = this.subfield?.split(".")[1];
    let item = undefined;
    if (this.subfield) {
      if (this.entity[this.field] && this.entity[this.field][this.subfield]) {
        item = this.entity[this.field][this.subfield];
      }
    } else {
      item = this.entity[this.field];
    }

    if (item && Array.isArray(item) && item.length > 0) {
      this.hasImgList = true;
      /* If we want to display all imgs
       * this.imgList = [...item];
       */
      this.imgList = [item[0]];
    } else {
      this.hasImgList = false;
    }
  }

  onChangeAttachedFile($event: Event) {
    const target = $event.target as HTMLInputElement;
    const file0 = target?.files?.item(0);
    if (target.files && file0) {
      this.attachedFile = file0;
    }
  }

  upload() {
    // TODO: make upload generic?
    this.entityService
      .uploadRefImg(this.attachedFile, this.entity, this.showProgress)
      .subscribe((response) => {
        if (response.body?.Uploaded.spp.ref_img[0]) {
          this.imgList = [response.body?.Uploaded.spp.ref_img[0]];
          this.inputFile.nativeElement.value = "";
          this.cdr.markForCheck();
        }
        /* If we want more than one img ref:
         * this.imgList = [...this.imgList, ...response['body']['Uploaded']['spp']['ref_img']];
         */
      });
  }

  delete(id: string) {
    if (confirm("Are you sure you want to delete this ref img?")) {
      this.entityService.deleteRefImg(this.entity, id).subscribe((response) => {
        if (response["spp"]["ref_img"].length > 0) {
          this.imgList = [response["spp"]["ref_img"][0]];
        } else {
          this.imgList = [];
        }
        this.cdr.markForCheck();
      });
    }
  }
}
