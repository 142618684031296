<ng-container [ngSwitch]="page">
  <ng-container *ngSwitchCase="Page.Entity">
    <ng-container [ngSwitch]="displayType">
      <ng-container *ngSwitchCase="DisplayType.TABLE">
        <app-entities-table-view
          [allowUploadInFileBrowser]="allowUploadInFileBrowser"
          [allowDownloadWorkfileInMediaPlayer]="allowDownloadWorkfileInMediaPlayer">
        </app-entities-table-view>
      </ng-container>
      <ng-container *ngSwitchCase="DisplayType.CARD">
        <app-entities-card-view
          [primarySoftware]="primarySoftware"
          [allowUploadInFileBrowser]="allowUploadInFileBrowser"
          [allowDownloadWorkfileInMediaPlayer]="allowDownloadWorkfileInMediaPlayer">
        </app-entities-card-view>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="Page.Episode">
    <app-episode
      *ngIf="(users$ | async) && (usersInProject$ | async) && (statuses$ | async)"
      [projectCode]="projectCode"
      [statuses]="statuses"
      [users]="users"
      [usersInProject]="usersInProject">
    </app-episode>
  </ng-container>

  <ng-container *ngSwitchCase="Page.Scripts">
    <!--    Have to use $any for entities because no way to cast them to ScriptEntities -->
    <app-scripts
      *ngIf="
        (users$ | async) && (usersInProject$ | async) && (statuses$ | async) && (episodes$ | async)
      "
      [projectCode]="projectCode"
      [episodes]="episodes"
      [statuses]="statuses"
      [users]="groupedUsers"
      [usersInProject]="usersInProject">
    </app-scripts>
  </ng-container>

  <ng-container *ngSwitchCase="Page.Hdri">
    <app-hdri *ngIf="entities$ | async as entities" [projectCode]="projectCode" [hdris]="entities">
    </app-hdri>
  </ng-container>
</ng-container>
