<div #parent class="dropdown">
  <button
    class="btn btn-no-border btn-secondary dropdown-toggle"
    type="button"
    (click)="showFilters = !showFilters; (showFilters)">
    <i
      class="material-icons md-18 align-text-bottom"
      [style.color]="isFilterEnabled ? '#8bc483' : '#6c757d'"
      >filter_alt</i
    >
    Filters
  </button>
  <div
    *ngIf="showFilters"
    class="show dropdown-menu shadow p-3 mb-5 bg-white rounded"
    [style.left]="(parent.offsetLeft * -0.95).toString() + 'px'"
    aria-labelledby="dropdownMenuButton">
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="showFilters = false">
      <span aria-hidden="true">&times;</span>
    </button>

    <div class="d-flex">
      <div class="d-flex">
        <div class="h-min-content d-flex align-items-center">
          Filter is: &nbsp;&nbsp;
          <div class="custom-control custom-switch mr-2">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitch1"
              [checked]="isFilterEnabled"
              (change)="enableFilter()" />
            <label class="custom-control-label" for="customSwitch1">{{ switchLabel }}</label>
          </div>
          <ng-container *ngIf="isFilterEnabled">
            Custom Filter: &nbsp;
            <app-dropdown
              (selectionChanged)="changeFilter($event)"
              [selectedItem]="defaultFilter || 'No saved filters'"
              [choices]="savedFilters">
            </app-dropdown>
            &nbsp;
            <button
              *ngIf="defaultFilter != defaultNoSaveFilter"
              type="button"
              class="btn btn-outline-danger"
              (click)="deleteCustomFilter()">
              Delete Filter
            </button>
          </ng-container>
        </div>
      </div>

      <form *ngIf="isFilterEnabled && fields.length > 0 && filterForm">
        <div class="container">
          <div
            *ngFor="let form of filterForm.controls; index as i"
            class="mb-2 rounded d-flex flex-wrap">
            <div class="d-inline-flex">
              <app-dropdown
                (selectionChanged)="
                  filterForm.controls[i].value['field'] = $event;
                  updateFieldOperatorOptions(filterForm.controls[i].value['field'], i)
                "
                [selectedItem]="filterForm.controls[i].value['field'] || 'Select field'"
                [choices]="fields">
              </app-dropdown>
            </div>
            <div
              class="d-inline-flex"
              (click)="updateFieldOperatorOptions(filterForm.controls[i].value['field'], i)">
              <app-dropdown
                *ngIf="
                  filterForm.controls[i].value['operator'] ||
                  filterForm.controls[i].value['fieldOperatorOptions']
                "
                (selectionChanged)="filterForm.controls[i].value['operator'] = $event"
                [selectedItem]="getFieldOperatorOptions(i)"
                [choices]="filterForm.controls[i].value['fieldOperatorOptions']"></app-dropdown>
            </div>
            <div class="d-inline-flex flex-grow-1" (click)="updateSuggestions(i)">
              <app-typeahead
                [placeholder]="''"
                [preDeterminedValue]="filterForm.controls[i].value['values'] || ''"
                style="width: 100%"
                [items$]="filterForm.controls[i].value['suggestions'] || []"
                [isSingleSelection]="false"
                (added)="filterForm.controls[i].value['values'] = $event"
                [isAutofocused]="false"
                [showDropdownWhenAutofocused]="
                  filterForm.controls[i].value['showDropdownWhenAutofocused']
                "
                [applyBorderWarningOnBlur]="false"
                [emitStringArrayAsString]="true"
                [emitOnBlur]="true"
                [minWidth]="'400'">
              </app-typeahead>
            </div>
            <div class="d-inline-flex">
              <app-dropdown
                (selectionChanged)="filterForm.controls[i].value['logicalOperator'] = $event"
                [selectedItem]="
                  filterForm.controls[i].value['logicalOperator'] || 'Select operator'
                "
                [choices]="['and', 'or']"></app-dropdown>
            </div>
            <!--input type="hidden" [formControl]="$any(filterForm.controls[i])"-->
            <button type="button" class="btn btn-outline-danger" (click)="removeFormFilter(i)">
              Remove
            </button>
          </div>
        </div>

        <hr />
        <div class="container d-flex flex-row-reverse align-items-center mt-2">
          <div *ngIf="filterNameWarning" class="container-small-padding alert-warning mb-0 mr-2">
            Filter name already exists. Overwrite? <br />
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm ml-1"
              (click)="onSubmit(filterName.value, true)">
              Yes</button
            >&nbsp;
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm ml-1"
              (click)="filterName.value = ''; filterNameWarning = false; isSubmitting = false">
              No
            </button>
          </div>
          <div class="input-group w-75">
            <input
              #filterName
              type="text"
              class="form-control"
              placeholder="Filter name"
              aria-label="Filter name"
              aria-describedby="button-addon2" />
            <div class="input-group-append">
              <button
                class="btn btn-outline-primary"
                type="button"
                id="button-addon2"
                (click)="onSubmit(filterName.value); isSubmitting = true"
                [attr.disabled]="filterNameWarning || isSubmitting ? true : null">
                Apply and Save Filter
              </button>
              <button class="btn btn-outline-danger" type="button" (click)="showFilters = false">
                Close Window <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="ml-3 mr-1">
            <button type="button" class="btn btn-outline-primary" (click)="applyFilter()">
              Apply
            </button>
          </div>
          <div class="mx-1">
            <button type="button" class="btn btn-outline-success" (click)="addFormFilter()">
              Add Filter
            </button>
          </div>
          <div class="mx-1">
            <a class="px-2 mt-2" [routerLink]="['/docs']" target="_blank"
              ><i class="material-icons md-24 align-text-bottom">help_outline</i></a
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
