<div #tableContainer class="table-responsive">
  <div
    class="position-sticky left-0 top-0 bg-white d-flex flex-wrap py-1 align-items-center"
    id="table-options">
    <app-typeahead
      #searchTypeahead
      [placeholder]="'Search example: e0123↵'"
      [items$]="'entityCode' | typeaheadItems : '' : projectCode : context"
      [isSingleSelection]="true"
      (added)="onSearch($event)"
      [isAutofocused]="true"
      [showDropdownWhenAutofocused]="false"
      [applyBorderWarningOnBlur]="false">
    </app-typeahead>
    <div class="ml-1 form-check">
      <input
        class="form-check-input"
        type="checkbox"
        name="show-status-colors"
        id="show-status-colors"
        [checked]="showStatusColors"
        (change)="onChangeShowStatusColors()" />
      <label class="form-check-label" for="show-status-colors">Show status colors</label>
    </div>
    <app-filters
      [filters$]="filters$"
      [context]="context"
      [projectCode]="projectCode"
      (toggledFiltersOnOrOff)="onToggledFilters($event)"
      (filtered)="getEpisodes()"></app-filters>
    <button type="button" class="btn btn-sm btn-outline-primary" (click)="createBundle()">
      Create New Bundle
    </button>
    <button
      type="button"
      class="btn btn-sm btn-outline-primary"
      (click)="openUploadDuedates = true">
      Upload Due dates
    </button>
  </div>

  <table class="table table-bordered table-hover table-sm w-auto">
    <thead class="thead-light">
      <tr>
        <th class="position-sticky" style="left: 0px; z-index: 122">Episodes</th>
        <th class="position-sticky">Bundle #</th>
        <th class="position-sticky">Status</th>
        <th class="position-sticky">CN Code</th>
        <th class="position-sticky">DOM Code</th>
        <th class="position-sticky">Script Code</th>
        <th class="position-sticky topBorderScript">Script</th>
        <th class="position-sticky topBorderStoryboard">Storyboard</th>
        <th class="position-sticky topBorderLeica">Leica</th>
        <th class="position-sticky topBorderAssembly">Assembly</th>
      </tr>
    </thead>
    <tbody *ngIf="!openMediaPlayer && statuses && episodes; else placeholder">
      <tr #entityRows *ngFor="let episode of episodes; let i = index">
        <td class="position-sticky bg-white" style="left: 0; z-index: 118">
          <span class="d-flex">
            <a
              routerLink="../shots"
              [queryParams]="{ expand: episode.toString() }"
              fragment="{{ episode.toString() }}"
              [state]="{ clickedEpisodeLinkInEpisodeTab: true }"
              class="align-self-center"
              >{{ episode.toString() }}</a
            >
            <span
              *ngIf="!editMode[episode.toString() + '_episode_name']"
              class="font-weight-normal px-1 font-italic"
              title="Click to Edit"
              style="cursor: text"
              (click)="startEditMode(episode.toString() + '_episode_name', true)">
              · {{ episode["episode_name"] || "&lt;Click to Edit&gt;" }}
            </span>

            <input
              #thisInput
              *ngIf="editMode[episode.toString() + '_episode_name']"
              class="form-control episode-name"
              type="text"
              style="width: 220px"
              [ngModel]="episode['episode_name']"
              [placeholder]="episode['episode_name'] || ''"
              (keyup.enter)="saveEpisode(thisInput.value, episode, 'episode_name', i)"
              (blur)="saveEpisode(thisInput.value, episode, 'episode_name', i)"
              appAutofocus />
          </span>
          <div class="flex-grow-1" style="text-align: left; padding: 5px">
            <button
              *ngIf="!exportSpreadsheetMode[episode.toString()]"
              type="button"
              class="btn btn-outline-primary btn-sm ml-1"
              (click)="exportSpreadsheet(episode)"
              title="Export">
              Export spreadsheet
            </button>
            <button
              *ngIf="exportSpreadsheetMode[episode.toString()]"
              disabled="true"
              type="button"
              class="btn btn-outline-primary btn-sm ml-1"
              title="Processing">
              Processing spreadsheet
            </button>
          </div>
        </td>
        <td class="text-center">
          <app-dropdown
            [selectedItem]="episode['bundle_code'] || ''"
            [choices]="$any(bundles)"
            [choiceKey]="'bundle_code'"
            [emitChoiceObj]="true"
            (selectionChanged)="updateBundleCode(episode, $event)"
            [emptyChoice]="'Select bundle'"></app-dropdown>
        </td>
        <td
          [style.background-color]="
            showStatusColors ? findStatusColor(episode['episode_status'], statuses) : null
          ">
          <app-dropdown
            [selectedItem]="episode['episode_status']"
            [choices]="statuses"
            [choiceKey]="'text'"
            (selectionChanged)="saveEpisodeStatus(episode, $event)"
            [showIconOnlyForSelectedItem]="true"
            [emptyChoice]="statuses[0]"
            [applyBgColor]="showStatusColors"></app-dropdown>
        </td>
        <td
          title="Click to Edit"
          style="cursor: text"
          (click)="startEditMode(episode.toString() + '_cn_code', true)">
          <span
            *ngIf="!editMode[episode.toString() + '_cn_code']"
            class="font-weight-normal px-1"
            >{{ episode["cn_code"] || "" }}</span
          >

          <input
            #thisInput
            *ngIf="editMode[episode.toString() + '_cn_code']"
            class="form-control episode-name"
            type="text"
            style="width: 220px"
            [ngModel]="episode['cn_code']"
            [placeholder]="episode['cn_code'] || ''"
            (keyup.enter)="saveEpisode(thisInput.value, episode, 'cn_code', i)"
            (blur)="saveEpisode(thisInput.value, episode, 'cn_code', i)"
            appAutofocus />
        </td>
        <td
          title="Click to Edit"
          style="cursor: text"
          (click)="startEditMode(episode.toString() + '_dom_code', true)">
          <span
            *ngIf="!editMode[episode.toString() + '_dom_code']"
            class="font-weight-normal px-1"
            >{{ episode["dom_code"] || "" }}</span
          >

          <input
            #thisInput
            *ngIf="editMode[episode.toString() + '_dom_code']"
            class="form-control episode-name"
            type="text"
            style="width: 220px"
            [ngModel]="episode['dom_code']"
            [placeholder]="episode['dom_code'] || ''"
            (keyup.enter)="saveEpisode(thisInput.value, episode, 'dom_code', i)"
            (blur)="saveEpisode(thisInput.value, episode, 'dom_code', i)"
            appAutofocus />
        </td>
        <td>
          <app-dropdown
            [selectedItem]="episode.script_code ?? ''"
            [choices]="scriptCodes"
            [choiceKey]="'script_code'"
            [emitChoiceObj]="true"
            (selectionChanged)="updateScriptCode(episode, $event)"
            [emptyChoice]="'Select script'"></app-dropdown>
        </td>
        <td class="borderScript">
          <span
            *ngIf="episode.script_code"
            class="upload"
            (click)="openUploadComponent(episode); uploadDirectory = 'scripts'"
            title="Upload">
            <i class="material-icons md-24 align-text-bottom">cloud_upload</i>
          </span>
          <span class="download" (click)="onClickedPlay(episode, 'stages')" title="Download">
            {{ episode | getLatestScriptOrStoryboard : scriptEntities : "script" }}</span
          >
        </td>
        <td class="borderStoryboard">
          <span
            *ngIf="episode['script_code']"
            class="upload"
            (click)="openUploadComponent(episode); uploadDirectory = 'storyboards'"
            title="Upload">
            <i class="material-icons md-24 align-text-bottom">cloud_upload</i>
          </span>
          <span class="download" (click)="onClickedPlay(episode, 'storyboards')" title="Download">
            {{ episode | getLatestScriptOrStoryboard : scriptEntities : "storyboard" }}</span
          >
        </td>
        <td class="borderLeica">
          <div class="align-items-center d-flex">
            <span
              *ngIf="episode['script_code']"
              class="upload"
              (click)="openUploadComponent(episode); uploadDirectory = 'leica'"
              title="Upload">
              <i class="material-icons md-24 align-text-bottom">cloud_upload</i>
            </span>
            <img
              *ngIf="isAvailable(episode, 'leica')"
              class="img-thumb"
              [src]="episode | thumbnailURL : 'leica' : scriptEntities"
              (click)="onClickedPlay(episode, 'leica')"
              width="114"
              height="64" />
          </div>
        </td>
        <td class="borderAssembly">
          <div class="align-items-center d-flex">
            <span
              class="upload"
              (click)="uploadDirectory = 'assembly'; openUpload = episode"
              title="Upload">
              <i class="material-icons md-24 align-text-bottom">cloud_upload</i>
            </span>
            <img
              *ngIf="isAvailable(episode, 'assembly')"
              class="img-thumb"
              [src]="episode | thumbnailURL : 'assembly' : scriptEntities"
              (click)="onClickedPlay(episode, 'assembly')"
              width="114"
              height="64" />
          </div>
        </td>
      </tr>
    </tbody>

    <ng-template #placeholder>
      <!-- this will show Fetching data after the table headings are rendered -->
      <tbody>
        <tr>
          {{
            episodes === undefined ? "Fetching data..." : "No episodes found given the filters."
          }}
        </tr>
      </tbody>

      <tbody></tbody
    ></ng-template>
  </table>
</div>

<app-upload
  *ngIf="openUpload"
  [entity]="openUpload"
  [episodeName]="openUpload.toString() || ''"
  [scriptsAndStory]="uploadDirectory"
  [usersInProject]="usersInProject"
  (closed)="onClose()">
</app-upload>

<app-media-player
  *ngIf="openMediaPlayer"
  [allowDownloadWorkfileInMediaPlayer]="false"
  [play$]="viewMedia.media"
  [entity]="viewMedia.entity"
  [notes]="viewMedia.notes"
  [mediaSource]="viewMedia.src"
  [selectedVersionID1]="viewMedia.selectedVersion"
  [selectedVersionID2]="viewMedia.compare"
  [audio]="viewMedia.audio"
  [selectedDefaultThumbnail]="viewMedia.defaultThumbnail"
  [statuses]="statuses"
  [users]="usersInProject"
  (closed)="openMediaPlayer = $event">
</app-media-player>

<ng-container *ngIf="openUploadDuedates">
  <div
    class="fade show d-block modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="mediaPlayer"
    aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header align-items-baseline">
          <span>Upload Due dates</span>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="openUploadDuedates = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div
            *ngIf="processStatus.status === 'Complete'"
            class="container alert alert-success alert-dismissible"
            role="alert">
            Successfully updated due dates!
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <app-typeahead
            #selectedEpisode
            [items$]="episodes"
            [labelString]="'Episode:'"
            [idString]="'episodes'"
            [isAutofocused]="true"
            [customToString]="customToString"
            [isSingleSelection]="true"></app-typeahead>

          <div class="input-group font-smaller">
            <div class="custom-file">
              <input
                #file
                class="custom-file-input"
                id="custom-file"
                type="file"
                name="file"
                [accept]="['.xlsx']"
                (change)="onChangeAttachedFile($event)" />
              <label
                class="custom-file-label text-left"
                [ngClass]="{
                  'font-weight-bold': file.files?.length === 1,
                  'font-weight-normal': file.files?.length === 0
                }"
                for="custom-file"
                >{{
                  file.files?.length === 1
                    ? (file.files?.item(0))!.name
                    : "Click Browse or drag-n-drop a file"
                }}</label
              >
            </div>
          </div>

          <button
            type="button"
            class="btn btn-primary mt-2 float-right"
            (click)="upload()"
            [attr.disabled]="
              ['Started', 'Uploading', 'Processing'].includes(processStatus.status) ? true : null
            ">
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="fade show d-block modal-backdrop"></div>
</ng-container>
