import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { last, map, tap } from "rxjs/operators";

import { Entity, IEntity } from "./entity";
import { environment } from "../environments/environment";
import type MongoDocument from "./mongo-document";
import { Note, PartialNote, NoteResponse } from "./notes";
import { MongoDate } from "./mongo-document";
import { DownloadProgress, HttpProgress } from "./utils";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

// Todo: use this or IMediaDropdownItem?
// export interface MediaVersion extends Partial<MongoDocument> {
//   artists?: any; // double check
//   cc_departments?: any;
//   comment?: string|null;
//   "css-classes"?: string;
//   dept: string;
//   episode_code?: string;
//   extern?: boolean;
//   frame_timestamps?: number[];
//   frames_info?: {
//     diff_a: number;
//     diff_b: number;
//     first_frame: number;
//     fps: number;
//     total_frames: number;
//   };
//   icon?: string;
//   movie?: string;
//   normalized_text?: string;
//   original_movie?: string;
//   res: string;
//   source_file?: null;
//   submitted_by?: string;
//   text?: string;
//   thumbnail: string;
//   upload_date?: MongoDate;
// }

type AddNoteReturn = Pick<Note, "created_by" | "body_annotations" | "html"> & MongoDocument;

@Injectable({
  providedIn: "root",
})
export class NoteService {
  constructor(private http: HttpClient) {}

  getNotes(entity: Entity | IEntity, filters?: object): Observable<Note[] | null> {
    if (entity.context === "scripts") {
      return this.getNotesForScript(entity as Entity, entity["group"] || entity["episode_code"]);
    }
    let context = entity.context === "episodes" ? "shots" : entity.context;
    let entityCode = entity.context === "episodes" ? "assembly" : entity.entityCode;
    const url = `${environment.projectsURL}/${entity.projectCode}/${context}/${entity.group}/${entityCode}/notes`;
    // TODO: there is no POST method defined for this route on the backend. filters parameter is never used.
    // if (filters) {
    //   return this.http.post<object[]>(url, JSON.stringify(filters), httpOptions);
    // }
    return this.http.get<Note[] | null>(url);
  }

  getNotesForScript(entity: Entity, episodeCode = ""): Observable<Note[]> {
    let url = `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.entityCode}/notes`;
    if (episodeCode != "" && (entity.context == "scripts" || entity.context == "episodes")) {
      url = `${environment.projectsURL}/${entity.projectCode}/scripts/${entity.entityCode}/${episodeCode}/notes`;
    }
    return this.http.get<Note[]>(url);
  }

  uploadAttachment(files: File[]): Observable<{ annotation_ids: string[] }> {
    let formData = new FormData();
    for (const file of files) {
      if (file) {
        formData.append("file", file, file.name);
      }
    }
    const url = `${environment.annotationsURL}/upload`;
    return this.http.post<{ annotation_ids: string[] }>(url, formData, {
      headers: new HttpHeaders({ enctype: "multipart/form-data" }),
    });
  }

  updateNote(projectCode: string, noteId: string, toBeUpdated: any, responseId?: string) {
    const url = responseId
      ? `${environment.projectsURL}/${projectCode}/notes/${noteId}/responses/${responseId}`
      : `${environment.projectsURL}/${projectCode}/notes/${noteId}`;
    return this.http.put<Partial<Note> | { responses: Partial<NoteResponse> }>(
      url,
      JSON.stringify(toBeUpdated),
      httpOptions
    );
  }

  addNote(
    entity: Entity | IEntity,
    noteObject: PartialNote,
    files: File[] = []
  ): Observable<AddNoteReturn | null> {
    let formData = new FormData();
    for (const file of files) {
      if (file) {
        formData.append("file", file, file.name);
      }
    }
    formData.append("note", JSON.stringify(noteObject));

    let url = `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/note/add`;
    if (entity.context === "scripts") {
      url = `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.entityCode}/note/add`;
    }
    return this.http.post<AddNoteReturn | null>(url, formData, {
      headers: new HttpHeaders({ enctype: "multipart/form-data" }),
    });
  }

  addNoteForEpisode(
    entity: Entity,
    noteObject: PartialNote,
    files?: File[],
    mediaType?: string
  ): Observable<AddNoteReturn | null> {
    let formData = new FormData();
    let url = "";
    if (files) {
      for (const file of files) {
        if (file) {
          formData.append("file", file, file.name);
        }
      }
    }

    formData.append("note", JSON.stringify(noteObject));

    if (entity.context == "episodes" && mediaType !== undefined) {
      url = `${environment.projectsURL}/${entity.projectCode}/shots/${entity.group}/${mediaType}/note/add`;
    } else {
      url = `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.entityCode}/note/add`;
    }

    return this.http.post<AddNoteReturn | null>(url, formData, {
      headers: new HttpHeaders({ enctype: "multipart/form-data" }),
    });
  }

  getAnnotationThumbnailURL(annotationId: string): string {
    return `${environment.annotationsURL}/thumbnail/${annotationId}`;
  }

  getAnnotationURL(annotationId: string): string {
    return `${environment.annotationsURL}/${annotationId}`;
  }

  getDownloadAnnotationURL(annotationId: string): string {
    return `${environment.annotationsURL}/download?id=${annotationId}`;
  }

  downloadFile(
    annotation_id: string,
    showProgress: (arg0: HttpProgress<Blob>) => void
  ): Observable<HttpProgress<Blob>> {
    let url = this.getDownloadAnnotationURL(annotation_id);
    const req = new HttpRequest("GET", url, { reportProgress: true, responseType: "blob" });

    return this.http.request<Blob>(req).pipe(
      map((event) => DownloadProgress.getEventMessage(event)),
      tap((message) => showProgress(message)),
      last() // return last (completed) message to caller
    );
  }

  deleteNote(projectCode: string, noteId: string): Observable<number> {
    const url = `${environment.projectsURL}/${projectCode}/notes/${noteId}/delete`;
    return this.http.delete<number>(url);
  }

  deleteNoteResponse(
    projectCode: string,
    noteId: string,
    responseId: string
  ): Observable<{ last_edited: MongoDate }> {
    const url = `${environment.projectsURL}/${projectCode}/notes/${noteId}/responses/${responseId}`;
    return this.http.delete<{ last_edited: MongoDate }>(url);
  }
}
