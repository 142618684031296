import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription, Observable, forkJoin, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { IntersectionObserverService } from "../intersection-observer.service";
import { ProjectService } from "../project.service";
import { QueryParamService } from "../query-param.service";
import { EntityService, PlayMedia } from "../entity.service";
import { NoteService } from "../note.service";
import { PlateService } from "../plate.service";
import { UserService } from "../user.service";
import { Entity, IEntity, Context, IMedia } from "../entity";
import { entityTrackBy, columnTrackBy } from "../utils";
import { TypeaheadComponent } from "../typeahead/typeahead.component";
import { TableConfigColumnItem } from "../../types/table-config";
import { Filters } from "../../types/filters";

@Component({
  selector: "app-plates",
  templateUrl: "./plates.component.html",
  styleUrls: ["./plates.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatesComponent implements OnInit, OnDestroy, AfterViewInit {
  subs: Subscription[] = [];
  groupedEntities$: Observable<IEntity[]>;
  columns$: Observable<TableConfigColumnItem[]>;
  columns: TableConfigColumnItem[];
  projectCode: string;
  context: Context;
  viewMedia: PlayMedia;
  openMediaPlayer: boolean;
  groupedEntities: IEntity[];

  searchTypeahead: TypeaheadComponent;
  @ViewChild("searchTypeahead") set setSearchTypeahead(ref: TypeaheadComponent) {
    this.searchTypeahead = ref;
  }

  @ViewChildren("entityRows") entityRows: QueryList<ElementRef>;

  expanded: Set<string> = new Set<string>();
  editMode: { [key: string]: string } = {};

  searchByOption: "tags" | "entityCode" | "" = "tags";
  searching: boolean;

  filters$: Observable<Filters>;
  isFiltersEnabled: boolean;

  entityTrackBy = entityTrackBy;
  columnTrackBy = columnTrackBy;

  tableContainer: ElementRef;
  @ViewChild("tableContainer", { static: true }) set setTableContainer(ref: ElementRef) {
    this.tableContainer = ref;
  }
  scrollPosition: { x: number; y: number } = { x: 0, y: 0 };

  showOOP: boolean;

  constructor(
    private projectService: ProjectService,
    private entityService: EntityService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private queryParamService: QueryParamService,
    private noteService: NoteService,
    private plateService: PlateService,
    private userService: UserService,
    private iso: IntersectionObserverService
  ) {
    const parentURL = this.activatedRoute.parent?.snapshot.url;
    const urlSegments = this.activatedRoute.snapshot.url;
    this.projectCode = parentURL ? parentURL[1].path : "";
    this.context = urlSegments[0].path as Context;

    this.expanded = this.queryParamService.queryParamsAsSet("expand");
  }

  default() {
    return this.entityService.getChildrenOfContext(this.projectCode, this.context as Context).pipe(
      switchMap((groupedEntities) => {
        if (this.expanded.size === 0) {
          return of(groupedEntities);
        }

        const expanded: string[] = Array.from(this.expanded);
        const groups = expanded.map((group) => {
          let groupType = undefined;
          if (group.split("_").length === 1) {
            groupType = "drives";
          } else if (group.split("_").length === 2) {
            groupType = "reels";
          } else if (group.split("_").length > 2) {
            groupType = "plates";
          }
          return this.entityService.getEntityCodesForGroup(
            this.projectCode,
            this.context as Context,
            group,
            groupType
          );
        });

        return forkJoin(groups).pipe(
          map((values) => {
            for (let i = 0; i < groupedEntities.length; i++) {
              if (!this.expanded.has(groupedEntities[i]["__code"])) {
                continue;
              }
              let toInsert: IEntity[] = values.shift() || [];
              for (let j = 0; j < toInsert.length; j++) {
                toInsert[j]["__depth"] = (groupedEntities[i]["__depth"] || 0) + 1;

                if (groupedEntities[i]["__group_type"] === "plates") {
                  toInsert[j]["parent"] = groupedEntities[i]["__code"];
                }
              }
              groupedEntities.splice(i + 1, 0, ...toInsert);
            }

            return groupedEntities;
          })
        );
      })
    );
  }

  getGroupedEntities() {
    this.filters$ = this.projectService.getFilters(this.projectCode, this.context).pipe(
      tap((filters) => {
        if (filters !== undefined && typeof filters == "object") {
          this.isFiltersEnabled = filters["is_enabled"];
        }

        // if user has a filter enabled, we only grab the entities with that filter applied
        if (this.isFiltersEnabled) {
          this.groupedEntities$ = this.entityService
            .getGroupedEntities(this.projectCode, this.context)
            .pipe(map((groupedEntities) => this.showExpanded(groupedEntities)));
        } else {
          this.groupedEntities$ = this.default();
        }
      })
    );
  }

  ngOnInit() {
    this.columns$ = this.projectService
      .getTableConfig(this.projectCode, this.context.slice(0, this.context.length - 1))
      .pipe(map((columns) => (this.columns = columns)));
    this.getGroupedEntities();

    this.subs.push(
      this.entityService.playMedia$.subscribe((viewMedia) => {
        this.scrollPosition = {
          x: this.tableContainer.nativeElement.scrollLeft,
          y: this.tableContainer.nativeElement.scrollTop,
        };

        // TODO: This gets the media twice. Once here and in media-player.component ngOnInit()
        viewMedia["media"].subscribe((media: IMedia[]) => {
          if (media.length > 0) {
            this.viewMedia = viewMedia;
            this.openMediaPlayer = true;
            this.cdr.markForCheck();
          } else {
            alert(
              `No items in ${viewMedia["src"].toLowerCase()} for ${viewMedia["entity"].toString()}.`
            );
          }
        });
      })
    );

    this.play();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.iso.childObservers.clear();
  }

  ngAfterViewInit() {
    this.entityRows.changes.subscribe((changes) => {
      const routeFragment = this.activatedRoute.snapshot.fragment;

      if (routeFragment && changes.length) {
        const fragment = document.getElementById(routeFragment);
        if (fragment) {
          this.tableContainer.nativeElement.scrollTop = fragment.offsetTop - 110;
        }
      }

      if (
        (changes.length && !this.openMediaPlayer && this.scrollPosition.x !== 0) ||
        this.scrollPosition.y !== 0
      ) {
        this.scrollBack();
      }
    });
  }

  getChildren(i: number, entity: IEntity, groupedEntities: IEntity[]) {
    if (this.searching || this.isFiltersEnabled) {
      let k = 0;
      for (const group of this.groupedEntities) {
        if (group["__code"] === entity["__code"]) {
          let children = [];
          let n = group["__direct_children"] || group["__children"]; // __direct_children is from Drive, Reels, and __children is from Children row
          for (let j = k + 1; j < this.groupedEntities.length; j++) {
            if (children.length === n) break;
            // non Children rows. Must check for __nice_name otherwise the same Children row will appear twice in the table
            if (
              this.groupedEntities[j]["__nice_name"] !== "Children" &&
              this.groupedEntities[j]["__depth"] === group["__depth"] + 1
            ) {
              children.push(this.groupedEntities[j]);
            }

            // look ahead, if plate has a child, add the Children row to the table
            if (
              this.groupedEntities[j + 1] &&
              this.groupedEntities[j + 1]["__nice_name"] === "Children" &&
              this.groupedEntities[j + 1]["__depth"] === group["__depth"] + 1
            ) {
              children.push(this.groupedEntities[j + 1]);
              n++;
            }
          }
          groupedEntities.splice(i + 1, 0, ...children);
          this.groupedEntities$ = of([...groupedEntities]);
          break;
        }
        k++;
      }
      this.cdr.markForCheck();
    } else {
      this.entityService
        .getEntityCodesForGroup(
          this.projectCode,
          this.context as Context,
          entity["__code"],
          entity["__group_type"]
        )
        .subscribe((children) => {
          for (let j = 0; j < children.length; j++) {
            children[j]["__depth"] = entity["__depth"] + 1;

            if (entity["__group_type"] === "plates") {
              children[j]["parent"] = entity["__code"];
            }
          }
          groupedEntities.splice(i + 1, 0, ...children);
          this.groupedEntities$ = of([...groupedEntities]);
          this.cdr.markForCheck();
        });
    }
  }

  toggleChildVisibility(i: number, entity: IEntity, groupedEntities: IEntity[]) {
    if (this.expanded.has(entity["__code"])) {
      // find next entity with same depth
      let splice_count = 0;
      for (let j = i + 1; j < groupedEntities.length; j++) {
        if (
          groupedEntities[j]["__depth"] === entity["__depth"] ||
          groupedEntities[j]["__depth"] === entity["__depth"] - 1 ||
          groupedEntities[j]["__depth"] < entity["__depth"] - 1
        ) {
          break;
        }
        splice_count++;
      }
      groupedEntities.splice(i + 1, splice_count);

      const expanded = Array.from(this.expanded).filter((e) => !e.startsWith(entity["__code"]));
      this.expanded = new Set(expanded);

      if (expanded.length === 0) {
        // reset each one manually
        for (const key of ["expand", "show-all-notes", "note", "show-completed-notes"]) {
          this.queryParamService.updateQueryParams({ [key]: null });
        }
      }
      this.queryParamService.updateQueryParams({
        expand: expanded,
        play: null,
        note: null,
        version: null,
        "show-all-notes": null,
        "show-completed-notes": null,
      });
    } else {
      this.getChildren(i, entity, groupedEntities);
      this.expanded.add(entity["__code"]);
      this.queryParamService.updateQueryParams({ expand: Array.from(this.expanded) });
    }
  }

  play() {
    const play = this.activatedRoute.snapshot.queryParams["play"];
    const version = this.activatedRoute.snapshot.queryParams["version"];
    const context = this.activatedRoute.snapshot.queryParams["play-ctx"];
    const src = this.activatedRoute.snapshot.queryParams["play-src"];
    if (!play && !version && !context && !src) {
      return;
    }

    this.scrollPosition = {
      x: this.tableContainer.nativeElement.scrollLeft,
      y: this.tableContainer.nativeElement.scrollTop,
    };

    this.groupedEntities$ = this.groupedEntities$;
    const _entity = {
      projectCode: this.projectCode,
      context: context as Context,
      group: play.split("_")[0],
      entityCode: play,
    } as IEntity;
    this.entityService
      .findOne(
        this.projectCode,
        this.context as Context,
        _entity.group,
        _entity.entityCode,
        "fields=drive,reel,plate_code,media&exclude_fields=_id"
      )
      .subscribe((entity) => {
        let media = [entity["media"]];

        entity = new Entity({ ...entity, projectCode: this.projectCode, context: context });
        this.noteService.getNotes(entity).subscribe((notes) => {
          this.entityService.playMedia.next({
            notes: notes || [],
            media: of(media),
            selectedVersion: version,
            src: src,
            entity: entity as Entity,
            audio: "left",
            compare: "",
            defaultThumbnail: entity["default_thumbnail"],
          });
        });
      });
  }

  onChangeColumns(columns: { name: string }[]) {
    this.columns = [...(columns as TableConfigColumnItem[])];
  }

  onSavedColumns(columns: { name: string }[]) {
    this.userService
      .saveTableConfig(
        this.projectCode,
        this.context.slice(0, this.context.length - 1),
        columns as TableConfigColumnItem[]
      )
      .subscribe();
  }

  markChildrenOutOfPicture(i: number, entity: IEntity, groupedEntities: IEntity[], isOOP: boolean) {
    let children =
      entity["__direct_children"] ||
      groupedEntities[i]["__direct_children"] ||
      entity["__children"] ||
      groupedEntities[i]["__children"];
    groupedEntities[i]["__oop_count"] = isOOP ? children : 0;

    for (let j = i + 1; j < groupedEntities.length; j++) {
      if (
        ("__depth" in groupedEntities[j] && groupedEntities[j]["__depth"] === entity["__depth"]) ||
        groupedEntities[j]["__depth"] === entity["__depth"] - 1
      ) {
        // exit if we reach an entity with the same depth or 1 depth less (new grouping)
        break;
      }

      if ("__code" in groupedEntities[j]) {
        children = groupedEntities[j]["__direct_children"] || groupedEntities[j]["__children"];
        groupedEntities[j]["__oop_count"] = isOOP ? children : 0;
      }

      groupedEntities[j] = { ...groupedEntities[j], out_of_picture: isOOP };
    }
  }

  onChangeOutOfPictureCheckbox(
    i: number,
    entity: IEntity,
    groupedEntities: IEntity[],
    $event: Event
  ) {
    const checked = ($event.target as HTMLInputElement).checked;
    if (entity["__nice_name"].startsWith("Drive")) {
      this.saveDrive(i, entity, groupedEntities, "out_of_picture", checked);
    } else {
      this.saveReel(i, entity, groupedEntities, "out_of_picture", checked);
    }
  }

  saveDrive(i: number, entity: IEntity, groupedEntities: IEntity[], field: string, value: any) {
    this.plateService
      .updateDrive(this.projectCode, entity["__code"], { [field]: value }, true)
      .subscribe((_) => {
        groupedEntities[i][field] = value;

        if (field === "out_of_picture") {
          if (this.expanded.has(entity["__code"])) {
            this.markChildrenOutOfPicture(i, entity, groupedEntities, value);
          } else {
            groupedEntities[i]["__oop_count"] = value ? entity["__children"] : 0;
          }

          if (this.isFiltersEnabled) {
            this.markChildrenOutOfPicture(i, entity, this.groupedEntities, value);
          }
        }

        delete this.editMode[entity["__code"]];
        this.cdr.markForCheck();
      });
  }

  saveReel(i: number, entity: IEntity, groupedEntities: IEntity[], field: string, value: any) {
    const drive = entity["drive"].split("_")[0];
    const reel = entity["code"];

    this.plateService
      .updateReel(this.projectCode, drive, reel, { [field]: value }, true)
      .subscribe((_) => {
        groupedEntities[i][field] = value;

        if (field === "out_of_picture") {
          if (this.expanded.has(entity["__code"])) {
            this.markChildrenOutOfPicture(i, entity, groupedEntities, value);
          } else {
            groupedEntities[i]["__oop_count"] = value ? entity["__direct_children"] : 0;
          }

          if (!value) {
            this.plateService
              .updateDrive(this.projectCode, drive, { [field]: value }, false)
              .subscribe((_) => this.updateOOPCount(i, groupedEntities, "Drive", drive, value));
          } else {
            this.updateOOPCount(i, groupedEntities, "Drive", drive, value, false);
          }
        }

        delete this.editMode[entity["__code"]];
        this.cdr.markForCheck();
      });
  }

  updateOOPCount(
    i: number,
    groupedEntities: IEntity[],
    driveOrReel: "Drive" | "Reel",
    code: string,
    isOOP: boolean,
    toggleOOP = true
  ) {
    let driveOrReelWasOOP = true;

    for (i = i - 1; i > -1; i--) {
      if (groupedEntities[i]["__nice_name"] === `${driveOrReel} ${code}`) {
        if (isOOP) {
          groupedEntities[i]["__oop_count"]++;
        } else {
          groupedEntities[i]["__oop_count"]--;
        }

        driveOrReelWasOOP = groupedEntities[i]["out_of_picture"];
        if (toggleOOP) {
          groupedEntities[i] = { ...groupedEntities[i], out_of_picture: isOOP };
        }

        break;
      }
    }

    this.cdr.markForCheck();
    return driveOrReelWasOOP;
  }

  updateParentPlateOOP(
    i: number,
    groupedEntities: IEntity[],
    plateCode: string,
    isOOP: boolean,
    toggleOOP = true
  ) {
    let parentPlateWasOOP = true;

    for (i = i - 1; i > -1; i--) {
      if (groupedEntities[i]["__nice_name"] === "Children") {
        if (isOOP) {
          groupedEntities[i]["__oop_count"]++;
        } else {
          groupedEntities[i]["__oop_count"]--;
        }
      }

      if (groupedEntities[i]["plate_code"] === plateCode) {
        parentPlateWasOOP = groupedEntities[i]["out_of_picture"];

        if (toggleOOP) {
          groupedEntities[i] = { ...groupedEntities[i], out_of_picture: isOOP };
        }

        break;
      }
    }

    this.cdr.markForCheck();
    return parentPlateWasOOP;
  }

  onToggledOOP(
    i: number,
    groupedEntities: IEntity[],
    entity: IEntity,
    $event: { isOOP: boolean; children?: number }
  ) {
    groupedEntities[i]["out_of_picture"] = $event.isOOP;

    if ($event.children) {
      this.markChildrenOutOfPicture(i + 1, entity, groupedEntities, $event.isOOP);
    }

    if (!$event.isOOP) {
      if (entity["parent"]) {
        this.entityService
          .updateOne(
            new Entity({
              entityCode: entity["parent"],
              group: entity["parent"].split("_")[0],
              projectCode: this.projectCode,
              context: this.context,
            }),
            { out_of_picture: $event.isOOP },
            false
          )
          .subscribe((_) => {
            const parentPlateWasOOP = this.updateParentPlateOOP(
              i,
              groupedEntities,
              entity["parent"],
              $event.isOOP
            );
            this.plateService
              .updateReel(
                this.projectCode,
                entity["drive"],
                entity["reel"],
                { out_of_picture: $event.isOOP },
                false
              )
              .subscribe((_) => {
                if (parentPlateWasOOP) {
                  const reelWasOOP = this.updateOOPCount(
                    i,
                    groupedEntities,
                    "Reel",
                    entity["reel"],
                    $event.isOOP
                  );
                  this.plateService
                    .updateDrive(
                      this.projectCode,
                      entity["drive"],
                      { out_of_picture: $event.isOOP },
                      false
                    )
                    .subscribe((_) => {
                      if (reelWasOOP) {
                        this.updateOOPCount(
                          i,
                          groupedEntities,
                          "Drive",
                          entity["drive"],
                          $event.isOOP
                        );
                      }
                    });
                }
              });
          });
      } else {
        this.plateService
          .updateReel(
            this.projectCode,
            entity["drive"],
            entity["reel"],
            { out_of_picture: $event.isOOP },
            false
          )
          .subscribe((_) => {
            const reelWasOOP = this.updateOOPCount(
              i,
              groupedEntities,
              "Reel",
              entity["reel"],
              $event.isOOP
            );
            this.plateService
              .updateDrive(
                this.projectCode,
                entity["drive"],
                { out_of_picture: $event.isOOP },
                false
              )
              .subscribe((_) => {
                if (reelWasOOP) {
                  this.updateOOPCount(i, groupedEntities, "Drive", entity["drive"], $event.isOOP);
                }
              });
          });
      }
    } else {
      if (entity["parent"]) {
        this.updateParentPlateOOP(i, groupedEntities, entity["parent"], $event.isOOP, false);
      } else {
        this.updateOOPCount(i, groupedEntities, "Reel", entity["reel"], $event.isOOP, false);
      }
    }
  }

  showZeroDepthOnly(groupedEntities: IEntity[]) {
    let groups = [];
    this.groupedEntities = groupedEntities;

    for (const group of groupedEntities) {
      if (group["__depth"] === 0) {
        groups.push(group);
      }
    }

    return groups;
  }

  showExpanded(groupedEntities: IEntity[]) {
    let groups = [];
    let currentlyExpanded: { entity: IEntity; counter: number }[] = [];
    this.groupedEntities = groupedEntities;

    for (let i = 0; i < groupedEntities.length; i++) {
      if (groupedEntities[i]["__depth"] === 0) {
        groups.push(groupedEntities[i]);
      }

      if (currentlyExpanded.length > 0) {
        let l = currentlyExpanded.length - 1;
        if (
          currentlyExpanded[l].counter <
            (currentlyExpanded[l].entity["__direct_children"] ||
              currentlyExpanded[l].entity["__children"]) &&
          groupedEntities[i]["__depth"] === currentlyExpanded[l].entity["__depth"] + 1
        ) {
          groups.push(groupedEntities[i]);
          if (groupedEntities[i]["__nice_name"] !== "Children") {
            currentlyExpanded[l].counter++;
          }
        }

        if (
          currentlyExpanded[l].counter ===
          (currentlyExpanded[l].entity["__direct_children"] ||
            currentlyExpanded[l].entity["__children"])
        ) {
          currentlyExpanded.pop();
        }
      }

      if (this.expanded.has(groupedEntities[i]["__code"])) {
        currentlyExpanded.push({ entity: groupedEntities[i], counter: 0 });
      }
    }

    return groups;
  }

  onSearch(searchTerm: string | undefined | string[]) {
    this.expanded = new Set([]);
    this.queryParamService.updateQueryParams({
      expand: null,
      "show-all-notes": null,
      note: null,
      "show-completed-notes": null,
    });
    this.scrollPosition = { x: 0, y: 0 };

    // Don't support array here.
    if (searchTerm === undefined || Array.isArray(searchTerm)) {
      this.searching = false;
      return;
    }

    if (searchTerm === "") {
      this.searching = false;
      this.getGroupedEntities();
      this.subs.push(this.filters$.subscribe(() => this.cdr.markForCheck()));
      return;
    }

    this.searching = true;
    let search = searchTerm;
    const plusRegexp = /\s*\+\s*/g;
    const commaRegexp = /\s*,\s*/g;
    search = search.replace(plusRegexp, "%2B").replace(commaRegexp, ",").trim();
    this.searchGroupedEntities({
      search: search,
      searchType: this.searchByOption,
      exactMatch: false,
    });
  }

  searchGroupedEntities($event: { search: string; searchType: string; exactMatch: boolean }) {
    this.groupedEntities$ = this.entityService
      .searchGroupedEntities(this.projectCode, this.context, $event)
      .pipe(map((groupedEntities) => this.showZeroDepthOnly(groupedEntities)));
  }

  searchAndFilter($event: string | Event) {
    this.expanded = new Set([]);
    this.queryParamService.updateQueryParams({
      expand: null,
      "show-all-notes": null,
      note: null,
      "show-completed-notes": null,
    });
    this.scrollPosition = { x: 0, y: 0 };

    if (this.searchTypeahead.inputString !== "") {
      this.searchGroupedEntities({
        search: this.searchTypeahead.inputString,
        searchType: this.searchByOption,
        exactMatch: false,
      });
    } else {
      if ($event !== "") {
        this.groupedEntities$ = this.entityService
          .getGroupedEntities(this.projectCode, this.context)
          .pipe(map((groupedEntities) => this.showZeroDepthOnly(groupedEntities)));
      }
    }
  }

  onToggledFilters($event: boolean) {
    this.isFiltersEnabled = $event;
    this.queryParamService.updateQueryParams({ expand: null });
    this.expanded = new Set([]);
    if (!this.isFiltersEnabled) {
      this.groupedEntities$ = this.default();
    }
  }

  scrollBack() {
    this.tableContainer.nativeElement.scrollLeft = this.scrollPosition.x;
    this.tableContainer.nativeElement.scrollTop = this.scrollPosition.y;
  }

  _deleteKey(key: string, obj: { [key: string]: any }) {
    delete obj[key];
  }

  updateEntity(entities: IEntity[], $event: { entity: Entity }) {
    const i = entities.findIndex(
      (g) =>
        new Entity({ ...g, context: this.context } as IEntity).toString() ===
        $event.entity.toString()
    );
    if (i === -1) return;

    entities[i]["__updates"] = { ...$event };
  }

  showEntity(entity: IEntity, groupedEntities?: IEntity[], i?: number) {
    let visible = false;

    if (this.showOOP) {
      visible = true;
    } else {
      if (
        groupedEntities &&
        i !== undefined &&
        (entity["__nice_name"] || "").startsWith("Children")
      ) {
        visible = groupedEntities[i - 1]["out_of_picture"] !== true;
      } else {
        visible = entity["out_of_picture"] !== true;
      }
    }

    return visible;
  }
}
