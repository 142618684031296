import { Injectable } from "@angular/core";
import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { marked as _marked } from "marked";

@Injectable({
  providedIn: "root",
})
@Pipe({
  name: "marked",
})
export class MarkedPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  marked(value: string, removeDisabledAttr = true) {
    let markedValue = _marked(value)
      .replace(/<ul>/g, '<ul style="white-space: normal;">')
      .replace(/<blockquote>/g, '<blockquote class="blockquote">')
      .replace(/<img /g, '<img class="img-fluid" ')
      .replace(/\/api\/annotations\//g, "/api/annotations/thumbnail/")
      .replace(/\/api\/annotations\/thumbnail\/thumbnail\//g, "/api/annotations/thumbnail/")
      .replace(/<a /g, '<a target="_blank" ')
      .trim();

    if (removeDisabledAttr) {
      markedValue = markedValue.replace(/ disabled=""/g, "");
    }
    return markedValue;
  }

  transform(value: string, removeDisabledAttr = true): SafeHtml {
    if (!value) {
      return value;
    }

    return this.sanitizer.bypassSecurityTrustHtml(this.marked(value, removeDisabledAttr));
  }
}
