export const domain = 'https://rtracker.elliottanimation.com';
export const frontEnd = 'https://rtracker.elliottanimation.com';

export const cuiDomain = "/c"; // cui served from the same server in production!

export const environment = {
  production: true,
  annotationsURL: `/api/annotations`,
  loginURL: `/api/login`,
  logoutURL: `/api/logout`,
  projectsURL: `/api/projects`,
  usersURL: `/api/users`,
  rolesURL: `/api/roles`,
  permissionsURL: `/api/permissions`,
  deptsURL: `/api/departments`,
  forgotPasswordURL: `/api/forgot-password`,
  tokensURL: `/api/tokens/check`,
  filtersURL: `/api/filters`,
  devListURL: `/api/dev/list`,
  downloadInfo: `/api/download-info`,
  foldersURL: `/api/folders`,
  documentationLinksURL: `/api/documentation-links`,
};
