import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  ElementRef,
  QueryList,
  Output,
  EventEmitter,
} from "@angular/core";

import { UserService } from "../user.service";
import { getUpdatedItems } from "../utils";
import { Role } from "../role";
import { Permission } from "../permission";

@Component({
  selector: "app-add-role",
  templateUrl: "./add-role.component.html",
  styleUrls: ["./add-role.component.css"],
})
export class AddRoleComponent implements OnInit {
  @ViewChild("name", { static: true }) name: ElementRef;
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;

  @Output() createdRole = new EventEmitter<Role>();

  role: Role;
  permissionsList: Permission[] = [];
  addSuccess: boolean;
  addError: boolean;
  errorMessage: string;
  currentCategory: string = "";

  constructor(private userService: UserService) {}

  ngOnInit() {
    //get ALL permissions list
    this.userService
      .getPermissionsList()
      .subscribe((permissions) => (this.permissionsList = permissions));
  }

  add(formElement: Element, name: string) {
    const permissionCheckboxes = formElement.querySelectorAll("input[type=checkbox].permissionBox");
    const selectedPermissions = getUpdatedItems(permissionCheckboxes);

    const newRole = {
      name: name,
      permissions: selectedPermissions,
    };

    this.userService.addRole(newRole).subscribe(
      (role) => {
        this.role = role;
        this.addSuccess = true;
        this.addError = false;
        this.clearForm();
        this.createdRole.emit(this.role);
      },
      (err) => {
        this.addSuccess = false;
        this.addError = true;

        if (err["status"] === 409) {
          this.errorMessage = `The role name '${this.name.nativeElement.value}' already exists.`;
        } else {
          this.errorMessage =
            "An error occured when adding this role. Please contact the administrator.";
        }
      }
    );
  }

  clearForm() {
    this.name.nativeElement.value = "";
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
  }

  isDifferentCategory(category: string) {
    if (this.currentCategory !== category) {
      this.currentCategory = category;
      return true;
    }
    return false;
  }
}
