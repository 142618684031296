<!--=========================== Upload Meeting Notes Spreadsheet ======================================-->
<div
  *ngIf="willShowUploadMeeting && isInialized"
  class="fade show d-block modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addMeetingModal"
  aria-hidden="true"
  (exited)="shouldExit($event)"
  appModalExit>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="uploadMeetingModal">Upload Meeting notes</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="willShowUploadMeeting = false; closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div
          *ngIf="errorMsg"
          class="container alert alert-danger alert-dismissible fade show"
          role="alert">
          {{ errorMsg }}
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            (click)="errorMsg = ''">
            <span aria-hidden="true">&times;</span>
          </button>
          <!--          <div *ngIf="errorMsg.includes('overwrite')" class="d-block text-right">-->
          <!--            <button type="button" class="btn btn-outline-secondary mr-1" (click)="errorMsg=''">No</button>-->
          <!--&lt;!&ndash;            upload('true') here is weird. Was changed from upload(true) because passing a boolean for meeting title didn't make sense. But this change is all around questionable &ndash;&gt;-->
          <!--            <button type="button" class="btn btn-danger" (click)="upload(true)">Yes, overwrite existing files</button>-->
          <!--          </div>-->
        </div>

        <form>
          <div class="d-flex flex-wrap">
            <h6>Meeting Title:</h6>
            <input
              #meetingTitle
              type="text"
              class="form-control my-1"
              id="meetingTitle"
              name="meetingTitle"
              placeholder="Please enter a title" />
          </div>
          <div class="d-flex flex-wrap">
            <h6>Origin:</h6>
            <input
              #meetingOrigin
              type="text"
              class="form-control my-1"
              id="meetingOrigin"
              name="meetingOrigin"
              placeholder="Please enter an origin" />
          </div>
          <div class="d-flex flex-wrap">
            <div class="form-group max-wide-input-width">
              <h6>Episode Code:</h6>
              <div class="btn2">
                <app-dropdown
                  [selectedItem]="{ text: 'Select Episode Code' }"
                  [choices]="episodeCodes"
                  [choiceKey]="'text'"
                  [emptyChoice]="{ text: 'Select Episode Code' }"
                  (selectionChanged)="selectedEpisodeCode = $event"></app-dropdown>
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap">
            <div class="form-group max-wide-input-width">
              <h6>Template:</h6>
              <div class="btn2">
                <app-dropdown
                  [selectedItem]="{ text: 'Select Template version' }"
                  [choices]="templatesList"
                  [choiceKey]="'text'"
                  [emptyChoice]="{ text: 'Select Template version' }"
                  (selectionChanged)="getSelectedTemplate($event)"></app-dropdown>
              </div>
            </div>
          </div>

          <div class="container text-left">
            <span *ngIf="this.processStatus.status !== 'Idle'">{{ this.processStatus.status }}</span
            ><span *ngIf="!['Submitted', 'Started', 'Idle'].includes(this.processStatus.status)"
              >. Please do not close this tab or your browser.
              {{ this.processStatus.progress }}</span
            >
            <div class="progress" style="height: 8px">
              <div #progressBar class="progress-bar" role="progressbar"></div>
            </div>
          </div>

          <div class="input-group mb-1">
            <div class="custom-file">
              <input
                #file1
                class="custom-file-input"
                id="custom-file1"
                type="file"
                name="file"
                [accept]="getSupportedTypes()['accepts']"
                (change)="onInputChange($event, file1)" />
              <!-- Note, I'm not sure this function call will actually work, but it's worth a try.-->
              <label
                class="custom-file-label text-center"
                [ngClass]="{
                  'font-weight-bold': file1.files?.length === 1,
                  'font-weight-normal': file1.files?.length === 0
                }"
                for="custom-file1"
                >{{
                  file1.files?.length === 1
                    ? displayFilename(file1.files?.item(0))
                    : "Click Browse or drag-n-drop a file"
                }}</label
              >
            </div>
          </div>

          <div class="container text-left" *ngIf="attachedFiles[0] != null">
            <b>Files to upload: </b
            ><span class="mr-3" *ngFor="let file of attachedFiles">{{
              displayFilename(file)
            }}</span>
          </div>

          <p class="mt-1">
            <i class="material-icons-outlined md-18 align-text-bottom">info</i> Supported types:
            {{ getSupportedTypes()["filetypes"].join(", ") }}
          </p>
          <div class="text-right">
            <button
              type="button"
              class="btn btn-primary"
              (click)="upload(meetingTitle.value, meetingOrigin.value)"
              [attr.disabled]="
                ['Started', 'Uploading', 'Processing', 'Submitted'].includes(
                  this.processStatus.status
                )
                  ? true
                  : null
              ">
              Upload
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="fade show d-block modal-backdrop"></div>
