import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
} from "@angular/core";
import { Subscription, Observable, of } from "rxjs";
import { tap, filter } from "rxjs/operators";

import { IDropdownItem } from "../dropdown-item";
import { Entity, IEntity, Context, IStatus, IMedia } from "../entity";
import { EpisodeService } from "../episode.service";
import { EntityService } from "../entity.service";
import { ProjectService } from "../project.service";
import { ScriptService } from "../script.service";
import { BundleService, IBundle } from "../bundle.service";
import { NavigationService } from "../navigation.service";
import { User } from "../user";
import { Filters } from "../../types/filters";
import { ActivatedRoute, Router, RouterEvent, NavigationEnd } from "@angular/router";
import { QueryParamService } from "../query-param.service";
import { Status, findStatusColor, HttpProgress } from "../utils";
import { TypeaheadComponent } from "../typeahead/typeahead.component";
import { PlayMedia } from "../entity.service";
import { NoteService } from "../note.service";
import { flattenAssemblyLeicaFields, compareUploadDate } from "../utils";

@Component({
  selector: "app-episode",
  templateUrl: "./episode.component.html",
  styleUrls: ["./episode.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EpisodeComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() statuses: IStatus[];
  @Input() projectCode: string;
  @Input() usersInProject: User[];
  @Input() users: User[];

  @ViewChild("selectedEpisode") selectedEpisode: TypeaheadComponent;
  @ViewChild("file") file: ElementRef<HTMLInputElement>;
  @ViewChild("tableContainer", { static: true }) tableContainer: ElementRef;
  @ViewChildren("entityRows") entityRows: QueryList<ElementRef>;

  context: Context = "episodes";
  editMode: { [key: string]: boolean } = {};
  episodes: Entity[];
  exportSpreadsheetMode: { [key: string]: boolean } = {};
  openUpload: undefined | Entity;
  uploadDirectory: string = "";
  openUploadDuedates: boolean;
  documentViewerDownloadDir: string = "scripts";
  documentViewerFileName: string;
  scriptsDocs = [];
  storyboardsDocs = [];
  openMediaPlayer: boolean;
  viewMedia: PlayMedia = {
    notes: [],
    media: of([]),
    selectedVersion: "",
    compare: "",
    audio: "left",
    src: "assembly",
    entity: new Entity({} as IEntity),
    defaultThumbnail: {} as IMedia,
    statuses: [],
  };
  scrollPosition: { x: number; y: number } = { x: 0, y: 0 };

  filters$: Observable<Filters>;
  isFiltersEnabled: boolean;

  bundles: IBundle[] = [];

  scriptEntities: Entity[] = [];
  selected_script_code = { script_code: "" };
  scriptCodes: { script_code: string }[] = [];

  subs: Subscription[] = [];

  attachedFile: File;
  processStatus: {
    status: Status;
    msg: string;
    progress: string | number;
  } = { status: "Idle", msg: "", progress: 0 };
  showProgress: (status: { status: Status; progress: string | number }) => void;

  showStatusColors = true;
  findStatusColor = findStatusColor;

  searching: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private episodeService: EpisodeService,
    private projectService: ProjectService,
    private bundleService: BundleService,
    private noteService: NoteService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected queryParamService: QueryParamService,
    private scriptService: ScriptService,
    private entityService: EntityService,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    const showStatusColors: string = window.localStorage.getItem("showStatusColors") || "";
    if (showStatusColors) {
      this.showStatusColors = showStatusColors === "true";
    }

    this.showProgress = (status: { status: Status; progress: string | number }) => {
      this.processStatus = { ...status, msg: "" };
      this.cdr.markForCheck();
    };

    this.filters$ = this.projectService.getFilters(this.projectCode, this.context).pipe(
      tap((filters) => {
        this.isFiltersEnabled = filters["is_enabled"];
        this.getScriptEntities();
        this.getEpisodes();
      })
    );

    this.bundleService.getAllBundles(this.projectCode).subscribe((bundles) => {
      this.bundles = bundles;
    });
    this.bundles.unshift({ bundle_code: "Select bundle", episodes: [], _id: { $oid: "" } });

    // Router.events don't have a NavigationEnd
    this.router.events.pipe(filter((e) => e instanceof RouterEvent)).subscribe((e) => {
      const regex = /\/projects\/\w{3,}\/shots\?expand=\w{3,}#\w{3,}/g;
      if ((e as NavigationEnd).url.match(regex)) {
        this.navigationService.clickedEpisodeLinkInEpisodeTab.next(true);
      }
    });

    this.play();
  }

  ngAfterViewInit() {
    this.entityRows.changes.subscribe((changes) => {
      const routeFragment = this.activatedRoute.snapshot.fragment;

      if (routeFragment && changes.length) {
        const fragment = document.getElementById(routeFragment);
        if (fragment) {
          this.tableContainer.nativeElement.scrollTop = fragment.offsetTop - 110;
        }
      }

      if (
        (changes.length && !this.openMediaPlayer && this.scrollPosition.x !== 0) ||
        this.scrollPosition.y !== 0
      ) {
        this.scrollBack();
      }
    });
  }

  onClickedPlay(episode: Entity, src: "assembly" | "leica" | "stages" | "storyboards") {
    this.scrollPosition = {
      x: this.tableContainer.nativeElement.scrollLeft,
      y: this.tableContainer.nativeElement.scrollTop,
    };

    if (src === "assembly") {
      this.entityService
        .findOne(
          this.projectCode,
          "shots",
          episode.entityCode,
          src,
          "fields=media&exclude_fields=_id"
        )
        .subscribe((entity) => {
          const _entity = new Entity({
            projectCode: this.projectCode,
            context: "shots",
            group: episode.entityCode,
            entityCode: src,
          } as IEntity);
          this.noteService.getNotes(_entity).subscribe((notes) => {
            if ((entity["media"] ?? []).length === 0) {
              alert(`No items in ${src} for ${_entity.toString()}.`);
              return;
            }
            this.viewMedia.media = of(entity["media"] ?? []);
            this.viewMedia.notes = notes ?? [];
            this.viewMedia.entity = _entity;
            this.viewMedia.src = src;
            this.viewMedia.selectedVersion = entity["media"][0]["_id"]["$oid"] || "";
            this.viewMedia.compare = "";
            this.viewMedia.audio = "left";
            this.viewMedia.defaultThumbnail = entity["default_thumbnail"];

            this.queryParamService.updateQueryParams({
              play: _entity.toString(),
              version: this.viewMedia.selectedVersion,
              "play-ctx": "shots",
              "play-src": src,
            });
            this.openMediaPlayer = true;
            this.cdr.markForCheck();
          });
        });
    } else {
      this.entityService
        .findOne(
          this.projectCode,
          "scripts",
          "",
          episode["script_code"] ?? "",
          `fields=${src}&exclude_fields=_id`
        )
        .subscribe((entity) => {
          const _entity = new Entity({
            projectCode: this.projectCode,
            context: "scripts",
            group: "",
            entityCode: episode["script_code"] ?? "",
          } as IEntity);
          this.noteService.getNotes(_entity).subscribe((notes) => {
            if ((entity[src] ?? []).length === 0) {
              alert(`No items in ${src} for ${_entity.toString()}.`);
              return;
            }

            let media: IMedia[] = flattenAssemblyLeicaFields(entity, src);
            media.sort(compareUploadDate);
            this.viewMedia.media = of(media ?? []);
            this.viewMedia.notes = notes ?? [];
            this.viewMedia.entity = _entity;
            this.viewMedia.src = src;
            this.viewMedia.selectedVersion = media[0]["_id"]["$oid"] || "";
            this.viewMedia.compare = "";
            this.viewMedia.audio = "left";
            this.viewMedia.defaultThumbnail = entity["default_thumbnail"];

            this.queryParamService.updateQueryParams({
              play: _entity.toString(),
              version: this.viewMedia.selectedVersion,
              "play-ctx": "scripts",
              "play-src": src,
            });
            this.openMediaPlayer = true;
            this.cdr.markForCheck();
          });
        });
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  onToggledFilters($event: boolean) {
    this.isFiltersEnabled = $event;
    this.getEpisodes();
  }

  onChangeAttachedFile($event: Event) {
    const target = $event.target as HTMLInputElement;
    const file0 = target?.files?.item(0);
    if (target.files && file0) {
      this.attachedFile = file0;
    }
  }

  getEpisodes() {
    this.entityService
      .getUnGroupedEntities(this.projectCode, this.context)
      .subscribe((eps: IEntity[]) => {
        let episodes: Entity[] = eps.map((ep) => {
          ep.projectCode = this.projectCode;
          ep.context = this.context;
          const e = new Entity(ep);
          this.editMode[e.toString()] = false;
          return e;
        });

        this.episodes = episodes;
        this.cdr.markForCheck();
      });
  }

  getEpisodesWithFilters() {
    this.filters$ = this.projectService.getFilters(this.projectCode, this.context).pipe(
      tap((filters) => {
        this.isFiltersEnabled = filters["is_enabled"];
        this.getEpisodes();
      })
    );
  }

  hideEpisode(index: number) {
    setTimeout(() => {
      this.episodes.splice(index, 1);
      this.cdr.markForCheck();
    }, 500);
  }

  updateScriptCode(episode: Entity, scriptCode: IDropdownItem) {
    let itemTobeUpdated = { ...scriptCode };
    const s_code = scriptCode["script_code"] as string;
    if (episode["script_code"] === s_code) {
      return;
    }
    itemTobeUpdated["previous_script_code"] = episode["script_code"];
    itemTobeUpdated["script_code"] = s_code.includes("Select script") ? "" : s_code;
    episode["script_code"] = s_code.includes("Select script") ? "" : s_code;

    this.episodeService.update(episode, itemTobeUpdated).subscribe(() => {
      this.editMode[`${episode.toString()}`] = false;
      this.cdr.markForCheck();
    });
  }

  createBundle() {
    this.bundleService.add(this.projectCode).subscribe((bundle) => {
      this.bundles.push(bundle);
      alert(`Bundle #${bundle["bundle_code"]} has been created`);
      this.cdr.markForCheck();
    });
  }

  updateBundleCode(episode: Entity, bundleCode: IDropdownItem) {
    if (episode["bundle_code"].toString() === bundleCode["bundle_code"]) {
      return;
    }

    const previousBundleCode = episode["bundle_code"];
    let itemTobeUpdated = { bundle_code: parseInt(bundleCode["bundle_code"], 10) };
    episode["bundle_code"] =
      bundleCode["bundle_code"] === "Select bundle" ? "" : bundleCode["bundle_code"];
    this.episodeService.update(episode, itemTobeUpdated).subscribe(() => {
      if (bundleCode["bundle_code"] !== "Select bundle") {
        this.bundleService
          .updateBundleEpisodes(this.projectCode, bundleCode["bundle_code"], {
            add: [episode.group],
            remove: [],
          })
          .subscribe();
      }
      if (previousBundleCode) {
        this.bundleService
          .updateBundleEpisodes(this.projectCode, previousBundleCode, {
            add: [],
            remove: [episode.group],
          })
          .subscribe();
      }

      this.editMode[`${episode.toString()}`] = false;
      this.cdr.markForCheck();
    });
  }

  saveEpisode(value: any, episode: Entity, field: string, index: number) {
    if (!this.editMode[`${episode.toString()}_${field}`]) {
      return;
    }

    this.episodeService.update(episode, { [field]: value }).subscribe(() => {
      this.episodes[index][field] = value;
      this.editMode[`${episode.toString()}_${field}`] = false;
      this.cdr.markForCheck();
    });
  }

  saveEpisodeStatus(episode: Entity, status: IDropdownItem) {
    let found = this.episodes.findIndex((ep) => ep.toString() === episode.toString());

    this.episodeService.update(episode, { episode_status: status }).subscribe(() => {
      this.episodes[found]["episode_status"] = status;
      this.cdr.markForCheck();
    });
  }

  getScriptEntities() {
    this.scriptService.getAllEntities(this.projectCode).subscribe((entities) => {
      this.scriptEntities = (entities as IEntity[]).map((entity) => {
        entity.projectCode = this.projectCode;
        entity.context = "scripts";
        return new Entity(entity);
      });
      this.scriptEntities.forEach((element) => {
        this.scriptCodes.push({ script_code: element["script_code"] || "" });
      });
      this.scriptCodes.unshift({ script_code: "Select script" });
      this.cdr.markForCheck();
    });
  }

  isAvailable(theEpisode: Entity, theProperty: string): boolean {
    var theScript: Entity;
    let found = -1;
    let key = "latest_" + theProperty;

    if (theProperty === "assembly") {
      return theEpisode[key] !== undefined;
    }

    found = this.scriptEntities.findIndex(
      (script) => script.script_code === theEpisode.script_code
    );
    if (found !== -1) {
      theScript = this.scriptEntities[found];
      return theScript[key] !== undefined;
    } else {
      return false;
    }
  }

  openUploadComponent(theEpisode: Entity) {
    let found = -1;
    found = this.scriptEntities.findIndex(
      (script) => script.script_code === theEpisode.script_code
    );
    if (found !== -1) {
      this.openUpload = this.scriptEntities[found];
    } else {
      this.openUpload = undefined;
    }
  }

  startEditMode(key: string, value: boolean) {
    this.editMode[key] = value;
  }

  onClose() {
    if (this.uploadDirectory === "assembly") {
      this.entityService
        .findOne(this.projectCode, "episodes", "", (this.openUpload as Entity).toString())
        .subscribe((episode) => {
          let i = this.episodes.findIndex((e) => e.entityCode === episode["episode_code"]);
          const updated = new Entity({
            ...episode,
            projectCode: this.projectCode,
            context: "episodes",
          } as IEntity);
          this.episodes[i] = updated;
          this.openUpload = undefined;
          this.cdr.markForCheck();
        });
    } else {
      // scripts, storyboards, leica are stored in the script collection
      this.entityService
        .findOne(this.projectCode, "scripts", "", (this.openUpload as Entity).toString())
        .subscribe((script) => {
          let i = this.scriptEntities.findIndex(
            (s) => s.episode_code === (this.openUpload as Entity)["episode_code"]
          );
          if (i > -1) {
            const updated = new Entity({
              ...script,
              projectCode: this.projectCode,
              context: "scripts",
            } as IEntity);
            this.scriptEntities[i] = updated;

            let j = this.episodes.findIndex(
              (e) => e.entityCode === this.scriptEntities[i]["episode_code"]
            );
            let pointer = { ...this.episodes[j] };
            this.episodes[j] = new Entity({} as IEntity);
            this.episodes[j] = new Entity({ ...pointer });
            this.openUpload = undefined;
            this.cdr.markForCheck();
          }
        });
    }
  }

  exportSpreadsheet(theEpisode: Entity) {
    let episodeName = theEpisode["episode_name"].replace(" ", "_");
    let filename = theEpisode.entityCode + "_" + episodeName + ".xlsx";

    this.exportSpreadsheetMode[theEpisode.toString()] = true;

    this.episodeService
      .entityExportSpreadsheet(theEpisode as Entity, this.showProgress)
      .subscribe((_data) => {
        this.postDownload(_data, filename);
        this.exportSpreadsheetMode[theEpisode.toString()] = false;
        this.cdr.markForCheck();
      });
  }

  postDownload(_data: HttpProgress<Blob>, filename: string) {
    const data = _data?.body;
    if (data === undefined || data === null) {
      return;
    }
    const blob = new Blob([data], { type: data.type });
    const url = window.URL.createObjectURL(blob);

    const location = document.createElement("a");
    location.href = url;
    location.download = filename;
    location.dispatchEvent(new MouseEvent("click"));

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  customToString(item: IEntity) {
    return `${item["entityCode"]} - ${item["episode_name"]}`;
  }

  upload() {
    this.processStatus = { status: "Idle", msg: "", progress: 0 };
    const episode = this.selectedEpisode.inputString.trim();

    if (!episode) {
      alert("No episode selected.");
      return;
    }

    this.episodeService
      .uploadDueDates(this.attachedFile, this.projectCode, episode, this.showProgress)
      .subscribe(
        (_) => {
          this.processStatus = { status: "Complete", msg: "", progress: 100 };
          this.file.nativeElement.value = "";
          this.cdr.markForCheck();
        },
        (_) => {
          this.processStatus = { status: "Idle", msg: "", progress: 0 };
          this.file.nativeElement.value = "";
          this.cdr.markForCheck();
        },
        () => {
          this.file.nativeElement.value = "";
        }
      );
  }

  onChangeShowStatusColors() {
    this.showStatusColors = !this.showStatusColors;
    window.localStorage.setItem("showStatusColors", String(this.showStatusColors));
  }

  scrollBack() {
    this.tableContainer.nativeElement.scrollLeft = this.scrollPosition.x;
    this.tableContainer.nativeElement.scrollTop = this.scrollPosition.y;
  }

  play() {
    const play = this.activatedRoute.snapshot.queryParams["play"];
    const version = this.activatedRoute.snapshot.queryParams["version"];
    const context = this.activatedRoute.snapshot.queryParams["play-ctx"];
    const src = this.activatedRoute.snapshot.queryParams["play-src"];
    const compare = this.activatedRoute.snapshot.queryParams["compare"];
    const audio = this.activatedRoute.snapshot.queryParams["audio"] || "left";
    if (!play && !version && !context && !src) {
      return;
    }

    if (this.tableContainer) {
      this.scrollPosition = {
        x: this.tableContainer.nativeElement.scrollLeft,
        y: this.tableContainer.nativeElement.scrollTop,
      };
    }

    const _entity = {
      projectCode: this.projectCode,
      context: context as Context,
      group: context === "scripts" ? "" : play.split("_")[0],
      entityCode: context === "scripts" ? play : play.split("_").slice(1).join("_"),
    } as IEntity;
    this.entityService
      .findOne(
        this.projectCode,
        _entity.context,
        _entity.group,
        _entity.entityCode,
        "fields=episode_code,script_code,media,leica,stages,storyboards&exclude_fields=_id"
      )
      .subscribe((entity) => {
        let media: IMedia[] = [];
        if (context === "scripts" && src !== "assembly") {
          media = flattenAssemblyLeicaFields(entity, src);
          media.sort(compareUploadDate);
        } else {
          media = entity["media"];
        }

        entity = new Entity({ ...entity, projectCode: this.projectCode, context: context });
        this.noteService.getNotes(entity).subscribe((notes) => {
          (this.viewMedia.notes = notes || []),
            (this.viewMedia.media = of(media)),
            (this.viewMedia.selectedVersion = version),
            (this.viewMedia.compare = compare),
            (this.viewMedia.audio = audio),
            (this.viewMedia.src = src),
            (this.viewMedia.entity = entity as Entity),
            (this.viewMedia.defaultThumbnail = entity["default_thumbnail"]),
            (this.viewMedia.statuses = this.statuses),
            (this.openMediaPlayer = true);
          this.cdr.markForCheck();
        });
      });
  }

  onSearch(searchTerm: string | string[] | undefined) {
    this.queryParamService.updateQueryParams({
      expand: null,
      "show-all-notes": null,
      note: null,
      "show-completed-notes": null,
    });
    this.scrollPosition = { x: 0, y: 0 };

    if (searchTerm === undefined || Array.isArray(searchTerm)) {
      // search is cancelled by pressing 'esc')
      this.searching = false;
      return;
    }

    if (searchTerm === "") {
      this.searching = false;
      this.getEpisodesWithFilters();
      this.subs.push(this.filters$.subscribe(() => this.cdr.markForCheck()));
      return;
    }

    this.searching = true;
    let search = searchTerm;
    const plusRegexp = /\s*\+\s*/g;
    const commaRegexp = /\s*,\s*/g;
    search = search.replace(plusRegexp, "%2B").replace(commaRegexp, ",").trim();
    this.entityService
      .searchUnGroupedEntities(this.projectCode, "episodes", {
        search: search,
        searchType: "entityCode",
        exactMatch: true,
      })
      .subscribe((episodes) => {
        this.episodes = episodes.map((ep) => {
          ep.projectCode = this.projectCode;
          ep.context = this.context;
          return new Entity(ep);
        });
        this.cdr.markForCheck();
      });
  }
}
