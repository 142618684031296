import { ElementRef, ViewChild, Directive } from "@angular/core";

@Directive()
export class DropdownArrowSelect {
  focusedItem = -1;
  step = 1;
  maxItemsShown = 10;
  liHeight = 27; // or this.dropdown.nativeElement.children[0].children[0].offsetHeight;
  maxHeight = this.liHeight * this.maxItemsShown; // or this.dropdown.nativeElement.offsetHeight;
  showDropdown = false;

  /*
   * Used to store the scroll value if dropdown-menu is not shown.
   * The user can 'scroll' without the dropdown menu being shown using the up/down arrow keys.
   * We calculate the scroll value even if the dropdown-menu is not shown so that when the user opens the dropdown menu,
   * it will scroll to the correct item that the user had selected using the arrow keys.
   */
  currentScroll = 0;

  @ViewChild("dropdown") dropdown: ElementRef;

  arrowSelect(i: number, choices: number): number {
    if (!this.dropdown) {
      return -1;
    }

    if (this.focusedItem + i < 0 || this.focusedItem + i >= choices) {
      return this.focusedItem;
    }

    if (choices < this.maxItemsShown) {
      this.maxHeight = this.liHeight * choices;
    }

    this.focusedItem += i;
    let bottomEdge = (this.step - 1) * this.liHeight + (this.maxHeight - this.liHeight);

    if (i > 0) {
      if (this.liHeight * this.focusedItem > bottomEdge) {
        this.dropdown.nativeElement.scrollTop = this.step * this.liHeight;
        this.currentScroll = this.step * this.liHeight;
        this.step += i;
      }
    } else {
      if (this.showDropdown) {
        if (this.liHeight * this.focusedItem < this.dropdown.nativeElement.scrollTop) {
          this.dropdown.nativeElement.scrollTop = this.liHeight * this.focusedItem;
          this.currentScroll = this.liHeight * this.focusedItem;
          this.step += i;
        }
      } else {
        if (this.liHeight * this.focusedItem < this.currentScroll) {
          this.currentScroll = this.liHeight * this.focusedItem;
          this.step += i;
        }
      }
    }

    return this.focusedItem;
  }
}
