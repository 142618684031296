import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  constructor(private http: HttpClient) {}

  checkTokenValidity(token: string): Observable<{ msg: string }> {
    const url = `${environment.tokensURL}/${token}`;
    return this.http.get<{ msg: string }>(url);
  }
}
