import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { last, map, tap } from "rxjs/operators";

import { Entity } from "./entity";
import { environment } from "../environments/environment";
import { DownloadProgress, HttpProgress, UploadProgress } from "./utils";

export type CategorizedFileItem = { type: string; name: string; is_workfile?: boolean };

export type DownloadInfoDiff = {
  frame_range?: "new" | "outdated" | "up to date";
  audio?: "new" | "outdated" | "up to date";
  plate?: "new" | "outdated" | "up to date";
  [rnNode_or_frameRangeChangedFrom: string]: "new" | "outdated" | "up to date" | undefined;
};

@Injectable({
  providedIn: "root",
})
export class FileBrowserService {
  constructor(private http: HttpClient) {}

  projectListDir(
    projectCode: string,
    entityCode: string,
    path?: string
  ): Observable<CategorizedFileItem[]> {
    const url = path
      ? `${environment.projectsURL}/${projectCode}/${entityCode}/listdir?path=${path}`
      : `${environment.projectsURL}/${projectCode}/${entityCode}/listdir`;

    return this.http.get<CategorizedFileItem[]>(url);
  }

  entityFileNeedsProcessing(
    entity: Entity,
    path: string,
    rootDir: string = ""
  ): Observable<boolean> {
    let url = "";

    if (rootDir) {
      url = path
        ? `${environment.projectsURL}/${entity.projectCode}/${rootDir}/${entity.context}/${entity.group}/${entity.entityCode}/file-needs-processing?path=${path}`
        : `${environment.projectsURL}/${entity.projectCode}/${rootDir}/${entity.context}/${entity.group}/${entity.entityCode}/file-needs-processing`;
    } else {
      url = path
        ? `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/file-needs-processing?path=${path}`
        : `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/file-needs-processing`;
    }

    return this.http.get<boolean>(url);
  }

  entityListDir(
    entity: Entity,
    path?: string,
    rootDir: string = ""
  ): Observable<CategorizedFileItem[]> {
    let url = "";

    if (rootDir) {
      url = path
        ? `${environment.projectsURL}/${entity.projectCode}/${rootDir}/${entity.context}/${entity.group}/${entity.entityCode}/listdir?path=${path}`
        : `${environment.projectsURL}/${entity.projectCode}/${rootDir}/${entity.context}/${entity.group}/${entity.entityCode}/listdir`;
    } else {
      url = path
        ? `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/listdir?path=${path}`
        : `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/listdir`;
    }

    return this.http.get<CategorizedFileItem[]>(url);
  }

  projectDownloadFile(
    projectCode: string,
    entityCode: string,
    path: string,
    showProgress: (arg0: HttpProgress<Blob>) => void
  ): Observable<HttpProgress<Blob>> {
    const url = path
      ? `${environment.projectsURL}/${projectCode}/${entityCode}/download?path=${path}`
      : `${environment.projectsURL}/${projectCode}/${entityCode}/download`;

    const req = new HttpRequest("GET", url, { reportProgress: true, responseType: "blob" });

    return this.http.request<Blob>(req).pipe(
      map((event) => DownloadProgress.getEventMessage(event)),
      tap((message) => showProgress(message)),
      last() // return last (completed) message to caller
    );
  }

  projectDownloadFiles(
    projectCode: string,
    entityCode: string,
    path: string,
    files: string[],
    showProgress: (arg0: HttpProgress<Blob>) => void
  ): Observable<HttpProgress<Blob>> {
    const url = path
      ? `${
          environment.projectsURL
        }/${projectCode}/${entityCode}/${path}/download-multi?files=${files.join(",")}`
      : `${environment.projectsURL}/${projectCode}/${entityCode}/download-multi?files=${files.join(
          ","
        )}`;

    const req = new HttpRequest("GET", url, { reportProgress: true, responseType: "blob" });

    return this.http.request<Blob>(req).pipe(
      map((event) => DownloadProgress.getEventMessage(event)),
      tap((message) => showProgress(message)),
      last() // return last (completed) message to caller
    );
  }

  getPreviewFileURL(entity: Entity, path: string, rootDir: string): string {
    let url = "";

    if (rootDir) {
      if (!entity.group) {
        url = `${environment.projectsURL}/${entity.projectCode}/${rootDir}/${entity.context}/${entity.entityCode}/download?path=${path}&preview=true`;
      } else {
        url = path
          ? `${environment.projectsURL}/${entity.projectCode}/${rootDir}/${entity.context}/${entity.group}/${entity.entityCode}/download?path=${path}&preview=true`
          : `${environment.projectsURL}/${entity.projectCode}/${rootDir}/${entity.context}/${entity.group}/${entity.entityCode}/download?preview=true`;
      }
    } else {
      url = path
        ? `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/download?path=${path}&preview=true`
        : `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/download?preview=true`;
    }

    return url;
  }

  entityDownloadFile(
    entity: Entity,
    path: string,
    rootDir: string = "",
    showProgress: (arg0: HttpProgress<Blob>) => void,
    needsProcessing: boolean,
    fromUpstream: boolean = false,
    whitelist?: string[]
  ): Observable<any> {
    let url = "";

    if (rootDir) {
      if (!entity.group) {
        url = `${environment.projectsURL}/${entity.projectCode}/${rootDir}/${entity.context}/${entity.entityCode}/download?path=${path}`;
      } else {
        url = path
          ? `${environment.projectsURL}/${entity.projectCode}/${rootDir}/${entity.context}/${entity.group}/${entity.entityCode}/download?path=${path}&from-upstream=${fromUpstream}`
          : `${environment.projectsURL}/${entity.projectCode}/${rootDir}/${entity.context}/${entity.group}/${entity.entityCode}/download`;
      }
    } else {
      url = path
        ? `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/download?path=${path}&from-upstream=${fromUpstream}`
        : `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/download`;
    }

    if (whitelist) {
      url = `${url}&whitelist=${whitelist.join(",")}`;
    }

    if (needsProcessing || fromUpstream) {
      return this.http.get<any>(url);
    }

    const req = new HttpRequest("GET", url, { reportProgress: true, responseType: "blob" });

    return this.http.request<Blob>(req).pipe(
      map((event) => DownloadProgress.getEventMessage<Blob>(event)),
      tap((message) => showProgress(message)),
      last() // return last (completed) message to caller
    );
  }

  downloadMedia(
    srcURL: string,
    showProgress: (arg0: HttpProgress<Blob>) => void
  ): Observable<HttpProgress<Blob>> {
    const req = new HttpRequest("GET", `${srcURL}&as-attachment=true`, {
      reportProgress: true,
      responseType: "blob",
    });

    return this.http.request<Blob>(req).pipe(
      map((event) => DownloadProgress.getEventMessage(event)),
      tap((message) => showProgress(message)),
      last() // return last (completed) message to caller
    );
  }

  getLatestDownloadInfoForEntity(entity: Entity, path: string) {
    /**
     * Note: on the backend, this only supports shots for the time being.
     */
    const url = `${environment.downloadInfo}/projects/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/${path}`;
    return this.http.get<DownloadInfoDiff>(url);
  }

  createFolder(entity: Entity, path: string): Observable<string> {
    /**
     * os.path.basename(path) from the Python side.
     */
    const url = `${environment.foldersURL}/projects/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/${path}`;
    return this.http.post<string>(url, null);
  }

  deletePath(entity: Entity, path: string): Observable<""> {
    /**
     * Note: deletion currently only permitted for tdi00 project.
     */
    const url = `${environment.foldersURL}/projects/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/${path}`;
    return this.http.delete<"">(url);
  }

  uploadFiles(
    files: File[],
    path: string,
    entity: Entity,
    showProgress: (arg0: HttpProgress<{}>) => void
  ): Observable<HttpProgress<{}>> {
    let formData = new FormData();
    for (const file of files) {
      if (file) {
        formData.append("file", file, file.name);
      }
    }

    const req = new HttpRequest(
      "POST",
      `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/${path}/upload`,
      formData,
      { reportProgress: true, headers: new HttpHeaders({ enctype: "multipart/form-data" }) }
    );

    return this.http.request<{}>(req).pipe(
      map((event) => UploadProgress.getEventMessage<{}>(event)),
      tap((message) => showProgress(message)),
      last() // return last (completed) message to caller
    );
  }

  downloadFiles(
    files: string[],
    path: string,
    entity: Entity,
    showProgress: (arg0: HttpProgress<Blob>) => void,
    omitVersions: boolean = false
  ): Observable<HttpProgress<Blob>> {
    let url: string;
    if (path === "") {
      url = `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${
        entity.entityCode
      }/download-multi?files=${files.join(",")}`;
    } else {
      url = `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${
        entity.entityCode
      }/${path}/download-multi?files=${files.join(",")}`;
    }

    if (omitVersions) {
      url = `${url}&omit-versioning=1`;
    }

    const req = new HttpRequest("GET", url, { reportProgress: true, responseType: "blob" });

    return this.http.request<Blob>(req).pipe(
      map((event) => DownloadProgress.getEventMessage<Blob>(event)),
      tap((message) => showProgress(message)),
      last() // return last (completed) message to caller
    );
  }

  deleteFiles(files: string[], path: string, entity: Entity): Observable<""> {
    let url: string;
    if (path === "") {
      url = `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${
        entity.entityCode
      }/delete-multi?files=${files.join(",")}`;
    } else {
      url = `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${
        entity.entityCode
      }/${path}/delete-multi?files=${files.join(",")}`;
    }
    return this.http.delete<"">(url);
  }

  downloadRenders(entity: Entity, dept: string, showProgress: (arg0: HttpProgress<Blob>) => void) {
    const url = `${environment.projectsURL}/${entity.projectCode}/renders/${entity.context}/${entity.group}/${entity.entityCode}/${dept}/download`;
    const req = new HttpRequest("GET", url, { reportProgress: true, responseType: "blob" });

    return this.http.request<Blob>(req).pipe(
      map((event) => DownloadProgress.getEventMessage<Blob>(event)),
      tap((message) => showProgress(message)),
      last() // return last (completed) message to caller
    );
  }
}
