import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

import { TableConfigColumnItem } from "../../types/table-config";

@Component({
  selector: "app-display-columns",
  templateUrl: "./display-columns.component.html",
  styleUrls: ["./display-columns.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayColumnsComponent implements OnInit {
  showColumns = false;

  @Input() columns: TableConfigColumnItem[];

  @Output() changedVisibility = new EventEmitter<TableConfigColumnItem[]>();
  @Output() saved = new EventEmitter<TableConfigColumnItem[]>();

  constructor() {}

  ngOnInit() {}

  toggleVisibility(i: number, column: TableConfigColumnItem) {
    this.columns[i]["visible"] = !column["visible"];
    this.columns[i] = { ...column };

    this.changedVisibility.emit(this.columns);
  }
}
