<div
  *ngIf="play$ | async as media"
  class="show d-block modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mediaPlayer"
  aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <div class="d-flex flex-wrap align-items-center">
          <span>{{ entity.toString() }}:</span>

          <app-dropdown
            class="text-monospace"
            [selectedItem]="selectedVersion1"
            [choices]="media"
            [choiceKey]="'text'"
            [itemTransformer]="mediaToString"
            [emitChoiceObj]="true"
            [showDropArrow]="true"
            (selectionChanged)="onChangeVersion($event)"></app-dropdown>

          <ng-container *ngIf="entity.context !== 'plates'">
            <span
              class="hover-pointer download"
              title="Download media"
              (click)="
                srcType === 'pdf'
                  ? downloadPDF(selectedVersion1.pdf_file.file_path, false)
                  : download()
              ">
              <i class="material-icons md-24 align-middle">get_app</i>
            </span>
            <span
              *ngIf="allowDownloadWorkfileInMediaPlayer"
              class="hover-pointer download"
              title="Download workfile"
              (click)="downloadWorkFile()">
              <i class="material-icons md-24 align-middle">system_update_alt</i>
            </span>
            <span
              *ngIf="allowDownloadWorkfileInMediaPlayer"
              class="hover-pointer download"
              title="Download renders"
              (click)="downloadRenders()">
              <i class="material-icons md-24 align-middle">move_down</i>
              <span *ngIf="status === 'Processing'">Preparing your download, please wait.</span>
            </span>

            <span *ngIf="status && progress">{{ status }} {{ progress }}%</span>
            <span
              *ngIf="selectedVersion1['_id']['$oid'] === defaultThumbnailId"
              class="badge badge-pill badge-info"
              >Default thumbnail</span
            >
            <button
              *ngIf="selectedVersion1['_id']['$oid'] !== defaultThumbnailId"
              type="button"
              class="btn btn-primary btn-sm mr-5"
              (click)="setDefaultThumbnail()">
              Set as default thumbnail
            </button>
            <button
              *ngIf="defaultThumbnailId"
              type="button"
              class="btn btn-secondary btn-sm mr-5"
              (click)="revertDefaultThumbnail()">
              Revert default thumbnail
            </button>
            <button
              *ngIf="mediaSource === 'CATALOG'"
              type="button"
              class="btn btn-secondary btn-sm mr-5"
              (click)="removeFromCatalog()">
              <!--              <span class="material-icons">bookmark_remove</span>-->
              Remove from catalog
            </button>
          </ng-container>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closePlayer()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <ng-container *ngIf="selectedVersion1 && srcType !== 'pdf'">
          <div class="form-check d-flex align-items-center">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="compare"
              [checked]="isComparing"
              (change)="toggleCompare()" />
            <label class="form-check-label" for="compare">Compare:</label>
            <app-dropdown
              *ngIf="isComparing"
              class="text-monospace"
              [selectedItem]="selectedVersion2"
              [choices]="filterChoicesByType()"
              [choiceKey]="'text'"
              [itemTransformer]="mediaToString"
              [emitChoiceObj]="true"
              [showDropArrow]="true"
              (selectionChanged)="onChangeVersion2($event)"></app-dropdown>

            <ng-container *ngIf="isComparing && srcType === 'video'">
              <div class="form-check mr-3">
                <input
                  class="form-check-input"
                  type="radio"
                  name="audio"
                  id="left"
                  value="left"
                  [checked]="audio === 'left' ? true : undefined"
                  (change)="changeAudio($event)" />
                <label class="form-check-label" for="left">Audio from left split</label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="audio"
                  id="right"
                  value="right"
                  [checked]="audio === 'right' ? true : undefined"
                  (change)="changeAudio($event)" />
                <label class="form-check-label" for="right">Audio from right split</label>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <div class="modal-body row column-1">
        <div
          #iFrameContainer
          *ngIf="mediaSource === 'stages' || mediaSource === 'storyboards'"
          class="col-xl-8"
          [style.height]="srcType === 'pdf' ? '85vh' : null">
          <!-- iframe content placeholder -->
        </div>
        <app-canvas
          *ngIf="mediaSource !== 'stages' && mediaSource !== 'storyboards'"
          #canvasComponent
          [ngClass]="{
            'col-xl-8 p-0': entity.context !== 'plates',
            'w-100': entity.context === 'plates'
          }"
          style="z-index: 10"
          [useAdvancedControls]="entity.context !== 'plates'"
          [src]="srcURL"
          [src2]="src2URL"
          [srcType]="srcType"
          [frameTimestamps]="
            !isComparing
              ? selectedVersion1['frame_timestamps']
              : (selectedVersion1['frame_timestamps'] || []).length >=
                (selectedVersion2['frame_timestamps'] || []).length
              ? selectedVersion1['frame_timestamps']
              : selectedVersion2['frame_timestamps']
          "
          [isComparing]="isComparing"
          [activeAudio]="audio">
        </app-canvas>
        <div *ngIf="entity.context !== 'plates'" class="p-0 col-xl-4">
          <div
            *ngIf="entity.context === 'assets' || entity.context === 'shots'"
            class="status-component">
            {{ selectedVersion1["dept"] | titlecase }} status:
            <app-dropdown
              (selectionChanged)="saveShotStatus($event, selectedVersion1['dept'])"
              [selectedItem]="entity[selectedVersion1['dept'].concat('_status')]"
              [choices]="statuses"
              [choiceKey]="'text'"
              [emptyChoice]="{ text: 'waiting_to_start', icon: 'remove' }"
              [showIconOnlyForSelectedItem]="true"></app-dropdown>
          </div>
          <app-notes
            #notesComponent
            [notes]="notes"
            [statuses]="statuses"
            [entity]="entity"
            [media]="media"
            [users]="users"
            [mediaVersion]="$any(selectedVersion1 || media[0])"
            [mediaVersion2]="isComparing ? selectedVersion2 : undefined"
            [shouldFilterByDeptOnAdd]="true"
            [canvasComponent]="canvasComponent"
            [willShowModal]="false"
            (changedVersion)="onChangeVersion($event)"
            (showAoverB)="toggleAoverB($event)">
          </app-notes>
        </div>

        <div *ngIf="entity.context !== 'plates'" class="w-100 d-block">
          <div *ngIf="mediaSource === 'CATALOG'" class="d-block">
            Catalog Description: {{ selectedVersion1["catalog_description"] }}
          </div>
          <div *ngIf="mediaSource === 'CATALOG'" class="d-block mb-2">
            Catalog Comment: {{ selectedVersion1["catalog_comment"] }}
          </div>
          <div class="d-block">Comment: {{ selectedVersion1["comment"] || "" }}</div>
          <div class="d-block">{{ getSourceFile(selectedVersion1) }}</div>
          <hr />

          <div *ngIf="mediaSource === 'MEDIA'" class="row align-items-end">
            <div class="col-md-2">
              <label class="font-smaller m-0">Description:</label>
              <input
                #catalogDescription
                type="text"
                class="form-control form-control-sm d-inline-block"
                [value]="'Approved ' + selectedVersion1['dept']" />
            </div>
            <div class="col-md-2">
              <label class="font-smaller m-0">Comment:</label>
              <input
                #catalogComment
                type="text"
                class="form-control form-control-sm d-inline-block"
                placeholder="optional" />
            </div>
            <div class="col-md-1">
              <label class="font-smaller m-0"></label>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                (click)="addToCatalog(catalogDescription.value, catalogComment.value)">
                Add to Catalog
              </button>
            </div>
            <div
              *ngIf="added"
              class="col-md-2 font-smaller alert alert-success alert-dismissible mb-0"
              role="alert">
              Successfully added to Catalog!
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
                (click)="added = false">
                <span class="font-small" aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
