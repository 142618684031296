import {Component, HostListener, OnInit} from '@angular/core';
import {domain} from '../../environments/environment';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-swagger-api-reference',
  templateUrl: './swagger-api-reference.component.html',
  styleUrls: ['./swagger-api-reference.component.css']
})
export class SwaggerApiReferenceComponent implements OnInit {

  swaggerURL: SafeUrl;
  windowHeight: number;

  constructor(private sanitizer: DomSanitizer) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowHeight = window.innerHeight;
  }

  ngOnInit(): void {
    this.windowHeight = window.innerHeight;
    this.swaggerURL = this.sanitizer.bypassSecurityTrustResourceUrl(`${domain}/api/reference`);
  }

}
