import { Pipe, PipeTransform } from "@angular/core";
import { Observable, of, zip } from "rxjs";
import { EntityService } from "./entity.service";
import { TableConfigSubCol, TableConfigColumnItem } from "../types/table-config";
import { map, switchMap } from "rxjs/operators";
import { Entity, IEntity, Context, IMedia, isIEntity, MediaType } from "./entity";
import { mediaToString } from "./utils";
import { ScriptService } from "./script.service";
import { EpisodeService } from "./episode.service";

@Pipe({
  name: "runner",
})
export class RunnerPipe implements PipeTransform {
  transform(value: any, func: Function): any {
    return func(value);
  }
}

@Pipe({
  name: "entity",
})
export class EntityPipe implements PipeTransform {
  transform(entity: IEntity, projectCode: string, context: Context) {
    if (!entity) {
      return new Entity({
        projectCode: projectCode,
        context: context,
        group: "---",
        entityCode: "---",
      } as IEntity);
    }

    entity.projectCode = projectCode;
    entity.context = context;
    return new Entity({ ...entity });
  }
}

@Pipe({
  name: "entityToString",
})
export class EntityToStringPipe implements PipeTransform {
  transform(entity: Entity) {
    if (entity.context === "plates" || entity.context === "scripts") {
      return entity.entityCode;
    }
    if (entity.context === "episodes") {
      return entity.group;
    }

    return entity.entityCode
      ? `${entity.group ? entity.group.concat("_") : ""}${entity.entityCode}`
      : `${entity.group}`;
  }
}

@Pipe({
  name: "depthAsPadding",
})
export class DepthAsPaddingPipe implements PipeTransform {
  transform(depth: number, twoIsZero = true) {
    if (twoIsZero && depth === 2) return "0 rem";
    return `${depth || 0}rem`;
  }
}

@Pipe({
  name: "thumbnailURL",
})
export class ThumbnailURLPipe implements PipeTransform {
  constructor(
    private entityService: EntityService,
    private scriptService: ScriptService,
    private episodeService: EpisodeService
  ) {}

  transform(entity: Entity, leicaOrAsm?: "leica" | "assembly", stories?: Entity[]): string {
    if (entity["default_thumbnail"]) {
      return this.entityService.getMediaURL(entity, MediaType.THUMBNAIL, {
        _id: entity["default_thumbnail"]["_id"],
      } as IMedia);
    }

    if (leicaOrAsm && entity.context === "scripts") {
      if (leicaOrAsm === "leica") {
        let stage = "";
        let version = "";
        if (entity[`thumbnail_${leicaOrAsm}`]) {
          const thumbnail_arr = entity[`thumbnail_${leicaOrAsm}`].split("/");
          let filename = thumbnail_arr.pop();
          stage = thumbnail_arr.pop();
          version = filename.split(".")[0].split("_").pop();
        }
        return this.scriptService.getThumbnailURL(
          entity.projectCode,
          entity.entityCode,
          leicaOrAsm,
          stage,
          version
        );
      } else {
        // get the thumbnail from the assembly shot (e.g. e101b_assembly) rather than the script's assembly (not accurate)
        const assemblyShot = new Entity({
          projectCode: entity.projectCode,
          group:
            (entity.context === "scripts" ? entity["episode_code"] : entity["entityCode"]) ||
            "episode_not_found",
          context: "shots",
          entityCode: "assembly",
        } as IEntity);
        if (entity["assembly"]) {
          const latestAssembly = entity["assembly"][0];
          return this.entityService.getMediaURL(
            assemblyShot,
            MediaType.THUMBNAIL,
            latestAssembly as IMedia
          );
        }
        return this.entityService.getMediaURL(assemblyShot, MediaType.THUMBNAIL);
      }
    } else if (leicaOrAsm && entity.context === "episodes" && stories) {
      let found = -1;
      if (leicaOrAsm === "leica") {
        //theProperty is Leica
        found = stories.findIndex((story) => story.script_code === entity.script_code);
        if (found !== -1) {
          let story = stories[found];
          const thumbnail_arr = story["thumbnail_leica"].split("/");
          const filename = thumbnail_arr.pop();
          const stage = thumbnail_arr.pop();
          const version = filename.split(".")[0].split("_").pop();
          return this.scriptService.getThumbnailURL(
            entity.projectCode,
            entity.script_code || "",
            leicaOrAsm,
            stage,
            version
          );
        } else {
          return "";
        }
      } else {
        if (entity["thumbnail_assembly"] !== undefined) {
          const thumbnail_arr = entity["thumbnail_assembly"].split("/");
          const arrLen = thumbnail_arr.length;
          const stage = thumbnail_arr[arrLen - 3];
          const version = thumbnail_arr[arrLen - 2];
          return this.episodeService.getThumbnailURL(
            entity.projectCode,
            entity.toString(),
            leicaOrAsm,
            stage,
            version
          );
        } else {
          return "";
        }
      }
    }
    return this.entityService.getMediaURL(entity, MediaType.THUMBNAIL, entity["media"][0]);
  }
}

@Pipe({
  name: "refImgURL",
})
export class RefImgURLPipe implements PipeTransform {
  constructor(private entityService: EntityService) {}

  transform(entity: Entity, id: string): string {
    return this.entityService.getRefImgURL(entity, id);
  }
}

@Pipe({
  name: "fullCGURL",
})
export class FullCGURLPipe implements PipeTransform {
  constructor(private entityService: EntityService) {}

  transform(entity: Entity): string {
    entity;
    return this.entityService.getFullCGURL();
  }
}

@Pipe({
  name: "mediaToString",
})
export class MediaToStringPipe implements PipeTransform {
  transform(media: IMedia): any {
    return mediaToString(media);
  }
}

@Pipe({
  name: "keys",
})
export class KeysPipe implements PipeTransform {
  transform(obj: object): any {
    if (obj === undefined || obj === null) {
      return "";
    }
    return Object.keys(obj).join(", ");
  }
}

@Pipe({
  name: "valueOfEntityField",
})
export class ValueOfEntityFieldPipe implements PipeTransform {
  getValue(entity: Entity, subcol: TableConfigSubCol | TableConfigColumnItem) {
    const split = (subcol["to-edit"] || "").split(".");
    let level = entity[split[0]] || "";
    if (!level) {
      return level;
    }

    for (let i = 1; i < split.length; i++) {
      try {
        level = level[split[i]];
      } catch {
        level = "";
      }
    }
    return level;
  }

  transform(
    entity: Entity,
    context: Context,
    col: TableConfigSubCol | TableConfigColumnItem,
    asRelativeLink?: boolean
  ) {
    // col['type'] === 'type-ahead' && !col['is-single-selection'] ? (entity[col['to-edit']] || []).join(', ') : entity[col['to-edit']]
    let items = this.getValue(entity, col) || [];
    if (col["type"] === "type-ahead" && !col["is-single-selection"]) {
      if (items.length === 0) {
        return "";
      }

      if (isIEntity(items[0])) {
        return items
          .map((e: IEntity) => new Entity({ ...e, context: context } as IEntity).toString())
          .join(", ");
      } else {
        return items.join(", ");
      }
    } else {
      if (asRelativeLink && context === "shots") {
        try {
          // works on shots context only since assets don't have asset type as prefix e.g. chr_lucas
          const group = items.split("_")[0];
          const entityCode = items.split("_")[1];
          return `./${group}/${entityCode}`;
        } catch {
          return items;
        }
      } else {
        return items;
      }
    }
  }
}

@Pipe({
  name: "materialIcon",
})
export class MaterialIconPipe implements PipeTransform {
  transform(value: any): any {
    if (value === undefined) {
      return "";
    }

    if (typeof value === "string") {
      return "";
    }

    if (value["icon"]) {
      return value["icon"];
    }

    if ("extern" in value) {
      return value["extern"] ? "cloud_queue" : "home";
    }

    return "";
  }
}

@Pipe({
  name: "getLatestScriptOrStoryboard",
})
export class GetLatestScriptOrStoryBoardPipe implements PipeTransform {
  transform(episode: Entity, stories: Entity[], scriptOrStoryboard: "script" | "storyboard"): any {
    var story: Entity;

    // Given an episode entity, find the associated script entity
    let found = -1;
    found = stories.findIndex((s) => s.script_code === episode.script_code);
    if (found !== -1) {
      story = stories[found];
      return story[`latest_${scriptOrStoryboard}`]
        ? story[`latest_${scriptOrStoryboard}`].split("/").pop()
        : "";
    } else {
      return "";
    }
  }
}

@Pipe({
  name: "typeaheadItems",
})
export class TypeaheadItemsPipe implements PipeTransform {
  constructor(private entityService: EntityService) {}

  getAllEntityNames(exclude: string, projectCode: string, context: Context) {
    return this.entityService.getAllEntitiesWithCodesOnly(projectCode, context).pipe(
      map((entities) => {
        let names: string[] = [];
        for (const entity of entities) {
          entity.projectCode = projectCode;
          entity.context = context;
          const _entity = new Entity(entity);
          if (exclude === _entity.toString()) {
            continue;
          }

          names.push(_entity.toString());
        }
        return names;
      })
    );
  }

  getEntityCodesForSameAsCam(
    exclude: string,
    projectCode: string,
    context: Context,
    shotPlate: undefined | { plate_code: string; drive: string; [key: string]: any }
  ) {
    let shotCodeNamesFromPlate$: Observable<{ class: string; toString: () => string }[]> = of([]);
    let shotCodeNamesFromShot$: Observable<{ class: string; toString: () => string }[]> = of([]);

    if (shotPlate !== undefined) {
      let shotPlateCode = shotPlate["plate_code"];
      let shotPlateDrive = shotPlate["drive"];

      shotCodeNamesFromPlate$ = this.entityService
        .findOne(projectCode, "plates", shotPlateDrive, shotPlateCode, "shot_code")
        .pipe(
          switchMap((entity) => {
            let names: { class: string; toString: () => string }[] = [];
            for (const shotCode of entity.shot_code || []) {
              if (exclude === shotCode) {
                continue;
              }
              let dataObj = {
                class: "font-weight-bold",
                toString: () => {
                  return shotCode;
                },
              };

              names.push(dataObj);
            }
            return of(names);
          })
        );
    }

    shotCodeNamesFromShot$ = this.entityService
      .getAllEntitiesWithCodesOnly(projectCode, context)
      .pipe(
        switchMap((entities) => {
          let names: { class: string; toString: () => string }[] = [];
          for (const entity of entities || []) {
            entity.projectCode = projectCode;
            entity.context = context;
            const _entity = new Entity(entity);
            if (exclude === _entity.toString()) {
              continue;
            }
            let dataObj = {
              class: "font-weight-normal",
              toString: () => {
                return _entity.toString();
              },
            };

            names.push(dataObj);
          }
          return of(names);
        })
      );

    if (shotPlate !== undefined) {
      return zip(shotCodeNamesFromPlate$, shotCodeNamesFromShot$).pipe(
        map((x) => x[0].concat(x[1]))
      );
    } else {
      return shotCodeNamesFromShot$;
    }
  }

  transform(
    value: any,
    exclude: string,
    projectCode: string,
    context: Context,
    shotPlate?: undefined | { plate_code: string; drive: string; [key: string]: any }
  ): any {
    // TODO: unhardcode this
    if (value === "tags") {
      return this.entityService.getAllTags(projectCode, context);
    } else if (
      ["shots", "assets", "scripts", "episodes"].includes(context) &&
      [
        "entityCode",
        "shot_code",
        "lgt_children",
        "lgt_parent",
        "cmp_children",
        "cmp_parent",
        "spp.lay_reuse",
        "spp.lgt_reuse",
      ].includes(value)
    ) {
      return this.getAllEntityNames(exclude, projectCode, context);
    } else if (value === "same_as_camera" || value === "same_as_camera_children") {
      return this.getEntityCodesForSameAsCam(exclude, projectCode, context, shotPlate);
    } else if (value === "plate.plate_code" || value === "spp.plate.plate_code") {
      return this.getAllEntityNames(exclude, projectCode, "plates");
    } else if (context === "plates") {
      if (value === "parent") {
        return this.getAllEntityNames(exclude, projectCode, "plates");
      } else if (value === "children") {
        return this.entityService
          .searchUnGroupedEntities(projectCode, context, {
            search: "",
            searchType: "plate_code",
            exactMatch: false,
          })
          .pipe(
            switchMap((entities) => {
              let names: IEntity[] = [];
              for (let entity of entities) {
                entity.toString = () => {
                  return entity["plate_code"];
                };
                if (entity.toString() === exclude) {
                  continue;
                }
                names.push({ ...entity });
              }
              return of(names);
            })
          );
      } else if (value === "shot_code") {
        return this.getAllEntityNames("", projectCode, "shots");
      } else if (value === "entityCode") {
        return this.getAllEntityNames("", projectCode, "plates");
      }
    }

    return of([]);
  }
}
