<div
  class="fade show d-block modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mediaPlayer"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closed.emit(processStatus.status)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body row">
        <div
          *ngIf="errorMsg"
          class="container alert alert-danger alert-dismissible fade show"
          role="alert">
          {{ errorMsg }}

          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            (click)="errorMsg = ''">
            <span aria-hidden="true">&times;</span>
          </button>

          <div *ngIf="errorMsg.includes('overwrite')" class="d-block text-right">
            <button type="button" class="btn btn-outline-secondary mr-1" (click)="errorMsg = ''">
              No
            </button>
            <button type="button" class="btn btn-danger" (click)="upload(true)">
              Yes, overwrite existing files
            </button>
          </div>
        </div>

        <div *ngIf="showDepts" class="container">
          <h6>Department:</h6>
          <div *ngFor="let dept of depts" class="form-check form-check-inline">
            <input
              #deptInputs
              class="form-check-input"
              type="radio"
              name="depts"
              [id]="dept"
              [value]="dept"
              (change)="selectedDept = dept"
              required />
            <label class="form-check-label" [for]="dept">{{ dept }}</label>
          </div>
        </div>

        <div class="container">
          <h6>Upload type:</h6>
          <div *ngFor="let uploadType of uploadTypes" class="form-check form-check-inline">
            <input
              #typeInputs
              class="form-check-input"
              type="radio"
              name="uploadTypes"
              [id]="'_' + uploadType"
              [value]="uploadType"
              [checked]="selectedUploadType === uploadType"
              (change)="selectedUploadType = uploadType" />
            <label class="form-check-label" [for]="'_' + uploadType">{{ uploadType }}</label>
          </div>
        </div>

        <div class="container text-left">
          <span *ngIf="this.processStatus.status !== 'Idle'">{{ this.processStatus.status }}</span
          ><span *ngIf="!['Submitted', 'Started', 'Idle'].includes(this.processStatus.status)"
            >. Please do not close this tab or your browser. {{ this.processStatus.progress }}</span
          >
          <div class="progress" style="height: 8px">
            <div #progressBar class="progress-bar" role="progressbar"></div>
          </div>
        </div>

        <div class="container text-center">
          <div class="mr-2">
            <h5 *ngIf="scriptsAndStory === undefined">
              Uploading for:
              <strong
                >{{ episodeName ? episodeName + " · " : "" }}{{ entity.group }} -
                {{ entity.entityCode }}</strong
              >
            </h5>
            <h5 *ngIf="scriptsAndStory !== undefined">
              Uploading for: <strong>{{ entity.entityCode }} - {{ scriptsAndStory }}</strong>
            </h5>
          </div>
        </div>

        <div class="container">
          <div *ngIf="assemblyUpload || scriptsAndStory === undefined" class="input-group mb-1">
            <div class="custom-file">
              <ng-container
                *ngIf="
                  (primarySoftware === 'blender' && selectedUploadType !== 'workfile') ||
                  primarySoftware !== 'blender'
                ">
                <input
                  #file
                  class="custom-file-input"
                  id="custom-file1"
                  type="file"
                  name="file"
                  [accept]="getSupportedTypes()['accepts']"
                  (change)="onIthInputFileChanged(file, 0)" />
                <label
                  class="custom-file-label text-center"
                  [ngClass]="{
                    'font-weight-bold': file.files?.length === 1,
                    'font-weight-normal': file.files?.length === 0
                  }"
                  for="custom-file1"
                  >{{
                    file.files?.length === 1
                      ? file.files?.item(0)?.name
                      : "Click Browse or drag-n-drop a file"
                  }}</label
                >
              </ng-container>
              <ng-container
                *ngIf="primarySoftware === 'blender' && selectedUploadType === 'workfile'">
                <input
                  #inputFiles
                  type="file"
                  class="custom-file-input"
                  id="multi-files"
                  multiple="true"
                  (change)="inputFilesChangedMark(inputFiles.files)"
                  [accept]="getSupportedTypes()['accepts']" />
                <label class="custom-file-label" for="multi-files">Upload file(s)</label>
              </ng-container>
            </div>
          </div>

          <div *ngIf="scriptsAndStory === undefined" class="input-group mb-1">
            <div
              *ngIf="
                (primarySoftware !== 'blender' && selectedUploadType === 'workfile') ||
                selectedUploadType === 'adobe'
              "
              class="custom-file">
              <input
                #file2
                class="custom-file-input"
                id="custom-file2"
                type="file"
                name="file"
                [accept]="getSupportedTypes()['accepts']"
                (change)="onIthInputFileChanged(file2, 1)" />
              <label
                class="custom-file-label text-center"
                [ngClass]="{
                  'font-weight-bold': file2.files?.length === 1,
                  'font-weight-normal': file2.files?.length === 0
                }"
                for="custom-file2"
                >{{
                  file2.files?.length === 1
                    ? displayFilename(file2.files?.item(0))
                    : "Click Browse or drag-n-drop a file"
                }}</label
              >
            </div>
          </div>

          <div *ngIf="scriptsAndStory !== undefined" class="input-group mb-1">
            <div *ngIf="scriptsAndStory === 'scripts'" class="dropdown-bg">
              <app-dropdown
                [selectedItem]="{ text: 'Select Script Stage' }"
                [choices]="scriptStages"
                [choiceKey]="'text'"
                [emptyChoice]="{ text: 'Select Script Stage' }"
                (selectionChanged)="selectedStage = $event"></app-dropdown>
            </div>
            <div *ngIf="scriptsAndStory === 'storyboards'" class="dropdown-bg">
              <app-dropdown
                [selectedItem]="{ text: 'Select Storyboard Stage' }"
                [choices]="storyboardStages"
                [choiceKey]="'text'"
                [emptyChoice]="{ text: 'Select Storyboard Stage' }"
                (selectionChanged)="selectedStage = $event"></app-dropdown>
            </div>
            <div *ngIf="scriptsAndStory === 'leica'" class="dropdown-bg">
              <app-dropdown
                [selectedItem]="{ text: 'Select Leica Stage' }"
                [choices]="leicaStages"
                [choiceKey]="'text'"
                [emptyChoice]="{ text: 'Select Leica Stage' }"
                (selectionChanged)="selectedStage = $event"></app-dropdown>
            </div>
            <div
              *ngIf="scriptsAndStory === 'scripts' || scriptsAndStory === 'storyboards'"
              class="custom-file">
              <input
                #file
                multiple
                class="custom-file-input"
                id="custom-file-SS"
                type="file"
                name="file"
                type="file"
                [accept]="getSupportedTypes()['accepts']"
                (change)="inputFilesChanged(file.files)" />
              <label
                class="custom-file-label text-center"
                [ngClass]="{
                  'font-weight-bold': file.files?.length !== 0,
                  'font-weight-normal': file.files?.length === 0
                }"
                for="custom-file-SS"
                >{{
                  file.files?.length === 1
                    ? displayFilename(file.files?.item(0))
                    : "Click Browse or drag-n-drop files"
                }}</label
              >
            </div>
          </div>

          <div *ngIf="scriptsAndStory === 'leica'" class="input-group mb-1">
            <div class="custom-file">
              <input
                #file1
                class="custom-file-input"
                id="custom-file11"
                type="file"
                name="file"
                [accept]="getSupportedTypes()['accepts']"
                (change)="onIthInputFileChanged(file1, 0)" />
              <label
                class="custom-file-label text-center"
                [ngClass]="{
                  'font-weight-bold': file1.files?.length === 1,
                  'font-weight-normal': file1.files?.length === 0
                }"
                for="custom-file11"
                >{{
                  file1.files?.length === 1
                    ? displayFilename(file1.files?.item(0))
                    : "Click Browse or drag-n-drop a file"
                }}</label
              >
            </div>
          </div>
          <div class="container text-left" *ngIf="attachedFiles[0] != null">
            <b>Files to upload: </b
            ><span class="mr-3" *ngFor="let file of attachedFiles"
              >{{ displayFilename(file) }}
            </span>
          </div>

          <p class="mt-1">
            <i class="material-icons-outlined md-18 align-text-bottom">info</i> Supported types:
            {{ getSupportedTypes()["filetypes"].join(", ") }}
          </p>

          <input
            *ngIf="scriptsAndStory === undefined || assemblyUpload"
            [(ngModel)]="comment"
            type="text"
            class="form-control my-3"
            placeholder="Comment"
            aria-label="Comment"
            aria-describedby="Comment"
            required />
          <div *ngIf="assemblyUpload">
            <app-typeahead
              #artists
              [items$]="assignableUsernames"
              [labelString]="'Artist(s):'"
              [idString]="'artists'"
              [showDropdownWhenAutofocused]="false"></app-typeahead>
          </div>

          <div *ngIf="scriptsAndStory !== undefined && !assemblyUpload">
            <div>
              <input
                [(ngModel)]="commentSS"
                type="text"
                class="form-control my-3"
                placeholder="Comment"
                aria-label="Comment"
                aria-describedby="Comment" />
            </div>
            <div *ngIf="scriptsAndStory !== 'scripts'">
              <app-typeahead
                #artists
                [items$]="assignableUsernames"
                [labelString]="'Artist(s):'"
                [idString]="'artists'"
                [showDropdownWhenAutofocused]="false"></app-typeahead>
            </div>
          </div>

          <div class="text-right">
            <button
              type="button"
              class="btn btn-primary"
              (click)="
                scriptsAndStory === undefined || scriptsAndStory === 'assembly'
                  ? upload()
                  : upload_ss()
              "
              [attr.disabled]="
                ['Started', 'Uploading', 'Processing'].includes(this.processStatus.status)
                  ? true
                  : null
              ">
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="fade show d-block modal-backdrop"></div>
