import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from "@angular/core";

interface IEventTarget extends EventTarget {
  id: string;
}

@Component({
  selector: "app-drag-n-drop",
  templateUrl: "./drag-n-drop.component.html",
  styleUrls: ["./drag-n-drop.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragNDropComponent implements OnInit {
  showDropdown: boolean;

  @Input() ordering: { name: string }[];

  @Output() changedOrder = new EventEmitter<{ name: string }[]>();
  @Output() saved = new EventEmitter<{ name: string }[]>();

  constructor() {}

  ngOnInit() {}

  onDragstart(event: DragEvent) {
    event.dataTransfer?.setData("text/plain", (event.target as IEventTarget).id);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    // Get the id of the target and add the moved element to the target's DOM
    // dragged element
    const data = event.dataTransfer?.getData("text/plain") || "";
    // Get a reference to the parent node
    const parentDiv = document.getElementById(data)?.parentNode;
    const dragged = document.getElementById(data);

    const origin = this.ordering.findIndex((order) => order["name"] === data);
    const target = this.ordering.findIndex(
      (order) => order["name"] === (event.target as IEventTarget).id
    );
    const actualData = this.ordering[this.ordering.findIndex((order) => order["name"] === data)];
    // slide over mechanism
    let step;
    if (origin < target) {
      parentDiv?.insertBefore(event.target as Node, dragged);
      for (step = origin; step < target; step++) {
        this.ordering[step] = this.ordering[step + 1];
      }
    } else {
      parentDiv?.insertBefore(dragged as Node, event.target as Node);
      for (step = origin; step > target; step--) {
        this.ordering[step] = this.ordering[step - 1];
      }
    }

    this.ordering[target] = actualData;

    this.changedOrder.emit(this.ordering);
  }

  onDragover(event: DragEvent) {
    event.preventDefault();
    // Set the dropEffect to move
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = "move";
    }
  }
}
