<h2 style="margin: 10px">Documentation Links</h2>

<h3 style="margin: 10px">Add New Documentation Link</h3>
<form style="margin: 10px" (ngSubmit)="onSubmit()" #documentForm="ngForm">
  <div class="form-group">
    <label class="mr-2">Document Scope:</label>
    <div class="form-check-inline">
      <input
        type="radio"
        class="form-check-input"
        id="generalRadio"
        name="documentScope"
        value="general"
        required
        [(ngModel)]="newDocumentScope">

      <label class="form-check-label" for="generalRadio">General</label>
    </div>
    <div class="form-check-inline">
      <input type="radio"
             class="form-check-input"
             id="projectRadio"
             name="documentScope"
             value="project"
             required
             [(ngModel)]="newDocumentScope">
      <label class="form-check-label" for="projectRadio">Project</label>
    </div>
  </div>
  <div class="form-group">
    <label class="mr-2" for="heading">Heading:</label>
    <input type="text" class="form-control-sm" id="heading" name="heading" [(ngModel)]="newDocument.heading" required>
  </div>
  <div class="form-group">
    <label class="mr-2" for="title">Title:</label>
    <input type="text" class="form-control-sm" id="title" name="title" [(ngModel)]="newDocument.title" required>
  </div>
  <div class="form-group">
    <label class="mr-2" for="link">Original URL:</label>
    <input type="text" class="form-control-sm" id="link" name="link" [(ngModel)]="newDocument.url" required>
  </div>
  <div class="form-group">
    <label class="mr-2" for="owncloud_link">OwnCloud URL:</label>
    <input type="text" class="form-control-sm" id="owncloud_link" name="owncloud_link" [(ngModel)]="newDocument.url_owncloud">
  </div>
  <div class="form-group">
    <label class="mr-2" for="document_type">Document Type:</label>
    <select class="form-control-sm" id="document_type" name="document_type" [(ngModel)]="newDocument.documentType">
      <option value=""></option>
      <option value="Google Doc">Google Doc</option>
      <option value="Webpage">Webpage</option>
      <option value="Video">Video</option>
    </select>
  </div>
  <div class="form-group">
    <label class="mr-2" for="summary">Summary:</label>
    <textarea class="form-control" id="summary" name="summary" [(ngModel)]="newDocument.summary" rows="3"></textarea>
  </div>
  <div class="form-group">
    <button type="submit" class="btn btn-primary" [disabled]="!documentForm.form.valid">Add Document</button>
  </div>
</form>

<h3 style="margin: 10px">General Documentation</h3>
<div class="card" style="margin: 10px" *ngFor="let group of generalLinks">
  <h3 class="card-header">{{ group.heading }}</h3>
  <ul class="list-group list-group-flush">
    <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let item of group.items; index as i; first as isFirst; last as isLast">

      <ng-container *ngIf="editingItem === null || editingItem._id.$oid !== item._id.$oid; else activeEditor">
        <div>
          <div><a href="{{ item.url_owncloud || item.url }}" target="_blank" rel="noreferrer noopener">{{ item.title }}</a> -
            <span>Doc type: {{item.documentType}}</span></div>
          <p>{{item.summary}}</p>
        </div>
        <div *ngIf="editingItem === null">
          <i *ngIf="!isFirst" (click)="onChangePosition(item, -1, 'general')" class="material-icons hover-pointer">arrow_upward</i>
          <i *ngIf="!isLast" (click)="onChangePosition(item, 1, 'general')" class="material-icons hover-pointer">arrow_downward</i>
          <i (click)="onStartEdit(item, 'general')" class="material-icons hover-pointer">edit</i>
          <i (click)="onDelete(item, 'general')" class="material-icons hover-pointer">delete</i>
        </div>
      </ng-container>
    </li>
  </ul>
</div>

<h3 style="margin: 10px; margin-top: 30px">{{projectCode.toUpperCase()}} Documentation</h3>
<div class="card" style="margin: 10px" *ngFor="let group of documentationLinks">
  <h3 class="card-header">{{ group.heading }}</h3>
  <ul class="list-group list-group-flush">
    <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let item of group.items; index as i; first as isFirst; last as isLast">

      <ng-container *ngIf="editingItem === null || editingItem._id.$oid !== item._id.$oid; else activeEditor">
        <div>
          <div><a href="{{ item.url_owncloud || item.url }}" target="_blank" rel="noreferrer noopener">{{ item.title }}</a> -
            <span>Doc type: {{item.documentType}}</span></div>
          <p>{{item.summary}}</p>
        </div>
        <div *ngIf="editingItem === null">
          <i *ngIf="!isFirst" (click)="onChangePosition(item, -1)" class="material-icons hover-pointer">arrow_upward</i>
          <i *ngIf="!isLast" (click)="onChangePosition(item, 1)" class="material-icons hover-pointer">arrow_downward</i>
          <i (click)="onStartEdit(item)" class="material-icons hover-pointer">edit</i>
          <i (click)="onDelete(item)" class="material-icons hover-pointer">delete</i>
        </div>
      </ng-container>
    </li>
  </ul>
</div>


<ng-template #activeEditor>
  <ng-container *ngIf="editingItem">
    <form style="margin: 10px" (ngSubmit)="onSubmitEdit()" #documentForm="ngForm">
      <div class="form-group">
        <label class="mr-2" for="heading">Heading:</label>
        <input type="text" class="form-control-sm" id="heading" name="heading" [(ngModel)]="editingItem.heading"
               required>
      </div>
      <div class="form-group">
        <label class="mr-2" for="title">Title:</label>
        <input type="text" class="form-control-sm" id="title" name="title" [(ngModel)]="editingItem.title"
               required>
      </div>
      <div class="form-group">
        <label class="mr-2" for="link">Original URL:</label>
        <input type="text" class="form-control-sm" id="link" name="link" [(ngModel)]="editingItem.url" required>
      </div>
      <div class="form-group">
        <label class="mr-2" for="owncloud_link">OwnCloud URL:</label>
        <input type="text" class="form-control-sm" id="owncloud_link" name="owncloud_link" [(ngModel)]="editingItem.url_owncloud" required>
      </div>
      <div class="form-group">
        <label class="mr-2" for="document_type">Document Type:</label>
        <select class="form-control-sm" id="document_type" name="document_type"
                [(ngModel)]="editingItem.documentType">
          <option value=""></option>
          <option value="Google Doc">Google Doc</option>
          <option value="Webpage">Webpage</option>
          <option value="Video">Video</option>
        </select>
      </div>
      <div class="form-group">
        <label class="mr-2" for="summary">Summary:</label>
        <textarea class="form-control" id="summary" name="summary" [(ngModel)]="editingItem.summary"
                  rows="3"></textarea>
      </div>
      <div class="form-group">
        <button type="reset" class="btn btn-secondary" (click)="onCancelEdit()">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="!documentForm.form.valid">Submit Edit</button>
      </div>
    </form>
  </ng-container>
</ng-template>
