<div #tableContainer class="table-responsive">
  <ng-container *ngIf="columns$ | async">
    <div
      class="position-sticky left-0 top-0 bg-white d-flex flex-wrap py-1 align-items-center"
      id="table-options">
      <app-drag-n-drop
        [ordering]="columns"
        (changedOrder)="onChangeColumns($event)"
        (saved)="onSavedColumns($event)"></app-drag-n-drop>
      <app-display-columns
        [columns]="columns"
        (changedVisibility)="onChangeColumns($event)"
        (saved)="onSavedColumns($event)"></app-display-columns>
      <app-typeahead
        #searchTypeahead
        [placeholder]="'Search example: e0123↵'"
        [items$]="'entityCode' | typeaheadItems : '' : projectCode : context"
        [isSingleSelection]="true"
        (added)="onSearch($event)"
        [isAutofocused]="true"
        [showDropdownWhenAutofocused]="false"
        [applyBorderWarningOnBlur]="false">
      </app-typeahead>
      <app-filters
        [filters$]="filters$"
        [context]="context"
        [projectCode]="projectCode"
        [inShotsByEpisodePage]="false"
        (toggledFiltersOnOrOff)="onToggledFilters($event)"
        (filtered)="searchAndFilter()"></app-filters>
      <button
        type="button"
        class="btn btn-outline-primary btn-sm ml-1"
        (click)="openAddEntity = true">
        Create {{ context.substring(0, context.length - 1) | titlecase }}
      </button>
    </div>

    <table class="table table-bordered table-hover table-sm">
      <thead class="thead-light">
        <tr>
          <ng-container *ngFor="let col of columns; trackBy: columnTrackBy; index as i">
            <th
              *ngIf="col.visible"
              style="top: 41.5px"
              class="position-sticky text-center"
              [attr.colspan]="col.colspan"
              [attr.rowspan]="col.rowspan"
              [style.border]="col.styles.border"
              [style.min-width]="i === 0 ? col.styles['min-width'] : null"
              [style.max-width]="i === 0 ? col.styles['min-width'] : null"
              [style.left]="i === 0 ? 0 : null"
              [style.z-index]="i === 0 ? 120 : 100"
              (click)="sort(col['short-code']!)">
              {{ col.name }}
              <i
                *ngIf="
                  col['short-code']! === 'script_code' || col['short-code']! === 'episode_code'
                "
                class="material-icons md-18 align-text-bottom font-weight-bold"
                >{{ chooseIcon(col["short-code"]!) }}</i
              >
            </th>
          </ng-container>
        </tr>
        <tr>
          <ng-container *ngFor="let col of columns; trackBy: columnTrackBy">
            <ng-container *ngFor="let subcol of col.cols; trackBy: columnTrackBy; index as i">
              <th
                *ngIf="col.visible && subcol"
                class="text-center position-sticky"
                style="z-index: 100; top: 75.4px"
                [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
                [style.border-right]="
                  i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
                ">
                {{ subcol.name }}
              </th>
            </ng-container>
          </ng-container>
        </tr>
      </thead>
      <tbody *ngIf="(!openMediaPlayer || !openDocumentViewer) && (scripts$ | async) as scripts">
        <ng-container #entityRows *ngIf="scripts.length !== 0; else noResults"></ng-container>
        <!-- #isEntity is most of of a shot row, minus the leftmost cell for some reason.-->
        <tr
          *ngFor="let script of scripts; trackBy: entityTrackBy; index as i"
          style="height: 118px"
          appIntersectionObserver
          #intersection="intersection"
          app-entity-table-view
          [columns]="columns"
          [statuses]="statuses"
          [users]="users"
          [usersInProject]="usersInProject"
          [_entity]="script"
          [isIntersecting]="intersection.isIntersecting"
          [showStatusColors]="true"
          [inShotsByEpisodePage]="false"></tr>
      </tbody>

      <ng-template #noResults>
        <!-- this will show Fetching data after the table headings are rendered -->
        <tbody>
          <tr>
            No
            {{
              context
            }}
            found given the filters.
          </tr>
        </tbody>

        <tbody></tbody
      ></ng-template>
    </table>

    <app-media-player
      *ngIf="openMediaPlayer && viewMedia"
      [allowDownloadWorkfileInMediaPlayer]="false"
      [play$]="viewMedia.media"
      [entity]="viewMedia.entity"
      [notes]="viewMedia.notes"
      [mediaSource]="viewMedia.src"
      [selectedVersionID1]="viewMedia.selectedVersion"
      [selectedVersionID2]="viewMedia.compare"
      [audio]="viewMedia.audio"
      [selectedDefaultThumbnail]="viewMedia.defaultThumbnail"
      [statuses]="viewMedia.statuses || statuses"
      [users]="usersInProject"
      (closed)="openMediaPlayer = $event">
    </app-media-player>
    <app-add-entity
      *ngIf="openAddEntity"
      [projectCode]="projectCode"
      [context]="context"
      (closed)="openAddEntity = false; $event ? ngOnInit() : null"></app-add-entity>
  </ng-container>
</div>
