import { Injectable } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class QueryParamService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  queryParamsAsSet(key: string) {
    let qp = this.activatedRoute.snapshot.queryParams[key];
    if (qp instanceof Array) {
      qp.sort();
      return new Set<string>(qp);
    } else if (typeof qp === "string") {
      return new Set<string>([qp]);
    } else {
      // undefined
      return new Set<string>();
    }
  }

  updateQueryParams(queryParams: Params) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: "merge",
    });
  }
}
