import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IntersectionObserverService {
  observer: IntersectionObserver;
  childObservers: Map<Element, Function> = new Map();

  constructor() {
    let callbacks = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        let callback = this.childObservers.get(entry.target);
        if (callback) {
          callback(entry.isIntersecting);
        }
      });
    };

    this.observer = new IntersectionObserver(callbacks, {
      root: null,
      rootMargin: "0px",
      threshold: [0, 0.25, 0.5, 0.75, 1],
    });
  }

  addChild(element: Element, callback: Function) {
    this.childObservers.set(element, callback);
    this.observer.observe(element);
  }

  removeChild(element: Element) {
    this.childObservers.delete(element);
    this.observer.unobserve(element);
  }
}
