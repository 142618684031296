<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">New User</h4>
      <div *ngIf="addSuccess" class="container alert alert-success alert-dismissible" role="alert">
        Successfully added: <strong>{{ user.username }}</strong>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="addError" class="container alert alert-danger alert-dismissible" role="alert">
        <strong>{{ errorMessage }}</strong>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <form #addUserForm>
        <div class="form-group">
          <label for="email">Email address</label>
          <input #email type="email" class="form-control" id="email" />
        </div>

        <div class="form-group">
          <label for="firstname">First name</label>
          <input #firstname type="text" class="form-control" id="firstname" />
        </div>

        <div class="form-group">
          <label for="lastname">Last name</label>
          <input #lastname type="text" class="form-control" id="lastname" />
        </div>

        <div class="form-group">
          <label for="username">Username</label>
          <input #username type="text" class="form-control" id="username" />
        </div>

        <span class="list">Projects:</span>
        <div *ngFor="let proj of projects" class="row list">
          <div class="form-check">
            <input
              class="form-check-input project"
              type="checkbox"
              [value]="proj.name"
              [id]="proj.code" />
            <label class="form-check-label" [for]="proj.code">
              {{ proj.name }}
            </label>
          </div>
        </div>

        <span class="list">Departments:</span>
        <div class="d-flex flex-row flex-wrap">
          <div *ngFor="let dept of departments" class="row list">
            <div class="form-check">
              <input
                class="form-check-input department"
                type="checkbox"
                [value]="dept"
                [id]="dept" />
              <label class="form-check-label" [for]="dept">
                {{ dept }}
              </label>
            </div>
          </div>
        </div>

        <span class="list">Roles:</span>
        <div *ngFor="let role of roles" class="row list">
          <div class="form-check">
            <input
              #roleInput
              class="form-check-input role"
              type="radio"
              name="roles"
              [value]="role.name"
              [id]="role.name"
              (change)="selectedRole = role.name"
              required />
            <label class="form-check-label" [for]="role.name">
              {{ role.name }}
            </label>
          </div>
        </div>

        <div class="text-right">
          <button
            type="button"
            class="btn btn-primary"
            (click)="
              add(addUserForm, email.value, firstname.value, lastname.value, username.value)
            ">
            Add
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
