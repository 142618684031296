<div class="container-fluid">
  <div class="card-deck justify-content-center">
    <div
      class="card text-center"
      *ngFor="let project of projects$ | async"
      (click)="reRoute(project)">
      <img [src]="getThumbnail(project.code)" class="card-img-top" />
      <div class="card-body">
        <h5 class="card-title">{{ project.name }}</h5>
      </div>
    </div>
  </div>
</div>
