import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";

import { Project } from "./project";
import { domain, environment } from "../environments/environment";
import type { FilterableFields, Filters } from "../types/filters";
import { DevListFieldUpdate, DevListIndexUpdate, DevListItem } from "../types/dev-list";
import { TableConfigColumnItem } from "../types/table-config";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  filters = new BehaviorSubject<{ [context: string]: Filters } | string>({} || "");
  filters$ = this.filters.asObservable();

  constructor(private http: HttpClient) {}

  getProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(environment.projectsURL);
  }

  getProjectByCode(projectCode: string, fields: string = ""): Observable<Project> {
    let projectURL = `${environment.projectsURL}/${projectCode}`;
    if (fields !== "") {
      projectURL = projectURL.concat(`?fields=${fields}`);
    }
    return this.http.get<Project>(projectURL);
  }

  // getDisplayType(projectCode: string): Observable<object> {
  //   const projectURL = `${environment.projectsURL}/${projectCode}?fields=display_type`;
  //   return this.http.get<object>(projectURL);
  // }

  getTableConfig(projectCode: string, context: string): Observable<TableConfigColumnItem[]> {
    return this.http.get<TableConfigColumnItem[]>(
      `${environment.projectsURL}/${projectCode}/table_config/${context}`
    );
  }

  getDepts(projectCode: string, context: string, uploadable = false): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.projectsURL}/${projectCode}/departments/${context}?uploadable=${uploadable}`
    );
  }

  getProjectThumbnail(projectCode: string): string {
    return `${environment.projectsURL}/${projectCode}/thumbnail`;
  }

  // TODO: grab the fps from comp config if video is from comp
  // getFPS(projectCode: string): Observable<any> {
  //   const url = `${environment.projectsURL}/${projectCode}/fps`;
  //   return this.http.get<string>(url);
  // }

  getFilters(projectCode: string, context: string, filterName?: string): Observable<Filters> {
    let url = `${environment.projectsURL}/${projectCode}/${context}/filters`;
    if (filterName) {
      url = `${environment.projectsURL}/${projectCode}/${context}/filters?filtername=${filterName}`;
    }
    return this.http.get<Filters>(url);
  }

  // TODO: these should be moved.
  // temporary putting it here
  //====== DEV TASK LIST ======
  getDevList(): Observable<DevListItem[]> {
    return this.http.get<DevListItem[]>(`${environment.devListURL}`);
  }

  // temporary putting it here
  addToDevList(item: {
    status: string;
    name: string;
    completion_date: string;
  }): Observable<boolean | DevListItem> {
    return this.http.post<boolean | DevListItem>(
      `${environment.devListURL}`,
      JSON.stringify(item),
      httpOptions
    );
  }

  // temporary putting it here
  updateDevList(
    item: DevListIndexUpdate | DevListFieldUpdate | DevListItem
  ): Observable<{ msg: "Task moved!" | "Task edited!" }> {
    return this.http.put<{ msg: "Task moved!" | "Task edited!" }>(
      `${environment.devListURL}`,
      JSON.stringify(item),
      httpOptions
    );
  }

  //============================

  // canvasDebug(event: any): Observable<any> {
  //   const url = `${domain}/api/canvas/debug`;
  //   return this.http.post<any>(url, JSON.stringify(event), httpOptions);
  // }

  getFilterableFields(projectCode: string, context: string): Observable<FilterableFields> {
    return this.http.get<FilterableFields>(
      `${environment.projectsURL}/${projectCode}/${context}/fields/filterable`
    );
  }

  getAllValuesForField(projectCode: string, context: string, field: string): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.projectsURL}/${projectCode}/${context}/fields/${field}/values`
    );
  }
}
