import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { forkJoin } from "rxjs";
import { UserService } from "../user.service";
import { User } from "../user";
import { Project } from "../project";
import { Role } from "../role";
import { contentsTheSame, getUpdatedItems, Attribute } from "../utils";

export type UsersProjectsDeptsUpdate = {
  projects: string[];
  projectsNiceNames: string[];
  role: string;
  active: boolean;
  depts: string[];
};
export type OneUserProjectsDeptsUpdate = {
  user: string;
  projects: string[];
  depts: string[];
  role: string;
  active: boolean;
};

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  @Input() user: User;
  @Input() hasAccess: boolean;
  @Input() projects: Project[];
  @Input() departments: string[];
  @Input() roles: Role[];
  @Output() updatedUsersProjectsDepts = new EventEmitter<UsersProjectsDeptsUpdate>();
  @Output() updatedOneUser = new EventEmitter<OneUserProjectsDeptsUpdate>();

  checkedProjects: { [index: string]: boolean } = {};
  checkedDepts: { [index: string]: boolean } = {};
  checkedRoles: { [index: string]: boolean } = {};
  selectedRole: string;
  edit = false;

  constructor(private userService: UserService) {}

  ngOnInit() {
    const userProjects = this.user.assigned_projects || [];
    const userDepts = this.user.departments || [];
    const userRole = this.user.role || "";

    this.selectedRole = this.user.role || "";

    if (this.projects) {
      for (const proj of this.projects) {
        this.checkedProjects[proj.code] = userProjects.includes(proj.code);
      }
    }

    if (this.departments) {
      for (const dept of this.departments) {
        this.checkedDepts[dept] = userDepts.includes(dept);
      }
    }
    if (this.roles) {
      for (const role of this.roles) {
        if (role.name == userRole) {
          this.checkedRoles[role.name] = true;
        } else {
          this.checkedRoles[role.name] = false;
        }
      }
    }
  }

  save(formElement: Element) {
    if (!this.hasAccess) {
      return;
    }

    const projectCheckboxes = formElement.querySelectorAll("input[type=checkbox].project");
    const deptCheckboxes = formElement.querySelectorAll("input[type=checkbox].department");
    const activeCheckbox: HTMLInputElement | null = formElement.querySelector(
      "input[type=checkbox].active"
    );
    if (!activeCheckbox) {
      return;
    }
    const updatedUserProjects = getUpdatedItems(
      projectCheckboxes,
      this.checkedProjects,
      Attribute.ID
    );
    const updatedUserDepts = getUpdatedItems(deptCheckboxes, this.checkedDepts);
    //const userProjects = this.user.projects.map(proj => proj.name) || [];
    //const userProjects = this.user.assigned_projects;
    const userProjects = this.getProjectsName(this.user.assigned_projects);
    const userDepts = this.user.departments || [];
    const userRole = this.user.role || "";
    const activated = activeCheckbox.checked;

    const requests = [];

    if (!contentsTheSame(updatedUserProjects, userProjects)) {
      requests.push(this.userService.addProjectToUser(this.user.username, updatedUserProjects));
    }

    if (!contentsTheSame(updatedUserDepts, userDepts)) {
      requests.push(
        this.userService.updateUser(this.user.username, { departments: updatedUserDepts })
      );
    }

    if (this.selectedRole !== userRole) {
      requests.push(this.userService.updateUser(this.user.username, { role: this.selectedRole }));
    }

    if (activated !== this.user.is_active) {
      requests.push(this.userService.toggleUserActivation(this.user.username, activated));
    }

    forkJoin(requests).subscribe(() => {
      this.edit = false;
      this.userService.getUserByUsername(this.user.username).subscribe((user) => {
        this.user = user;
        this.updatedOneUser.emit({
          user: this.user.username,
          projects: updatedUserProjects,
          depts: updatedUserDepts,
          role: this.selectedRole,
          active: activated,
        });
      });
    });
  }

  saveAll(formElement: Element) {
    if (!this.hasAccess) {
      return;
    }

    const projectCheckboxes = formElement.querySelectorAll("input[type=checkbox].project");
    const deptCheckboxes = formElement.querySelectorAll("input[type=checkbox].department");
    const activeCheckbox: HTMLInputElement | null = formElement.querySelector(
      "input[type=checkbox].active"
    );
    if (!activeCheckbox) {
      return;
    }
    const updatedUserProjects = getUpdatedItems(
      projectCheckboxes,
      this.checkedProjects,
      Attribute.ID
    );
    const updatedUserProjectsNiceNames = getUpdatedItems(projectCheckboxes, this.checkedProjects);
    const updatedUserDepts = getUpdatedItems(deptCheckboxes, this.checkedDepts);
    const activated = activeCheckbox.checked;

    const updated = {
      projects: updatedUserProjects,
      depts: updatedUserDepts,
      active: activated,
      projectsNiceNames: updatedUserProjectsNiceNames,
      role: this.selectedRole,
    };

    this.updatedUsersProjectsDepts.emit(updated);
  }

  getProjectsName(projectCodes: string[]): string[] {
    let projectNames: string[] = [];

    projectCodes.forEach((code) => {
      const found = this.projects.find((project) => project.code == code);
      if (found) {
        projectNames.push(found.name);
      }
    });
    return projectNames;
  }
}
