<div
  class="fade show d-block modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mediaPlayer"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add {{ context | titlecase }} for {{ projectCode }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closed.emit(refreshOnExit)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body row">
        <form class="container">
          <ng-container *ngIf="context !== 'scripts'">
            <div *ngIf="assetLevels" class="form-group">
              <label for="group">Asset Level</label>
              <div *ngFor="let level of assetLevels" class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="asset-levels"
                  [id]="level"
                  [value]="level"
                  (click)="selectedLevel = level" />
                <label class="form-check-label" [for]="level">{{ level }}</label>
              </div>
            </div>
            <div class="form-group">
              <label for="group">{{
                context === "assets" ? "Asset type:" : "Episode/Sequence code:"
              }}</label>
              <app-typeahead
                [placeholder]="''"
                [items$]="groups$"
                [isSingleSelection]="true"
                (added)="selectedGroup = $event"
                [emitOnBlur]="true"
                [showDropdownWhenAutofocused]="false"
                [applyBorderWarningOnBlur]="false">
              </app-typeahead>
            </div>
            <div class="form-group">
              <label for="entityCode"
                >{{ context.substring(0, context.length - 1) | titlecase }} code:</label
              >
              <!-- this one doesnt show the border-danger as you type.. but it works in typeaheadcomponent...
              <input #entityCode [ngClass]="{'border-danger': entityCode.value}" type="text" class="form-control" id="entityCode" pattern="^[a-z0-9_]*$"> -->
              <input
                #entityCode
                name="entityCode"
                [ngClass]="{ 'border-danger': hasDangerBorder }"
                type="text"
                class="form-control"
                id="entityCode"
                [ngModel]="entityCodeInput" />
            </div>
            <div *ngIf="assetLevels" class="form-group">
              <label for="name">Name</label>
              <input name="name" type="text" class="form-control" id="name" [(ngModel)]="name" />
            </div>
            <div *ngIf="assetLevels" class="form-group">
              <label for="description">Description</label>
              <input
                name="description"
                type="text"
                class="form-control"
                id="description"
                [(ngModel)]="description" />
            </div>
            <div class="d-flex flex-row-reverse w-100 align-items-center">
              <div class="text-right">
                <button type="button" class="btn btn-primary" (click)="add(entityCode.value)">
                  Add
                </button>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="context === 'scripts'">
            <div class="form-group">
              <div class="container-small-padding alert-info mb-0 mr-2">
                Please name the script code as follows: <br />
                <b>s</b>[season #]<b>t</b>[script #]<i>[optional letter]</i>
                <br />
                e.g. s01t09<i>a</i> - season 01, script 09, 'a' variant
              </div>

              <label for="entityCode"
                >{{ context.substring(0, context.length - 1) | titlecase }} code:</label
              >
              <input
                #entityCode
                name="entityCode"
                [ngClass]="{ 'border-danger': hasDangerBorder }"
                type="text"
                class="form-control"
                id="entityCode"
                [ngModel]="entityCodeInput" />
            </div>
            <div class="form-group">
              <label for="scriptName">Script name:</label>
              <input #scriptName type="text" class="form-control" id="script-name" />
            </div>

            <div class="d-flex flex-row-reverse w-100 align-items-center">
              <div class="text-right">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="addScript(entityCode.value, scriptName.value)">
                  Add
                </button>
              </div>
            </div>
          </ng-container>
          <div class="d-flex flex-row-reverse w-100 align-items-center">
            <div
              *ngIf="added"
              class="container alert alert-success alert-dismissible mb-0 mr-2"
              role="alert">
              Successfully added!
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
                (click)="added = false">
                <span class="font-small" aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="fade show d-block modal-backdrop"></div>
