import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";

import { Project } from "../project";
import { ProjectService } from "../project.service";
import { cuiDomain} from "../../environments/environment";

@Component({
  selector: "app-project-detail",
  templateUrl: "./project-detail.component.html",
  styleUrls: ["./project-detail.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDetailComponent implements OnInit {
  project$: Observable<Project>;

  shouldDefaultRedirectToNewUI: boolean;
  cuiDomain = cuiDomain;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService
  ) {}

  ngOnInit() {
    const projectCode = this.activatedRoute.snapshot.paramMap.get("projectCode");
    this.project$ = this.projectService.getProjectByCode(projectCode || "");
    this.project$.subscribe((project) => {
      if (project) {
        this.shouldDefaultRedirectToNewUI = project.display_type === 'cui_card'
          && localStorage.getItem('use_old_ui') !== 'true';
      }
    });
  }

  showNewUILinkButton(project: Project): boolean {
    if (project.display_type !== 'cui_card') {
      return false;
    }
    const path = this.activatedRoute.firstChild?.routeConfig?.path;
    if (!path) {
      return false;
    }
    if (path === 'assets' || path === 'shots') {
      return true;
    } else {
      return false;
    }
  }

  linkToNewUI(projectCode: string) {
    const path = this.activatedRoute.firstChild?.routeConfig?.path as string;
    return `${cuiDomain}/projects/${projectCode}/${path}`;
  }

  // don't need to set anything else here, because this event will trigger on
  // navigation anyway.
  setNewUILocalStorage() {
    localStorage.setItem('use_old_ui', 'false');
  }

  isActive(link: string): boolean {
    if (!this.activatedRoute.firstChild?.routeConfig?.path?.startsWith(link)) {
      return false;
    }

    const routerConfigItems =
      `${this.activatedRoute.routeConfig?.path}/${this.activatedRoute.firstChild.routeConfig.path}`.split(
        "/"
      );

    let routerUrlItems = this.router.url.split("/");
    if (routerUrlItems[0] === "") {
      routerUrlItems = routerUrlItems.slice(1);
    }

    if (routerConfigItems.length !== routerUrlItems.length) {
      return false;
    }

    const zipped = routerConfigItems.map((config, i) => {
      return [config, routerUrlItems[i]];
    });

    for (const [config, url] of zipped) {
      if (!config.startsWith(":")) {
        if (url.includes("?") && !url.startsWith(config)) {
          return false;
        }
      }
    }

    return true;
  }
}
