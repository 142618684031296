import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-docs",
  templateUrl: "./docs.component.html",
  styleUrls: ["./docs.component.css"],
})
export class DocsComponent implements OnInit {
  blenderGuideVideos: { id: string; title: string; src: string }[];
  activeBlenderGuide: string;

  constructor() {}

  ngOnInit() {
    this.blenderGuideVideos = [
      {
        id: "bgv-0",
        title: "Upload/download PreProduction files",
        src: "assets/video/upload-and-download-preproduction-files.webm",
      },
      {
        id: "bgv-1",
        title: "Upload/download .ai and .psd files",
        src: "assets/video/upload-and-download-ai-psd-files.webm",
      },
      {
        id: "bgv-2",
        title: "Upload/download Blender files",
        src: "assets/video/upload-blender-files-download-from-media-player.webm",
      },
      { id: "bgv-3", title: "Edit shot details", src: "assets/video/edit-shot-details.webm" },
    ];
    this.activeBlenderGuide = this.blenderGuideVideos[0].id;
  }
}
