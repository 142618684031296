<div #tableContainer class="table-responsive">
  <ng-container *ngIf="obs$ | async as obs">
    <ng-container *ngIf="columns$ | async">
      <div
        class="position-sticky left-0 top-0 bg-white d-flex flex-wrap py-1 align-items-center"
        id="table-options">
        <app-drag-n-drop
          [ordering]="columns"
          (changedOrder)="onChangeColumns($event)"
          (saved)="onSavedColumns($event)"></app-drag-n-drop>
        <app-display-columns
          [columns]="columns"
          (changedVisibility)="onChangeColumns($event)"
          (saved)="onSavedColumns($event)"></app-display-columns>
        <app-typeahead
          #searchTypeahead
          [placeholder]="
            searchByOption === 'tags'
              ? 'a, b -> a or b | a + b -> a and b'
              : 'Search example: e0123↵'
          "
          [items$]="searchByOption | typeaheadItems : '' : projectCode : context"
          [isSingleSelection]="searchByOption === 'entityCode'"
          (added)="onSearch($event)"
          [isAutofocused]="true"
          [showDropdownWhenAutofocused]="false"
          [applyBorderWarningOnBlur]="false"
          [emitStringArrayAsString]="searchByOption === 'tags'">
        </app-typeahead>

        <div class="ml-1 form-check">
          <input
            class="form-check-input"
            type="checkbox"
            name="search-tags"
            id="search-tags"
            [checked]="searchByOption === 'tags'"
            (change)="searchByOption = searchByOption === 'tags' ? 'entityCode' : 'tags'" />
          <label class="form-check-label" for="search-tags">Search tags</label>
        </div>
        <div class="ml-1 form-check">
          <input
            class="form-check-input"
            type="checkbox"
            name="show-oop"
            id="show-oop"
            [checked]="showOOP"
            (change)="showOOP = !showOOP" />
          <label class="form-check-label" for="show-oop">Show out-of-picture</label>
        </div>
        <div class="ml-1 form-check">
          <input
            class="form-check-input"
            type="checkbox"
            name="show-completed"
            id="show-completed"
            [checked]="showCompleted"
            (change)="onChangeShowCompleted()" />
          <label class="form-check-label" for="show-completed">Show completed episodes</label>
        </div>
        <div class="ml-1 form-check">
          <input
            class="form-check-input"
            type="checkbox"
            name="show-status-colors"
            id="show-status-colors"
            [checked]="showStatusColors"
            (change)="onChangeShowStatusColors()" />
          <label class="form-check-label" for="show-status-colors">Show status colors</label>
        </div>
        <app-filters
          [filters$]="filters$"
          [context]="context"
          [projectCode]="projectCode"
          [inShotsByEpisodePage]="inShotsByEpisodePage"
          (toggledFiltersOnOrOff)="onToggledFilters($event)"
          (filtered)="searchAndFilter()"></app-filters>
        <button
          type="button"
          class="btn btn-outline-primary btn-sm ml-1"
          (click)="openAddEntity = true">
          Create {{ context.substring(0, context.length - 1) | titlecase }}
        </button>
        <button
          type="button"
          class="btn btn-outline-primary btn-sm ml-1"
          (click)="openDuplicateEntity = true">
          Duplicate {{ context.substring(0, context.length - 1) | titlecase }}
        </button>
      </div>

      <table class="table table-bordered table-hover table-sm">
        <thead class="thead-light">
          <tr>
            <ng-container *ngFor="let col of columns; trackBy: columnTrackBy; index as i">
              <th
                *ngIf="col.visible"
                style="top: 41.5px"
                class="position-sticky text-center"
                [attr.colspan]="col.colspan"
                [attr.rowspan]="col.rowspan"
                [style.border]="col.styles.border"
                [style.min-width]="i === 0 ? col.styles['min-width'] : null"
                [style.max-width]="i === 0 ? col.styles['min-width'] : null"
                [style.left]="i === 0 ? 0 : null"
                [style.z-index]="i === 0 ? 120 : 100">
                {{ col.name }}
              </th>
            </ng-container>
          </tr>
          <tr>
            <ng-container *ngFor="let col of columns; trackBy: columnTrackBy">
              <ng-container *ngFor="let subcol of col.cols; trackBy: columnTrackBy; index as i">
                <th
                  *ngIf="col.visible && subcol"
                  class="text-center position-sticky"
                  style="z-index: 100; top: 75.4px"
                  [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
                  [style.border-right]="
                    i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
                  ">
                  {{ subcol.name }}
                </th>
              </ng-container>
            </ng-container>
          </tr>
        </thead>
        <tbody *ngIf="!openMediaPlayer && (entities$ | async) as entities; else fetchingData">
          <ng-container *ngIf="entities.length !== 0; else noResults"></ng-container>
          <ng-container
            #entityRows
            *ngFor="let entity of entities; trackBy: entityTrackBy; index as i">
            <!--            Refactor entity rows into components? -->
            <ng-container *ngIf="showCompleted || entity['episode_status'] !== 'complete'">
              <tr
                *ngIf="entity['__code']; else isEntity"
                class="whole-row top"
                style="background-color: #e9ecef"
                [id]="entity['__code']">
                <!--              Loop through cells in row-->
                <ng-container *ngFor="let col of columns; trackBy: columnTrackBy; index as j">
                  <td
                    *ngIf="col.visible && j === 0"
                    [style.border]="col.styles.border"
                    [style.border-bottom]="'1px solid #dee2e6'"
                    [style.border-top]="'1px solid #dee2e6'"
                    [style.padding-left]="entity['__depth'] | depthAsPadding : false"
                    class="position-sticky hover-pointer"
                    style="left: 0px; z-index: 12; background-color: #6f42c1"
                    (click)="toggleChildVisibility(i, entity, entities)">
                    <div class="d-flex justify-content-between">
                      <span
                        class="text-nowrap text-truncate d-inline-block font-weight-bold"
                        [style.max-width]="col.styles['min-width']">
                        <i class="material-icons md-18 align-text-bottom font-weight-bold">{{
                          expanded.has(entity["__code"]) ? "keyboard_arrow_down" : "chevron_right"
                        }}</i>
                        {{ entity["__nice_name"] }}</span
                      ><span class="text-right" style="min-width: max-content"
                        >{{ entity["bundle_code"] ? "Bundle " + entity["bundle_code"] : "" }} ({{
                          entity["__children"]
                        }})</span
                      >
                    </div>
                  </td>

                  <td
                    *ngIf="col.visible && j > 0"
                    [attr.colspan]="col.cols ? col.colspan : 1"
                    [style.border]="col.styles.border"
                    [style.border-bottom]="'1px solid #dee2e6'"
                    [style.border-top]="'1px solid #dee2e6'"></td>
                </ng-container>
              </tr>
            </ng-container>

            <!-- #isEntity is most of of a shot row, minus the leftmost cell for some reason.-->
            <ng-template #isEntity>
              <tr
                *ngIf="showEntity(entity)"
                style="height: 118px"
                appIntersectionObserver
                #intersection="intersection"
                app-entity-table-view
                [columns]="columns"
                [statuses]="obs.statuses"
                [users]="obs.users"
                [usersInProject]="obs.usersInProject"
                [_entity]="entity"
                [isIntersecting]="intersection.isIntersecting"
                [__updates]="entity['__updates']"
                [showStatusColors]="showStatusColors"
                [inShotsByEpisodePage]="inShotsByEpisodePage"
                (toggledOOP2)="onToggledOOP(i, entities, $event)"
                (updatedEntity)="updateEntity(entities, $event)">
                >
              </tr>
            </ng-template>
          </ng-container>
        </tbody>

        <ng-template #fetchingData>
          <!-- this will show Fetching data after the table headings are rendered -->
          <tbody>
            <tr>
              Fetching data...
            </tr>
          </tbody>

          <tbody></tbody
        ></ng-template>

        <ng-template #noResults>
          <!-- this will show Fetching data after the table headings are rendered -->
          <tbody>
            <tr>
              No
              {{
                context
              }}
              found given the filters.
            </tr>
          </tbody>

          <tbody></tbody
        ></ng-template>
      </table>
    </ng-container>

    <app-media-player
      *ngIf="openMediaPlayer && viewMedia && obs"
      [allowDownloadWorkfileInMediaPlayer]="allowDownloadWorkfileInMediaPlayer"
      [play$]="viewMedia.media"
      [entity]="viewMedia.entity"
      [notes]="viewMedia.notes"
      [mediaSource]="viewMedia.src"
      [selectedVersionID1]="viewMedia.selectedVersion"
      [selectedVersionID2]="viewMedia.compare"
      [audio]="viewMedia.audio"
      [selectedDefaultThumbnail]="viewMedia.defaultThumbnail"
      [statuses]="viewMedia.statuses || obs.statuses"
      [users]="obs.usersInProject"
      (closed)="openMediaPlayer = $event">
    </app-media-player>
    <app-duplicate-entity
      *ngIf="openDuplicateEntity"
      [projectCode]="projectCode"
      [context]="context"
      (closed)="openDuplicateEntity = false"></app-duplicate-entity>
    <app-add-entity
      *ngIf="openAddEntity"
      [projectCode]="projectCode"
      [context]="context"
      (closed)="openAddEntity = false; $event ? ngOnInit() : null"></app-add-entity>
  </ng-container>
</div>
