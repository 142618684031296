import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { last, map, tap } from "rxjs/operators";

import { Entity, IEntity, IMedia, MediaType, Context, IStatus } from "./entity";
import { TableConfigColumnItem } from "../types/table-config";
import { UploadProgress, HttpProgress } from "./utils";
import { Note } from "./notes";
import type MongoDocument from "./mongo-document";
import { domain, environment } from "../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

export interface IVaultedInfoItem {
  comment: string;
  datetime: string;
  full_name: string;
  version: string;
}

export interface IVaultedInfo {
  [assetName: string]: IVaultedInfoItem[];
}

export interface ISPPRefImg {
  spp: {
    ref_img: ({ path: string } & MongoDocument)[];
  };
}

export interface IUploadedSPPRefImg {
  Uploaded: ISPPRefImg;
}

export interface PlayMedia {
  notes: Note[];
  media: Observable<IMedia[]>;
  selectedVersion: string;
  compare: string;
  audio: "left" | "right";
  src: "MEDIA" | "CATALOG" | "assembly" | "leica" | "stages" | "storyboards";
  entity: Entity;
  defaultThumbnail: IMedia;
  statuses?: IStatus[];
}

@Injectable({
  providedIn: "root",
})
export class EntityService {
  playMedia = new Subject<PlayMedia>();
  playMedia$ = this.playMedia.asObservable();
  // TODO: check if below is no longer needed
  closeMedia = new Subject<boolean>();
  closeMedia$ = this.closeMedia.asObservable();
  showChildView = new BehaviorSubject<Entity | boolean>(false);
  showChildView$ = this.showChildView.asObservable();

  constructor(private http: HttpClient) {}

  addEntity(
    projectCode: string,
    context: Context,
    group: string,
    entityCode: string,
    level?: string,
    assetInfo?: { name: string; description: string }
  ) {
    let url = "";
    if (level) {
      url = `${environment.projectsURL}/${projectCode}/${context}/${level}/${group}/${entityCode}`;
    } else {
      url = `${environment.projectsURL}/${projectCode}/${context}/${group}/${entityCode}`;
    }

    return this.http.post<{ msg: string }>(url, JSON.stringify(assetInfo || {}), httpOptions);
  }

  addEntityScript(projectCode: string, script: { script_code: string; script_name: string }) {
    const url = `${environment.projectsURL}/${projectCode}/scripts`;
    return this.http.post<{ msg: string }>(url, JSON.stringify(script), httpOptions);
  }

  duplicateEntity(
    projectCode: string,
    context: Context,
    group: string,
    entityCode: string,
    dstProjectCode: string,
    dstGroup: string,
    dstEntityCode: string,
    copyVersions: boolean,
    copyNotes: boolean,
    copyMedia: boolean,
    copyCaches?: boolean,
    copyPlates?: boolean
  ) {
    let url = `${environment.projectsURL}/${projectCode}/${context}/${group}/${entityCode}/duplicate?dst_project_code=${dstProjectCode}&dst_group=${dstGroup}&dst_entity_code=${dstEntityCode}&copy_versions=${copyVersions}&copy_notes=${copyNotes}&copy_media=${copyMedia}`;
    if (context === "shots") {
      url = url.concat(`&copy_caches=${copyCaches}&copy_plates=${copyPlates}`);
    }
    return this.http.post<{ msg: string; job_id: string }>(url, null);
  }

  addEntityGroup(projectCode: string, context: Context, group: string) {
    const url = `${environment.projectsURL}/${projectCode}/${context}/${group}`;
    return this.http.post<{ msg: string }>(url, null);
  }

  getUnGroupedEntities(
    projectCode: string,
    context: Context,
    fields?: string,
    last?: string
  ): Observable<IEntity[]> {
    last = last ? `&last=${last}` : "";
    fields = fields ? `&fields=${fields}` : "";
    let url = `${environment.projectsURL}/${projectCode}/${context}?grouped=0${last}${fields}`;
    return this.http.get<IEntity[]>(url);
  }

  getChildrenOfContext(projectCode: string, context: Context): Observable<IEntity[]> {
    let url = "";
    if (context === "shots") {
      url = `${environment.projectsURL}/${projectCode}/episodes/children`;
    } else if (context === "assets") {
      url = `${environment.projectsURL}/${projectCode}/asset_types/children`;
    } else if (context === "plates") {
      url = `${environment.projectsURL}/${projectCode}/drives/children`;
    }
    return this.http.get<IEntity[]>(url);
  }

  getGroupedEntities(projectCode: string, context: Context, last?: string): Observable<IEntity[]> {
    let additionalQueryStrs = "";
    if (context === "shots") {
      additionalQueryStrs = "&fields=episode_code,shot_code,shot_status,episode_status";
    } else if (context === "assets") {
      additionalQueryStrs =
        "&fields=asset_type,asset_code,art_status,mdl_status,rig_status,srf_status";
    } else if (context === "plates") {
      additionalQueryStrs = "&fields=plate_code,drive,reel,parent,children,out_of_picture";
    }
    let url =
      last || last === ""
        ? `${environment.projectsURL}/${projectCode}/${context}?last=${last}&grouped=1${additionalQueryStrs}`
        : `${environment.projectsURL}/${projectCode}/${context}?grouped=1${additionalQueryStrs}`;
    return this.http.get<IEntity[]>(url);
  }

  getEntityCodesForGroup(
    projectCode: string,
    context: Context,
    group: string,
    groupType?: string
  ): Observable<IEntity[]> {
    let fields = "";
    if (context === "assets") {
      fields = "asset_type,asset_code,art_status,mdl_status,rig_status,srf_status";
    } else if (context === "shots") {
      fields = "shot_code,episode_code,shot_status,episode_status";
    } else if (context === "plates") {
    }

    let url = `${environment.projectsURL}/${projectCode}/${context}?use_saved_filters=0&grouped=0&fields=${fields}&exclude_fields=_id`;

    if (context === "assets") {
      url = url.concat(`&asset_type=is:${group}`);
    } else if (context === "shots") {
      url = url.concat(`&episode_code=is:${group}`);
    } else if (context === "plates") {
      if (groupType === "drives") {
        url = `${environment.projectsURL}/${projectCode}/reels/${group}?${fields}&exclude_fields=_id`;
      } else if (groupType === "reels") {
        fields = "fields=plate_code,drive,reel,parent,children,out_of_picture";
        const drive = group.split("_")[0];
        const reel = group.split("_")[1];
        url = `${environment.projectsURL}/${projectCode}/plates?reel=is:${reel}&drive=is:${drive}&field_ops=drive%2breel&${fields}&exclude_fields=_id`;
      } else if (groupType === "plates") {
        fields = "fields=plate_code,drive,reel,out_of_picture";
        url = `${environment.projectsURL}/${projectCode}/plates?parent=is:${group}&${fields}&exclude_fields=_id`;
      }
    }

    return this.http.get<IEntity[]>(url);
  }

  findOne(
    projectCode: string,
    context: Context,
    group: string,
    entityCode: string,
    fields?: string
  ): Observable<IEntity> {
    let url = `${environment.projectsURL}/${projectCode}/${context}/${group}/${entityCode}?${fields}`;
    if (context === "scripts" || context === "episodes") {
      url = `${environment.projectsURL}/${projectCode}/${context}/${entityCode}?${fields}`;
    }
    return this.http.get<IEntity>(url);
  }

  getStatuses(projectCode: string, pipe: string): Observable<IStatus[]> {
    const url = `${environment.projectsURL}/${projectCode}/status_list/pipe/${pipe}`;
    return this.http.get<IStatus[]>(url);
  }

  updateOne(
    entity: Entity,
    toBeUpdated: { [key: string]: any },
    updateChildren = false
  ): Observable<{ [key: string]: any }> {
    const url = `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group ? entity.group + '/' : ''}${entity.entityCode}?update-children=${updateChildren}`;
    return this.http.put<{ [key: string]: any }>(url, JSON.stringify(toBeUpdated), httpOptions);
  }

  getLatestMediaURL(entity: Entity, dept?: string): string {
    const latestMedia = entity.getLatestMedia(dept);

    if (latestMedia) {
      if ("movie" in latestMedia) {
        return `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/${latestMedia.dept}/movie?res=${latestMedia.res}&version=${latestMedia.version}`;
      } else {
        return `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/${latestMedia.dept}/thumbnail?res=${latestMedia.res}&version=${latestMedia.version}`;
      }
    }

    return "";
  }

  getFullCGURL() {
    return `/api/thumbnail/full-cg`;
  }

  getRefImgURL(entity: IEntity, id: string) {
    return `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/ref-img?id=${id}`;
  }

  deleteRefImg(entity: IEntity, id: string) {
    const url = `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/ref-img?id=${id}`;
    return this.http.delete<ISPPRefImg>(url);
  }

  getMediaURL(
    entity: IEntity,
    mediaType: MediaType,
    media: IMedia = { _id: { $oid: "" }, dept: "", res: "", version: "", extern: false }
  ): string {
    if (media._id) {
      return `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/${mediaType}?id=${media._id.$oid}`;
    } else if (!media.dept && !media.res && !media.version) {
      return `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/${mediaType}`;
    } else if (media.dept && !media.res && !media.version) {
      return `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/${media.dept}/${mediaType}`;
    } else if (media.dept && media.res && !media.version) {
      return `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/${media.dept}/${mediaType}?res=${media.res}`;
    } else {
      return `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}/${media.dept}/${mediaType}?res=${media.res}&version=${media.version}&extern=${media.extern}`;
    }
  }

  upload(
    files: File[],
    entity: Entity,
    dept: string,
    res: string,
    showProgress: (msg: HttpProgress) => void,
    comment: string = "",
    overwrite: boolean,
    artists?: string
  ) {
    const formData = new FormData();
    const context = entity.context === "episodes" ? "shots" : entity.context;
    const entityCode = context === "shots" && res === "assembly" ? res : entity.entityCode;

    for (const file of files) {
      if (file) {
        formData.append("file", file, file.name);
      }
    }
    formData.append("text", comment);
    formData.append("episode_code", entity.group);
    formData.append("artists", artists || "");

    let url = `${environment.projectsURL}/${entity.projectCode}/${context}/${entity.group}/${entityCode}/${dept}/manual-upload?res=${res}&overwrite=${overwrite}`;
    if (entity.context === "scripts" && res === "assembly") {
      // upload to the assembly shot code (e.g. e101b_assembly)
      const group = entity.episode_code.split("_")[0];
      url = `${environment.projectsURL}/${entity.projectCode}/shots/${group}/assembly/${dept}/manual-upload?res=${res}&overwrite=${overwrite}`;
    }

    const req = new HttpRequest("POST", url, formData, {
      reportProgress: true,
      headers: new HttpHeaders({ enctype: "multipart/form-data" }),
    });

    return this.http.request(req).pipe(
      map((event) => UploadProgress.getEventMessage(event)),
      tap((message) => showProgress(message)),
      last() // return last (completed) message to caller
    );
  }

  uploadRefImg(
    file: File,
    entity: Entity,
    showProgress: (msg: HttpProgress<IUploadedSPPRefImg>) => void
  ) {
    const formData = new FormData();
    formData.append("file", file, file.name);

    const req = new HttpRequest(
      "POST",
      `${environment.projectsURL}/${entity.projectCode}/upload-ref-img/${entity.context}/${entity.group}/${entity.entityCode}?`,
      formData,
      { reportProgress: true, headers: new HttpHeaders({ enctype: "multipart/form-data" }) }
    );

    return this.http.request<IUploadedSPPRefImg>(req).pipe(
      map((event) => UploadProgress.getEventMessage<IUploadedSPPRefImg>(event)),
      tap((message) => showProgress(message)),
      last() // return last (completed) message to caller
    );
  }

  uploadScriptsStoryboards(
    files: File[],
    entity: Entity,
    showProgress: (msg: HttpProgress) => void,
    directory: string = "",
    theStage: string = "",
    comment?: string,
    artists?: string
  ) {
    const formData = new FormData();
    for (const file of files) {
      if (file) {
        formData.append("file", file, file.name);
      }
    }
    formData.append("stage", theStage);
    formData.append("comment", comment || "");
    formData.append("artists", artists || "");

    let req: HttpRequest<any>;
    req = new HttpRequest(
      "POST",
      `${environment.projectsURL}/${
        entity.projectCode
      }/scripts/${entity.toString()}/${directory}/upload`,
      formData,
      { reportProgress: true, headers: new HttpHeaders({ enctype: "multipart/form-data" }) }
    );

    return this.http.request(req).pipe(
      map((event) => UploadProgress.getEventMessage(event)),
      tap((message) => showProgress(message)),
      last() // return last (completed) message to caller
    );
  }

  getAllEntitiesWithCodesOnly(
    projectCode: string,
    context: Context,
    additionalField = ""
  ): Observable<IEntity[]> {
    let url = `${environment.projectsURL}/${projectCode}/${context}`;
    if (context === "shots") {
      if (additionalField !== "") {
        url = url.concat(
          "?fields=episode_code%2Cshot_code%2C",
          additionalField,
          "&grouped=0&use_saved_filters=0"
        );
      } else {
        url = url.concat("?fields=episode_code%2Cshot_code&grouped=0&use_saved_filters=0");
      }
    } else if (context === "assets") {
      url = url.concat("?fields=asset_type%2Casset_code&grouped=0&use_saved_filters=0");
    } else if (context === "episodes") {
      url = url.concat("?fields=episode_code&grouped=0&use_saved_filters=0");
    } else if (context === "plates") {
      url = url.concat("?fields=drive%2Cplate_code&grouped=0&use_saved_filters=0");
    } else if (context === "scripts") {
      url = url.concat("?fields=script_code&grouped=0&use_saved_filters=0");
    }
    return this.http.get<IEntity[]>(url);
  }

  getChildren(entity: Entity, ids: { $oid: string }[]): Observable<IEntity[]> {
    const _ids = ids.map((i) => i["$oid"]);
    const url = `${environment.projectsURL}/${entity.projectCode}/${entity.context}?_id=${_ids.join(
      ","
    )}`;
    return this.http.get<IEntity[]>(url);
  }

  getAssetLevels(projectCode: string): Observable<string[]> {
    const url = `${environment.projectsURL}/${projectCode}/assets/asset_level_list`;
    return this.http.get<string[]>(url);
  }

  getGroups(projectCode: string, context: Context): Observable<string[]> {
    const url = `${environment.projectsURL}/${projectCode}/${context}/groups`;
    return this.http.get<string[]>(url);
  }

  getAllTags(projectCode: string, context: string): Observable<string[]> {
    const url = `${environment.projectsURL}/${projectCode}/${context}/tags/all`;
    return this.http.get<string[]>(url);
  }

  getAllByField(projectCode: string, context: string, field_name: string): Observable<string[]> {
    const url = `${environment.projectsURL}/${projectCode}/${context}/field/${field_name}`;
    return this.http.get<string[]>(url);
  }

  searchGroupedEntities(
    projectCode: string,
    context: string,
    search: { search: string; searchType: string; exactMatch: boolean }
  ): Observable<IEntity[]> {
    // in Shots by episode page,
    // TODO: if we ever make episodes searchable this should change
    if (context === "episodes") {
      context = "shots";
    }

    let url = `${environment.projectsURL}/${projectCode}/${context}?grouped=1&qexact=${search.exactMatch}&`;
    if (context === "assets") {
      url = url.concat(
        `${search.searchType === "tags" ? "tags" : "asset_code"}=is:${
          search.search
        }&fields=asset_code,asset_type,art_status,rig_status,srf_status`
      );
      return this.http.get<IEntity[]>(url);
    }

    if (context === "plates") {
      url = url.concat(
        `${search.searchType === "tags" ? "tags" : "plate_code"}=is:${
          search.search
        }&fields=plate_code,drive,reel,children,parent,out_of_picture,tags`
      );
      return this.http.get<IEntity[]>(url);
    }

    // shot context
    if (search.searchType === "tags") {
      url = url.concat(`tags=is:${search.search}&fields=episode_code,shot_code,shot_status`);
      return this.http.get<IEntity[]>(url);
    }

    // episode code _ shot code
    let regex = /^e[0-9]{3}[a-z]?_(s[0-9]{4}[a-z]?|assembly)$/g;
    if (regex.test(search.search)) {
      url = url.concat(
        `shot_code=is:${search.search.split("_")[1]}&episode_code=is:${
          search.search.split("_")[0]
        }&fields=episode_code,shot_code,shot_status&field_ops=episode_code%2Bshot_code`
      );
      return this.http.get<IEntity[]>(url);
    }

    // episode code starting with 'e'
    regex = /e[0-9]{3}[a-z]?(_?|\*?)/g;
    if (regex.test(search.search)) {
      url = url.concat(
        `episode_code=is:${search.search.replace(
          "_",
          ""
        )}&fields=episode_code,shot_code,shot_status`
      );
      return this.http.get<IEntity[]>(url);
    }

    // shot code
    regex = /s[0-9]{4}[a-z]?/g;
    if (regex.test(search.search)) {
      url = url.concat(`shot_code=is:${search.search}&fields=episode_code,shot_code,shot_status`);
    } else {
      // anything else just search for shot code as a fallback..
      url = url.concat(`shot_code=is:${search.search}&fields=episode_code,shot_code,shot_status`);
    }

    url = url + ",episode_status"; // add episode status to fields parameter. Request episode status field.
    return this.http.get<IEntity[]>(url);
  }

  searchUnGroupedEntities(
    projectCode: string,
    context: string,
    search: { search: string; searchType: string; exactMatch: boolean }
  ): Observable<IEntity[]> {
    let url = `${environment.projectsURL}/${projectCode}/${context}?grouped=0&qexact=${search.exactMatch}&`;
    if (context === "assets") {
      url = url.concat(
        `${search.searchType === "tags" ? "tags" : "asset_code"}=is:${search.search}`
      );
      return this.http.get<IEntity[]>(url);
    }

    if (context === "scripts") {
      url = url.concat(`script_code=is:${search.search}`);
      return this.http.get<IEntity[]>(url);
    }

    if (context === "episodes") {
      url = url.concat(`episode_code=is:${search.search}`);
      return this.http.get<IEntity[]>(url);
    }

    if (context === "plates") {
      // TODO: search.search is hardcoded as empty string for searching plate children
      // if we want to get all the plate codes, we need to say !is:doesnotexist
      if (search.search.trim() === "") {
        url = url.concat(
          `${
            search.searchType === "tags" ? "tags" : "plate_code"
          }=!is:thisplatedoesnotexist&fields=plate_code,drive,reel&use_saved_filters=0`
        );
      } else {
        url = url.concat(
          `${search.searchType === "tags" ? "tags" : "plate_code"}=is:${
            search.search
          }&fields=plate_code,drive,reel&use_saved_filters=0`
        );
      }
      return this.http.get<IEntity[]>(url);
    }

    // shot context
    if (search.searchType === "tags") {
      url = url.concat(`tags=is:${search.search}`);
      return this.http.get<IEntity[]>(url);
    }

    // episode code _ shot code
    let regex = /^e[0-9]{3}[a-z]?_(s[0-9]{4}[a-z]?|assembly)$/g;
    if (regex.test(search.search)) {
      url = url.concat(
        `shot_code=is:${search.search.split("_")[1]}&episode_code=is:${
          search.search.split("_")[0]
        }&field_ops=episode_code%2Bshot_code`
      );
      return this.http.get<IEntity[]>(url);
    }

    // episode code and shot code
    regex = /^[A-Z]{3}[0-9]{3}_[0-9]{3}(_[0-9]{2})?$/g;
    if (regex.test(search.search)) {
      url = url.concat(
        `shot_code=is:${search.search.split("_").slice(1).join("_")}&episode_code=is:${
          search.search.split("_")[0]
        }&field_ops=episode_code%2Bshot_code`
      );
      return this.http.get<IEntity[]>(url);
    }

    // 3 digit episode code and shot code
    regex = /^[0-9]{3}_[0-9]{3}(_[0-9]{2})?$/g;
    if (regex.test(search.search)) {
      url = url.concat(
        `shot_code=is:${search.search.split("_").slice(1).join("_")}&episode_code=is:${projectCode
          .toUpperCase()
          .slice(0, -2)}${search.search.split("_")[0]}&field_ops=episode_code%2Bshot_code`
      );
      return this.http.get<IEntity[]>(url);
    }

    // episode code starting with 'e'
    regex = /e[0-9]{3}[a-z]?(_?|\*?)/g;
    if (regex.test(search.search)) {
      url = url.concat(`episode_code=is:${search.search.replace("_", "")}`);
      return this.http.get<IEntity[]>(url);
    }

    // 3 digit episode code
    regex = /^[0-9]{3}$/g;
    if (regex.test(search.search)) {
      url = url.concat(
        `episode_code=is:${projectCode.toUpperCase().slice(0, -2)}${search.search.replace("_", "")}`
      );
      return this.http.get<IEntity[]>(url);
    }

    // shot code
    regex = /s[0-9]{4}[a-z]?/g;
    if (regex.test(search.search)) {
      url = url.concat(`shot_code=is:${search.search}`);
    } else {
      // anything else just search for shot code as a fallback..
      url = url.concat(`shot_code=is:${search.search}`);
    }
    return this.http.get<IEntity[]>(url);
  }

  filterEntities(projectCode: string, context: string, filter: string, fields: string) {
    return this.http.get<IEntity[]>(
      `${environment.projectsURL}/${projectCode}/${context}?${filter}&fields=${fields}&use_saved_filters=0`
    );
  }

  getVaultedInfo(projectCode: string, startDate: string, endDate: string) {
    return this.http.get<IVaultedInfo>(
      `${environment.projectsURL}/${projectCode}/vault-logs/start-date/${startDate}/cutoff/${endDate}`
    );
  }

  deleteEntity(entity: Entity) {
    return this.http.delete(
      `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.group}/${entity.entityCode}`
    );
  }
}
