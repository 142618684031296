<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">New User Role</h4>
      <div *ngIf="addSuccess" class="container alert alert-success alert-dismissible" role="alert">
        Successfully added: <strong>{{ role.name }}</strong>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="addError" class="container alert alert-danger alert-dismissible" role="alert">
        <strong>{{ errorMessage }}</strong>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <form #addRoleForm>
        <div class="form-group">
          <h5><label for="name">Role name</label></h5>
          <input #name type="text" class="form-control" id="name" />
        </div>

        <span class="list"><h5>Permissions:</h5></span>
        <div *ngFor="let permission of permissionsList" class="w-100">
          <div *ngIf="isDifferentCategory(permission.category)" class="w-100">
            <strong>{{ permission.category }}</strong>
          </div>
          <div class="form-check">
            <input
              class="form-check-input permissionBox"
              type="checkbox"
              [value]="permission.code"
              [id]="permission.code"
              #checkboxes />
            <label class="form-check-label" [for]="permission.code">
              {{ permission.label }}
            </label>
          </div>
        </div>

        <div class="text-right">
          <button type="button" class="btn btn-primary" (click)="add(addRoleForm, name.value)">
            Add
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
