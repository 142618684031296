import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { last, map, tap } from "rxjs/operators";

import { environment } from "../environments/environment";
import { UploadProgress } from "./utils";
import MongoDocument, { MongoDate, ObjectID } from "./mongo-document";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

export interface Meeting extends Partial<MongoDocument> {
  invalid_shot_code: string[];
  title: string;
  episode_code: string;
  notes: ObjectID[];
  filepath: string;
  created_by: string;
  datetime: MongoDate;
}

@Injectable({
  providedIn: "root",
})
export class MeetingNoteService {
  constructor(private http: HttpClient) {}

  getAllMeetings(projectCode: string): Observable<Meeting[]> {
    const url = `${environment.projectsURL}/${projectCode}/meeting`;
    return this.http.get<Meeting[]>(url);
  }

  update(
    projectCode: string,
    scheduleId: string,
    itemToBeUpdated: Partial<Meeting>
  ): Observable<boolean> {
    const url = `${environment.projectsURL}/${projectCode}/meeting/${scheduleId}`;
    return this.http.put<boolean>(url, JSON.stringify(itemToBeUpdated), httpOptions);
  }

  upload(
    projectCode: string,
    meetingTitle: string,
    meetingOrigin: string,
    episodeCode: string,
    templateVersion: string,
    files: (File | null)[],
    showProgress: (arg0: ReturnType<typeof UploadProgress.getEventMessage>) => void
  ) {
    let formData = new FormData();
    formData.append("meeting_title", meetingTitle);
    formData.append("meeting_origin", meetingOrigin);
    formData.append("episode_code", episodeCode);
    formData.append("template_version", templateVersion);

    for (const file of files) {
      if (file) {
        formData.append("file", file, file.name);
      }
    }

    const req = new HttpRequest(
      "POST",
      `${environment.projectsURL}/${projectCode}/notes/upload`,
      formData,
      { reportProgress: true, headers: new HttpHeaders({ enctype: "multipart/form-data" }) }
    );

    return this.http.request(req).pipe(
      map((event) => UploadProgress.getEventMessage(event)),
      tap((message) => showProgress(message)),
      last() // return last (completed) message to caller
    );
  }
}
