<div
  class="fade show d-block modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addChild"
  aria-hidden="true"
  (exited)="shouldExit($event)"
  appModalExit>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          Add Child to
          <span
            ><strong>{{ entity.entityCode }}</strong></span
          >
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body row">
        <form class="m-auto">
          <div class="form-group">
            <label class="d-block" for="name">Name:</label>
            <input
              type="text"
              class="form-control d-inline-block w-50 text-right"
              id="name"
              name="name"
              [value]="entity.entityCode + '_'"
              disabled />
            <input type="text" class="form-control d-inline-block w-50" id="name" name="name" />
          </div>

          <div class="form-group">
            <label for="desc">Description:</label>
            <input type="text" class="form-control" id="desc" name="desc" />
          </div>

          <div class="form-group">
            <label for="tags">Tags:</label>
            <input type="text" class="form-control" id="tags" name="tags" />
          </div>

          <div class="form-group">
            <label for="shot">Episodes:</label>
            <app-typeahead [items$]="episodes" [isSingleSelection]="true"></app-typeahead>
          </div>

          <div class="form-group">
            <label for="level">Level:</label>
            <app-typeahead [items$]="levels" [isSingleSelection]="true"></app-typeahead>
          </div>

          <div class="form-group">
            <label for="asset-type">Asset Type:</label>
            <app-typeahead [items$]="types" [isSingleSelection]="true"></app-typeahead>
          </div>

          <div class="form-group text-right">
            <button type="button" class="btn btn-primary">Create</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="fade show d-block modal-backdrop"></div>
