import { Context, IMedia, IEntity, IDocument, IStatus } from "./entity";
import { TableConfigColumnItem, TableConfigSubCol } from "../types/table-config";
import { UserFiltersByProject } from "../types/filters";
import { MongoDate } from "./mongo-document";
import { User } from "./user";
import {
  IDropdownItem,
  IMediaDropDownItem,
  IDocumentDropDownItem,
  IStage,
  mapDocumentToDropDown,
  mapScriptDataToDropDown,
} from "./dropdown-item";
import { HttpEvent, HttpEventType } from "@angular/common/http";

export function createEmptyUser(): User {
  const user: User = {
    email: "",
    username: "-- unassigned --",
    firstname: "",
    lastname: "",
    profile_picture: "",
    is_admin: false,
    projects: [],
    assigned_projects: [],
    departments: [],
    role: "",
    is_active: false,
    is_dev: false,
  };
  return user;
}

// https://www.typescriptlang.org/docs/handbook/mixins.html
export function applyMixins(derivedCtor: any, baseCtors: any[]) {
  baseCtors.forEach((baseCtor) => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
      derivedCtor.prototype[name] = baseCtor.prototype[name];
    });
  });
}

export function contentsTheSame(left: any[], right: any[]): boolean {
  const numItemsLeft = left.length;
  const numItemsRight = right.length;
  if (numItemsLeft !== numItemsRight) {
    return false;
  }

  let itemsFound = 0;
  for (const item of left) {
    if (right.includes(item)) {
      itemsFound++;
    }
  }
  return itemsFound === right.length;
}

export enum Attribute {
  ID = "id",
  VALUE = "value",
}

// this has a side effect of updating what items are checked in the same loop
export function getUpdatedItems(
  elements: NodeList,
  checkedItems?: { [key: string]: boolean },
  attribute: Attribute = Attribute.VALUE
): string[] {
  const updatedItems: string[] = [];
  elements.forEach((item) => {
    if (item.nodeType === 1 /*ELEMENT_NODE*/ && (item as HTMLInputElement).checked) {
      updatedItems.push((item as HTMLInputElement)[attribute]);
    }

    if (checkedItems) {
      checkedItems[(item as HTMLInputElement)[attribute]] = (item as HTMLInputElement).checked;
    }
  });

  return updatedItems;
}

export function mapToDropdownItem(list: string[]): IDropdownItem[];
export function mapToDropdownItem(
  list: { [key: string]: any }[],
  keyToMap: string
): IDropdownItem[];
export function mapToDropdownItem(
  list: string[] | { [key: string]: any }[],
  keyToMap?: string
): IDropdownItem[] {
  if (list.length === 0) {
    return [];
  }

  if (keyToMap === undefined) {
    const _list = list as string[];
    return _list.map((item) => {
      return { text: item };
    });
  } else {
    const _list = list as { [key: string]: any }[];
    return _list.map((item) => {
      return { text: item[keyToMap] };
    });
  }
}

export function formatDateTime(date: string) {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  let _date = new Date(date)
    .toLocaleDateString("default", options)
    .replace(/[\d]{2}([\d]{2})/g, "`$1");
  return _date;
}

export function compareDate(obj1: { datetime: MongoDate }, obj2: { datetime: MongoDate }) {
  let date1 = obj1["datetime"]["$date"];
  let date2 = obj2["datetime"]["$date"];

  if (date1 > date2) return -1;
  if (date1 < date2) return 1;
  return 0;
}

export function compareUploadDate(
  obj1: { upload_date?: MongoDate },
  obj2: { upload_date?: MongoDate }
) {
  let date1 = obj1.upload_date?.$date ?? "";
  let date2 = obj2.upload_date?.$date ?? "";

  if (date1 > date2) return -1;
  if (date1 < date2) return 1;
  return 0;
}

function getMaxStrLength(array: { [key: string]: any }[], key: string): number {
  let max = array[0][key] ? array[0][key].length : 0;

  for (const item of array) {
    if (item[key]) {
      if (item[key].length > max) {
        max = item[key].length;
      }
    }
  }

  return max;
}

export function mediaToString(media: IMedia) {
  if (media["text"] === "-- no specific media / version --") {
    return media["text"];
  }

  if (!("upload_date" in media)) {
    return media["movie"] ? media["movie"].split("/").pop() : media.thumbnail?.split("/").pop();
  }

  const date = formatDateTime(media.upload_date?.$date || "");
  const submittedBy = media["submitted_by"] || media["artists"] || "";

  if (media?.["movie_file"]?.["file_path"]) {
    return `${media.movie_file.file_path.split("/").pop()} - ${submittedBy} · ${date}`;
  }

  if (media?.["thumbnail_file"]?.["file_path"]) {
    return `${media.thumbnail_file.file_path.split("/").pop()} - ${submittedBy} · ${date}`;
  }

  if (media?.["pdf_file"]?.["file_path"]) {
    return `${media.pdf_file.file_path.split("/").pop()} - ${submittedBy} · ${date}`;
  }

  return `${media["dept"] ?? media["stage"] ?? ""}_${media["version"]} - ${
    media["res"] ?? ""
  } - ${submittedBy} · ${date}`;
}

export function mediaToDropdownItem(
  media: IMedia | IMedia[]
): IMediaDropDownItem | IMediaDropDownItem[] {
  if (media === null || media === undefined) return [];

  if ("length" in media) {
    if (media.length === 0) {
      return [];
    }

    // used to align the text of the dropdown item visible to the user
    const maxUsernameStrLength = getMaxStrLength(media as IMedia[], "submitted_by");
    const maxResStrLength = getMaxStrLength(media as IMedia[], "res");
    const maxVersionStrLength = getMaxStrLength(media as IMedia[], "version");

    return media.map((item: IMedia) => {
      if (!("upload_date" in item)) {
        return item as IMediaDropDownItem;
      }

      const obj = item;
      const date = formatDateTime(item.upload_date?.$date || "");

      obj["icon"] = item["extern"] ? "cloud_queue" : "home";

      const submittedBy = item["submitted_by"] || "";

      obj["text"] = item["dept"]
        ? `${item["dept"]}_${item["version"].padEnd(maxVersionStrLength)} - ${item["res"].padEnd(
            maxResStrLength
          )} - ${submittedBy.padEnd(maxUsernameStrLength)} · ${date}`
        : `${item["res"]}_${item["version"].padEnd(maxVersionStrLength)} · ${date}`;

      obj["normalized_text"] = item["dept"]
        ? `${item["dept"]}_${item["version"]} - ${item["res"]} - ${submittedBy} · ${date}`
        : `${item["res"]}_${item["version"]} · ${date}`;

      return obj as IMediaDropDownItem;
    });
  } else {
    if (!("upload_date" in media)) {
      return media as IMediaDropDownItem;
    }
    const obj = { ...media };
    const date = formatDateTime(obj.upload_date?.$date || "");

    obj["icon"] = obj["extern"] ? "cloud_queue" : "home";

    obj["text"] = obj["dept"]
      ? `${obj["dept"]}_${obj["version"]} - ${obj["res"]} - ${obj["submitted_by"]} · ${date}`
      : `${obj["res"]}_${obj["version"]} - ${obj["submitted_by"]} · ${date}`;

    return obj as IMediaDropDownItem;
  }
}

export class Duration {
  static formatTime(timeInSeconds: number): string {
    if (timeInSeconds < 60) {
      return `00:00:${this.leftPadOneZero(Math.floor(timeInSeconds))}`;
    }

    if (timeInSeconds >= 60 && timeInSeconds < 3600) {
      const mins = Math.floor(timeInSeconds / 60);
      const secs = Math.floor(timeInSeconds - mins * 60);
      return `00:${this.leftPadOneZero(mins)}:${this.leftPadOneZero(secs)}`;
    }

    const hrs = Math.floor(timeInSeconds / 3600);
    const mins = Math.floor(timeInSeconds - hrs * 3600);
    const secs = Math.floor(timeInSeconds - hrs * 3600 - mins * 60);
    return `${this.leftPadOneZero(hrs)}:${this.leftPadOneZero(mins)}:${this.leftPadOneZero(secs)}`;
  }

  static leftPadOneZero(i: number) {
    if (i < 10) {
      return `0${i}`;
    }
    return i;
  }
}

export type HttpProgress<T = unknown> = { status: Status; progress: number; body?: T | null };

export type Status =
  | "Idle"
  | "Started"
  | "Downloading"
  | "Processing"
  | "Completed"
  | "Failed"
  | "Suspended"
  | "Uploading"
  | "Submitted"
  | "Complete";

export class UploadProgress {
  /** Return distinct message for sent, upload progress, & response events */
  static getEventMessage<T = unknown>(event: HttpEvent<T>): HttpProgress<T> {
    switch (event.type) {
      case HttpEventType.Sent:
        return { status: "Started", progress: 0 };

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone =
          event.total !== undefined ? Math.round((100 * event.loaded) / event.total) : 0;
        if (percentDone === 100) {
          return { status: "Processing", progress: 99 }; // upload is done but server is processing it, just show 99% done
        } else {
          return { status: percentDone ? "Processing" : "Uploading", progress: percentDone };
        }

      case HttpEventType.Response:
        //  return {status: 'Complete', progress: 100, body: event.body};
        return { status: "Submitted", progress: 100, body: event.body };

      default:
        // this one doesn't get shown so we show the processing as soon as 100% upload progress is hit
        // TODO: test and verify this behavior
        return { status: "Processing", progress: 0 };
    }
  }
}

export class DownloadProgress {
  /** Return distinct message for sent, upload progress, & response events */
  static getEventMessage<T = unknown>(event: HttpEvent<T>): HttpProgress<T> {
    switch (event.type) {
      case HttpEventType.Sent:
        return { status: "Processing", progress: 0 };

      case HttpEventType.DownloadProgress:
        // Compute and show the % done:
        const percentDone =
          event.total !== undefined ? Math.round((100 * event.loaded) / event.total) : 0;
        return { status: "Downloading", progress: percentDone };

      case HttpEventType.Response:
        return { status: "Completed", progress: 100, body: event.body };

      case HttpEventType.ResponseHeader:
        return { status: event.ok ? "Processing" : "Failed", progress: 0 };

      default:
        return { status: "Downloading", progress: 0 };
    }
  }
}

export function findTrue(key: string, filters: { [key: string]: any }): string {
  if (Object.keys(filters).length <= 0) {
    return "";
  }

  if (!(key in filters)) {
    return "";
  }

  let trues = [];
  for (const [k, v] of Object.entries(filters[key])) {
    if (v) {
      trues.push(k);
    }
  }

  return trues.join(",");
}

export function processFiltersToQueryStr(
  url: string,
  filters: UserFiltersByProject,
  context: Context
): string {
  if (context === "assets") {
    url = `${url}&fields=asset_code%2Casset_type`;
    for (const key of Object.keys(filters["assets"] || {})) {
      if (key.endsWith("_status")) {
        const trues = findTrue(key, filters["assets"] || {});
        if (trues) {
          url = url.concat(`&${key}=${trues}`);
        }
      }
    }
    const users = findTrue("users", filters["assets"] || {});
    if (users) {
      url = url.concat(`&assigned=${users}`);
    }
  } else if (context === "shots") {
    url = `${url}&fields=episode_code%2Cshot_code`;
    for (const key of Object.keys(filters["assets"] || {})) {
      if (key.endsWith("_status")) {
        const trues = findTrue(key, filters["assets"] || {});
        if (trues) {
          url = url.concat(`&${key}=${trues}`);
        }
      }
    }
    const users = findTrue("users", filters["shots"] || {});
    if (users) {
      url = url.concat(`&assigned=${users}`);
    }
  } else {
    url = `${url}&fields=episode_code`;
    const episodeStatus = findTrue("episode_status", filters["episodes"] || {});
    if (episodeStatus) {
      url = url.concat(`&episode_status=${episodeStatus}`);
    }
  }

  return url;
}

export async function copyToClipboard(link: string): Promise<boolean> {
  try {
    await navigator.clipboard.writeText(link);
    return true;
  } catch (err) {
    console.error("Failed to copy: ", err);
    alert(`Failed to copy: ${err}`);
    return false;
  }
}

export function addFrameTimeStamps(media: IMedia | IDocument) {
  if (!("frames_info" in media)) {
    return;
  }

  if (media["frames_info"] === undefined) {
    return;
  }

  if (media["frames_info"]) {
    for (const key of ["first_frame", "total_frames", "diff_a", "diff_b", "fps"]) {
      if (!Object.keys(media["frames_info"]).includes(key)) {
        return;
      }
    }
  }

  let n = media["frames_info"]["first_frame"];
  const fts = [n];
  for (let i = 0; i < Math.floor(media["frames_info"]["total_frames"] / 3); i++) {
    n = (n * 1000000 + media["frames_info"]["diff_a"] * 1000000) / 1000000;
    fts.push(n);
    n = (n * 1000000 + media["frames_info"]["diff_b"] * 1000000) / 1000000;
    fts.push(n);
    n = (n * 1000000 + media["frames_info"]["diff_a"] * 1000000) / 1000000;
    fts.push(n);
  }
  if (media["frames_info"]["total_frames"] % 3 === 1) {
    n = (n * 1000000 + media["frames_info"]["diff_a"] * 1000000) / 1000000;
    fts.push(n);
  } else if (media["frames_info"]["total_frames"] % 3 === 2) {
    n = (n * 1000000 + media["frames_info"]["diff_a"] * 1000000) / 1000000;
    fts.push(n);
    n = (n * 1000000 + media["frames_info"]["diff_b"] * 1000000) / 1000000;
    fts.push(n);
  }
  fts.pop();
  fts.push(media["frames_info"]["fps"]);

  media["frame_timestamps"] = fts;
}

export function columnTrackBy(index: number, obj: TableConfigColumnItem | TableConfigSubCol) {
  index;
  return obj["name"];
}

export function entityTrackBy(index: number, obj: IEntity) {
  if (obj["__code"]) {
    return obj["__code"];
  } else if (obj["episode_code"] && obj["shot_code"]) {
    return `${obj["episode_code"]}${obj["shot_code"]}`;
  } else if (obj["asset_type"] && obj["asset_code"]) {
    return `${obj["asset_type"]}${obj["asset_code"]}`;
  } else if (obj["plate_code"]) {
    return obj["plate_code"];
  } else if (obj["entityCode"]) {
    return obj["entityCode"];
  } else {
    return index;
  }
}

export function difference(setA: Set<any>, setB: Set<any>) {
  for (let elem of setB) {
    setA.delete(elem);
  }
  return setA;
}

enum FOUND {
  NO,
  YES,
}

function hasEveryItemInArray2(array1: any[], array2: any[]) {
  return array1.every((item) => array2.includes(item) === true) ? FOUND.YES : FOUND.NO;
}

export function validateInput(
  inputData: string | string[],
  field: string,
  validItems: any[] = []
): { fieldName: string; isValid: boolean } {
  if (validItems.length === 0) {
    return { fieldName: field, isValid: true };
  }

  // tags can be any input, i.e. if we validate it against validItems, we wont be able to add new tags
  if (field === "tags") {
    return { fieldName: "Tags", isValid: true };
  }

  let fieldsMap: { [key: string]: string } = {
    "plate.plate_code": "Plates - Plate Code",
    lgt_parent: "Lighting - Parent",
    lgt_children: "Lighting - Children",
    cmp_parent: "Comp - Parent",
    cmp_children: "Comp - Children",
    same_as_camera: "Camera - Same as",
    parent: "Parent",
    children: "Children",
    shot_code: "Shot Code",
    same_as_camera_children: "Camera - Children",
    "spp.lay_reuse": "SPP - Layout Reuse",
    "spp.lgt_reuse": "SPP - Lighting Reuse",
    "spp.plate.plate_code": "SPP - Plate code",
  };

  let found: FOUND = FOUND.NO;
  let fields = [
    "spp.plate.plate_code",
    "plate.plate_code",
    "lgt_parent",
    "cmp_parent",
    "lgt_children",
    "cmp_children",
    "parent",
    "children",
    "shot_code",
    "same_as_camera",
    "same_as_camera_children",
    "spp.lay_reuse",
    "spp.lgt_reuse",
  ];

  if (field === "same_as_camera_children") {
    // validItems is an array of objects
    found = (inputData as string[]).every(
      (item) => validItems.findIndex((v) => v.toString() === item) > -1
    )
      ? FOUND.YES
      : FOUND.NO;
  } else if (field === "same_as_camera") {
    if ((inputData as string).trim() === "") {
      found = FOUND.YES;
    } else {
      found = validItems.findIndex((v) => v.toString() === inputData) > -1 ? FOUND.YES : FOUND.NO;
    }
  } else {
    if (typeof inputData === "string" && validItems.length > 0 && fields.includes(field)) {
      if (inputData.trim() === "") {
        found = FOUND.YES;
      } else {
        found = validItems.findIndex((item) => item === inputData) > -1 ? FOUND.YES : FOUND.NO;
      }
    } else if (Array.isArray(inputData)) {
      found = hasEveryItemInArray2(inputData, validItems);
    }
  }

  return { fieldName: fieldsMap[field], isValid: found === FOUND.YES };
}

export function mapStageToDropdown(
  stage: { [key: string]: IStage[] },
  versionDropdownItems: IDocumentDropDownItem[],
  mediaType: string
) {
  let stageName = Object.keys(stage).pop() || "";
  let documents = stage[stageName];

  for (let i = documents.length - 1; i >= 0; i--) {
    if (mediaType === "scripts" || mediaType === "storyboards") {
      versionDropdownItems.push(...mapDocumentToDropDown(documents[i], mediaType));
    } else {
      // mediaType === 'assembly' or 'leica'
      versionDropdownItems.push(...mapScriptDataToDropDown(documents[i], mediaType));
    }
  }
}

export function formatVersionDropDownText(versionDropdownItems: IDropdownItem[]) {
  let maxVersionStrLen = 0;
  let maxTextStrLen = 0;
  for (let item of versionDropdownItems) {
    if (maxVersionStrLen < item["version"].length) {
      maxVersionStrLen = item["version"].length;
    }
    if (maxTextStrLen < item["text"].length) {
      maxTextStrLen = item["text"].length;
    }
  }
  for (let item of versionDropdownItems) {
    //formatting
    item["text"] = `${item["text"].padEnd(maxTextStrLen)} · ${item["version"].padEnd(
      maxVersionStrLen
    )} · ${formatDateTime(item.upload_date?.$date || "")}`;
  }
}

export function toJSDate(date: string | MongoDate | Date) {
  /**
   * Take an input that may be a MongoDate, an ISO string, or a JS Date and return it as a JS Date.
   */
  if (date instanceof Date) {
    return date;
  } else if (typeof date === "string") {
    return new Date(date);
  } else {
    return new Date(date.$date);
  }
}

export function findStatusColor(status: string, statuses: IStatus[]) {
  for (const statusObj of statuses) {
    if (status === statusObj["text"]) {
      return statusObj["color"];
    }
  }

  return statuses[0]["color"];
}

export function flattenAssemblyLeicaFields(
  entity: IEntity,
  field: "leica" | "assembly" | "stages" | "storyboards"
) {
  let flattened: IMedia[] = [];
  for (let item of entity[field] ?? []) {
    for (let value of Object.values(item) as IMedia[][]) {
      try {
        for (let media of value) {
          flattened.push(media);
        }
      } catch {
        // array is already flat
        return entity[field];
      }
    }
  }
  return flattened;
}
