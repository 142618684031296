import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";

import { of, Observable } from "rxjs";

import { Context } from "../entity";
import { EntityService } from "../entity.service";

@Component({
  selector: "app-add-entity",
  templateUrl: "./add-entity.component.html",
  styleUrls: ["./add-entity.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEntityComponent implements OnInit {
  @Input() projectCode: string;
  @Input() context: Context;

  @Output() closed = new EventEmitter<boolean>();

  groups$: Observable<string[]>;
  selectedLevel: string;
  selectedGroup:
    | string
    | /*string is the 'real' type the following types are added to match typeahead.component.added*/ string[]
    | undefined;
  added = false;
  refreshOnExit = false;
  hasDangerBorder = false;
  entityCodeInput = "";
  assetLevels: string[];
  name: string = "";
  description: string = "";

  constructor(private entityService: EntityService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.context !== "scripts") {
      this.groups$ = this.entityService.getGroups(this.projectCode, this.context);
    } else {
      this.groups$ = of([]);
    }

    if (this.context === "assets") {
      this.entityService
        .getAssetLevels(this.projectCode)
        .subscribe((levels) => (this.assetLevels = levels));
    }
  }

  add(entityCode: string) {
    this.added = false;
    if (this.assetLevels && !this.selectedLevel) {
      return;
    }

    // an Entity's 'group' field should always be a string
    // but the typeahead component emits a string | string[] | undefined, we ignore those undefined/string[]
    if (this.selectedGroup === undefined || Array.isArray(this.selectedGroup)) {
      return;
    }

    if (!entityCode) {
      this.entityService
        .addEntityGroup(this.projectCode, this.context, this.selectedGroup)
        .subscribe((_) => {
          this.added = true;
          this.refreshOnExit = true;
          this.groups$ = this.entityService.getGroups(this.projectCode, this.context);
          this.cdr.markForCheck();
        });
    } else {
      this.entityCodeInput = entityCode;
      const assetInfo =
        this.name.trim() === "" && this.description.trim() === ""
          ? undefined
          : { name: this.name.trim(), description: this.description.trim() };
      this.entityService
        .addEntity(
          this.projectCode,
          this.context,
          this.selectedGroup,
          entityCode,
          this.selectedLevel,
          assetInfo
        )
        .subscribe((_) => {
          this.added = true;
          this.refreshOnExit = true;
          this.groups$ = this.entityService.getGroups(this.projectCode, this.context);
          this.cdr.markForCheck();
        });
    }
  }

  addScript(entityCode: string, scriptName: string) {
    this.added = false;
    if (!entityCode) {
      return;
    }
    const scriptData = { script_code: entityCode, script_name: scriptName };

    this.entityService.addEntityScript(this.projectCode, scriptData).subscribe((_) => {
      this.added = true;
      this.cdr.markForCheck();
      this.refreshOnExit = true;
    });
  }
}
