import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { last, map, tap } from "rxjs/operators";

import { Entity, IEntity } from "./entity";
import { UploadProgress, DownloadProgress, HttpProgress } from "./utils";
import { environment } from "../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class EpisodeService {
  constructor(private http: HttpClient) {}

  getOneEpisode(projectCode: string, episodeCode: string): Observable<IEntity> {
    const url = `${environment.projectsURL}/${projectCode}/episodes/${episodeCode}`;
    return this.http.get<IEntity>(url);
  }

  update(entity: Entity, itemToBeUpdated: object): Observable<boolean> {
    let url = `${environment.projectsURL}/${entity.projectCode}/episodes/${entity.group}`;
    return this.http.put<boolean>(url, JSON.stringify(itemToBeUpdated), httpOptions);
  }

  // getShotsByEpisode(projectCode: string, episodeCode: string): Observable<object> {
  //   let url = `${environment.projectsURL}/${projectCode}/episodes/${episodeCode}/shots?fields=episode_code%2Cshot_code&grouped=1`;
  //   return this.http.get<object[]>(url);
  // }

  getThumbnailURL(
    projectCode: string,
    episodeCode: string,
    propertyType: string,
    stage = "",
    version = ""
  ): string {
    if (!stage && !version) {
      return `${environment.projectsURL}/${projectCode}/episodes/${propertyType}/${episodeCode}/thumbnail`;
    } else {
      return `${environment.projectsURL}/${projectCode}/episodes/${propertyType}/${episodeCode}/thumbnail?stage=${stage}&version=${version}`;
    }
  }

  // TODO: an Entity instance CAN have an episode_name, if it's type is Episode. Might be be better to subclass Entity for different
  //   contexts of Entity.
  entityExportSpreadsheet(
    entity: Entity,
    showProgress: (arg0: HttpProgress<Blob>) => void
  ): Observable<HttpProgress<Blob>> {
    let url = `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.entityCode}/export`;

    let formData = new FormData();
    formData.append("episode_name", entity["episode_name"]);

    const req = new HttpRequest<FormData>("POST", url, formData, {
      reportProgress: true,
      responseType: "blob",
      headers: new HttpHeaders({ enctype: "multipart/form-data" }),
    });

    return this.http.request<Blob>(req).pipe(
      map((event) => DownloadProgress.getEventMessage<Blob>(event)),
      tap((message) => showProgress(message)),
      last() // return last (completed) message to caller
    );
  }

  uploadDueDates(
    file: File,
    projectCode: string,
    episode: string,
    showProgress: (arg0: ReturnType<typeof UploadProgress.getEventMessage>) => void
  ): Observable<ReturnType<typeof UploadProgress.getEventMessage>> {
    let formData = new FormData();
    formData.append("file", file, file.name);

    const req = new HttpRequest(
      "POST",
      `${environment.projectsURL}/${projectCode}/shots/${episode}/upload-due-dates?`,
      formData,
      { reportProgress: true, headers: new HttpHeaders({ enctype: "multipart/form-data" }) }
    );

    return this.http.request(req).pipe(
      map((event) => UploadProgress.getEventMessage(event)),
      tap((message) => showProgress(message)),
      last() // return last (completed) message to caller
    );
  }
}
