<div class="dropdown" [ngClass]="{ 'form-group': labelString }">
  <label *ngIf="labelString" [for]="idString">{{ labelString }}</label>
  <input
    #textbox
    (blur)="onBlur($event)"
    type="text"
    class="form-control"
    [ngClass]="{ 'on-label': labelString }"
    [id]="idString"
    [name]="idString"
    (click)="showDropdown = true"
    autocomplete="off"
    [(ngModel)]="inputString"
    (keydown)="onKeyDown($event)"
    (keyup.escape)="onBlur2()"
    (keyup.enter)="onKeyEnter(textbox.value)"
    (keydown.arrowup)="
      arrowSelect(-1, filteredItems.length > 0 ? filteredItems.length : items.length)
    "
    (keydown.arrowdown)="
      arrowSelect(1, filteredItems.length > 0 ? filteredItems.length : items.length)
    "
    (focus)="textbox.classList.remove('border-blink')"
    [ngStyle]="{ 'min-width.px': minWidth }"
    [placeholder]="placeholder" />

  <div
    *ngIf="showDropdown"
    class="show dropdown-menu"
    [ngClass]="{ 'push-down-menu': labelString }"
    (mousedown)="$event.preventDefault()">
    <ul
      #dropdown
      *ngIf="filteredItems.length > 0; else originalItems"
      class="p-0 m-0"
      [style.max-height]="maxHeight + 'px'">
      <ng-container *ngFor="let item of filteredItems; index as i">
        <li
          *ngIf="item !== null && item !== undefined"
          class="dropdown-item"
          [style.background-color]="i === focusedItem ? '#e9ecef' : '#fff'"
          (mousedown)="focusedItem = i; onBlur($event)">
          <span [ngClass]="getStyle(item)">{{
            customToString ? customToString(item) : item.toString()
          }}</span>
        </li>
      </ng-container>
    </ul>
    <ng-template #originalItems>
      <ul #dropdown class="p-0 m-0" [style.max-height]="maxHeight + 'px'">
        <ng-container *ngFor="let item of items; index as i">
          <li
            *ngIf="item !== null && item !== undefined"
            class="dropdown-item"
            [style.background-color]="i === focusedItem ? '#e9ecef' : '#fff'"
            (mousedown)="focusedItem = i; onBlur($event)">
            <span [ngClass]="getStyle(item)">{{
              customToString ? customToString(item) : item.toString()
            }}</span>
          </li>
        </ng-container>
      </ul>
    </ng-template>
  </div>
</div>
