import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { EntityContainerComponent } from "./entity-container/entity-container.component";
import { UserListComponent } from "./user-list/user-list.component";
import { LoginComponent } from "./login/login.component";
import { ProjectsComponent } from "./projects/projects.component";
import { ProjectDetailComponent } from "./project-detail/project-detail.component";
import { ProjectDetailTabComponent } from "./project-detail-tab/project-detail-tab.component";
import { AddUserComponent } from "./add-user/add-user.component";
import { RoleListComponent } from "./role-list/role-list.component";
import { ActivateAccountComponent } from "./activate-account/activate-account.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { SimpleChecklistComponent } from "./simple-checklist/simple-checklist.component";
import { ScheduleComponent } from "./schedule/schedule.component";
import { MeetingNotesComponent } from "./meeting-notes/meeting-notes.component";
import { DocsComponent } from "./docs/docs.component";
import { EntityDetailTableViewComponent } from "./entity-detail-table-view/entity-detail-table-view.component";
import { PlatesComponent } from "./plates/plates.component";
import { BundlesComponent } from "./bundles/bundles.component";
import { VaultLogsComponent } from "./vault-logs/vault-logs.component";
// import {SphinxDocsComponent} from './sphinx-docs/sphinx-docs.component';
import {SwaggerApiReferenceComponent} from './swagger-api-reference/swagger-api-reference.component';
import { TokenGuard } from "./token.guard";
import {DocLinksPageComponent} from './doc-links-page/doc-links-page.component';


const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "projects", component: ProjectsComponent },
  {
    path: "projects/:projectCode",
    component: ProjectDetailComponent,
    children: [
      { path: "details", component: ProjectDetailTabComponent },
      { path: "assets", component: EntityContainerComponent },
      { path: "assets/:group/:entityCode", component: EntityDetailTableViewComponent },
      { path: "shots", component: EntityContainerComponent },
      { path: "shots/:group/:entityCode", component: EntityDetailTableViewComponent },
      { path: "episodes", component: EntityContainerComponent },
      //{ path: 'episodes/:group/shots', component: EntityContainerComponent },
      { path: "plates", component: PlatesComponent },
      { path: "scripts", component: EntityContainerComponent },
      { path: "hdri", component: EntityContainerComponent },
      { path: "schedule", component: ScheduleComponent },
      { path: "meeting", component: MeetingNotesComponent },
      { path: "bundles", component: BundlesComponent },
      { path: "vault-logs", component: VaultLogsComponent },
      { path: "documentation-links", component: DocLinksPageComponent}
    ],
  },
  { path: "docs", component: DocsComponent },
  // { path: "sphinxdocs", children: [
  //     {path: '**', component: SphinxDocsComponent}
  // ]},
  {path: "swagger-api-reference", component: SwaggerApiReferenceComponent},
  { path: "devlist", component: SimpleChecklistComponent },
  { path: "users", component: UserListComponent },
  { path: "users/new", component: AddUserComponent },
  { path: "users/manageRole", component: RoleListComponent },
  { path: "activate/:token", component: ActivateAccountComponent, canActivate: [TokenGuard] },
  { path: "reset-password/:token", component: ActivateAccountComponent, canActivate: [TokenGuard] },
  { path: "login", component: LoginComponent },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
