import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  clickedEpisodeLinkInEpisodeTab = new BehaviorSubject<boolean>(false);
  clickedEpisodeLinkInEpisodeTab$ = this.clickedEpisodeLinkInEpisodeTab.asObservable();

  constructor() {}
}
