<div
  *ngIf="Object.keys(renderLayers).length > 0"
  class="text-nowrap"
  [ngClass]="{ 'shrink-height': shrinkHeight }">
  Layers to Preserve:
  <div class="form-check d-flex-inline">
    <input
      class="form-check-input"
      type="checkbox"
      [id]="group + entityCode + 'layers-to-preserve'"
      (change)="toggleSelectAll($event)" />
    <label class="form-check-label" [for]="group + entityCode + 'layers-to-preserve'"
      >Select all</label
    >
  </div>
  <div #checkboxes *ngFor="let layer of renderLayers | keyvalue" class="form-check d-flex-inline">
    <input
      class="form-check-input"
      type="checkbox"
      [id]="group + entityCode + layer.key"
      [checked]="layer.value['preserve'] !== undefined ? layer.value['preserve'] : false"
      (change)="updateRenderLayers(layer.key, $event)" />
    <label class="form-check-label" [for]="group + entityCode + layer.key">{{ layer.key }}</label>
  </div>
</div>
<div *ngIf="Object.keys(renderLayers).length > 3" class="form-check d-flex-inline">
  <a
    href="#"
    (click)="$event.preventDefault(); shrinkHeight = !shrinkHeight"
    class="card-link font-smaller"
    >{{ shrinkHeight ? Object.keys(renderLayers).length - 3 + " more..." : "less..." }}</a
  >
</div>
