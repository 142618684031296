import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ViewChildren,
  QueryList,
  ElementRef,
} from "@angular/core";

import { Context, Entity } from "../entity";
import { EntityService } from "../entity.service";

@Component({
  selector: "app-render-layers",
  templateUrl: "./render-layers.component.html",
  styleUrls: ["./render-layers.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenderLayersComponent implements OnInit {
  @Input() renderLayers: { [key: string]: { preserve: boolean } };
  @Input() projectCode: string;
  @Input() context: Context;
  @Input() group: string;
  @Input() entityCode: string;

  shrinkHeight: boolean = true;
  entity: Entity;
  Object = Object;

  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;

  constructor(private entityService: EntityService) {}

  ngOnInit() {
    this.entity = {
      projectCode: this.projectCode,
      context: this.context,
      group: this.group,
      entityCode: this.entityCode,
    } as Entity;
  }

  updateRenderLayers(layer: string, $event: Event) {
    const checkbox = $event.target;
    if (!checkbox || !(checkbox instanceof HTMLInputElement)) {
      return;
    }
    for (let l in this.renderLayers) {
      if (l === layer) {
        this.renderLayers[layer]["preserve"] = checkbox.checked;
      } else {
        // TODO: we can probably clean up this else statement.
        if (this.renderLayers[l]["preserve"] === undefined) {
          this.renderLayers[l] = { preserve: false };
        }
      }
    }

    this.entityService
      .updateOne(this.entity, { render_layers: this.renderLayers })
      .subscribe((renderLayers) => (this.renderLayers = renderLayers["render_layers"]));
  }

  toggleSelectAll($event: Event) {
    const checkbox = $event.target;
    if (checkbox && checkbox instanceof HTMLInputElement) {
      const selected = checkbox.checked;

      for (let l in this.renderLayers) {
        this.renderLayers[l] = { preserve: selected };
      }

      this.entityService
        .updateOne(this.entity, { render_layers: this.renderLayers })
        .subscribe((renderLayers) => (this.renderLayers = renderLayers["render_layers"]));
    }
  }
}
