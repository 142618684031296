import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
} from "@angular/core";
import { Observable, Subscription, of } from "rxjs";
import { map, tap } from "rxjs/operators";

import { IEntity, Entity, IStatus, ScriptEntity, Context, IMedia } from "../entity";
import { ScriptService } from "../script.service";
import { EpisodeService } from "../episode.service";
import { User } from "../user";
import {
  IDocumentDropDownItem,
  IStage,
  mapDocumentToDropDown,
  mapScriptDataToDropDown,
} from "../dropdown-item";
import { flattenAssemblyLeicaFields, compareUploadDate } from "../utils";
import { ActivatedRoute, Router } from "@angular/router";
import { QueryParamService } from "../query-param.service";
import { TableConfigColumnItem } from "../../types/table-config";
import { ProjectService } from "../project.service";
import { entityTrackBy, columnTrackBy } from "../utils";
import { EntityService, PlayMedia } from "../entity.service";
import { NoteService } from "../note.service";
import { UserService } from "../user.service";
import { TypeaheadComponent } from "../typeahead/typeahead.component";
import { Filters } from "../../types/filters";

@Component({
  selector: "app-scripts",
  templateUrl: "./scripts.component.html",
  styleUrls: ["./scripts.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScriptsComponent implements OnInit, AfterViewInit {
  @Input() episodes: Entity[];
  @Input() projectCode: string;
  @Input() statuses: IStatus[];
  @Input() usersInProject: User[];
  @Input() users: { [key: string]: User[] };

  scripts$: Observable<IEntity[]>;
  scripts: ScriptEntity[] = [];
  viewMedia: PlayMedia;
  context: Context = "scripts";
  columns$: Observable<TableConfigColumnItem[]>;
  columns: TableConfigColumnItem[];
  openUpload: undefined | Entity;
  openAddEntity: boolean;
  openDocumentViewer: undefined | Entity;
  openMediaPlayer: boolean;
  documentViewerDownloadDir: string = "scripts";
  documentViewerFileName: string;
  initialized = false;
  activeSortColumn: "script_code" | "episode_code" = "script_code";
  sortByScriptCode: "ascending" | "descending" = "ascending";
  sortByEpisodeCode: "ascending" | "descending" = "descending";

  searching: boolean;
  uploadDirectory: string;
  getNotesFromScriptAssembly: boolean;

  entityTrackBy = entityTrackBy;
  columnTrackBy = columnTrackBy;

  @ViewChild("tableContainer", { static: true }) tableContainer: ElementRef;
  scrollPosition: { x: number; y: number } = { x: 0, y: 0 };

  searchTypeahead: TypeaheadComponent;
  @ViewChild("searchTypeahead") set setSearchTypeahead(ref: TypeaheadComponent) {
    this.searchTypeahead = ref;
  }

  @ViewChildren("entityRows") entityRows: QueryList<ElementRef>;

  filters$: Observable<Filters>;
  isFiltersEnabled: boolean;

  subs: Subscription[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private scriptService: ScriptService,
    private episodeService: EpisodeService,
    private projectService: ProjectService,
    private entityService: EntityService,
    private noteService: NoteService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private queryParamService: QueryParamService
  ) {}

  ngOnInit() {
    this.columns$ = this.projectService
      .getTableConfig(this.projectCode, this.context.slice(0, this.context.length - 1))
      .pipe(map((columns) => (this.columns = columns)));

    this.getScriptsWithFilters();

    this.subs.push(
      this.entityService.playMedia$.subscribe((viewMedia) => {
        this.scrollPosition = {
          x: this.tableContainer.nativeElement.scrollLeft,
          y: this.tableContainer.nativeElement.scrollTop,
        };

        // TODO: This gets the media twice. Once here and in media-player.component ngOnInit()
        viewMedia["media"].subscribe((media: IMedia[]) => {
          if (media.length > 0) {
            this.viewMedia = viewMedia;
            this.openMediaPlayer = true;
            this.cdr.markForCheck();
          } else {
            alert(
              `No items in ${viewMedia["src"].toLowerCase()} for ${viewMedia["entity"].toString()}.`
            );
          }
        });
      })
    );

    this.play();
  }

  ngAfterViewInit() {
    this.entityRows.changes.subscribe((changes) => {
      const routeFragment = this.activatedRoute.snapshot.fragment;

      if (routeFragment && changes.length) {
        const fragment = document.getElementById(routeFragment);
        if (fragment) {
          this.tableContainer.nativeElement.scrollTop = fragment.offsetTop - 110;
        }
      }

      if (
        (changes.length && !this.openMediaPlayer && this.scrollPosition.x !== 0) ||
        this.scrollPosition.y !== 0
      ) {
        this.scrollBack();
      }
    });
  }

  play() {
    const play = this.activatedRoute.snapshot.queryParams["play"];
    const version = this.activatedRoute.snapshot.queryParams["version"];
    const context = this.activatedRoute.snapshot.queryParams["play-ctx"];
    const src = this.activatedRoute.snapshot.queryParams["play-src"];
    const compare = this.activatedRoute.snapshot.queryParams["compare"];
    const audio = this.activatedRoute.snapshot.queryParams["audio"] || "left";
    if (!play && !version && !context && !src) {
      return;
    }

    if (this.tableContainer) {
      this.scrollPosition = {
        x: this.tableContainer.nativeElement.scrollLeft,
        y: this.tableContainer.nativeElement.scrollTop,
      };
    }

    const _entity = {
      projectCode: this.projectCode,
      context: context as Context,
      group: this.context === "scripts" ? "" : play.split("_")[0],
      entityCode: this.context === "scripts" ? play : play.split("_").slice(1).join("_"),
    } as IEntity;

    this.entityService
      .findOne(
        this.projectCode,
        this.context as Context,
        _entity.group,
        _entity.entityCode,
        "fields=episode_code,asset_type,asset_code,shot_code,script_code,media,catalog,plate,spp,default_thumbnail&exclude_fields=_id"
      )
      .subscribe((entity) => {
        let media: IMedia[] = flattenAssemblyLeicaFields(entity, src);
        media.sort(compareUploadDate);

        entity = new Entity({ ...entity, projectCode: this.projectCode, context: context });
        this.noteService.getNotes(entity).subscribe((notes) => {
          // trigger for media component to open
          this.entityService.playMedia.next({
            notes: notes || [],
            media: of(media),
            selectedVersion: version,
            compare: compare,
            audio: audio,
            src: src,
            entity: entity as Entity,
            defaultThumbnail: entity["default_thumbnail"],
            statuses: this.statuses,
          });
        });
      });
  }

  scrollBack() {
    this.tableContainer.nativeElement.scrollLeft = this.scrollPosition.x;
    this.tableContainer.nativeElement.scrollTop = this.scrollPosition.y;
  }

  compareByScriptCode(obj1: ScriptEntity, obj2: ScriptEntity) {
    let code1 = obj1["script_code"] ?? "";
    let code2 = obj2["script_code"] ?? "";

    if (code1 < code2) return -1;
    if (code1 > code2) return 1;
    return 0;
  }

  compareByEpisodeCode(obj1: ScriptEntity, obj2: ScriptEntity) {
    let code1 = obj1["episode_code"] ?? "";
    let code2 = obj2["episode_code"] ?? "";

    if (code1 < code2) return -1;
    if (code1 > code2) return 1;
    return 0;
  }

  sort(column: string) {
    if (column === "script_code") {
      this.activeSortColumn = "script_code";
      if (this.sortByScriptCode === "ascending") {
        this.scripts.sort(this.compareByScriptCode);
        this.scripts.reverse();
        this.sortByScriptCode = "descending";
      } else {
        this.scripts.sort(this.compareByScriptCode);
        this.sortByScriptCode = "ascending";
      }
      this.scripts$ = of(this.scripts);
    }

    if (column == "episode_code") {
      this.activeSortColumn = "episode_code";
      if (this.sortByEpisodeCode === "ascending") {
        this.scripts.sort(this.compareByEpisodeCode);
        this.scripts.reverse();
        this.sortByEpisodeCode = "descending";
      } else {
        this.scripts.sort(this.compareByEpisodeCode);
        this.sortByEpisodeCode = "ascending";
      }
      this.scripts$ = of(this.scripts);
    }
  }

  chooseIcon(column: string): string {
    if (this.activeSortColumn !== column) {
      return "";
    }

    if (this.activeSortColumn === "script_code") {
      return this.sortByScriptCode === "ascending" ? "arrow_drop_up" : "arrow_drop_down";
    } else if (this.activeSortColumn === "episode_code") {
      return this.sortByEpisodeCode === "ascending" ? "arrow_drop_up" : "arrow_drop_down";
    }
    return "";
  }

  onSearch(searchTerm: string | string[] | undefined) {
    this.queryParamService.updateQueryParams({
      expand: null,
      "show-all-notes": null,
      note: null,
      "show-completed-notes": null,
    });
    this.scrollPosition = { x: 0, y: 0 };

    if (searchTerm === undefined || Array.isArray(searchTerm)) {
      // search is cancelled by pressing 'esc')
      this.searching = false;
      return;
    }

    if (searchTerm === "") {
      this.searching = false;
      this.getScriptsWithFilters();
      this.subs.push(this.filters$.subscribe(() => this.cdr.markForCheck()));
      return;
    }

    this.searching = true;
    let search = searchTerm;
    const plusRegexp = /\s*\+\s*/g;
    const commaRegexp = /\s*,\s*/g;
    search = search.replace(plusRegexp, "%2B").replace(commaRegexp, ",").trim();
    this.scripts$ = this.entityService
      .searchUnGroupedEntities(this.projectCode, "scripts", {
        search: search,
        searchType: "entityCode",
        exactMatch: true,
      })
      .pipe(
        tap((scripts) => {
          for (let script of scripts) {
            script.projectCode = this.projectCode;
            script.context = this.context;
            script.entityCode = script.script_code;
            this.scripts.push(script as ScriptEntity);
          }
        })
      );
  }

  onChangeColumns(columns: { name: string }[]) {
    this.columns = [...(columns as TableConfigColumnItem[])];
  }

  onSavedColumns(columns: { name: string }[]) {
    this.userService
      .saveTableConfig(
        this.projectCode,
        this.context.slice(0, this.context.length - 1),
        columns as TableConfigColumnItem[]
      )
      .subscribe();
  }

  searchAndFilter() {
    this.queryParamService.updateQueryParams({
      expand: null,
      "show-all-notes": null,
      note: null,
      "show-completed-notes": null,
    });
    this.scrollPosition = { x: 0, y: 0 };

    if (this.searchTypeahead.inputString !== "") {
      // if we are filtering and there is any text on the search box, search for that item using the filters
      this.scripts$ = this.entityService
        .searchUnGroupedEntities(this.projectCode, "scripts", {
          search: this.searchTypeahead.inputString,
          searchType: "entityCode",
          exactMatch: false,
        })
        .pipe(
          tap((scripts) => {
            for (let script of scripts) {
              script.projectCode = this.projectCode;
              script.context = this.context;
              script.entityCode = script.script_code;
              this.scripts.push(script as ScriptEntity);
            }
          })
        );
    } else {
      this.getScriptsWithFilters();
    }
  }

  getScripts() {
    this.scripts$ = this.entityService.getUnGroupedEntities(this.projectCode, this.context).pipe(
      tap((scripts) => {
        for (let script of scripts) {
          script.projectCode = this.projectCode;
          script.context = this.context;
          script.entityCode = script.script_code;
          this.scripts.push(script as ScriptEntity);
        }
      })
    );
  }

  getScriptsWithFilters() {
    this.filters$ = this.projectService.getFilters(this.projectCode, this.context).pipe(
      tap((filters) => {
        this.isFiltersEnabled = filters["is_enabled"];
        this.getScripts();
      })
    );
  }

  onToggledFilters($event: boolean) {
    this.isFiltersEnabled = $event;
    this.queryParamService.updateQueryParams({ expand: null });
    if (!this.isFiltersEnabled) {
      this.getScripts();
    }
  }
}
