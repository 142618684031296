import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

enum DateTime {
  isUndefined,
  dateOnly,
  dateWithTime,
}

@Component({
  selector: "app-due-date",
  templateUrl: "./due-date.component.html",
  styleUrls: ["./due-date.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DueDateComponent implements OnInit, OnChanges {
  @Input() dueDate: { $date: string } | string | undefined;
  @Input() callback?: Function;
  @Output() saved = new EventEmitter<{ $date: string } | string>();
  @ViewChild("date") date: ElementRef;
  @ViewChild("time") time: ElementRef;

  editMode = false;
  DateTime = DateTime;
  dateCategory: DateTime = DateTime.isUndefined;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.categorizeDate();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!("dueDate" in changes)) {
      return;
    }

    const change = changes["dueDate"];
    if (change.currentValue !== change.previousValue) {
      this.categorizeDate();
    }
  }

  categorizeDate() {
    if (!this.dueDate) {
      this.dateCategory = DateTime.isUndefined;
      return;
    }

    if (typeof this.dueDate === "string") {
      this.dateCategory = DateTime.dateOnly;
      return;
    }

    const value = Object.values(this.dueDate)[0];
    if (!value) {
      this.dateCategory = DateTime.isUndefined;
      return;
    }

    if (typeof value === "string") {
      this.dateCategory = value.includes("T") ? DateTime.dateWithTime : DateTime.dateOnly;
    }

    if (typeof value === "object") {
      const innerDate = Object.values(value)[0];
      if (!innerDate) {
        this.dateCategory = DateTime.isUndefined;
      } else if (typeof innerDate === "string") {
        this.dateCategory = innerDate.includes("T") ? DateTime.dateWithTime : DateTime.dateOnly;
      }
    }

    if (this.dateCategory === DateTime.dateOnly && typeof this.dueDate === "object") {
      this.dueDate = this.dueDate["$date"];
    }
  }

  transformDate(): string {
    if (!this.dueDate) {
      return "";
    }

    if (typeof this.dueDate === "object") {
      const localDate = new Date(this.dueDate["$date"]);
      const year = localDate.getFullYear();
      const month = (localDate.getMonth() + 1).toString().padStart(2, "0");
      const _date = localDate.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${_date}`;
    }

    if (typeof this.dueDate === "string") {
      return this.dueDate;
    }

    return "";
  }

  transformTime() {
    if (this.dateCategory !== DateTime.dateWithTime) {
      return "";
    } else {
      const localDate = new Date(this.dueDate as { $date: string }["$date"]);
      const hour = localDate.getHours().toString().padStart(2, "0");
      const mins = localDate.getMinutes().toString().padStart(2, "0");
      return `${hour}:${mins}`; // 2018-06-12T19:30
    }
  }

  updateDate() {
    const date = this.date.nativeElement.value;
    const time = this.time.nativeElement.value;

    let isoDueDate = "";

    try {
      if (date && time) {
        isoDueDate = new Date(`${date} ${time}`).toISOString();
      } else if (date && !time) {
        isoDueDate = date;
      }
    } catch (e) {
      isoDueDate = "";
    }

    if (typeof isoDueDate === "string" && isoDueDate.includes("T")) {
      this.dueDate = { $date: isoDueDate };
    } else {
      this.dueDate = isoDueDate;
    }

    this.saved.emit(this.dueDate);

    if (this.callback) {
      this.callback(this.dueDate).subscribe(() => {
        this.editMode = false;
        this.categorizeDate();
        this.cdr.markForCheck();
      });
    } else {
      this.editMode = false;
      this.categorizeDate();
      this.cdr.markForCheck();
    }
  }

  getDueDate() {
    if (this.dateCategory === DateTime.dateWithTime) {
      return (this.dueDate as { $date: string })["$date"];
    } else if (this.dateCategory === DateTime.dateOnly) {
      return this.dueDate as string;
    }
    return "";
  }
}
