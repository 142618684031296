import {
  Directive,
  HostListener,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
  Input,
} from "@angular/core";

// @IMPORTANT! this must be a bootstrap modal or else this wouldn't work
@Directive({
  selector: "[appModalExit]",
})
export class ModalExitDirective implements AfterViewInit {
  @Input() shouldFireExit?: boolean = true;
  @Input() focus?: boolean = true;
  @Output() exited = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    // focus so that we can listen to keyboard events i.e. Esc to exit
    if (this.focus) {
      this.elementRef.nativeElement.focus();
    }
  }

  @HostListener("keyup.escape", ["$event"]) onKeyUp($event: KeyboardEvent) {
    $event.stopPropagation();

    if (!this.shouldFireExit) {
      return;
    }

    this.exited.emit(true);
  }
}
