import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import { User } from "../user";
import { Role } from "../role";
import { Project } from "../project";
import { UserService } from "../user.service";
import { ProjectService } from "../project.service";
import { DepartmentService } from "../department.service";
import { getUpdatedItems, Attribute } from "../utils";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.css"],
})
export class AddUserComponent implements OnInit {
  @ViewChild("email", { static: true }) email: ElementRef;
  @ViewChild("firstname", { static: true }) firstname: ElementRef;
  @ViewChild("lastname", { static: true }) lastname: ElementRef;
  @ViewChild("username", { static: true }) username: ElementRef;

  user: User;
  roles: Role[];
  addSuccess: boolean;
  projects: Project[];
  departments: string[];
  addError: boolean;
  errorMessage: string;
  selectedRole = "";

  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    private deptService: DepartmentService
  ) {}

  ngOnInit() {
    this.projectService.getProjects().subscribe((projects) => (this.projects = projects));

    this.deptService
      .getAllDepartments()
      .subscribe((departments) => (this.departments = departments));

    this.userService.getRoles().subscribe((roles) => (this.roles = roles));
  }

  add(formElement: Element, email: string, firstname: string, lastname: string, username: string) {
    const projectCheckboxes = formElement.querySelectorAll("input[type=checkbox].project");
    const deptCheckboxes = formElement.querySelectorAll("input[type=checkbox].department");
    const selectedProjects = getUpdatedItems(projectCheckboxes, undefined, Attribute.ID); // project codes
    const selectedDepartments = getUpdatedItems(deptCheckboxes);

    const newUser = {
      email: email,
      username: username,
      firstname: firstname,
      lastname: lastname,
      projects: selectedProjects, // these are project codes, not Project[]
      departments: selectedDepartments,
      role: this.selectedRole,
    };

    this.userService.addUser(newUser).subscribe(
      (user) => {
        this.user = user;
        this.addSuccess = true;
        this.addError = false;
        this.email.nativeElement.value = "";
        this.firstname.nativeElement.value = "";
        this.lastname.nativeElement.value = "";
        this.username.nativeElement.value = "";
      },
      (err) => {
        this.addSuccess = false;
        this.addError = true;

        if (err["status"] === 409) {
          this.errorMessage = `The username '${this.username.nativeElement.value}' is already taken`;
        } else if (this.selectedRole === "") {
          this.errorMessage = "Please select a role for this new user.";
        } else {
          this.errorMessage =
            "An error occured when adding this user. Please contact the tech department";
        }
      }
    );
  }
}
