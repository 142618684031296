import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { Subscription } from "rxjs";

import { Status } from "../utils";
import { UserToken } from "../user";
import { IEntity } from "../entity";
import { MeetingNoteService } from "../meeting-note.service";
import { EntityService } from "../entity.service";
import { IDropdownItem } from "../dropdown-item";

@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFileComponent implements OnInit, OnDestroy {
  @Input() context: string;
  @Input() projectCode: string;

  @Output() closed = new EventEmitter<string>();

  progressBar: ElementRef;
  @ViewChild("progressBar") set setProgressBar(ref: ElementRef) {
    this.progressBar = ref;
  }

  episodeCodes: { text: string }[];
  subs: Subscription[] = [];

  meeting_id: string = "";
  uploadTypes: string[];
  selectedUploadType: string;
  selectedEpisodeCode: string;
  selectedTemplate: string;
  attachedFiles: (File | null)[] = []; // todo: the odd typing here could be improved if the code itself is tightened up. Inconsistent default cases.
  templatesList: { text: string }[] = [
    { text: "(V3)Comp Review" },
    { text: "(V2)Bluebook - Layout" },
    { text: "(V1)Bluebook - Master" },
  ];
  errorMsg = "";
  meetingTitle: string;
  isUploaded: boolean = false;
  willShowUploadMeeting: boolean = false;

  currentUser: string;
  isInialized: boolean = false;

  processStatus: {
    status: Status;
    msg: string;
    progress: string | number;
  } = { status: "Idle", msg: "", progress: 0 };
  showProgress: (status: { status: Status; progress: string | number }) => void;

  constructor(
    private meetingService: MeetingNoteService,
    private entityService: EntityService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentUser = UserToken.getUsername();

    if (this.context == "meeting-notes") {
      this.getEpisodeCodes();
      this.willShowUploadMeeting = true;
    }

    this.showProgress = (status: { status: Status; progress: string | number }) => {
      this.processStatus = { ...status, msg: "" };
      if (
        (this.processStatus.status === "Uploading" && this.processStatus.progress !== 100) ||
        this.processStatus.status === "Submitted"
      ) {
        this.processStatus.progress = `${status.progress}%`;
        this.progressBar.nativeElement.style.width = this.processStatus.progress;
      }
      this.cdr.markForCheck();
    };

    this.uploadTypes = ["spreadsheet/csv"];
    this.selectedUploadType = "spreadsheet/csv";
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  getEpisodeCodes() {
    this.entityService
      .getAllEntitiesWithCodesOnly(this.projectCode, "episodes")
      .subscribe((episodes) => {
        this.episodeCodes = (episodes as IEntity[]).map((episode) => {
          episode.projectCode = this.projectCode;
          return { text: episode.episode_code };
        });
        this.isInialized = true;
        this.cdr.markForCheck();
      });
  }

  displayFilename(file: File | null | undefined): string {
    if (file) return file["name"];
    return "";
  }

  isGood(uploadType: string, meetingTitle: string): boolean {
    if (
      !uploadType ||
      this.attachedFiles.length === 0 ||
      !meetingTitle ||
      !this.selectedEpisodeCode ||
      !this.selectedTemplate
    ) {
      this.errorMsg = "One or more fields is not filled out.";
      return false;
    }

    const filetypes = this.getSupportedTypes()["filetypes"];
    const attachedFile = this.attachedFiles[0];
    if (!attachedFile) {
      this.errorMsg = "One or more fields is not filled out.";
      return false;
    }

    const accepted = filetypes.filter((f) => attachedFile.name.toLowerCase().endsWith(f));
    if (accepted.length === 0) {
      this.errorMsg = `Supported filetypes for '${uploadType}': ${filetypes.join(", ")}
        Got: '${attachedFile.name}'`;
      return false;
    }

    this.errorMsg = "";
    return true;
  }

  upload(meetingTitle: string, meetingOrigin?: string) {
    const uploadType = "spreadsheet/csv";
    let origin = meetingOrigin ? meetingOrigin : "";

    if (!this.isGood(uploadType, meetingTitle)) {
      return;
    }

    this.meetingService
      .upload(
        this.projectCode,
        meetingTitle,
        origin,
        this.selectedEpisodeCode,
        this.selectedTemplate,
        this.attachedFiles,
        this.showProgress
      )
      .subscribe(
        () => (this.attachedFiles = []), // this seems to reset attachedFiles to an inconsistent value vs. it's initial value of [null].
        (err) => {
          if (err.error.description.includes("already exist")) {
            this.errorMsg = "These files already exist, would you like to overwrite them?";
          }
          this.processStatus.status = "Started";
          this.processStatus.progress = `0%`;
          this.progressBar.nativeElement.style.width = this.processStatus.progress;

          this.cdr.markForCheck();
        },
        () => {
          setTimeout(() => {
            this.willShowUploadMeeting = false;
            this.closeModal(this.processStatus.status);
          }, 2000);
        }
      );
  }

  closeModal(uploadStatus?: string) {
    if (uploadStatus) {
      this.closed.emit(uploadStatus);
    } else {
      this.closed.emit(this.processStatus.status);
    }
  }

  shouldExit($event: boolean) {
    if ($event) {
      this.closeModal();
    }
  }

  getSupportedTypes() {
    if (this.selectedUploadType === "spreadsheet/csv") {
      return { filetypes: [".xlsx"], accepts: [".xlsx"] };
    }
    return { filetypes: [], accepts: [] };
  }

  onInputChange($event: Event, fileInput: HTMLInputElement) {
    // attachedFiles[0] = file1.files[0]
    const files = fileInput.files;
    if (files && files[0]) {
      this.attachedFiles[0] = files[0];
    }
  }

  getSelectedTemplate($event: string) {
    if ($event.includes("V2")) {
      this.selectedTemplate = "V2";
    } else if ($event.includes("V3")) {
      this.selectedTemplate = "V3";
    } else {
      this.selectedTemplate = "V1";
    }
  }
}
