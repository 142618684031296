import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";

import { Entity, Context, emptyMedia, IMedia, IStatus, IEntity } from "../entity";
import { IDropdownItem, IMediaDropDownItem } from "../dropdown-item";
import {
  mediaToString,
  mapToDropdownItem,
  mediaToDropdownItem,
  copyToClipboard,
  findStatusColor,
  toJSDate,
  flattenAssemblyLeicaFields,
  compareUploadDate,
} from "../utils";
import { NoteService } from "../note.service";
import { Note, PartialNote, PartialNoteResponse, NoteResponse, NoteCreatedBy } from "../notes";
import { QueryParamService } from "../query-param.service";
import { EntityService } from "../entity.service";
import { ProjectService } from "../project.service";
import { User, UserMixin, UserToken } from "../user";
import { TypeaheadComponent } from "../typeahead/typeahead.component";
import { CanvasComponent } from "../canvas/canvas.component";
import { frontEnd } from "../../environments/environment";
import { MarkedPipe } from "../marked.pipe";
import { MarkdownEditorComponent } from "../markdown-editor/markdown-editor.component";
import { MongoDate } from "../mongo-document";
import { IFrameAnnotations } from "../canvas";
import { HttpProgress } from "../utils";

@Component({
  selector: "app-notes",
  templateUrl: "./notes.component.html",
  styleUrls: ["./notes.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent implements OnInit, OnDestroy {
  @Input() notes: Note[];
  @Input() updatedNote$?: Subject<Note>;
  @Input() canvasComponent?: CanvasComponent;
  @Input() statuses: IStatus[];
  @Input() entity: Entity | IEntity;
  @Input() users: User[];
  @Input() media: IMedia[] = [];
  @Input() willShowModal?: boolean = true;
  @Input() mediaVersion?: IMediaDropDownItem = emptyMedia;
  @Input() mediaVersion2?: IMediaDropDownItem = emptyMedia;
  @Input() showAllByDefault?: boolean;
  @Input() shouldFilterByDeptOnAdd?: boolean = false;
  @Input() filterByDept?: string = "";
  @Input() mediaSource?: "MEDIA" | "CATALOG" | "assembly" | "leica" | "stages" | "storyboards" =
    "MEDIA";
  @Input() showStatusColors: boolean = true;

  @Output() changedVersion = new EventEmitter<IMediaDropDownItem>();
  @Output() showAoverB = new EventEmitter<{
    selected_version2: IMediaDropDownItem;
    clip: number;
    frame: number;
    audio: "left" | "right";
  }>();

  @ViewChild("artists") artists: TypeaheadComponent;
  @ViewChild("cc") cc: TypeaheadComponent;
  @ViewChild("noteOrigin") noteOrigin: ElementRef;
  @ViewChild("preview") preview: ElementRef;
  @ViewChildren("noteOrigins") noteOrigins: QueryList<ElementRef>;

  depts: ElementRef;
  @ViewChild("depts") set setDepts(depts: ElementRef) {
    this.depts = depts;
  }

  queryParamPrefix: string = "";
  completedNotesCheckboxId: string; // used for the show-completed-notes checkbox id
  completedStatuses = ["complete", "lead_approved", "approved"];
  numCompletedNotes = 0;
  filteredNotes: Note[] = [];
  visibleNotes: { [key: string]: boolean } = {};
  editMode: { [k: string]: boolean | string; [i: number]: boolean | string } = {};
  showCompletedNotes: boolean;
  willShowAddNote: boolean;
  selectedNoteOrigin: string;
  currentUser: string;
  departments: IDropdownItem[];
  toggleAllNotes: boolean;
  assignableUsernames: string[] = [];
  assignableUsernamesCC: string[] = [];
  origins = [
    "Lead note",
    "Bluebook note",
    "Director review",
    "Blocking Review",
    "Flo Review",
    "Comp Review",
    "Custom..",
  ];
  willShowAttachment: boolean;
  willShowConfirmDelete: boolean;
  attachmentSrc: string;
  status: string;
  mediaToDropdownItem: (media: IMedia | IMedia[]) => IMediaDropDownItem | IMediaDropDownItem[];
  mediaToString: (media: IMedia) => string;
  copyToClipboard: (link: string) => Promise<boolean>;
  key: string;
  toBeDeleted: { note_id: string; response_id?: string } = {} as {
    note_id: string;
    response_id: string;
  };
  versionDropdownItems: IMediaDropDownItem[] = [];
  annotationId: string;
  frameAnnotations = new Set<number>();
  subs: Subscription[] = [];
  emptyMedia = emptyMedia;
  findStatusColor = findStatusColor;

  esc: boolean;

  attachedFiles: any[]; // I don't think this actually does anything. It's only ever set to [].

  constructor(
    private noteService: NoteService,
    private projectService: ProjectService,
    private queryParamService: QueryParamService,
    private entityService: EntityService,
    private cdr: ChangeDetectorRef,
    private markedPipe: MarkedPipe
  ) {
    this.mediaToDropdownItem = mediaToDropdownItem;
    this.copyToClipboard = copyToClipboard;
    this.mediaToString = mediaToString;
  }

  ngOnInit() {
    this.filterByDept = this.filterByDept?.toLowerCase();
    this.queryParamPrefix = this.filterByDept ? `${this.filterByDept}-` : "";
    this.toggleAllNotes = this.queryParamService
      .queryParamsAsSet("show-all-notes")
      .has(`${this.queryParamPrefix}${this.entity.toString()}`);
    this.showCompletedNotes = this.queryParamService
      .queryParamsAsSet("show-completed-notes")
      .has(`${this.queryParamPrefix}${this.entity.toString()}`);

    for (const noteQP of this.queryParamService.queryParamsAsSet("note")) {
      if (!noteQP.startsWith(`${this.queryParamPrefix}${this.entity.toString()}`)) {
        continue;
      }

      this.toggleNote(noteQP.split("-").pop() || ""); // noteID
      // TODO! load annotations
    }

    this.filter();

    this.currentUser = UserToken.getUsername();

    // // adding a prefix 'm_' so that if we click the completed notes checkbox inside the media player
    // // it will get toggled instead of the checkbox in the shots/assets table
    this.completedNotesCheckboxId = `${this.willShowModal ? "" : "m_"}${
      this.queryParamPrefix
    }${this.entity.toString()}`;

    this.getUsersCC();

    if (!this.updatedNote$) {
      return;
    }

    this.subs.push(
      this.updatedNote$.asObservable().subscribe((update) => {
        let i = 0;
        for (i = 0; i < this.notes.length; i++) {
          if (this.notes[i]._id.$oid === update._id.$oid) {
            this.notes[i] = update;
            this.cdr.markForCheck();
            break;
          }
        }

        if (i === this.notes.length) {
          this.notes.splice(0, 0, update);
          this.filter();
          this.cdr.markForCheck();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  isEmptyMediaVersion() {
    return JSON.stringify(this.mediaVersion) === JSON.stringify(this.emptyMedia);
  }

  noteBodyOnClick($event: MouseEvent, note: Note) {
    if (($event.target as HTMLParagraphElement).localName === "input") {
      note["removeDisabledAttr"] = false;
    }
    // @ts-ignore
    this.markdownHandler($event, note, note["media_version"]);
  }

  confirmDeleteCancelOnCLick() {
    this.willShowConfirmDelete = false;
    this.toBeDeleted = {} as { note_id: string; response_id: string };
  }

  noteResponseOnClick($event: MouseEvent, response: NoteResponse, note: Note) {
    if (($event.target as HTMLParagraphElement).localName === "input") {
      response["removeDisabledAttr"] = false;
    }
    this.markdownHandler(
      $event,
      response,
      note["media_version"] as IMediaDropDownItem,
      note["_id"]["$oid"],
      note["assigned"]
    );
  }

  hasEmptyMedia(dropdownItems: IDropdownItem[]): boolean {
    let found = false;

    if (dropdownItems.length == 0) {
      return false;
    }
    dropdownItems.forEach((element) => {
      if (element["_id"]["$oid"] == "") found = true;
    });

    return found;
  }

  postDownload(_data: HttpProgress<Blob>) {
    const data = _data.body;
    if (data === undefined || data === null) {
      return;
    }
    const blob = new Blob([data], { type: data.type });
    const url = window.URL.createObjectURL(blob);
    const location = document.createElement("a");
    location.href = url;
    location.download = "note_attachment";
    location.dispatchEvent(new MouseEvent("click"));

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 5000);
  }

  handleDownloadFile(annotation_id: string) {
    this.noteService
      .downloadFile(annotation_id, (m: HttpProgress<Blob>) => {
        m;
      })
      .subscribe((_data) => this.postDownload(_data));
  }

  _toggleAllNotes() {
    this.toggleAllNotes = !this.toggleAllNotes;

    let currentQueryParams = this.queryParamService.queryParamsAsSet("show-all-notes");

    if (this.toggleAllNotes) {
      currentQueryParams.add(`${this.queryParamPrefix}${this.entity.toString()}`);
      this.queryParamService.updateQueryParams({
        "show-all-notes": Array.from(currentQueryParams),
      });
    } else {
      this.hideOtherNotes("");
      currentQueryParams.delete(`${this.queryParamPrefix}${this.entity.toString()}`);
      let currentNoteQueryParams = this.queryParamService.queryParamsAsSet("note");
      for (const noteQP of currentNoteQueryParams) {
        if (noteQP.startsWith(`${this.queryParamPrefix}${this.entity.toString()}`)) {
          currentNoteQueryParams.delete(noteQP);
        }
      }
      this.queryParamService.updateQueryParams({
        "show-all-notes": Array.from(currentQueryParams),
        note: Array.from(currentNoteQueryParams),
      });
    }
  }

  toggleNote(id: string) {
    this.visibleNotes[id] = !this.visibleNotes[id];

    // reset parts of the canvas
    if (this.canvasComponent && this.canvasComponent.media) {
      this.canvasComponent.canvas.width = this.canvasComponent.media.clientWidth;
      this.canvasComponent.canvas.height = this.canvasComponent.media.clientHeight;
    }

    const currentQueryParams = this.queryParamService.queryParamsAsSet("note");

    if (this.visibleNotes[id]) {
      this.hideOtherNotes(id);
      this.notes
        .filter((note) => note["_id"]["$oid"] !== id)
        .forEach((note) =>
          currentQueryParams.delete(
            `${this.queryParamPrefix}${this.entity.toString()}-${note["_id"]["$oid"]}`
          )
        );
      currentQueryParams.add(`${this.queryParamPrefix}${this.entity.toString()}-${id}`);
      this.queryParamService.updateQueryParams({ note: Array.from(currentQueryParams) });
    } else {
      currentQueryParams.delete(`${this.queryParamPrefix}${this.entity.toString()}-${id}`);
      this.queryParamService.updateQueryParams({ note: Array.from(currentQueryParams) });
    }
  }

  hideOtherNotes(except: string) {
    for (const key of Object.keys(this.visibleNotes)) {
      if (except === key) {
        continue;
      }
      this.visibleNotes[key] = false;
    }
  }

  chooseCollapseIcon(i?: number | string) {
    if (!i && i !== 0) {
      return this.toggleAllNotes ? "keyboard_arrow_down" : "chevron_right";
    }
    return this.visibleNotes[i] ? "keyboard_arrow_down" : "chevron_right";
  }

  updateNoteOrigin(item: string, noteId?: string, index?: number) {
    if (item === "Custom..") {
      if (typeof index === "number") {
        // update the note origin without actually updating it in the server so we can show a blank input when the user chooses 'Custom..'
        this.noteOrigins.toArray()[index].nativeElement.focus();
        const selectedNote = this.notes.find((note) => note["_id"]["$oid"] === noteId);
        if (selectedNote) {
          const i = this.notes.indexOf(selectedNote);
          selectedNote["origin"] = "";
          this.notes[i] = selectedNote;
        }
      } else {
        this.selectedNoteOrigin = "";
        this.noteOrigin.nativeElement.focus();
      }
    } else {
      this.selectedNoteOrigin = item;
    }

    if (noteId && item !== "Custom.." && typeof index === "number") {
      const noteObj = {
        origin: item,
      };
      this.noteService.updateNote(this.entity.projectCode, noteId, noteObj).subscribe(() => {
        const selectedNote = this.notes.find((note) => note["_id"]["$oid"] === noteId);
        if (selectedNote === undefined) {
          return;
        }
        const i = this.notes.indexOf(selectedNote);
        selectedNote["origin"] = item;
        this.notes[i] = selectedNote;
        this.noteOrigins.toArray()[index].nativeElement.blur();
        if (this.updatedNote$) {
          this.updatedNote$.next(this.notes[i]);
        }
        this.cdr.markForCheck();
      });
    }
  }

  getCheckedDepts(depts: HTMLDivElement): string[] {
    const d = [];
    for (const dept of this.departments) {
      if (
        depts.querySelector<HTMLInputElement>(
          `input[type=checkbox]#${dept.text.replace(" ", "\\ ")}`
        )?.checked
      ) {
        d.push(dept.text);
      }
    }
    return d;
  }

  // `department`, `res`, `mediaVersion` and `status` are set in the `onChange[Dept/Res/MediaVersion/Status]`

  add(dueDate: string, dueTime: string, depts: HTMLDivElement, origin: string, note: string) {
    if (note.trim() === "") {
      alert("Cannot add a note without a body.");
      return;
    }

    const ccDepts = this.entity.context !== "scripts" ? this.getCheckedDepts(depts) : [];

    let noteObj: PartialNote = {
      assigned: this.artists.inputString
        .split(/[+,]/g)
        .filter((s) => s !== "")
        .map((s) => s.trim()),
      cc: this.cc.inputString
        .split(/[+,]/g)
        .filter((s) => s !== "")
        .map((s) => s.trim()),
      cc_departments: ccDepts,
      created_by: { username: this.currentUser },
      status: this.status || this.statuses[0]["text"],
      origin: origin,
      body: note.trim(),
      html: this.markedPipe.marked(note),
      frame_annotations: {},
      responses: [],
    };

    if (this.canvasComponent) {
      for (const frame of this.frameAnnotations) {
        noteObj["frame_annotations"][frame] = this.canvasComponent.frameAnnotations[frame];
        if (this.canvasComponent.isComparing && this.canvasComponent.src2) {
          noteObj["frame_annotations"][frame]["selected_version2"] = this.mediaVersion2;
          noteObj["frame_annotations"][frame]["audio"] = this.canvasComponent.leftMedia
            .nativeElement.muted
            ? "right"
            : "left";
        }
      }
    }

    try {
      if (dueDate && dueTime) {
        noteObj["due_date"] = { $date: new Date(`${dueDate} ${dueTime}`).toISOString() };
      } else if (dueDate && !dueTime) {
        noteObj["due_date"] = dueDate;
      }
    } catch (e) {}

    noteObj["media_version"] = this.isEmptyMediaVersion() ? null : { ...this.mediaVersion! };
    if (!this.isEmptyMediaVersion() && this.mediaVersion?.res === "assembly") {
      noteObj["media_type"] = this.mediaVersion.res;
    }

    if (!this.isEmptyMediaVersion() && this.entity.context === "scripts") {
      noteObj["media_version"]!["stage_name"] = this.mediaVersion!["stage"];
      delete noteObj.media_version?.frame_timestamps;
    }

    this.noteService.addNote(this.entity, noteObj).subscribe((newNote) => {
      noteObj = { ...noteObj, ...newNote };
      this.notes.splice(0, 0, noteObj as Note);
      this.filter();
      if (this.updatedNote$) {
        this.updatedNote$.next(noteObj as Note);
      }
      this.willShowAddNote = false;
      this.toggleAllNotes = true;
      this.frameAnnotations = new Set<number>();
      this.cdr.markForCheck();
    });
  }

  reply(response: string, noteId: string, markdownEditor: MarkdownEditorComponent) {
    if (response.trim() === "") {
      alert("Cannot add a reply without a body.");
      return;
    }

    const responseObj: { responses: PartialNoteResponse } = {
      responses: {
        from: this.currentUser,
        body: response.trim(),
        html: this.markedPipe.marked(response),
        frame_annotations: {},
      },
    };

    if (this.canvasComponent) {
      for (const frame of this.frameAnnotations) {
        responseObj["responses"]["frame_annotations"][frame] =
          this.canvasComponent.frameAnnotations[frame];
        if (this.canvasComponent.isComparing && this.canvasComponent.src2) {
          responseObj["responses"]["frame_annotations"][frame]["selected_version2"] =
            this.mediaVersion2;
          responseObj["responses"]["frame_annotations"][frame]["audio"] = this.canvasComponent
            .leftMedia.nativeElement.muted
            ? "right"
            : "left";
        }
      }
    }

    this.noteService
      .updateNote(this.entity.projectCode, noteId, responseObj)
      .subscribe((result) => {
        const selectedNote = this.notes.find((note) => note["_id"]["$oid"] === noteId);

        if (selectedNote) {
          const i = this.notes.indexOf(selectedNote);
          selectedNote["responses"].push(
            (result as { responses: Partial<NoteResponse> }).responses as NoteResponse
          );
          this.notes[i] = selectedNote;

          if (this.updatedNote$) {
            this.updatedNote$.next(selectedNote);
          }
        }

        markdownEditor.writeTextArea.nativeElement.value = "";

        if (this.canvasComponent) {
          this.canvasComponent.reDraw();
        }

        this.cdr.markForCheck();
      });
  }

  startEditMode(i: number, field: string) {
    this.editMode[i] = field;
  }

  saveNote(
    $event: string | string[] | MongoDate | Date | undefined,
    i: number,
    field: keyof Pick<
      Note,
      "assigned" | "body" | "cc" | "cc_departments" | "origin" | "status" | "due_date"
    >,
    noteId: string
  ) {
    if ($event === undefined) {
      this.editMode[i] = false;
      this.setDefaultEditModes(this.notes.length);
      return;
    }

    let toBeUpdated = {} as Note;
    // TODO: there's gotta be a better way than this to satisfy tsc...
    if (
      (Array.isArray($event) && field === "assigned") ||
      field === "cc_departments" ||
      field == "cc"
    ) {
      toBeUpdated[field] = $event as string[];
    } else if (field === "body" || field === "origin" || field === "status") {
      toBeUpdated[field] = $event as string;
    } else if ($event instanceof Date && field === "due_date") {
      toBeUpdated[field] = $event as Date;
    } else if (($event as MongoDate).$date && field === "due_date") {
      toBeUpdated[field] = $event as MongoDate;
    } else if (typeof $event === "string" && field === "due_date") {
      toBeUpdated[field] = $event;
    }

    this.noteService.updateNote(this.entity.projectCode, noteId, toBeUpdated).subscribe(() => {
      const selectedNote = this.notes.find((note) => note["_id"]["$oid"] === noteId);
      if (selectedNote === undefined) {
        return;
      }

      const j = this.notes.indexOf(selectedNote);
      if (
        (Array.isArray($event) && field === "assigned") ||
        field === "cc_departments" ||
        field === "cc"
      ) {
        selectedNote[field] = $event as string[];
      } else if (field === "body" || field === "origin" || field === "status") {
        selectedNote[field] = $event as string;
      } else if ($event instanceof Date && field === "due_date") {
        selectedNote[field] = $event as Date;
      } else if (($event as MongoDate).$date && field === "due_date") {
        selectedNote[field] = $event as MongoDate;
      } else if (typeof $event === "string" && field === "due_date") {
        toBeUpdated[field] = $event;
      }
      this.notes[j] = selectedNote;
      if (this.updatedNote$) {
        this.updatedNote$.next(selectedNote);
      }
      this.editMode[i] = false;
      this.setDefaultEditModes(this.notes.length);
      this.updateUsers(this.getDeptsFromNote(this.notes[j].cc_departments || []));
      this.cdr.markForCheck();
    });
  }

  isDeptChecked(deptName: string, cc_departments: Array<string>) {
    return cc_departments.includes(deptName);
  }

  setDefaultEditModes(notesLength: number) {
    for (let i = 0; i < notesLength; i++) {
      this.editMode[i] = false;
    }
  }

  filterByStage() {
    let numCompletedNotes = 0;
    this.filteredNotes = [];

    let stages: string[] = [];
    if (this.filterByDept === "stages") {
      stages = ["Premise", "Outline", "1stDraft", "2ndDraft", "Polish", "Record"];
    } else if (this.filterByDept === "storyboards") {
      stages = ["Rough", "Finaling", "Polish"];
    } else if (this.filterByDept === "leica") {
      stages = ["Pre-assembly", "Rough Assembly", "Leica", "Leica for Client"];
    } else if (this.filterByDept === "assembly") {
      stages = ["edt", "prv", "lay", "anm", "chf", "flo", "efx", "lgt", "cmp", "pst", "art", "plt"];
    }

    if (!this.willShowModal && !this.showCompletedNotes) {
      if (stages.length > 0) {
        this.filteredNotes = this.notes.filter(
          (note) =>
            note.media_version &&
            [this.mediaVersion!.stage, ...stages].includes(note.media_version.stage)
        );
      } else {
        this.filteredNotes = this.notes.filter(
          (note) => note.media_version && note.media_version.stage === this.mediaVersion!.stage
        );
      }
      const filteredByDeptLength = this.filteredNotes.length;

      this.filteredNotes = this.filteredNotes.filter(
        (note) => !this.completedStatuses.includes(note["status"])
      );
      numCompletedNotes = filteredByDeptLength - this.filteredNotes.length;
    } else if (!this.willShowModal && this.showCompletedNotes) {
      if (stages.length > 0) {
        this.filteredNotes = this.notes.filter(
          (note) =>
            note.media_version &&
            [this.mediaVersion!.stage, ...stages].includes(note.media_version.stage)
        );
      } else {
        this.filteredNotes = this.notes.filter(
          (note) => note.media_version && note.media_version.stage === this.mediaVersion!.stage
        );
      }
      numCompletedNotes = this.filteredNotes.filter((note) =>
        this.completedStatuses.includes(note["status"])
      ).length;
    } else if (this.willShowModal && !this.showCompletedNotes) {
      if (stages.length > 0) {
        // case where we are under Notes per dept
        // filteredNotes and notes should be the same
        this.filteredNotes = this.notes.filter(
          (note) => note.media_version && stages.includes(note.media_version.stage)
        );
        this.notes = [...this.filteredNotes];
        this.filteredNotes = this.notes.filter(
          (note) => !this.completedStatuses.includes(note.status)
        );
      } else {
        this.filteredNotes = this.notes.filter(
          (note) => !this.completedStatuses.includes(note.status)
        );
      }
      numCompletedNotes = this.notes.length - this.filteredNotes.length;
    } else {
      // TODO: this code seems to be unreachable
      if (stages.length > 0) {
        this.filteredNotes = this.notes.filter(
          (note) =>
            note.media_version &&
            [this.mediaVersion!.stage, ...stages].includes(note.media_version.stage)
        );
      } else {
        this.filteredNotes = this.notes;
      }
      numCompletedNotes = this.notes.filter((note) =>
        this.completedStatuses.includes(note["status"])
      ).length;
    }

    this.numCompletedNotes = numCompletedNotes;
  }

  filter() {
    if (this.entity.context === "scripts") {
      this.filterByStage();
      return;
    }

    let numCompletedNotes = 0;
    this.filteredNotes = [];

    if (!this.willShowModal && !this.showCompletedNotes) {
      if (this.filterByDept) {
        this.filteredNotes = this.notes.filter(
          (note) =>
            (note.media_version &&
              [this.mediaVersion!.dept, this.filterByDept].includes(note.media_version.dept)) ||
            note.cc_departments?.includes(this.mediaVersion!.dept) ||
            note.cc_departments?.includes(this.filterByDept!)
        );
      } else {
        this.filteredNotes = this.notes.filter(
          (note) =>
            (note.media_version && note.media_version.dept === this.mediaVersion!.dept) ||
            note.cc_departments?.includes(this.mediaVersion!.dept)
        );
      }
      const filteredByDeptLength = this.filteredNotes.length;

      this.filteredNotes = this.filteredNotes.filter(
        (note) => !this.completedStatuses.includes(note["status"])
      );
      numCompletedNotes = filteredByDeptLength - this.filteredNotes.length;
    } else if (!this.willShowModal && this.showCompletedNotes) {
      if (this.filterByDept) {
        this.filteredNotes = this.notes.filter(
          (note) =>
            (note.media_version &&
              [this.mediaVersion!.dept, this.filterByDept].includes(note.media_version.dept)) ||
            note.cc_departments?.includes(this.mediaVersion!.dept) ||
            note.cc_departments?.includes(this.filterByDept!)
        );
      } else {
        this.filteredNotes = this.notes.filter(
          (note) =>
            (note.media_version && note.media_version.dept === this.mediaVersion!.dept) ||
            note.cc_departments?.includes(this.mediaVersion!.dept)
        );
      }
      numCompletedNotes = this.filteredNotes.filter((note) =>
        this.completedStatuses.includes(note["status"])
      ).length;
    } else if (this.willShowModal && !this.showCompletedNotes) {
      if (this.filterByDept) {
        // case where we are under Notes per dept
        // filteredNotes and notes should be the same
        this.filteredNotes = this.notes.filter(
          (note) =>
            (note.media_version && [this.filterByDept].includes(note.media_version.dept)) ||
            note.cc_departments?.includes(this.filterByDept!)
        );
        this.notes = [...this.filteredNotes];
        this.filteredNotes = this.notes.filter(
          (note) => !this.completedStatuses.includes(note.status)
        );
      } else {
        this.filteredNotes = this.notes.filter(
          (note) => !this.completedStatuses.includes(note.status)
        );
      }
      numCompletedNotes = this.notes.length - this.filteredNotes.length;
    } else {
      // TODO: this code seems to be unreachable
      if (this.filterByDept) {
        this.filteredNotes = this.notes.filter(
          (note) =>
            (note.media_version &&
              [this.mediaVersion!.dept, this.filterByDept].includes(note.media_version.dept)) ||
            note.cc_departments?.includes(this.mediaVersion!.dept) ||
            note.cc_departments?.includes(this.filterByDept!)
        );
      } else {
        this.filteredNotes = this.notes;
      }
      numCompletedNotes = this.notes.filter((note) =>
        this.completedStatuses.includes(note["status"])
      ).length;
    }

    this.numCompletedNotes = numCompletedNotes;

    //if (this.showCompletedNotes) {
    //  this.showCompletedNotesQp.add(`${this.queryParamPrefix}${this.entity.toString()}`);
    //} else {
    //  this.showCompletedNotesQp.delete(`${this.queryParamPrefix}${this.entity.toString()}`);
    //}
  }

  updateQueryParams() {
    let currentQueryParams = this.queryParamService.queryParamsAsSet("show-completed-notes");
    currentQueryParams.add(`${this.queryParamPrefix}${this.entity.toString()}`);
    this.queryParamService.updateQueryParams({
      "show-completed-notes": Array.from(currentQueryParams),
    });
  }

  onChangeVersion(version: IMediaDropDownItem) {
    if (!this.mediaVersion) {
      return;
    }

    if (this.willShowAddNote && this.mediaVersion["_id"]["$oid"] === version["_id"]["$oid"]) {
      return;
    }

    this.mediaVersion = { ...version };
    if (this.isEmptyMediaVersion()) {
      return;
    }

    // only change the image/video in the canvas if we are inside the media player
    // i.e not in the modal of add note from
    if (!this.willShowModal || !this.willShowAddNote) {
      this.changedVersion.emit(version);
    }

    this.updateUsers(this.mediaVersion["dept"] ? [this.mediaVersion["dept"]] : []);
  }

  getDepts() {
    this.projectService
      .getDepts(this.entity.projectCode, this.entity.context)
      .subscribe((depts) => {
        this.departments = mapToDropdownItem(depts);
        this.cdr.markForCheck();
      });
  }

  showAddNote() {
    this.toggleAllNotes = this.willShowModal!;
    if (this.entity.context !== "scripts") {
      this.getDepts();
    }

    if (this.media && this.media[0] !== undefined && this.media[0] !== this.emptyMedia) {
      this.media.splice(0, 0, this.emptyMedia); // insert the no specific media/version for the dropdown
      this.mediaVersion = this.isEmptyMediaVersion()
        ? ((this.media[1] || this.emptyMedia) as IMediaDropDownItem)
        : this.mediaVersion;
      this.updateUsers(this.mediaVersion!.dept ? [this.mediaVersion!.dept] : []);
      this.getUsersCC();
      this.willShowAddNote = true;
    } else {
      this.entityService
        .findOne(
          this.entity.projectCode,
          this.entity.context as Context,
          this.entity.group,
          this.entity.entityCode,
          "fields=media,assembly,leica,stages,storyboards,plate&exclude_fields=_id"
        )
        .subscribe((entity) => {
          let i = 0;
          this.media = entity.media || [];

          if (this.entity.context === "scripts") {
            if (this.filterByDept === "") {
              // merge all assembly, leica, scripts, storyboards into one list
              this.media = this.media
                .concat(flattenAssemblyLeicaFields(entity, "stages"))
                .concat(flattenAssemblyLeicaFields(entity, "storyboards"))
                .concat(flattenAssemblyLeicaFields(entity, "leica"))
                .concat(flattenAssemblyLeicaFields(entity, "assembly"));
            } else {
              this.media = flattenAssemblyLeicaFields(
                entity,
                this.filterByDept as "assembly" | "leica"
              );
            }
            this.media.sort(compareUploadDate);
          }

          if (this.media!.length > 0 && this.media![0] !== this.emptyMedia) {
            this.media!.splice(0, 0, this.emptyMedia); // insert the no specific media/version for the dropdown
          }

          if (entity["plate"] && entity["plate"]["media"]) {
            // inject the entity's plate media into the entity's media
            this.media!.splice(1, 0, entity["plate"]["media"]);
          }

          if (this.entity.context === "scripts") {
            i = 1;
          } else if (this.filterByDept !== "plt" && this.media!.length > 1) {
            i = this.media!.findIndex((m) => m["dept"] === this.filterByDept);
            if (i === -1) {
              i = 2; // the latest of any department
            }
          } else {
            i = 1; // plate was inserted into index 1
          }

          this.mediaVersion = this.isEmptyMediaVersion()
            ? ((this.media![i] || this.emptyMedia) as IMediaDropDownItem)
            : this.mediaVersion;
          this.updateUsers(this.mediaVersion!.dept ? [this.mediaVersion!.dept] : []);
          this.getUsersCC();
          this.willShowAddNote = true;
          this.cdr.markForCheck();
        });
    }
  }

  showEditDeptNote() {
    this.getDepts();
  }

  changeMediaFromTag(mediaVersion: IMediaDropDownItem) {
    this.onChangeVersion(mediaToDropdownItem(mediaVersion) as IMediaDropDownItem);
  }

  getAnnotation(annotationId: string) {
    return this.noteService.getAnnotationURL(annotationId);
  }

  showAttachment(annotationId: string) {
    this.willShowAttachment = true;
    this.attachmentSrc = this.getAnnotation(annotationId);
    this.annotationId = annotationId;
  }

  getInitialsColour(username: string) {
    for (const user of this.users) {
      if (user.username === username) {
        return user["initials_colour"];
      }
    }
    return "red";
  }

  shouldExit($event: boolean) {
    if ($event) {
    }
  }

  canModifyResponse(response: NoteResponse | Note): boolean {
    if ("from" in response) {
      return response["from"] === this.currentUser;
    }
    return (
      response["created_by"] &&
      response["created_by"]["username"] === this.currentUser &&
      !response["deleted"]
    );
  }

  delete() {
    if (!this.toBeDeleted) {
      this.willShowConfirmDelete = false;
      return;
    }

    if (this.toBeDeleted["note_id"] && this.toBeDeleted["response_id"]) {
      this.deleteResponse(this.toBeDeleted["note_id"], this.toBeDeleted["response_id"]);
    } else {
      this.deleteNote(this.toBeDeleted["note_id"]);
    }
  }

  deleteNote(noteId: string) {
    this.noteService.deleteNote(this.entity.projectCode, noteId).subscribe(() => {
      setTimeout(() => {
        const selectedNote = this.notes.find((note) => note["_id"]["$oid"] === noteId);
        if (selectedNote === undefined) {
          return;
        }
        const i = this.notes.indexOf(selectedNote);
        this.notes.splice(i, 1);
        this.filter();
        this.toBeDeleted = {} as { note_id: string; response_id: string };
        this.willShowConfirmDelete = false;
        this.cdr.markForCheck();
      }, 250);
    });
  }

  deleteResponse(noteId: string, responseId: string) {
    this.noteService
      .deleteNoteResponse(this.entity.projectCode, noteId, responseId)
      .subscribe((dateDeleted) => {
        setTimeout(() => {
          const selectedNote = this.notes.find((note) => note["_id"]["$oid"] === noteId);
          if (selectedNote === undefined) {
            return;
          }

          const i = this.notes.indexOf(selectedNote);
          const selectedResponse = this.notes[i]["responses"].find(
            (resp) => resp["_id"]["$oid"] === responseId
          );

          if (selectedResponse === undefined) {
            return;
          }

          const j = this.notes[i]["responses"].indexOf(selectedResponse);
          this.notes[i]["responses"][j]["deleted"] = true;
          this.notes[i]["responses"][j]["last_edited"] = dateDeleted["last_edited"] as MongoDate;
          this.toBeDeleted = {} as { note_id: string; response_id: string };
          this.willShowConfirmDelete = false;
          this.cdr.markForCheck();
        }, 250);
      });
  }

  saveEdits(noteId: string, body: string, responseId?: string, annotations?: IFrameAnnotations) {
    let toBeUpdated: {
      body: string;
      html: string;
      frame_annotations: IFrameAnnotations | undefined;
    } = { body: body.trim(), html: this.markedPipe.marked(body), frame_annotations: undefined };

    if (annotations && this.canvasComponent) {
      for (const frame of this.frameAnnotations) {
        if (toBeUpdated["frame_annotations"] === undefined) {
          toBeUpdated["frame_annotations"] = {
            [frame]: this.canvasComponent.frameAnnotations[frame],
          };
        } else {
          toBeUpdated["frame_annotations"][frame] = this.canvasComponent.frameAnnotations[frame];
        }

        if (this.canvasComponent.isComparing && this.canvasComponent.src2) {
          toBeUpdated["frame_annotations"][frame] = {
            ...toBeUpdated["frame_annotations"][frame],
            selected_version2: this.mediaVersion2,
            audio: this.canvasComponent.leftMedia.nativeElement.muted ? "right" : "left",
          };
        }
      }
      toBeUpdated["frame_annotations"] = { ...toBeUpdated["frame_annotations"], ...annotations };
    }

    if (responseId) {
      this.noteService
        .updateNote(this.entity.projectCode, noteId, toBeUpdated, responseId)
        .subscribe((result) => {
          const selectedNote = this.notes.find((note) => note["_id"]["$oid"] === noteId);
          if (selectedNote === undefined) {
            return;
          }
          const i = this.notes.indexOf(selectedNote);
          const selectedResponse = this.notes[i]["responses"].find(
            (resp) => resp["_id"]["$oid"] === responseId
          );
          if (selectedResponse === undefined) {
            return;
          }
          result = result as Partial<Note>;
          const j = this.notes[i]["responses"].indexOf(selectedResponse);
          this.notes[i]["responses"][j]["body"] = result["responses"]![0]["body"];
          this.notes[i]["responses"][j]["removeDisabledAttr"] = true;
          this.notes[i]["responses"][j]["last_edited"] = result["responses"]![0]["last_edited"];
          this.notes[i]["responses"][j]["last_edited_by"] =
            result["responses"]![0]["last_edited_by"];
          if (result["responses"]![0]["frame_annotations"]) {
            this.notes[i]["responses"][j]["frame_annotations"] =
              result["responses"]![0]["frame_annotations"];
          }
          this.editMode["edit"] = false;
          if (this.updatedNote$) {
            this.updatedNote$.next(this.notes[i]);
          }
          this.cdr.markForCheck();
          if (this.canvasComponent) {
            this.canvasComponent.reDraw();
          }
        });
    } else {
      this.noteService
        .updateNote(this.entity.projectCode, noteId, toBeUpdated)
        .subscribe((result) => {
          const selectedNote = this.notes.find((note) => note["_id"]["$oid"] === noteId);
          if (selectedNote === undefined) {
            return;
          }
          const i = this.notes.indexOf(selectedNote);
          result = result as Partial<Note>;
          this.notes[i]["body"] = result["body"]!;
          this.notes[i]["removeDisabledAttr"] = true;
          this.notes[i]["created_by"]["last_edited"] = result["created_by"]!["last_edited"];
          this.notes[i]["created_by"]["last_edited_by"] = result["created_by"]!["last_edited_by"];
          if (result["frame_annotations"]) {
            this.notes[i]["frame_annotations"] = result["frame_annotations"];
          }
          if (this.updatedNote$) {
            this.updatedNote$.next(this.notes[i]);
          }
          // TODO:
          this.editMode["edit"] = false;
          this.cdr.markForCheck();
        });
    }
  }

  getDateOfLastEdit(obj: {
    deleted?: boolean;
    last_edited?: MongoDate | Date;
    datetime: string | MongoDate | Date;
  }) {
    if (obj["deleted"] && obj["last_edited"]) {
      if (obj.last_edited instanceof Date) {
        return { title: "deleted @", date: obj.last_edited.toISOString() };
      }
      return { title: "deleted @", date: obj["last_edited"]["$date"] };
    }
    if (obj.last_edited) {
      return { title: "edited @", date: toJSDate(obj.last_edited).toISOString() };
    }
    return { title: "", date: toJSDate(obj.datetime).toISOString() };
  }

  getLastEditedBy(noteOrResponse: Note | NoteResponse | NoteCreatedBy): string {
    if ("deleted" in noteOrResponse && noteOrResponse.deleted) {
      return "";
    }
    // older notes/responses do not have last_edited_by so return an empty string or else it will show 'by undefined'
    return noteOrResponse["last_edited_by"] ? `by ${noteOrResponse["last_edited_by"]}` : "";
  }

  getDeptsFromNote(cc_departments: string[]) {
    let depts = new Set(cc_departments);

    if (!this.isEmptyMediaVersion()) {
      depts.add(this.mediaVersion!.dept);
    }
    return Array.from(depts);
  }

  updateUsers(checkedDepts: string[]) {
    this.assignableUsernames = [];

    for (const dept of checkedDepts) {
      for (const user of UserMixin.getUsersInDept(this.users, dept)) {
        if (!this.assignableUsernames.includes(user.username)) {
          this.assignableUsernames.push(user.username);
        }
      }
    }

    // show all users if no dept is checked
    if (checkedDepts.length === 0) {
      for (const user of this.users) {
        if (user.departments.length > 0 && !this.assignableUsernames.includes(user.username)) {
          this.assignableUsernames.push(user.username);
        }
      }
    }

    this.assignableUsernames.sort();
  }

  getUsersCC() {
    for (const user of this.users) {
      if (!this.assignableUsernamesCC.includes(user.username)) {
        this.assignableUsernamesCC.push(user.username);
      }
    }
    this.assignableUsernamesCC.sort();
  }

  copyLink(
    projectCode: string,
    context: string,
    group: string,
    entityCode: string,
    noteId: string,
    noteStatus: string,
    media: IMedia
  ) {
    const showCompletedNotes = this.completedStatuses.includes(noteStatus)
      ? `&show-completed-notes=${group}_${entityCode}`
      : "";
    const q = context === "assets" || context === "scripts" ? entityCode : `${group}_${entityCode}`;

    if (!media || (media["_id"] === undefined && media["_id"]["$oid"] === undefined)) {
      // e.g. /projects/mya20/assets?q=aa_ant&expand=chr&show-all-notes=chr_aa_ant&note=chr_aa_ant-5f2d85960d17bf51c0cc8ee3
      this.copyToClipboard(
        `${frontEnd}/projects/${projectCode}/${context}?q=${q}&expand=${group}&show-all-notes=${group}_${entityCode}&note=${group}_${entityCode}-${noteId}${showCompletedNotes}`
      ).then((copied) => {
        if (copied) {
          document.getElementById(noteId)!.style.color = "#8bc483";
          setTimeout(() => {
            document.getElementById(noteId)!.style.color = "#495057";
          }, 300);
        }
      });
    } else {
      // e.g. /projects/mya20/assets?q=aa_ant&expand=chr&show-all-notes=chr_aa_ant&note=chr_aa_ant-5f2d85960d17bf51c0cc8ee3&play=chr_aa_ant&version=5f241f040d17bf689d88d17d&play-ctx=assets&play-src=MEDIA
      this.copyToClipboard(
        `${frontEnd}/projects/${projectCode}/${context}?q=${q}&expand=${group}&show-all-notes=${q}&note=${q}-${noteId}${showCompletedNotes}&play=${q}&version=${media["_id"]["$oid"]}&play-ctx=${context}&play-src=${this.mediaSource}`
      ).then((copied) => {
        if (copied) {
          document.getElementById(noteId)!.style.color = "#8bc483";
          setTimeout(() => {
            document.getElementById(noteId)!.style.color = "#495057";
          }, 300);
        }
      });
    }
  }

  viewAnnotations(
    frame: number,
    note: Note | undefined,
    mediaVersion: IMediaDropDownItem | undefined,
    response?: NoteResponse
  ) {
    if (this.canvasComponent === undefined || mediaVersion === undefined) {
      return;
    }

    let isSameMedia = note === undefined;
    if (note !== undefined) {
      // when *not* adding a new note
      isSameMedia = mediaVersion._id.$oid === this.mediaVersion!._id.$oid;
    }

    if (this.canvasComponent.srcType === "video") {
      if (this.canvasComponent.isPlaying) {
        this.canvasComponent.pause();
      }
      if (!isSameMedia) {
        this.onChangeVersion(mediaVersion);
        this.canvasComponent.userCurrentFrame.nativeElement.max = this.canvasComponent
          .frameTimestamps
          ? this.canvasComponent.frameTimestamps.length - 1
          : 0;
        this.canvasComponent.currentFrame.nativeElement.max = this.canvasComponent.frameTimestamps
          ? this.canvasComponent.frameTimestamps.length - 1
          : 0;
        this.canvasComponent.userCurrentFrame.nativeElement.value = frame;
        this.canvasComponent.currentFrame.nativeElement.value = frame;
        this.canvasComponent._currentFrame = frame;
        this.canvasComponent.leftMedia.nativeElement.onloadeddata = () => {
          this.canvasComponent!.seek(frame);
        };
      } else {
        this.canvasComponent.userCurrentFrame.nativeElement.value = frame;
        this.canvasComponent.currentFrame.nativeElement.value = frame;
        this.canvasComponent._currentFrame = frame;
        this.canvasComponent.seek(frame);
      }
    } else {
      if (!isSameMedia) {
        this.onChangeVersion(mediaVersion);
      }
    }

    this.loadAnnotations(note || ({} as Note));

    if (!note) {
      return;
    }

    let choice = ((response || note).frame_annotations || {})[frame];
    if (
      choice !== undefined &&
      choice["clip"] !== undefined &&
      choice["selected_version2"] !== undefined
    ) {
      this.showAoverB.emit({
        selected_version2: choice.selected_version2,
        clip: choice.clip,
        frame: frame,
        audio: choice.audio || "left",
      });
    } else if (
      choice !== undefined &&
      choice["clip"] === undefined &&
      choice["selected_version2"] === undefined
    ) {
      this.showAoverB.emit(undefined);
    }
  }

  markdownLinkHandler(e: Event, note: Note, mediaVersion: IMediaDropDownItem | undefined) {
    const target = e.target as HTMLElement;
    const href = target.getAttribute("href");

    if (href === "") {
      e.preventDefault();
      this.viewAnnotations(parseInt(target.innerText.split("frame ")[1]), note, mediaVersion);
      return true;
    }

    return false;
  }

  markdownHandler(
    e: Event,
    note: Note | NoteResponse,
    mediaVersion?: IMediaDropDownItem,
    noteID?: string,
    assigned?: string[]
  ) {
    if (this.markdownLinkHandler(e, note as Note, mediaVersion)) {
      return;
    }

    const target = e.target as HTMLElement;
    if (target.localName === "input") {
      if ("assigned" in note) {
        // from note body
        if (
          !note["assigned"].includes(this.currentUser) &&
          note["created_by"]["username"] !== this.currentUser
        ) {
          alert(
            "You must be assigned to the note, have created the note or created this response to make changes to this note."
          );
          return;
        }
      } else {
        // from note response
        if (
          !(assigned || []).includes(this.currentUser) &&
          note["from"] !== this.currentUser &&
          note.from !== this.currentUser
        ) {
          alert(
            "You must be assigned to the note, have created the note or created this response to make changes to this note."
          );
          return;
        }
      }

      let accumulator = "";

      let strings = note["body"].split("\n");
      let markedLines = this.markedPipe.marked(note["body"]).split("\n");

      let i = 0;

      for (const line of markedLines) {
        if (line.match(/<input (checked="" )?type="checkbox">/g)) {
          if (
            `<li>${target.parentElement?.innerHTML}`
              .replace(/\n/g, "")
              .startsWith(line.split("</li>")[0])
          ) {
            if ((target as HTMLInputElement).checked) {
              strings[i - 1] = strings[i - 1].replace("- [ ]", "- [x]");
            } else {
              strings[i - 1] = strings[i - 1].replace("- [x]", "- [ ]");
            }
          }
        }

        if (!line.trim().match(/^<\/[a-z]+>$/g)) {
          i++;
        }
      }

      for (let j = 0; j < strings.length; j++) {
        accumulator += strings[j] + "\n";
      }

      if (noteID) {
        // coming from a response
        this.saveEdits(noteID, accumulator, note["_id"]["$oid"]);
      } else {
        this.saveEdits(note["_id"]["$oid"], accumulator);
      }
    } else if (target.localName === "img") {
      let src = (target as HTMLMediaElement).currentSrc;
      let annotationID = "";
      if (src.endsWith("/")) {
        const split = src.split("/");
        annotationID = split[split.length - 2];
      } else {
        annotationID = src.split("/").pop() || "";
      }

      if (!annotationID) {
        return;
      }

      this.showAttachment(annotationID);
    }
  }

  loadAnnotations(note: Note) {
    if (!this.canvasComponent) {
      return;
    }

    if (note["frame_annotations"]) {
      this.canvasComponent.frameAnnotations = {
        ...this.canvasComponent.frameAnnotations,
        ...note["frame_annotations"],
      };
    }

    for (const response of note["responses"] || []) {
      if (response["frame_annotations"]) {
        this.canvasComponent.frameAnnotations = {
          ...this.canvasComponent.frameAnnotations,
          ...response["frame_annotations"],
        };
      }
    }

    this.canvasComponent.overlay.nativeElement.focus();
    this.canvasComponent.viewAnnotations = true;
    this.canvasComponent.clear();
    if (this.canvasComponent.canvas) {
      this.canvasComponent.reDraw();
    }
  }
}
