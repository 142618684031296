<div class="container pt-3">
  <label for="start" class="mr-1">Vaulted from:</label>
  <input
    #startDateRef
    class="mr-3"
    type="datetime-local"
    id="start"
    name="start"
    value="{{ startDate | date : 'yyyy-MM-ddTHH:mm' }}"
    min="2018-01-01T00:00"
    max="{{ now | date : 'yyyy-MM-ddTHH:mm' }}"
    (change)="onChangeStartDate($event)" />

  <label for="end" class="mr-1">to:</label>
  <input
    #endDateRef
    type="datetime-local"
    id="end"
    name="end"
    value="{{ endDate | date : 'yyyy-MM-ddTHH:mm' }}"
    max="{{ now | date : 'yyyy-MM-ddTHH:mm' }}"
    (change)="onChangeEndDate($event)"
    disabled />

  <div class="d-flex mt-2 align-items-center mb-3">
    <div class="form-check d-flex-inline mr-3">
      <input
        #is24hrRange
        class="form-check-input"
        type="checkbox"
        id="is-24hr-range"
        checked
        (change)="onToggle24hrRange($event)" />
      <label class="form-check-label" for="is-24hr-range"
        >Set end date as 24hr range from start date</label
      >
    </div>
    <button type="button" class="btn btn-primary btn-sm" (click)="getVaultedAssets()">
      Get Vaulted Assets
    </button>
  </div>

  <div *ngIf="vaultedInfo$ | async as vaultedInfo">
    <div *ngFor="let asset of vaultedInfo | keyvalue" class="card mb-3">
      <div class="row no-gutters">
        <!--div class="col-md-2">
          <img class="w-100" [src]="getThumbnailURL(asset.key)" alt="...">
        </div-->
        <div class="col-md-12">
          <div class="card-body">
            <h6 class="card-title">{{ asset.key }}</h6>
            <div *ngFor="let version of asset.value" class="mb-2">
              <span class="d-block card-text"
                >{{ version["version"] }} ⋅ {{ version["datetime"] }}</span
              >
              <span class="d-block card-text">By: {{ version["full_name"] }}</span>
              <span class="d-block card-text">Comment: {{ version["comment"] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showFetchingData">Fetching data... (this may take a while)</div>
</div>
