import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
  ChangeDetectionStrategy,
} from "@angular/core";

import { CanvasComponent } from "../canvas/canvas.component";
import { NoteService } from "../note.service";

@Component({
  selector: "app-markdown-editor",
  templateUrl: "./markdown-editor.component.html",
  styleUrls: ["./markdown-editor.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkdownEditorComponent implements OnInit, AfterViewInit {
  @Input() markdownText?: string;
  @Input() canvasComponent?: CanvasComponent;

  @Output() addedFrameAnnotations = new EventEmitter<number>();
  @Output() viewedFrameAnnotations = new EventEmitter<number>();

  @ViewChild("writeTextArea", { static: true }) writeTextArea: ElementRef<HTMLInputElement>;

  constructor(private noteService: NoteService) {}

  ngOnInit() {
    if (this.markdownText) {
      this.writeTextArea.nativeElement.value = this.markdownText;
    }
  }

  ngAfterViewInit() {
    this.writeTextArea.nativeElement.focus();
  }

  wrapText(front: string, back = "") {
    const start = this.writeTextArea.nativeElement.selectionStart ?? 0;
    const end = this.writeTextArea.nativeElement.selectionEnd ?? 0;
    const text = this.writeTextArea.nativeElement.value;

    let formatted = `${front}${text.slice(start, end)}${back}`;
    this.writeTextArea.nativeElement.value = `${text.substring(
      0,
      start
    )}${formatted}${text.substring(end)}`;
    this.writeTextArea.nativeElement.focus();
    this.writeTextArea.nativeElement.selectionEnd = this.writeTextArea.nativeElement.value.length;
  }

  prefixText(prefix: string) {
    const start = this.writeTextArea.nativeElement.selectionStart ?? 0;
    const end = this.writeTextArea.nativeElement.selectionEnd ?? 0;
    const text = this.writeTextArea.nativeElement.value;
    const selected = text.slice(start, end);
    let lines = selected.split("\n");
    let modified = "";
    for (let i = 0; i < lines.length; i++) {
      if (i < lines.length - 1) {
        modified += `${prefix}${lines[i]}\n`;
      } else {
        modified += `${prefix}${lines[i]}`;
      }
    }
    this.writeTextArea.nativeElement.value = `${text.substring(
      0,
      start
    )}${modified}${text.substring(end)}`;
    this.writeTextArea.nativeElement.focus();
    this.writeTextArea.nativeElement.selectionEnd = this.writeTextArea.nativeElement.value.length;
  }

  insertLink() {
    const end = this.writeTextArea.nativeElement.selectionEnd ?? 0;
    this.wrapText("[", "](URL)");
    this.writeTextArea.nativeElement.setSelectionRange(end + 3, end + 6);
    this.writeTextArea.nativeElement.focus();
  }

  onDropImage(event: DragEvent) {
    event.preventDefault();
    const supportedImages = ["image/png", "image/jpeg", "image/jpg"];

    const fileList = Array.from(event?.dataTransfer?.files || []);
    let finalList: File[] = [];
    for (const file of fileList) {
      if (supportedImages.includes(file.type)) {
        finalList.push(file);
      }
    }

    if (finalList.length === 0) {
      return;
    }

    this.noteService.uploadAttachment(finalList).subscribe((annotationIds) => {
      let i = 0;
      for (const id of annotationIds["annotation_ids"]) {
        this.writeTextArea.nativeElement.value = this.writeTextArea.nativeElement.value.concat(
          `![${finalList[i].name}](${this.noteService.getAnnotationThumbnailURL(id)})\n\n`
        );
        i++;
      }
      this.writeTextArea.nativeElement.focus();
      this.writeTextArea.nativeElement.selectionEnd = this.writeTextArea.nativeElement.value.length;
    });
  }

  addFrameAnnotations() {
    if (!this.canvasComponent) {
      return;
    }

    if (this.canvasComponent.frameAnnotations) {
      this.addedFrameAnnotations.emit(
        this.canvasComponent.currentFrame.nativeElement.valueAsNumber
      );
    }

    this.writeTextArea.nativeElement.value = `${this.writeTextArea.nativeElement.value.substring(
      0,
      this.writeTextArea.nativeElement.selectionStart ?? 0
    )}[frame ${
      this.canvasComponent.currentFrame.nativeElement.valueAsNumber
    }]()${this.writeTextArea.nativeElement.value.substring(
      this.writeTextArea.nativeElement.selectionStart ?? 0
    )}`;
    this.writeTextArea.nativeElement.focus();
    this.writeTextArea.nativeElement.selectionEnd = this.writeTextArea.nativeElement.value.length;
  }

  toggleActiveTab(clicked: HTMLElement, other: HTMLElement) {
    if (!clicked.classList.contains("active")) {
      clicked.classList.add("active");
      other.classList.remove("active");

      if (clicked.innerText === "Write") {
        // need setTimeout or the text area won't be in focus
        setTimeout(() => this.writeTextArea.nativeElement.focus());
      }
    }
  }

  markdownLinkHandler(e: Event) {
    const href = (e.target as HTMLLinkElement).getAttribute("href");

    if (href === "") {
      this.viewedFrameAnnotations.emit(
        parseInt((e.target as HTMLLinkElement).innerText.split("frame ")[1])
      );
      e.preventDefault();
    }
  }

  markdownHandler(e: Event) {
    this.markdownLinkHandler(e);
    // TODO: maybe we need to be able to click images in a bigger view?
  }
}
