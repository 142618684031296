import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "../auth.service";
import { UserToken } from "../user";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  error: string | boolean;
  forgotPassword: boolean;
  sentEmail: boolean;
  showThisPage: boolean;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.authService.checkLoggedIn().subscribe(
      (response) => {
        if (response.headers.get("X-Logged-In")) {
          this.router.navigate(["projects"]);
        } else {
          this.showThisPage = true;
        }
      },
      (_) => {
        this.showThisPage = true;
      }
    );
  }

  login(username: string, password: string): void {
    this.authService.login(username, password).subscribe(
      (token) => {
        UserToken.storeToken(token["user"]);
        this.router.navigate(["projects"]);
      },
      (err) => {
        if (typeof err === "string") {
          this.error = err;
        } else if (err.error && typeof err.error === "string") {
          this.error = err.error;
        } else if (err.error && err.error.description) {
          this.error = err.error.description;
        } else {
          console.error("uncaught error in login:");
          console.error(err);
        }
      }
    );
  }

  sendEmail(email: string): void {
    this.authService.sendResetPasswordMail(email).subscribe(
      (_) => {
        this.sentEmail = true;
        this.error = false;
      },
      (err) => {
        if (err.status === 404) {
          this.sentEmail = false;
          this.error = "Email not found";
        }
      }
    );
  }
}
