import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { UntypedFormArray, UntypedFormControl } from "@angular/forms";

import { ProjectService } from "../project.service";
import { UserService } from "../user.service";
import { Filters } from "../../types/filters";

@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent implements OnInit, OnDestroy, OnChanges {
  @Input() projectCode: string;
  @Input() context: string;
  @Input() filters$: Observable<Filters>;
  @Input() inShotsByEpisodePage?: boolean = false;

  @Output() filtered = new EventEmitter<string>();
  @Output() toggledFiltersOnOrOff = new EventEmitter<boolean>();

  @ViewChild("parent", { static: true }) parent: ElementRef;

  subs: Subscription[] = [];
  assignedUsers: string[] = [];

  filterForm: UntypedFormArray;
  fields: string[] = [];
  fieldsAndOperatorOptions: { [key: string]: ("is" | "is not" | "has" | "has not")[] }; // e.g. {anm_status: ['is', 'is not'], ...}
  filters: Filters = { is_enabled: false, No_Save: "", default: "" };
  activefilter: string;
  defaultFilter: string;
  savedFilters: string[] = [];
  willShowSaveFilter: boolean = false;
  willShowBackdrop: boolean = false;
  filterNameWarning: boolean = false;
  isSubmitting: boolean = false;
  isFilterEnabled: boolean = false;
  isMarkedForSave: boolean = false;
  defaultNoSaveFilter: string = "No_Save";
  switchLabel: string = "OFF";
  showFilters: boolean;

  operatorMap: { [key: string]: string } = {
    has: "has",
    "!has": "has not",
    is: "is",
    "!is": "is not",
  };
  operatorMapInvert: { [key: string]: string } = {
    has: "has",
    "has not": "!has",
    is: "is",
    "is not": "!is",
  };

  Object = Object;

  constructor(
    private projectService: ProjectService,
    private userService: UserService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["inShotsByEpisodePage"] && changes["inShotsByEpisodePage"].currentValue) {
      this.isFilterEnabled = false;
      this.switchLabel = "OFF";
    }
  }

  init() {
    this.subs.push(
      this.filters$.subscribe((filters) => {
        if (filters !== undefined && typeof filters === "object") {
          if (typeof this.filters == "string") {
            this.filters = { is_enabled: false, No_Save: "", default: "" };
          } else {
            this.filters = filters;
          }

          this.isFilterEnabled = this.inShotsByEpisodePage ? false : filters["is_enabled"];
          if (this.isFilterEnabled) {
            this.switchLabel = "ON";
          }

          if (filters["default"] !== undefined) {
            this.defaultFilter = filters["default"];
            this.activefilter = decodeURI(filters[this.defaultFilter] as string).replace(
              "%2B",
              "+"
            );
          } else {
            this.activefilter = "";
          }

          this.savedFilters = [];
          for (const key of Object.keys(filters)) {
            if (key !== "default" && key !== "is_enabled") {
              if (this.savedFilters.findIndex((item) => item == key) == -1) {
                this.savedFilters.push(key);
              }
            }
          }
        } else if (typeof filters == "string") {
          let hasFilterOn = filters !== undefined && filters !== "";
          this.activefilter = filters;
          this.isFilterEnabled = hasFilterOn;
        }

        this.build_form();
        this.cdr.markForCheck();
      })
    );
  }

  ngOnInit() {
    this.projectService.getFilterableFields(this.projectCode, this.context).subscribe((fields) => {
      this.fieldsAndOperatorOptions = fields;
      this.fields = Object.keys(fields);
      this.cdr.markForCheck();
    });
    this.init();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  addFormFilter() {
    this.filterForm.push(
      new UntypedFormControl({ field: "", operator: "", logicalOperator: "", values: "" })
    );
  }

  removeFormFilter(i: number) {
    this.filterForm.removeAt(i);
  }

  onSubmit(filterName: string, overwrite?: boolean) {
    // Check if filter name already exists
    if (filterName === "" && !this.isMarkedForSave) {
      return;
    }
    if (
      typeof this.filters === "object" &&
      filterName in this.filters &&
      filterName !== this.defaultNoSaveFilter &&
      !overwrite
    ) {
      this.filterNameWarning = true;
      return;
    }

    // build the query string
    let str = "";
    let processedForms: {
      values: string;
      operator: string;
      logicalOperator: "and" | "or";
      field: string;
    }[] = [];
    let fieldOps = "";
    let logicalOps: { and: string; or: string } = { and: "%2B", or: "," };
    let isAdded = false;
    const regexPlus = /\s*\+\s*/g;
    const regexComma = /\s*,\s*/g;

    if (filterName) {
      str = str.concat(`filtername=${filterName}&`);
    }

    for (const f of this.filterForm.value) {
      if (f["field"] === "" || f["field"] === undefined) {
        continue;
      }

      processedForms.forEach((pf) => {
        if (f["field"] == pf["field"] && f["operator"] == pf["operator"]) {
          if (!pf["values"].includes(f["values"].replace(",", ""))) {
            pf["values"] = pf["values"].concat(`,${f["values"]}`);
            isAdded = true;
          }
        }
      });
      if (!isAdded) {
        processedForms.push(f);
      }
      isAdded = false;
    }

    processedForms.forEach((pf) => {
      let trimmed = pf["values"].replace(regexComma, ",").replace(regexPlus, "%2B").trim();
      str = str.concat(`${pf["field"]}=${this.operatorMapInvert[pf["operator"]]}:${trimmed}&`);
      fieldOps = fieldOps.concat(`${pf["field"]}${logicalOps[pf["logicalOperator"]] || ""}`);
    });

    if (fieldOps) {
      str = str.concat(`field_ops=${fieldOps}`);
      if (str.endsWith(",") || str.endsWith("+")) {
        str = str.slice(0, -1);
      } else if (str.endsWith("%2B")) {
        str = str.slice(0, -3);
      }
    }

    this.filters$ = this.userService.saveFilters(this.projectCode, this.context, str).pipe(
      tap((filters) => {
        this.filtered.emit(decodeURI(filters[filterName] as string).replace("%2B", "+"));
        this.willShowSaveFilter = false;
        this.willShowBackdrop = false;
        this.filterNameWarning = false;
        this.isSubmitting = false;
        this.isFilterEnabled = true;
      })
    );
    this.init();
  }

  getFieldOperatorOptions(i: number) {
    if (this.filterForm.controls[i].value["operator"]) {
      return this.filterForm.controls[i].value["operator"];
    }

    if (this.filterForm.controls[i].value["fieldOperatorOptions"].length > 0) {
      return this.filterForm.controls[i].value["fieldOperatorOptions"][0];
    }

    return "Select:";
  }

  updateFieldOperatorOptions(field: string, i: number) {
    let options = [];
    for (const option of this.fieldsAndOperatorOptions[field]) {
      options.push(option);
    }
    this.filterForm.controls[i].value["fieldOperatorOptions"] = options;
    this.filterForm.controls[i].value["operator"] = options[0];
  }

  enableFilter() {
    this.userService
      .enableFilter(this.projectCode, this.context, !this.isFilterEnabled)
      .subscribe((enable) => {
        this.activefilter = "";
        this.defaultFilter = this.defaultNoSaveFilter;
        this.build_form();
        this.isFilterEnabled = enable["is_enabled"];
        this.toggledFiltersOnOrOff.emit(this.isFilterEnabled);
        if (this.isFilterEnabled) {
          this.switchLabel = "ON";
        } else {
          this.switchLabel = "OFF";
        }
        this.cdr.markForCheck();
      });
  }

  changeFilter($event: string) {
    this.userService
      .setDefaultFilter(this.projectCode, this.context, $event)
      .subscribe((filter) => {
        this.defaultFilter = filter["default"];
        if (this.defaultFilter == this.defaultNoSaveFilter) {
          (this.filters as Filters)[this.defaultNoSaveFilter] = "";
        }
        this.activefilter = decodeURI(
          (this.filters as Filters)[this.defaultFilter] as string
        ).replace("%2B", "+");
        this.filtered.emit(this.activefilter);
        this.isMarkedForSave = false;
        this.build_form();
        this.cdr.markForCheck();
      });
  }

  build_form() {
    this.filterForm = new UntypedFormArray([]);

    if (this.activefilter === "") {
      this.filterForm.push(
        new UntypedFormControl({
          field: "",
          operator: "",
          logicalOperator: "",
          values: "",
          suggestions: [],
          fieldOperatorOptions: [],
          showDropdownWhenAutofocused: false,
        })
      );
      return;
    }

    let fieldOps = "";
    for (const f of this.activefilter.split("&")) {
      const field = f.split("=")[0];
      if (field === "field_ops") {
        fieldOps = f.split("=")[1];
        break;
      }
    }

    for (const f of this.activefilter.split("&")) {
      const field = f.split("=")[0];
      if (field === "field_ops") {
        continue;
      }
      const temp = f.split("=")[1];
      const operator = this.operatorMap[temp.split(":")[0]];
      let values = "";
      if (field.includes("date")) {
        values = temp.substring(temp.indexOf(":") + 1);
      } else {
        values = temp.split(":")[1];
      }

      let logicalOperator = "";
      if (fieldOps.split(field)[1][0] === ",") {
        logicalOperator = "or";
      } else if (fieldOps.split(field)[1][0] === "+") {
        logicalOperator = "and";
      }
      this.filterForm.push(
        new UntypedFormControl({
          field: field,
          operator: operator,
          logicalOperator: logicalOperator,
          values: values,
          suggestions: [],
          fieldOperatorOptions: [],
          showDropdownWhenAutofocused: false,
        })
      );
    }
  }

  applyFilter() {
    if (this.isMarkedForSave) {
      this.willShowSaveFilter = true;
      this.willShowBackdrop = true;
    } else {
      this.onSubmit(this.defaultNoSaveFilter);
    }
  }

  deleteCustomFilter() {
    this.userService
      .deleteFilter(this.projectCode, this.context, this.defaultFilter)
      .subscribe((filters) => {
        this.filtered.emit(
          decodeURI((filters as Filters)[this.defaultNoSaveFilter] as string).replace("%2B", "+")
        );
        this.isMarkedForSave = false;
        this.init();
      });
  }

  updateSuggestions(i: number) {
    if (!this.filterForm.controls[i].value["field"]) {
      return;
    }

    this.filterForm.controls[i].value["suggestions"] = this.projectService.getAllValuesForField(
      this.projectCode,
      this.context,
      this.filterForm.controls[i].value["field"]
    );
    this.filterForm.controls[i].value["showDropdownWhenAutofocused"] = true;
  }
}
