// TODO: things are hardcoded; make this more generic
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ProjectService } from "../project.service";
import { UserService } from "../user.service";
import { User } from "../user";
import type { DevListFieldUpdate, DevListIndexUpdate, DevListItem } from "../../types/dev-list";

@Component({
  selector: "app-simple-checklist",
  templateUrl: "./simple-checklist.component.html",
  styleUrls: ["./simple-checklist.component.css"],
})
export class SimpleChecklistComponent implements OnInit, OnDestroy {
  @ViewChild("newName", { static: true }) name: ElementRef;
  @ViewChild("newDate", { static: true }) date: ElementRef;

  subs: Subscription[] = [];
  list: DevListItem[] = [];

  statuses: { text: string; icon: string }[] = [
    { text: "waiting_to_start", icon: "remove" },
    { text: "in_progress", icon: "timelapse" },
    { text: "complete", icon: "check" },
  ];
  userIDList$: Observable<User[]>;
  editMode: string | number = "";
  showCompletedTasks = false;
  nullChoice = "{'text': ''}";

  constructor(private projectService: ProjectService, private userService: UserService) {}

  ngOnInit() {
    this.subs.push(
      this.projectService.getDevList().subscribe((list) => {
        this.list = list.map((item) => {
          return { ...this.getStatusIcon(item), ...item };
        });
      })
    );

    this.userIDList$ = this.userService.getDevUsers().pipe(
      map((userList) => {
        userList.unshift({ username: "--unassigned--" } as User);
        return userList;
      })
    );
  }
  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  // getUserName(item: object) {
  //    return {'text': item['username']};
  // }

  getStatusIcon(item: DevListItem) {
    for (const status of this.statuses) {
      if (status.text === item["status"]) {
        // TODO: unhardcode status key
        return status;
      }
    }
    return undefined;
  }

  add(text: string, date: string) {
    const item = { status: "waiting_to_start", name: text, completion_date: date };
    this.projectService.addToDevList(item).subscribe((postedItem) => {
      if (typeof postedItem !== "boolean") {
        this.list.push(postedItem);
      }
      this.name.nativeElement.value = "";
      this.date.nativeElement.value = "";
    });
  }

  save(name: string, date: string, item: DevListItem, i: number) {
    item["name"] = name;
    item["completion_date"] = date;
    this.projectService.updateDevList(item).subscribe((_) => {
      this.list[i] = item;
      this.editMode = "";
    });
  }

  // individual edits
  edit(field: "name" | "status" | "assigned", value: string, item: DevListItem, i: number) {
    item[field] = value;
    if (field == "assigned" && value == "-- unassigned --") {
      item[field] = "";
    }
    this.projectService.updateDevList(item).subscribe((_) => {
      this.list[i] = item;
      this.editMode = "";
    });
  }

  onHandleMouseEvent($event: MouseEvent, handle: HTMLElement, draggableValue: boolean) {
    if (handle.parentNode instanceof HTMLElement) {
      handle.parentNode.draggable = draggableValue;
    }
  }

  onDragStart($event: DragEvent) {
    $event.dataTransfer?.setData("text/plain", ($event.target as Element)?.id);
  }

  onDrop($event: DragEvent) {
    $event.preventDefault();
    let parent = $event.target as Element | null;
    // if the drop target is not the intended target, find its parent
    if (parent?.classList.contains("material-icons") || parent?.localName === "span") {
      if (parent?.parentNode?.nodeType === 1) {
        parent = parent.parentNode as Element;
      }

      while (
        parent?.parentNode?.nodeType === 1 &&
        parent?.className !== "card card-body d-flex flex-row"
      ) {
        parent = parent.parentNode as Element;
      }
    }
    if (!parent) {
      return;
    }
    const eventTarget = parent;
    // Get the id of the target and add the moved element to the target's DOM
    // dragged element
    const data = $event.dataTransfer?.getData("text/plain");
    // Get a reference to the parent node
    if (!data) {
      return;
    }
    const parentDiv = document.getElementById(data)?.parentNode;
    if (!parentDiv) {
      return;
    }
    const dragged = document.getElementById(data);
    if (!dragged) {
      return;
    }

    const origin = this.list.findIndex((_, i) => i.toString() === data);
    const target = this.list.findIndex((_, i) => i.toString() === eventTarget.id);
    const actualData = this.list[this.list.findIndex((_, i) => i.toString() === data)];

    let swapData: DevListIndexUpdate;

    // slide over mechanism
    let step;
    if (origin < target) {
      parentDiv.insertBefore(eventTarget, dragged);
      for (step = origin; step < target; step++) {
        this.list[step] = this.list[step + 1];
      }

      swapData = { originIndex: origin, targetIndex: target, incValue: -1, operation: "move" };
    } else {
      parentDiv.insertBefore(dragged, eventTarget);
      for (step = origin; step > target; step--) {
        this.list[step] = this.list[step - 1];
      }
      swapData = { targetIndex: target, originIndex: origin, incValue: 1, operation: "move" };
    }

    this.list[target] = actualData;

    this.projectService.updateDevList(swapData).subscribe();
  }

  onDragover($event: DragEvent) {
    $event.preventDefault();
    // Set the dropEffect to move
    if ($event.dataTransfer) {
      $event.dataTransfer.dropEffect = "move";
    }
  }

  isVisible(item: DevListItem) {
    if (this.showCompletedTasks) {
      return true;
    }

    if (item.status === "complete" && !this.showCompletedTasks) {
      return false;
    }

    return true;
  }
}
