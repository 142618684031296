import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

import { ProjectService } from "../project.service";
import { Project } from "../project";
import { domain, cuiDomain } from "../../environments/environment";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsComponent implements OnInit {
  projects$: Observable<Project[]>;

  constructor(private projectService: ProjectService, private router: Router) {}

  ngOnInit() {
    this.projects$ = this.projectService.getProjects();
  }

  getThumbnail(projectCode: string): string {
    return this.projectService.getProjectThumbnail(projectCode);
  }

  reRoute(project: Project) {
    if (project.display_type === "cui_card") {
      // cuiDomain is empty string on production.
      window.location.href = `${cuiDomain}/projects/${project.code}/shots`;
      return;
    }
    if (project.code === "crc00") {
      // note: this only works on production. And this isn't a live project.
      window.location.href = `${domain}/craniac/`;
      return;
    }
    this.router.navigate([`/projects/${project.code}/shots`]);
  }
}
