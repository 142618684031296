import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { FileBrowserService, CategorizedFileItem, DownloadInfoDiff } from "../file-browser.service";
import { Entity, isIEntity, IEntity } from "../entity";
import { UserToken } from "../user";
import { Status, getUpdatedItems, Attribute, HttpProgress } from "../utils";
import { Observable } from "rxjs";

@Component({
  selector: "app-file-browser",
  templateUrl: "./file-browser.component.html",
  styleUrls: ["./file-browser.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileBrowserComponent implements OnInit {
  @Input() allowUploadInFileBrowser: boolean;
  @Input() projectCode: string;

  // entity is constant.
  @Input() entity: Entity | string;

  @Output() closed = new EventEmitter<boolean>();

  @ViewChild("progressBar", { static: true }) progressBar: ElementRef;

  contents: CategorizedFileItem[];
  subDirs: string[];
  rootDir: "" | 'transfer/sent' | 'media' = "";
  listDirResolved: boolean = true;
  processStatus: {
    status: Status;
    msg: string;
    progress: string | number;
  } = { status: "Idle", msg: "", progress: 0 };
  showProgress: (status: { status: Status; progress: string | number }) => any;
  addGenerateFileFromUpstreamOption = false;
  addGetTemplateFile = false;
  isIEntity: (obj: IEntity) => boolean;
  attachedFiles: File[] = [];
  selectedFiles: string[] = [];
  previewURL: string;
  selectAll: boolean;
  hasAtLeastOneFile: boolean;
  subContents: Observable<DownloadInfoDiff> | undefined;

  omitVersions: boolean = true;

  constructor(private fileBrowserService: FileBrowserService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.init();
    this.isIEntity = isIEntity;
  }

  init() {
    this.subDirs = [];
    this.showProgress = (status: { status: Status; progress: string | number }) => {
      this.processStatus = { ...status, msg: "" };
      if (status.progress !== undefined) {
        this.processStatus.progress = `${status.progress}%`;
        this.progressBar.nativeElement.style.width = this.processStatus.progress;
      }
      this.cdr.markForCheck();
    };

    if (typeof this.entity === "string") {
      this.subDirs.push(this.entity);
    } else {
      this.subDirs.push((this.entity as Entity).entityCode);
    }
    this.listDir();
  }

  closeModal() {
    this.closed.emit(true);
  }

  shouldExit($event: boolean) {
    if ($event && this.listDirResolved) {
      this.closeModal();
    }
  }

  listDir(subDir?: string) {
    this.listDirResolved = false;
    this.selectedFiles = [];
    this.selectAll = false;
    this.previewURL = "";
    this.hasAtLeastOneFile = false;

    if (subDir) {
      this.subDirs.push(subDir);
    }

    // we only want the subdirs after the entity name [lucas_the_spider, (from this point on)mdl, ...]
    const currentDir = this.subDirs.slice(1).join("/");

    if (this.entity instanceof Entity) {
      this.fileBrowserService
        .entityListDir(this.entity, currentDir, this.rootDir)
        .subscribe((contents) => {
          this.contents = contents;
          this.hasAtLeastOneFile = this.contents.findIndex((f) => f["type"] === "file") > -1;
          this.addGenerateFileFromUpstreamOption =
            currentDir.endsWith("work/maya") &&
            this.subDirs.length === 4 &&
            this.contents.find((content) => content["name"].endsWith(".ma")) === undefined;
          this.addGetTemplateFile =
            currentDir.endsWith("work/blender") &&
            this.contents.find((content) => content["name"].endsWith(".blend")) === undefined;
          this.listDirResolved = true;
          this.cdr.markForCheck();
        });
    } else {
      this.fileBrowserService
        .projectListDir(this.projectCode, this.entity, currentDir)
        .subscribe((contents) => {
          this.contents = contents;
          this.listDirResolved = true;
          this.cdr.markForCheck();
        });
    }
  }

  changeDir(to: number) {
    this.subDirs.splice(to + 1, this.subDirs.length - to);
    this.listDir();
  }

  onChangeFormCheckInput($event: Event, content: CategorizedFileItem) {
    const target = $event.target as HTMLInputElement;
    this.updateSelectedFiles(content["name"], target.checked ? "add" : "remove");
  }

  onChangeInputFiles(inputFiles: HTMLInputElement) {
    if (inputFiles.files) {
      this.attachedFiles = Array.from(inputFiles.files);
    }
    this.cdr.markForCheck();
  }

  // todo: typing here is incorrect.
  postDownload(_data: HttpProgress<Blob>, file: string, needsProcessing: boolean) {
    if (!needsProcessing) {
      this.processStatus.status = "Idle";
      this.processStatus.progress = `0%`;
      this.progressBar.nativeElement.style.width = this.processStatus.progress;

      const data = _data.body;
      if (data === undefined || data === null) {
        return;
      }
      const blob = new Blob([data], { type: data.type });
      const url = window.URL.createObjectURL(blob);
      const location = document.createElement("a");
      location.href = url;
      location.download = file;
      location.dispatchEvent(new MouseEvent("click"));

      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 1000);
    } else {
      if (Array.isArray(_data)) {
        // _data contains the job id
        // API call: download?path=anm/work/{username}/{jobId}/{projectCode}_{group}_{entityCode}_{dept}.zip
        const dept = this.subDirs[1];
        // TODO: this filename should not be specific
        const file =
          (this.entity as Entity).context === "assets"
            ? `${(this.entity as Entity).entityCode}.ma`
            : `${this.projectCode}_${(this.entity as Entity).toString()}_${dept}.zip`;
        const filepath = [
          ...this.subDirs.slice(1, this.subDirs.length - 1),
          UserToken.getUsername(),
          _data[0],
          file,
        ].join("/");

        this.fileBrowserService
          .entityDownloadFile(
            this.entity as Entity,
            filepath,
            "transfer/sent",
            this.showProgress,
            false
          )
          .subscribe((_data) => this.postDownload(_data, file, false));
      }
    }
  }

  _download(file: string, filepath: string, whitelist: string[]) {
    if (this.entity instanceof Entity) {
      this.fileBrowserService
        .entityFileNeedsProcessing(this.entity, filepath, this.rootDir)
        .subscribe((needsProcessing) => {
          this.processStatus.status = needsProcessing ? "Processing" : "Downloading";
          this.cdr.markForCheck();
          this.fileBrowserService
            .entityDownloadFile(
              this.entity as Entity,
              filepath,
              this.rootDir,
              this.showProgress,
              needsProcessing,
              false,
              whitelist
            )
            .subscribe(
              (_data) => this.postDownload(_data, file, needsProcessing),
              (err: HttpErrorResponse) => {
                this.processStatus.progress = `0%`;

                if (needsProcessing) {
                  this.processStatus.status = err.status === 500 ? "Failed" : "Suspended";
                  let promise = err.error.text();
                  promise.then((blob: string) => {
                    this.processStatus.msg = JSON.parse(blob).description;
                  });
                } else {
                  this.processStatus.status = "Idle";
                }

                this.progressBar.nativeElement.style.width = this.processStatus.progress;
                this.cdr.markForCheck();
              }
            );
        });
    } else {
      this.fileBrowserService
        .projectDownloadFile(this.projectCode, this.entity as string, filepath, this.showProgress)
        .subscribe((_data) => this.postDownload(_data, file, false));
    }
  }

  download(file: string, subContentsForm?: Element) {
    const filepath = [...this.subDirs.slice(1), file].join("/");
    let whitelist: string[] = [];

    if (subContentsForm) {
      const checkboxes = subContentsForm.querySelectorAll("input[type=checkbox]");
      whitelist = getUpdatedItems(checkboxes, {}, Attribute.ID);
      this._download(file, filepath, whitelist);
      return;
    }

    // default whitelist everything if clicking the shot file that is not under 'more...'
    if (this.entity instanceof Entity && (this.entity as Entity).context === "shots") {
      this.showFileSubContents(file).subscribe((subcontents) => {
        const whitelist = Object.keys(subcontents);
        this._download(file, filepath, whitelist);
      });
      return;
    }

    this._download(file, filepath, whitelist);
  }

  generateFileFromUpstreamDept() {
    const dept = this.subDirs[1];
    const file = `${this.projectCode}_${(this.entity as Entity).toString()}_${dept}.ma`;
    const filepath = [...this.subDirs.slice(1), file].join("/");

    this.processStatus.status = "Processing";
    this.cdr.markForCheck();
    this.fileBrowserService
      .entityDownloadFile(
        this.entity as Entity,
        filepath,
        this.rootDir,
        this.showProgress,
        true,
        true
      )
      .subscribe(
        (_data) => {
          this.postDownload(_data, file, true);
        },
        (err: { code: number; error: string; description: string }) => {
          console.error(err);
          this.processStatus.status = err.code === 500 ? "Failed" : "Suspended";
          this.processStatus.msg = err.description;
          this.cdr.markForCheck();
        }
      );
  }

  getTemplateFile() {
    const file = "template.blend";
    const filepath = [...this.subDirs.slice(1), file].join("/");

    this.processStatus.status = "Processing";
    this.cdr.markForCheck();
    this.fileBrowserService
      .entityDownloadFile(
        this.entity as Entity,
        filepath,
        this.rootDir,
        this.showProgress,
        false,
        false
      )
      .subscribe(
        (_data) => {
          this.postDownload(_data, file, false);
        },
        (err: { code: number; error: string; description: string }) => {
          console.error(err);
          this.processStatus.status = err.code === 500 ? "Failed" : "Suspended";
          this.processStatus.msg = err.description;
          this.cdr.markForCheck();
        }
      );
  }

  showFileSubContents(file: string) {
    const filepath = [...this.subDirs.slice(1), file].join("/");
    return this.fileBrowserService.getLatestDownloadInfoForEntity(this.entity as Entity, filepath);
  }

  createFolder(folder: HTMLInputElement) {
    const path = [...this.subDirs.slice(1), folder.value].join("/");
    this.fileBrowserService.createFolder(this.entity as Entity, path).subscribe((_) => {
      folder.value = "";
      this.listDir();
    });
  }

  uploadFiles(inputFiles: HTMLInputElement) {
    const path = [...this.subDirs.slice(1)].join("/");
    this.fileBrowserService
      .uploadFiles(this.attachedFiles, path, this.entity as Entity, this.showProgress)
      .subscribe(() => {
        inputFiles.value = "";

        this.processStatus.status = "Idle";
        this.processStatus.progress = `0%`;
        this.progressBar.nativeElement.style.width = this.processStatus.progress;

        this.attachedFiles = [];
        this.listDir();
        this.cdr.markForCheck();
      });
  }

  downloadSelected() {
    const path = [...this.subDirs.slice(1)].join("/");

    if (!(this.entity instanceof Entity)) {
      this.fileBrowserService
        .projectDownloadFiles(
          this.projectCode,
          this.entity,
          path,
          this.selectedFiles,
          this.showProgress
        )
        .subscribe((data) => {
          this.postDownload(data, "rtracker-downloads.zip", false);
        });
    } else {
      this.fileBrowserService
        .downloadFiles(this.selectedFiles, path, this.entity as Entity, this.showProgress, this.omitVersions)
        .subscribe((data) => {
          this.postDownload(data, "rtracker-downloads.zip", false);
        });
    }
  }

  deleteSelected() {
    const path = [...this.subDirs.slice(1)].join("/");
    if (
      window.confirm(`Are you sure you want to delete the selected files? Deleting is NOT undoable`)
    ) {
      this.fileBrowserService
        .deleteFiles(this.selectedFiles, path, this.entity as Entity)
        .subscribe((_) => this.listDir());
    }
  }

  updateSelectedFiles(filename: string | "/select_all/", addOrRemove: "add" | "remove") {
    if (filename === "/select_all/") {
      if (addOrRemove === "add") {
        for (const content of this.contents) {
          this.selectedFiles.push(content["name"]);
        }
      } else {
        this.selectedFiles = [];
      }
      return;
    }

    if (addOrRemove === "add") {
      this.selectedFiles.push(filename);
    } else {
      const i = this.selectedFiles.findIndex((f) => f === filename);
      if (i < 0) {
        return;
      }
      this.selectedFiles.splice(i, 1);
    }
  }

  updatePreview(file: string) {
    // Disable preview if it's not an entity for the time being
    if (!(this.entity instanceof Entity)) {
      return;
    }

    const path = [...this.subDirs.slice(1), file].join("/");
    this.previewURL = this.fileBrowserService.getPreviewFileURL(
      this.entity as Entity,
      path,
      this.rootDir
    );
  }

  deleteSinglePath($event: Event, filename: string) {
    $event.stopPropagation();
    if (window.confirm(`Are you sure you want to delete ${filename}? Deleting is NOT undoable`)) {
      const path = [...this.subDirs.slice(1), filename].join("/");
      this.fileBrowserService
        .deletePath(this.entity as Entity, path)
        .subscribe((_) => this.listDir());
    }
  }
}
