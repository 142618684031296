import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { Context } from "../entity";
import { EntityService } from "../entity.service";
import { ProjectService } from "../project.service";

@Component({
  selector: "app-duplicate-entity",
  templateUrl: "./duplicate-entity.component.html",
  styleUrls: ["./duplicate-entity.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DuplicateEntityComponent implements OnInit {
  @Input() projectCode: string;
  @Input() context: Context;

  @Output() closed = new EventEmitter();

  @ViewChild("caches") caches: ElementRef<HTMLInputElement>;
  @ViewChild("plates") plates: ElementRef<HTMLInputElement>;

  projects$: Observable<string[]>;
  groups$: Observable<string[]>;
  entities$: Observable<string[]>;
  dstProjectCode: string = "";
  srcGroup: string = "";
  srcEntityCode: string = "";
  hasDangerBorder = false;
  submitted: boolean | string;

  constructor(
    private entityService: EntityService,
    private projectService: ProjectService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.dstProjectCode = this.projectCode;

    this.projects$ = this.projectService
      .getProjects()
      .pipe(map((projects) => projects.map((p) => p.code)));

    this.groups$ = this.entityService.getGroups(this.projectCode, this.context).pipe(
      tap((groups) => {
        this.srcGroup = groups[0];

        this.entities$ = this.entityService
          .getEntityCodesForGroup(this.projectCode, this.context, groups[0])
          .pipe(
            map((entities) => {
              let entityCodes =
                entities.map((ent) => {
                  return ent["asset_code"] || ent["shot_code"];
                }) || [];

              if (entityCodes.length > 0) {
                this.srcEntityCode = entityCodes[0];
              } else {
                this.srcEntityCode = "";
              }

              return entityCodes as string[];
            })
          );
      })
    );
  }

  isValid(str: string): boolean {
    const regex = /^[a-z0-9_]*$/g;
    const valid = regex.test(str);
    this.hasDangerBorder = !valid;
    return valid;
  }

  onChangeGroup(group: string) {
    this.srcGroup = group;

    this.entities$ = this.entityService
      .getEntityCodesForGroup(this.projectCode, this.context, group)
      .pipe(
        map((entities) => {
          let entityCodes =
            entities.map((ent) => {
              return ent["asset_code"] || ent["shot_code"];
            }) || [];

          if (entityCodes.length > 0) {
            this.srcEntityCode = entityCodes[0];
          } else {
            this.srcEntityCode = "";
          }

          return entityCodes as string[];
        })
      );
  }

  onChangeEntityCode($event: string) {
    this.srcEntityCode = $event;
  }

  duplicate(
    dstGroup: string,
    dstEntityCode: string,
    copyNotes: boolean,
    copyMedia: boolean,
    copyVersions: boolean
  ) {
    this.entityService
      .duplicateEntity(
        this.projectCode,
        this.context,
        this.srcGroup,
        this.srcEntityCode,
        this.dstProjectCode,
        dstGroup,
        dstEntityCode,
        copyVersions,
        copyNotes,
        copyMedia,
        this.caches ? this.caches.nativeElement.checked : false,
        this.plates ? this.plates.nativeElement.checked : false
      )
      .subscribe(
        (msg) => {
          this.submitted = `Duplicate process submitted. Job id: ${msg["job_id"]}`;
          this.cdr.markForCheck();
        },
        (err) => console.error(err)
      );
  }
}
