import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";

@Component({
  selector: "app-schedule-viewer",
  templateUrl: "./schedule-viewer.component.html",
  styleUrls: ["./schedule-viewer.component.css"],
})
export class ScheduleViewerComponent implements OnInit {
  @ViewChild("scheduleFrame", { static: true }) scheduleFrame: ElementRef;

  @Input() srcLink: string;
  @Output() closed = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {
    if (this.srcLink.trim() !== "") {
      this.openViewer();
    }
  }

  openViewer() {
    this.scheduleFrame.nativeElement.src = this.srcLink;
    //window.frames[0].location.href = this.srcLink;
  }

  closeViewer() {
    this.closed.emit(true);
  }
}
