import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../environments/environment';
import MongoDocument from '../mongo-document';
import { forkJoin } from "rxjs";


interface DocumentationLink extends MongoDocument {
  title: string;
  heading: string;
  url: string; // original url.
  url_owncloud?: string; // we are apparently trying to move documents over to owncloud.
  // if there is both an original url, and an owncloud url, use the owncloud url.
  documentType: string;
  added_by: string;
  summary: string;
  order: number;
}

type GroupedLinks = {
  heading: string;
  items: DocumentationLink[];
}[];

type DocLinksScope = "project" | "general";

@Component({
  selector: 'app-doc-links-page',
  templateUrl: './doc-links-page.component.html',
  styleUrls: ['./doc-links-page.component.css']
})
export class DocLinksPageComponent implements OnInit {

  documentationLinks: GroupedLinks;
  generalLinks: GroupedLinks;

  newDocument: Partial<Omit<DocumentationLink, 'added_by' | 'order'>> = {};
  newDocumentScope: DocLinksScope = "project";

  projectCode: string;

  editingItem: DocumentationLink | null = null;
  editingScope: DocLinksScope | null = null;

  constructor(private activatedRoute: ActivatedRoute,
              private http: HttpClient,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    const parentParams = this.activatedRoute.snapshot.parent?.params;
    this.projectCode = parentParams?.projectCode;
    this.refreshData();
  }

  refreshData() {
    const projectLinks$ = this.http.get<DocumentationLink[]>(`${environment.documentationLinksURL}/${this.projectCode}`);
    const generalLinks$ = this.http.get<DocumentationLink[]>(`${environment.documentationLinksURL}/general`);

    forkJoin([projectLinks$, generalLinks$]).subscribe(([projectSpecific, general]) => {
      this.documentationLinks = this.transformLinksData(projectSpecific);
      this.generalLinks = this.transformLinksData(general);
      this.cdr.detectChanges();
    });
  }

  transformLinksData(data: DocumentationLink[]): GroupedLinks {
    const groups = new Map<string, any[]>();
    data.forEach(item => {
      const group = item.heading;
      const elements = groups.get(group) || [];
      elements.push(item);
      groups.set(group, elements);
    });
    return Array.from(groups, ([heading, items]) => ({ heading, items: (items as DocumentationLink[]).sort((a, b) => a.order - b.order) }));
  }

  onSubmit() {
    let url: string;
    if (this.newDocumentScope === "project") {
      url = `${environment.documentationLinksURL}/${this.projectCode}`;
    } else {
      url = `${environment.documentationLinksURL}/general`;
    }
    this.http.post<DocumentationLink[]>(url, this.newDocument)
      .subscribe((data) => {
        this.newDocument = {};
        if (this.newDocumentScope === "project") {
          this.documentationLinks = this.transformLinksData(data);
        } else {
          this.generalLinks = this.transformLinksData(data);
        }
        this.cdr.detectChanges();
      });
  }

  onDelete(item: DocumentationLink, scope: DocLinksScope = "project") {
    let url: string;
    if (scope === "project") {
      url = `${environment.documentationLinksURL}/${this.projectCode}`;
    } else {
      url = `${environment.documentationLinksURL}/general`;
    }

    this.http.delete<DocumentationLink[]>(url, {body: item})
      .subscribe((data) => {
        if (scope === "project") {
          this.documentationLinks = this.transformLinksData(data);
        } else {
          this.generalLinks = this.transformLinksData(data);
        }
        this.cdr.detectChanges();
      });
  }

  /**
   * Start editing the item in the ui.
   * @param item
   */
  onStartEdit(item: DocumentationLink, scope: DocLinksScope = "project") {
    this.editingItem = item;
    this.editingScope = scope;
  }

  onCancelEdit() {
    this.editingItem = null;
    this.editingScope = null;
  }

  onSubmitEdit() {
    let url: string;
    if (this.editingScope === 'project') {
      url = `${environment.documentationLinksURL}/${this.projectCode}`;
    } else {
      url = `${environment.documentationLinksURL}/general`;
    }
    this.http.put<DocumentationLink[]>(url, this.editingItem)
      .subscribe((data) => {
        if (this.editingScope === 'project') {
          this.documentationLinks = this.transformLinksData(data);
        } else {
          this.generalLinks = this.transformLinksData(data);
        }
        this.editingItem = null;
        this.editingScope = null;
        this.cdr.detectChanges();
      });
  }

  onChangePosition(item: DocumentationLink, direction: 1 | -1, scope: DocLinksScope = "project") {
    let url: string;
    if (scope === "project") {
      url = `${environment.documentationLinksURL}/${this.projectCode}/ordering/${direction.toString(10)}`;
    } else {
      url = `${environment.documentationLinksURL}/general/ordering/${direction.toString(10)}`;
    }
    this.http.put<DocumentationLink[]>(url, item)
      .subscribe((data) => {
        if (scope === 'project') {
          this.documentationLinks = this.transformLinksData(data);
        } else {
          this.generalLinks = this.transformLinksData(data);
        }
        this.cdr.detectChanges();
      });
  }

}
