import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { IEntity, IMedia, Context, MediaType, Entity, ScriptEntity, IStatus } from "../entity";
import { IMediaDropDownItem } from "../dropdown-item";
import { User } from "../user";
import { FileBrowserService } from "../file-browser.service";
import { NotesComponent } from "../notes/notes.component";
import { CanvasComponent } from "../canvas/canvas.component";
import { EntityService } from "../entity.service";
import { mediaToString, addFrameTimeStamps, HttpProgress } from "../utils";
import { QueryParamService } from "../query-param.service";
import { Note } from "../notes";
import { ScriptService } from "../script.service";

@Component({
  selector: "app-media-player",
  templateUrl: "./media-player.component.html",
  styleUrls: ["./media-player.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaPlayerComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() allowDownloadWorkfileInMediaPlayer: boolean;
  @Input() play$: Observable<IMedia[]>;
  @Input() notes: Note[];
  @Input() statuses: IStatus[];
  @Input() users: User[];
  @Input() entity: IEntity;
  @Input() selectedDefaultThumbnail: IMedia;
  @Input() mediaSource?: "MEDIA" | "CATALOG" | "assembly" | "leica" | "stages" | "storyboards" =
    "MEDIA";
  @Input() selectedVersionID1?: string;
  @Input() selectedVersionID2?: string;
  @Input() audio: "left" | "right" = "left";

  @Output() closed = new EventEmitter<boolean>();
  @Output() changedVersion = new EventEmitter<IMediaDropDownItem>();

  @ViewChild("canvasComponent") canvasComponent: CanvasComponent;
  @ViewChild("notesComponent") notesComponent: NotesComponent;
  @ViewChild("catalogComment") catalogComment: ElementRef;
  @ViewChild("iFrameContainer") iFrameContainer: ElementRef;

  isComparing = false;
  selectedVersion1: IMediaDropDownItem;
  selectedVersion2: IMediaDropDownItem;
  srcURL: string;
  src2URL: string;
  srcType: "video" | "image" | "pdf" = "video";
  shouldFireModalExit = true;
  status = "";
  progress = 0;
  defaultThumbnailId = "";
  added: boolean;
  tabContext: Context;
  mediaToString: (media: IMedia) => string;
  media: IMedia[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private entityService: EntityService,
    private queryParamService: QueryParamService,
    private fileBrowserService: FileBrowserService,
    private scriptService: ScriptService,
    private cdr: ChangeDetectorRef
  ) {
    const urlSegments = this.activatedRoute.snapshot.url;
    this.tabContext = urlSegments[0].path as Context;
    this.mediaToString = mediaToString;
  }

  ngOnInit() {
    if (this.selectedDefaultThumbnail) {
      this.defaultThumbnailId = this.selectedDefaultThumbnail["_id"]["$oid"];
    }

    this.play$ = this.play$.pipe(
      map((media) => {
        this.media = media;
        if (!this.selectedVersionID1) {
          for (const m of this.media) {
            if (m["dept"] !== "plt") {
              this.selectedVersion1 = m as IMediaDropDownItem;
              break;
            }
          }
        } else {
          if (media.length > 0) {
            this.selectedVersion1 = media.find(
              (item) => item["_id"]["$oid"] === this.selectedVersionID1
            ) as IMediaDropDownItem;
          }
        }

        if (!this.selectedVersionID2 && this.selectedVersion1) {
          this.setVersion2BasedOnVersion1();
        } else {
          if (media.length > 0) {
            this.selectedVersion2 = media.find(
              (item) => item["_id"]["$oid"] === this.selectedVersionID2
            ) as IMediaDropDownItem;
          }
        }

        if (this.selectedVersion1) {
          this.getSrcType();

          const mediaType =
            this.selectedVersion1.movie || this.selectedVersion1.movie_file
              ? MediaType.MOVIE
              : MediaType.THUMBNAIL;

          if (this.selectedVersion1.dept === "plt" && this.entity.context !== "plates") {
            const plate = {
              projectCode: this.entity["projectCode"],
              context: "plates" as Context,
              group: this.selectedVersion1["text"].split("_")[0],
              entityCode: this.selectedVersion1["text"],
            };
            this.srcURL = this.entityService.getMediaURL(plate, mediaType, this.selectedVersion1);
          } else if (this.entity.context === "scripts") {
            this.srcURL = this.scriptService.getMediaURL(
              this.entity as ScriptEntity,
              this.selectedVersion1,
              this.mediaSource!
            );
          } else {
            this.srcURL = this.entityService.getMediaURL(
              this.entity,
              mediaType,
              this.selectedVersion1
            );
          }
          addFrameTimeStamps(this.selectedVersion1);
        }

        if (this.selectedVersion2) {
          const mediaType =
            this.selectedVersion2.movie || this.selectedVersion2.movie_file
              ? MediaType.MOVIE
              : MediaType.THUMBNAIL;
          if (
            this.selectedVersion2.dept === "plt" &&
            this.entity.context !== "plates" &&
            this.entity["plate"]
          ) {
            const plate = {
              projectCode: this.entity["projectCode"],
              context: "plates" as Context,
              group: this.entity["plate"]["drive"],
              entityCode: this.entity["plate"]["plate_code"],
            };
            this.src2URL = this.entityService.getMediaURL(plate, mediaType, this.selectedVersion2);
          } else if (this.entity.context === "scripts") {
            this.src2URL = this.scriptService.getMediaURL(
              this.entity as ScriptEntity,
              this.selectedVersion2,
              this.mediaSource!
            );
          } else {
            this.src2URL = this.entityService.getMediaURL(
              this.entity,
              mediaType,
              this.selectedVersion2
            );
          }
          addFrameTimeStamps(this.selectedVersion2);
        }

        if (this.defaultThumbnailId) {
          const i = this.media.findIndex((item) => item["_id"]["$oid"] === this.defaultThumbnailId);
          this.media[i] = { ...this.media[i], "css-classes": "text-info" };
        }

        if (this.srcType === "pdf") {
          this.downloadPDF(this.selectedVersion1.pdf_file.file_path, true);
        }

        return this.media;
      })
    );
  }

  ngAfterViewInit() {
    if (this.selectedVersionID1 && this.selectedVersionID2) {
      this.isComparing = true;
      this.cdr.markForCheck();

      if (this.srcType !== "video") {
        return;
      }

      this.setCanvasBasedOnWhichVideoHasLongerFrames();
      setTimeout(() => {
        this.changeAudio({ target: { value: this.audio } });
      }, 0);
    }
  }

  ngOnDestroy() {
    document.body.style.overflow = "auto";
  }

  getSrcType() {
    if (this.selectedVersion1.movie || this.selectedVersion1.movie_file) {
      this.srcType = "video";
    } else if (this.selectedVersion1.pdf_file) {
      this.srcType = "pdf";
    } else {
      this.srcType = "image";
    }
  }

  filterChoicesByType() {
    if (this.selectedVersion1.movie) {
      return this.media.filter((m) => m["movie"]);
    } else if (this.selectedVersion1.movie_file) {
      return this.media.filter((m) => m["movie_file"]);
    } else {
      return this.media.filter((m) => !m["movie"]);
    }
  }

  setVersion2BasedOnVersion1() {
    if (this.entity.context !== "plates") {
      let isLatest = false;
      for (let i = 0; i < this.media.length; i++) {
        if (
          this.media[i]["dept"] !== "plt" &&
          this.selectedVersion1["_id"]["$oid"] === this.media[i]["_id"]["$oid"] &&
          (i === 0 || i === 1)
        ) {
          this.selectedVersion2 = {
            ...((this.media[i + 1] as IMediaDropDownItem) || this.selectedVersion1),
          };
          isLatest = true;
          break;
        }
      }

      if (!isLatest) {
        if (this.media[0]["dept"] !== "plt") {
          this.selectedVersion2 = { ...(this.media[0] as IMediaDropDownItem) };
        } else if (this.media[1]["dept"] !== "plt") {
          this.selectedVersion2 = { ...(this.media[1] as IMediaDropDownItem) };
        } else {
          this.selectedVersion2 = { ...this.selectedVersion1 };
        }
      }
    }
  }

  onChangeVersion2(
    version: IMediaDropDownItem,
    clip?: number,
    frame?: number,
    audio: "left" | "right" = this.audio,
    forceLoad?: boolean
  ) {
    //if (version.movie) {
    //  if (version['frames_info']['total_frames'] !== this.selectedVersion['frames_info']['total_frames']) {
    //    alert(`Mismatched number of frames. Left: ${this.selectedVersion['frames_info']['total_frames']}. Right: ${version['frames_info']['total_frames']}`);
    //    return;
    //  }
    //}
    const prevSrc2URL = this.src2URL;
    if (version["dept"] === "plt") {
      const drive = version["text"].split("_")[0];
      this.src2URL = this.entityService.getMediaURL(
        new Entity({
          projectCode: this.entity.projectCode,
          context: "plates",
          group: drive,
          entityCode: version["text"],
        }),
        version.movie ? MediaType.MOVIE : MediaType.THUMBNAIL,
        version
      );
    } else if (this.entity.context === "scripts") {
      this.src2URL = this.scriptService.getMediaURL(
        this.entity as ScriptEntity,
        this.selectedVersion2,
        this.mediaSource!
      );
    } else {
      const mediaType =
        this.selectedVersion2.movie || this.selectedVersion2.movie_file
          ? MediaType.MOVIE
          : MediaType.THUMBNAIL;
      this.src2URL = this.entityService.getMediaURL(this.entity, mediaType, this.selectedVersion2);
    }

    this.selectedVersion2 = { ...version };
    this.queryParamService.updateQueryParams({
      version: this.selectedVersion1["_id"]["$oid"],
      compare: this.selectedVersion2["_id"]["$oid"],
    });
    addFrameTimeStamps(this.selectedVersion2);

    if (!this.isComparing) {
      this.canvasComponent.reset();
    }

    if (version.movie || version.movie_file) {
      const seekClipAndUpdateAudio = () => {
        frame = frame || this.canvasComponent.currentFrame.nativeElement.valueAsNumber;
        clip = clip || 0.5;
        this.changeAudio({ target: { value: audio } });
        return this.canvasComponent.seekClipAndUpdateAudio(clip, frame ?? 1, audio);
      };

      setTimeout(() => {
        if (prevSrc2URL !== this.src2URL || forceLoad) {
          this.canvasComponent.rightMedia.nativeElement.onloadeddata = seekClipAndUpdateAudio;
          this.canvasComponent.rightMedia.nativeElement.load();
        } else {
          seekClipAndUpdateAudio();
        }
      }, 0);

      this.setCanvasBasedOnWhichVideoHasLongerFrames();
    }
  }

  onChangeVersion(version: IMediaDropDownItem) {
    if (this.selectedVersion1["_id"]["$oid"] === version["_id"]["$oid"]) {
      return;
    }

    this.selectedVersion1 = { ...version };
    this.queryParamService.updateQueryParams({ version: this.selectedVersion1["_id"]["$oid"] });
    addFrameTimeStamps(this.selectedVersion1);

    if (this.selectedVersion1["dept"] === "plt") {
      const drive = this.selectedVersion1["text"].split("_")[0];
      this.srcURL = this.entityService.getMediaURL(
        new Entity({
          projectCode: this.entity.projectCode,
          context: "plates",
          group: drive,
          entityCode: this.selectedVersion1["text"],
        }),
        this.selectedVersion1.movie ? MediaType.MOVIE : MediaType.THUMBNAIL,
        this.selectedVersion1
      );
    } else if (this.entity.context === "scripts") {
      this.srcURL = this.scriptService.getMediaURL(
        this.entity as ScriptEntity,
        this.selectedVersion1,
        this.mediaSource!
      );
    } else {
      const mediaType =
        this.selectedVersion1.movie || this.selectedVersion1.movie_file
          ? MediaType.MOVIE
          : MediaType.THUMBNAIL;
      this.srcURL = this.entityService.getMediaURL(this.entity, mediaType, this.selectedVersion1);
    }

    this.getSrcType();

    if (!this.isComparing) {
      this.canvasComponent.reset();
    }

    if (version.movie || version.movie_file) {
      const seekClipAndUpdateAudio = () => {
        const frame = this.canvasComponent.currentFrame.nativeElement.valueAsNumber;
        let clip = 0.5;
        if (this.isComparing) {
          clip = this.canvasComponent.clip.nativeElement.valueAsNumber;
        }
        return this.canvasComponent.seekClipAndUpdateAudio(clip, frame, this.audio);
      };

      setTimeout(() => {
        if (this.isComparing) {
          this.canvasComponent.leftMedia.nativeElement.onloadeddata = seekClipAndUpdateAudio;
        }
        this.canvasComponent.leftMedia.nativeElement.load();
      }, 0);

      if (!this.isComparing) {
        this.canvasComponent.frameTimestamps = this.selectedVersion1["frame_timestamps"];
      } else {
        this.setCanvasBasedOnWhichVideoHasLongerFrames();
      }
    }

    if (this.selectedVersion1) {
      this.notesComponent.mediaVersion = this.selectedVersion1;
      this.notesComponent.filterByDept = this.selectedVersion1["dept"];
      this.notesComponent.filter();
    }
  }

  private setCanvasBasedOnWhichVideoHasLongerFrames() {
    if (
      (this.selectedVersion1.frames_info?.total_frames ?? 0) <
      (this.selectedVersion2.frames_info?.total_frames ?? 0)
    ) {
      this.canvasComponent.frameTimestamps = this.selectedVersion2["frame_timestamps"];
      this.canvasComponent.canLoop = false;
      this.canvasComponent.isLooping = false;
      this.canvasComponent.longerFrames = "right";
    } else if (
      (this.selectedVersion1.frames_info?.total_frames ?? 0) ===
      (this.selectedVersion2.frames_info?.total_frames ?? 0)
    ) {
      this.canvasComponent.frameTimestamps = this.selectedVersion1["frame_timestamps"];
      this.canvasComponent.canLoop = true;
      this.canvasComponent.isLooping = true;
      this.canvasComponent.longerFrames = "left";
    } else {
      this.canvasComponent.frameTimestamps = this.selectedVersion1["frame_timestamps"];
      this.canvasComponent.canLoop = false;
      this.canvasComponent.isLooping = false;
      this.canvasComponent.longerFrames = "left";
    }
  }

  setDefaultThumbnail() {
    const i = this.media.findIndex(
      (item) => item["_id"]["$oid"] === this.selectedVersion1._id["$oid"]
    );

    this.entityService
      .updateOne(new Entity({ ...this.entity }), {
        default_thumbnail: { _id: this.selectedVersion1._id },
      })
      .subscribe((_) => {
        this.defaultThumbnailId = this.selectedVersion1._id["$oid"];
        this.media[i] = { ...this.media[i], "css-classes": "text-info" };
        this.selectedDefaultThumbnail = this.selectedVersion1;
        this.cdr.markForCheck();
      });
  }

  revertDefaultThumbnail() {
    const latestVersion = this.media.find(
      (item) => item["_id"]["$oid"] === this.entity.getLatestMedia()["_id"]["$oid"]
    );
    const defaultVersion = this.media.find(
      (item) => item["_id"]["$oid"] === this.defaultThumbnailId
    );

    this.entityService
      .updateOne(new Entity({ ...this.entity }), { $unset: { default_thumbnail: "" } })
      .subscribe((_) => {
        this.defaultThumbnailId = "";
        if (latestVersion) {
          this.selectedDefaultThumbnail = latestVersion;
        }
        delete this.entity["default_thumbnail"];
        this.media[0] = { ...this.media[0], "css-classes": "text-info" };
        if (defaultVersion) {
          delete defaultVersion["css-classes"];
        }
        this.cdr.markForCheck();
      });
  }

  removeFromCatalog() {
    if (this.selectedVersion1._id["$oid"] === this.defaultThumbnailId) {
      // If version is the default thumbnail, revert the default thumbnail before removing.
      this.revertDefaultThumbnail();
    }

    this.entityService
      .updateOne(new Entity({ ...this.entity }), {
        $pull: { catalog: { _id: this.selectedVersion1._id } },
      })
      .subscribe((result) => {
        // now that this has been submitted to the database, I need to do the same thing locally.
        this.entity.catalog = result.catalog;

        this.media = this.media.filter((v) => v._id["$oid"] !== this.selectedVersion1._id["$oid"]);

        if (this.media.length > 0) {
          this.selectedVersion1 = this.media[0] as IMediaDropDownItem;
        } else {
          alert("All items removed from catalog.");
          this.closePlayer();
        }
        this.play$ = of(this.media);
      });
  }

  closePlayer() {
    this.queryParamService.updateQueryParams({
      play: null,
      version: null,
      "play-ctx": null,
      "play-src": null,
      compare: null,
      audio: null,
      "show-all-notes": null,
      note: null,
    });
    this.closed.emit(false);
  }

  downloadWorkFile() {
    const showProgress = (status: HttpProgress) => {
      if (this.status === "Failed") {
        this.progress = 0;
        this.status = "";
      } else {
        this.progress = status["progress"];
        this.status = status["status"];
      }
      this.cdr.markForCheck();
    };
    const path = `${this.selectedVersion1.dept}/work/blender/versions/${this.selectedVersion1.version}/${this.entity.projectCode}_${this.entity.group}_${this.entity.entityCode}_${this.selectedVersion1.dept}.blend`;
    this.fileBrowserService
      .entityDownloadFile(this.entity as Entity, path, "", showProgress, false)
      .subscribe(
        (_data) => {
          this.status = "";
          this.progress = 0;

          const data = _data.body;
          const blob = new Blob([data], { type: data.type });
          const ext = blob.type === "application/blender" ? ".blend" : ".zip";
          const url = window.URL.createObjectURL(blob);
          const location = document.createElement("a");

          location.download = `${this.entity.projectCode}_${this.entity.group}_${this.entity.entityCode}_${this.selectedVersion1.dept}${ext}`;
          location.href = url;
          location.dispatchEvent(new MouseEvent("click"));

          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          }, 1000);

          this.cdr.markForCheck();
        },
        (_) => {
          this.status = "";
          this.progress = 0;
          this.cdr.markForCheck();
        }
      );
  }

  downloadRenders() {
    const showProgress = (status: HttpProgress) => {
      if (this.status === "Failed") {
        this.progress = 0;
        this.status = "";
      } else {
        this.progress = status["progress"];
        this.status = status["status"];
      }
      this.cdr.markForCheck();
    };
    this.fileBrowserService
      .downloadRenders(this.entity as Entity, this.selectedVersion1["dept"], showProgress)
      .subscribe(
        (_data) => {
          this.status = "";
          this.progress = 0;

          const data = _data.body;
          if (data === undefined || data === null) {
            return;
          }
          const blob = new Blob([data], { type: data.type });
          const url = window.URL.createObjectURL(blob);
          const location = document.createElement("a");

          location.download = `${this.entity.projectCode}_${this.entity.group}_${this.entity.entityCode}_${this.selectedVersion1.dept}_renders.zip`;
          location.href = url;
          location.dispatchEvent(new MouseEvent("click"));

          setTimeout(() => {
            window.URL.revokeObjectURL(url);
          }, 1000);

          this.cdr.markForCheck();
        },
        (_) => {
          this.status = "";
          this.progress = 0;
          this.cdr.markForCheck();
        }
      );
  }

  download() {
    const showProgress = (status: HttpProgress) => {
      if (this.status === "Failed") {
        this.progress = 0;
        this.status = "";
      } else {
        this.progress = status["progress"];
        this.status = status["status"];
      }
      this.cdr.markForCheck();
    };

    const srcURL =
      this.selectedVersion1.res === "adobe"
        ? this.srcURL.replace("/thumbnail?", "/adobe?")
        : this.srcURL;
    this.fileBrowserService.downloadMedia(srcURL, showProgress).subscribe(
      (_data) => {
        this.status = "";
        this.progress = 0;

        const data = _data.body;
        if (data === undefined || data === null) {
          return;
        }
        const blob = new Blob([data], { type: data.type });
        const url = window.URL.createObjectURL(blob);
        const extern = this.selectedVersion1["extern"] ? "extern_" : "";
        const ext = data.type.split("/")[1] === "quicktime" ? "mov" : data.type.split("/")[1];
        const location = document.createElement("a");

        const regexp = /v[0-9]{3}/;
        if (this.selectedVersion1.res === "assembly") {
          location.download = `${
            this.selectedVersion1.original_movie?.split("/")[
              this.selectedVersion1.original_movie.split("/").length - 1
            ]
          }`;
        } else if (this.selectedVersion1.res === "adobe") {
          location.download = `${
            this.selectedVersion1.source_file?.split("/")[
              this.selectedVersion1.source_file.split("/").length - 1
            ]
          }`;
        } else if (
          this.selectedVersion1.version.length == 4 &&
          this.selectedVersion1.version.match(regexp)
        ) {
          location.download = `${extern}${this.entity.projectCode}_${this.entity.group}_${this.entity.entityCode}_${this.selectedVersion1.dept}_${this.selectedVersion1.res}_${this.selectedVersion1.version}.${ext}`;
        } else {
          location.download = this.selectedVersion1.version;
        }

        location.href = url;
        location.dispatchEvent(new MouseEvent("click"));

        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 1000);

        this.cdr.markForCheck();
      },
      (_) => {
        this.status = "";
        this.progress = 0;
        this.cdr.markForCheck();
      }
    );
  }

  postDownload(_data: HttpProgress<Blob>, file: string, openPDF: boolean) {
    const data = _data.body;
    if (data === undefined || data === null) {
      return;
    }
    const blob = new Blob([data], { type: data.type });
    const url = window.URL.createObjectURL(blob);

    if (!file.toLowerCase().endsWith(".pdf") || !openPDF) {
      const location = document.createElement("a");
      location.href = url;
      location.download = file;
      location.dispatchEvent(new MouseEvent("click"));
    } else if (openPDF) {
      const iFrame = document.createElement("iframe");
      iFrame.src = url;
      iFrame.width = "100%";
      iFrame.height = "100%";
      this.iFrameContainer.nativeElement.innerHTML = "";
      this.iFrameContainer.nativeElement.appendChild(iFrame);
    }

    this.progress = 0;
    this.status = "";
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  downloadPDF(file: string, openPDF = false) {
    const showProgress = (status: HttpProgress) => {
      if (this.status === "Failed") {
        this.progress = 0;
        this.status = "";
      } else {
        this.progress = status["progress"];
        this.status = status["status"];
      }
      this.cdr.markForCheck();
    };
    const filename = file.split("/").pop() ?? "";
    const filepath = this.selectedVersion1.stage + "/" + filename;

    this.fileBrowserService
      .entityDownloadFile(
        this.entity as Entity,
        filepath,
        this.mediaSource === "stages" ? "scripts" : this.mediaSource,
        showProgress,
        false
      )
      .subscribe((_data) => this.postDownload(_data, filename, openPDF));
  }

  addToCatalog(description: string, comment: string) {
    const toCatalog = {
      ...this.selectedVersion1,
      catalog_description: description,
      catalog_comment: comment,
    };
    this.entityService
      .updateOne(new Entity({ ...this.entity }), { catalog: [toCatalog] })
      .subscribe((e) => {
        this.entity["catalog"] = e["catalog"];
        this.added = true;
        this.catalogComment.nativeElement.value = "";
        this.cdr.markForCheck();
      });
  }

  saveShotStatus(newValue: string, dept: string) {
    const toBeUpdated = {} as IEntity;
    toBeUpdated[dept.concat("_status")] = newValue;

    this.entityService.updateOne(new Entity({ ...this.entity }), toBeUpdated).subscribe(
      (result) => {
        const key = Object.keys(result);
        this.entity[key[0]] = result[key[0]];
      },
      (error) => {
        console.error(error);
      }
    );
  }

  changeAudio($event: Event | { target: { value: "left" | "right" } }) {
    if (($event.target as HTMLInputElement).value === "left") {
      this.audio = "left";
    } else {
      this.audio = "right";
    }

    this.queryParamService.updateQueryParams({ audio: this.audio });
  }

  toggleCompare() {
    this.canvasComponent.pause();
    this.isComparing = !this.isComparing;
    this.queryParamService.updateQueryParams({
      compare: this.isComparing ? this.selectedVersion2["_id"]["$oid"] : null,
      audio: this.isComparing ? this.audio : null,
    });

    if (this.isComparing) {
      this.setVersion2BasedOnVersion1();
      this.onChangeVersion2(
        this.selectedVersion2,
        /*clip=*/ undefined,
        /*frame=*/ undefined,
        this.audio,
        /*forceLoad=*/ true
      );
    } else {
      this.canvasComponent.frameTimestamps = this.selectedVersion1["frame_timestamps"];
      this.canvasComponent.longerFrames = "left";
      this.canvasComponent.leftMedia.nativeElement.style["clip-path"] = "";
    }
  }

  toggleAoverB(
    $event:
      | undefined
      | {
          selected_version2: IMediaDropDownItem;
          clip: number;
          frame: number;
          audio: "left" | "right";
        }
  ) {
    if ($event === undefined) {
      this.isComparing = false;
      this.canvasComponent.frameTimestamps = this.selectedVersion1["frame_timestamps"];
      this.canvasComponent.canLoop = true;
      this.canvasComponent.isLooping = true;
      this.canvasComponent.longerFrames = "left";
    } else {
      const prev = this.isComparing;
      this.isComparing = true;
      this.setVersion2BasedOnVersion1();
      this.onChangeVersion2(
        $event["selected_version2"],
        $event["clip"],
        $event["frame"],
        $event["audio"],
        prev !== this.isComparing
      );
    }
  }

  getSourceFile(selectedVersion: IMediaDropDownItem) {
    if (this.entity.context === "scripts") {
      if (this.srcType === "video") {
        if (selectedVersion.res === "assembly") {
          return `Original filename: ${(selectedVersion.movie || "").split("/").pop()}`;
        }
        return `Original filename: ${(selectedVersion.movie_file.original_filename || "")
          .split("/")
          .pop()}`;
      } else if (this.srcType === "pdf") {
        return `Original filename: ${(selectedVersion.pdf_file.original_filename || "")
          .split("/")
          .pop()}`;
      }
      return `Original filename: ${(selectedVersion.thumbnail_file.original_filename || "")
        .split("/")
        .pop()}`;
    }

    return `Source file: ${(selectedVersion.source_file || "").split("/").pop()}`;
  }
}
