<div class="container-fluid table-responsive">
  <div class="flex-grow-1">
    <button
      type="button"
      class="btn btn-outline-primary btn-sm ml-1"
      (click)="willShowAddSchedule = true; willShowBackdrop = true">
      Add Schedule
    </button>
  </div>
  <div style="text-align: right">
    <div>
      <app-refresh></app-refresh>
    </div>
  </div>

  <!--=========================== Add schedule ======================================-->
  <div
    *ngIf="willShowAddSchedule"
    class="fade show d-block modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="addScheduleModal"
    aria-hidden="true"
    (exited)="willShowAddSchedule = !$event"
    appModalExit>
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addScheduleModal">New Schedule</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="willShowAddSchedule = false; willShowBackdrop = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="d-flex flex-wrap">
              <div class="form-group max-wide-input-width">
                <label class="on-border-of-input" for="entity">Schedule name</label>
                <input
                  #scheduleName
                  type="text"
                  class="form-control"
                  id="scheduleName"
                  name="scheduleName" />
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-end justify-content-start">
              <textarea
                #writeTextArea
                class="form-control"
                id="schedule-body"
                name="schedule-body"
                rows="4"
                placeholder="Copy and paste Google Sheet link here"></textarea>
            </div>
            <div class="form-group text-right">
              <div class="container mt-2 mx-0">
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="add(scheduleName.value, writeTextArea.value)">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!--=========================== Schedules list ======================================-->
  <table class="table table-bordered table-hover table-sm w-auto">
    <thead class="thead-light">
      <tr>
        <th>Schedule Name</th>
        <th>Created by</th>
        <th>Date</th>
        <th>Share Link</th>
      </tr>
    </thead>
    <tbody *ngIf="initialized && schedules.length > 0; else placeholder">
      <tr *ngFor="let schedule of schedules; let i = index">
        <td id="schedule-name">
          <div id="name-main">
            <div id="name-vert" class="dropdown align-self-center">
              <button
                class="btn btn-secondary dropdown-toggle px-1"
                type="button"
                id="more-vert"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                <i class="material-icons md-18 align-text-bottom font-weight-bold">more_vert</i>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <ul>
                  <li class="dropdown-item" (click)="startEditMode(i, 'schedule_name')">
                    Edit schedule name
                  </li>
                  <li
                    class="dropdown-item"
                    (click)="
                      willShowConfirmDelete = true;
                      willShowBackdrop = true;
                      toBeDeleted = { schedule_id: schedule['_id']['$oid'] }
                    ">
                    Delete this schedule
                  </li>
                </ul>
              </div>
            </div>
            <div id="name-content">
              <span
                *ngIf="editMode[i] !== 'schedule_name'"
                class="download"
                (click)="scheduleLink = schedule['schedule_link']; openScheduleViewer = true"
                title="Download">
                {{ schedule["schedule_name"] }}
              </span>
              <span class="d-flex">
                <input
                  #thisInput
                  *ngIf="editMode[i] === 'schedule_name'"
                  class="form-control episode-name"
                  type="text"
                  style="width: 220px"
                  [ngModel]="schedule['schedule_name']"
                  [placeholder]="'<Click to Edit>'"
                  (keyup.enter)="
                    updateSchedule(thisInput.value, schedule['_id']['$oid'], 'schedule_name', i)
                  "
                  (blur)="
                    updateSchedule(thisInput.value, schedule['_id']['$oid'], 'schedule_name', i)
                  "
                  appAutofocus />
              </span>
            </div>
          </div>
        </td>
        <td>
          {{ schedule["created_by"] }}
        </td>
        <td>
          {{ getDate(schedule) }}
        </td>
        <td>
          <a target="_blank" href="{{ schedule['schedule_link'] }}">View/Edit in Google Drive</a>
          <span class="d-flex">
            <span
              *ngIf="editMode[i] !== 'schedule_link'"
              class="font-weight-normal px-1"
              title="Click to Edit"
              style="cursor: text"
              (click)="startEditMode(i, 'schedule_link')">
              {{ schedule["schedule_link"] }}
            </span>

            <input
              #thisInput
              *ngIf="editMode[i] === 'schedule_link'"
              class="form-control episode-name"
              type="text"
              style="width: 600px"
              [ngModel]="schedule['schedule_link']"
              [placeholder]="'<Click to Edit>'"
              (keyup.enter)="
                updateSchedule(thisInput.value, schedule['_id']['$oid'], 'schedule_link', i)
              "
              (blur)="updateSchedule(thisInput.value, schedule['_id']['$oid'], 'schedule_link', i)"
              appAutofocus />
          </span>
        </td>
      </tr>
    </tbody>

    <ng-template #placeholder>
      <!-- this will show Fetching data after the table headings are rendered -->
      <tbody>
        <tr>
          {{
            schedules === undefined ? "Fetching data..." : "No schedule found."
          }}
        </tr>
      </tbody>

      <tbody></tbody
    ></ng-template>
  </table>
</div>

<!--=========================== Confirm delete ======================================-->
<ng-container *ngIf="willShowConfirmDelete">
  <div
    class="fade show d-block modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="confirmDelete"
    aria-hidden="true"
    (exited)="willShowConfirmDelete = !$event"
    appModalExit>
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirm Delete Schedule</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="
              $event.stopPropagation(); willShowConfirmDelete = false; willShowBackdrop = false
            ">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span
            >Are you sure you want to delete this schedule? This operation cannot be reversed.</span
          >
          <div class="pt-2 text-right">
            <button
              type="button"
              class="btn btn-outline-secondary mr-1"
              (click)="willShowConfirmDelete = false; willShowBackdrop = false; toBeDeleted = {}">
              Cancel
            </button>
            <button type="button" class="btn btn-danger" (click)="delete()">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="fade show d-block modal-backdrop"></div>
</ng-container>

<app-schedule-viewer *ngIf="openScheduleViewer" [srcLink]="scheduleLink" (closed)="onClose($event)">
</app-schedule-viewer>
