export interface Project {
  _id: string;
  name: string;
  code: string;
  thumbnail: string;
  display_type: string;
  primary_software: string;
  allow_upload_in_file_browser?: boolean;
  allow_download_workfile_in_media_player?: boolean;
  [custom_property: string]: any; // projects may have additional custom fields defined in the database!
}

export enum DisplayType {
  CARD = "card",
  TABLE = "table",
}
