<span class="float-right position-relative hover-pointer" id="add-button" (click)="showAddNote()"
  ><i class="material-icons md-18 align-text-bottom font-weight-bold">add_circle_outline</i></span
>

<div class="position-relative p-2" id="table-wrapper">
  <div class="form-check mb-1">
    <input
      class="form-check-input mr-1"
      type="checkbox"
      [id]="completedNotesCheckboxId"
      [(ngModel)]="showCompletedNotes"
      (change)="filter(); updateQueryParams()" />
    <label
      class="ml-1 mb-0 form-check-label font-italic text-muted"
      [for]="completedNotesCheckboxId"
      >Show completed notes ({{ numCompletedNotes }})</label
    >
  </div>

  <table class="table table-bordered table-hover table-sm">
    <thead class="thead-light">
      <tr *ngIf="!willShowModal">
        <th colspan="4">Notes</th>
      </tr>
      <tr>
        <th (click)="_toggleAllNotes()" class="hover-pointer text-left">
          <i class="material-icons md-18 align-text-bottom font-weight-bold">{{
            chooseCollapseIcon()
          }}</i>
          <span *ngIf="notes" class="font-weight-normal font-smaller" style="padding-left: 2px"
            >({{ filteredNotes.length }})</span
          >
          Origin
        </th>
        <th>Assigned</th>
        <th>Status</th>
        <th>Due Date</th>
      </tr>
    </thead>
    <tbody *ngIf="toggleAllNotes">
      <ng-container *ngFor="let note of filteredNotes; let i = index">
        <tr class="highlight-hover">
          <td class="note-heading">
            <div class="d-flex align-items-center">
              <span class="hover-pointer" (click)="toggleNote(note['_id']['$oid'])"
                ><i class="material-icons md-18 align-text-bottom font-weight-bold">{{
                  chooseCollapseIcon(note["_id"]["$oid"])
                }}</i>
              </span>
              <div class="flex-grow-1">
                <input
                  #noteOrigins
                  type="text"
                  class="form-control"
                  id="origin"
                  name="origin"
                  [value]="note['origin']"
                  (keyup.enter)="
                    esc = true; updateNoteOrigin(noteOrigins.value, note['_id']['$oid'], i)
                  "
                  (keydown.escape)="
                    noteOrigins.value = note['origin']; esc = true; noteOrigins.blur()
                  "
                  (blur)="
                    esc ? null : updateNoteOrigin(noteOrigins.value, note['_id']['$oid'], i);
                    esc = false
                  " />
              </div>
              <div class="dropdown always-shift-left">
                <button
                  class="btn btn-outline-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"></button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <ul>
                    <li
                      *ngFor="let origin of origins"
                      class="dropdown-item"
                      (click)="updateNoteOrigin(origin, note['_id']['$oid'], i)">
                      {{ origin }}
                    </li>
                  </ul>
                </div>
              </div>

              <div class="dropdown align-self-center">
                <span class="hover-pointer" title="Copy note link"
                  ><i
                    id="{{ note['_id']['$oid'] }}"
                    class="material-icons-outlined md-18 align-text-bottom font-weight-bold"
                    (click)="
                      copyLink(
                        entity.projectCode,
                        entity.context,
                        entity.group,
                        entity.entityCode,
                        note['_id']['$oid'],
                        note['status'],
                        $any(note['media_version'])
                      )
                    "
                    >file_copy</i
                  ></span
                >
                <button
                  class="btn btn-secondary dropdown-toggle px-1"
                  type="button"
                  id="more-vert"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <i class="material-icons md-18 align-text-bottom font-weight-bold">more_vert</i>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <ul>
                    <li
                      class="dropdown-item"
                      (click)="
                        willShowConfirmDelete = true; toBeDeleted = { note_id: note['_id']['$oid'] }
                      ">
                      Delete this note
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </td>

          <td
            class="note-heading"
            (click)="
              startEditMode(i, 'assigned'); updateUsers(getDeptsFromNote(note.cc_departments || []))
            "
            style="min-width: 10rem">
            <div *ngIf="editMode[i] !== 'assigned'" class="d-flex flex-wrap">
              <div *ngFor="let assigned of note['assigned']" class="mr-1">{{ assigned }},</div>
            </div>
            <app-typeahead
              *ngIf="editMode[i] === 'assigned'"
              (added)="saveNote($event, i, 'assigned', note['_id']['$oid'])"
              [items$]="assignableUsernames"
              [labelString]="''"
              [idString]="'artists'"
              [emitOnBlur]="true"
              [preDeterminedValue]="note['assigned'].toString()"
              [isAutofocused]="true"
              [minWidth]="'400'">
            </app-typeahead>
          </td>

          <td
            class="note-heading text-center"
            [style.background-color]="
              showStatusColors ? findStatusColor(note['status'], statuses) : null
            ">
            <app-dropdown
              [selectedItem]="note['status']"
              [choices]="statuses"
              [choiceKey]="'text'"
              [showIconOnlyForSelectedItem]="true"
              [emptyChoice]="statuses[0]"
              [applyBgColor]="showStatusColors"
              (selectionChanged)="
                saveNote($event, i, 'status', note['_id']['$oid'])
              "></app-dropdown>
          </td>

          <td class="note-heading" style="width: 10rem" (click)="startEditMode(i, 'due_date')">
            <app-due-date
              [dueDate]="$any(note['due_date'])"
              (saved)="saveNote($event, i, 'due_date', note['_id']['$oid'])"></app-due-date>
          </td>
        </tr>

        <tr *ngIf="visibleNotes[note['_id']['$oid']]">
          <td colspan="4" class="note-body">
            <div class="td-wrapper">
              <ng-container *ngIf="editMode[i] !== 'to'">
                <div
                  class="font-weight-bold d-flex white-space-pre mb-2"
                  (click)="
                    startEditMode(i, 'to');
                    updateUsers(getDeptsFromNote(note['cc_departments'] || []))
                  ">
                  To:
                  <div class="font-weight-normal typeahead-closed">
                    {{ note["assigned"].join(", ") }}
                  </div>
                </div>
              </ng-container>
              <app-typeahead
                *ngIf="editMode[i] === 'to'"
                (added)="saveNote($event, i, 'assigned', note['_id']['$oid'])"
                [items$]="assignableUsernames"
                [labelString]="'To:'"
                [idString]="'artists'"
                [emitOnBlur]="true"
                [preDeterminedValue]="note['assigned'].toString()"
                [isAutofocused]="true">
              </app-typeahead>

              <ng-container *ngIf="editMode[i] !== 'cc'">
                <div
                  class="font-weight-bold d-flex white-space-pre mb-2"
                  (click)="startEditMode(i, 'cc')">
                  CC:
                  <div class="font-weight-normal typeahead-closed">
                    {{ note["cc"] ? note["cc"].join(", ") : "" }}
                  </div>
                </div>
              </ng-container>
              <app-typeahead
                *ngIf="editMode[i] === 'cc'"
                (added)="saveNote($event, i, 'cc', note['_id']['$oid'])"
                [items$]="assignableUsernamesCC"
                [labelString]="'CC:'"
                [idString]="'artists'"
                [emitOnBlur]="true"
                [preDeterminedValue]="note['cc'] ? note['cc'].toString() : ''"
                [isAutofocused]="true">
              </app-typeahead>

              <div class="mb-3">
                <span
                  *ngIf="note['media_version']"
                  class="badge badge-pill badge-primary hover-pointer"
                  (click)="changeMediaFromTag($any(note['media_version']))">
                  <!-- format it the way we format a DropdownItem that is a Media-->
                  {{ $any(note["media_version"]) | mediaToString }}
                </span>

                <ng-container *ngIf="entity.context !== 'scripts' && editMode[i] !== 'department'">
                  <span
                    *ngFor="let dept of note['cc_departments']"
                    class="badge badge-pill badge-info"
                    >{{ dept }}</span
                  >
                  <span class="hover-pointer"
                    ><i
                      class="material-icons md-18 font-weight-bold align-text-bottom"
                      (click)="startEditMode(i, 'department'); showEditDeptNote()"
                      >create</i
                    ></span
                  >
                </ng-container>

                <!-- Edit department -->
                <ng-container *ngIf="editMode[i] === 'department'">
                  <div class="form-group flex-grow-1 align-self-start">
                    <label class="on-border-of-input" for="depts">Departments:</label>
                    <div #editDepts class="d-flex flex-row flex-wrap" id="editDepts">
                      <div *ngFor="let dept of departments" class="mt-1 mr-1">
                        <div class="form-check">
                          <input
                            class="form-check-input department"
                            type="checkbox"
                            [value]="dept.text"
                            [id]="dept.text"
                            [checked]="isDeptChecked(dept.text, note['cc_departments'] || [])" />
                          <label
                            class="form-check-label"
                            [for]="dept.text"
                            >{{ dept.text }}</label
                          >
                        </div>
                      </div>

                      <button
                        type="button"
                        class="btn btn-primary btn-sm-space"
                        (click)="
                          saveNote(
                            getCheckedDepts(editDepts),
                            i,
                            'cc_departments',
                            note['_id']['$oid']
                          )
                        ">
                        Save
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary btn-sm mr-1"
                        (click)="editMode[i] = false">
                        Cancel
                      </button>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="response-wrapper d-flex flex-row align-items-start">
                <span
                  class="initials text-monospace"
                  [ngStyle]="{
                    'background-color': getInitialsColour(note['created_by']['username'])
                  }"
                  >{{ note["created_by"]["username"][0] | uppercase }}</span
                >
                <div class="d-block flex-grow-1">
                  <span class="font-weight-bold d-block username"
                    >{{ note["created_by"]["username"] }}
                    <span class="text-secondary font-weight-normal font-smaller"
                      >{{ getDateOfLastEdit(note["created_by"]).title }}
                      {{ getDateOfLastEdit(note["created_by"]).date | date : "MM/dd/yyyy hh:mma" }}
                      {{ getLastEditedBy(note["created_by"]) }}:</span
                    >
                  </span>
                  <p
                    #noteBody
                    *ngIf="editMode['edit'] !== note['_id']['$oid']"
                    class="response-body d-block"
                    (click)="noteBodyOnClick($event, note)"
                    [innerHTML]="
                      note['body']
                        | marked
                          : (note['removeDisabledAttr'] === undefined
                              ? true
                              : note['removeDisabledAttr'])
                    "></p>
                  <!-- Keep this since old notes are not in markdown-->
                  <ng-container *ngFor="let annotation_id of note['body_annotations'] || []">
                    <img
                      class="hover-pointer img-fluid annotation-thumbnail mr-1 mb-1"
                      [src]="getAnnotation(annotation_id)"
                      (click)="showAttachment(annotation_id)" />

                    <span
                      class="download"
                      (click)="handleDownloadFile(annotation_id)"
                      title="Download">
                      <i class="material-icons md-24 align-text-bottom">get_app</i>
                    </span>
                  </ng-container>
                  <!-- End Keep this-->
                  <div *ngIf="canModifyResponse(note)" class="pt-1">
                    <a
                      *ngIf="editMode['edit'] !== note['_id']['$oid']"
                      class="pr-2 text-secondary hover-pointer font-smaller"
                      (click)="editMode['edit'] = note['_id']['$oid']"
                      >edit</a
                    >
                    <a
                      *ngIf="editMode['edit'] !== note['_id']['$oid']"
                      class="pr-2 text-secondary hover-pointer font-smaller"
                      (click)="
                        willShowConfirmDelete = true; toBeDeleted = { note_id: note['_id']['$oid'] }
                      "
                      >delete this note
                    </a>

                    <div *ngIf="editMode['edit'] === note['_id']['$oid']" class="form-group">
                      <app-markdown-editor
                        #noteBodyEditor
                        [markdownText]="note['body']"
                        [canvasComponent]="canvasComponent"
                        (addedFrameAnnotations)="frameAnnotations.add($event)"
                        (viewedFrameAnnotations)="
                          viewAnnotations($event, note, $any(note['media_version']))
                        ">
                      </app-markdown-editor>
                      <div class="text-right mt-2 mr-1">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm mr-1"
                          (click)="editMode['edit'] = false">
                          Discard
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          (click)="
                            saveEdits(
                              note['_id']['$oid'],
                              noteBodyEditor.writeTextArea.nativeElement.value,
                              undefined,
                              note['frame_annotations']
                            )
                          ">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>

              <div
                *ngFor="let response of note['responses']"
                class="response-wrapper d-flex flex-row align-items-start">
                <span
                  class="initials text-monospace"
                  [ngStyle]="{ 'background-color': getInitialsColour(response['from']) }"
                  >{{ response["from"][0] | uppercase }}</span
                >
                <div class="d-block flex-grow-1">
                  <span class="font-weight-bold d-block username"
                    >{{ response["from"] }}
                    <span class="text-secondary font-weight-normal font-smaller"
                      >{{ getDateOfLastEdit(response).title }}
                      {{ getDateOfLastEdit(response).date | date : "MM/dd/yyyy hh:mma" }}
                      {{ getLastEditedBy(response) }}:</span
                    >
                  </span>
                  <p
                    #noteResponse
                    *ngIf="editMode['edit'] !== response['_id']['$oid']"
                    class="response-body d-block"
                    (click)="noteResponseOnClick($event, response, note)"
                    [innerHTML]="
                      response['deleted']
                        ? '&lt;message deleted&gt;'
                        : (response['body']
                          | marked
                            : (response['removeDisabledAttr'] === undefined
                                ? true
                                : response['removeDisabledAttr']))
                    "></p>
                  <!-- Keep this since old notes are not in markdown-->
                  <div class="d-flex flex-wrap">
                    <ng-container *ngFor="let annotation_id of response['annotation_ids'] || []">
                      <img
                        *ngIf="!response['deleted']"
                        class="hover-pointer img-fluid annotation-thumbnail mr-1 mb-1"
                        [src]="getAnnotation(annotation_id)"
                        (click)="showAttachment(annotation_id)" />
                      <span
                        class="download"
                        (click)="handleDownloadFile(annotation_id)"
                        title="Download">
                        <i class="material-icons md-24 align-text-bottom">get_app</i>
                      </span>
                    </ng-container>
                  </div>
                  <!-- End Keep this-->
                  <div *ngIf="canModifyResponse(response)" class="pt-1">
                    <a
                      *ngIf="editMode['edit'] !== response['_id']['$oid']"
                      class="pr-2 text-secondary hover-pointer font-smaller"
                      (click)="editMode['edit'] = response['_id']['$oid']"
                      >edit</a
                    >
                    <a
                      *ngIf="editMode['edit'] !== response['_id']['$oid']"
                      class="pr-2 text-secondary hover-pointer font-smaller"
                      (click)="
                        willShowConfirmDelete = true;
                        toBeDeleted = {
                          note_id: note['_id']['$oid'],
                          response_id: response['_id']['$oid']
                        }
                      "
                      >delete
                    </a>

                    <div *ngIf="editMode['edit'] === response['_id']['$oid']" class="form-group">
                      <app-markdown-editor
                        #replyBodyEditor
                        [markdownText]="response['body']"
                        [canvasComponent]="canvasComponent"
                        (addedFrameAnnotations)="frameAnnotations.add($event)"
                        (viewedFrameAnnotations)="
                          viewAnnotations($event, note, $any(note['media_version']), response)
                        ">
                      </app-markdown-editor>
                      <div
                        *ngIf="editMode['edit'] === response['_id']['$oid']"
                        class="text-right mt-2 mr-1">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-sm mr-1"
                          (click)="editMode['edit'] = false">
                          Discard
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          (click)="
                            saveEdits(
                              note['_id']['$oid'],
                              replyBodyEditor.writeTextArea.nativeElement.value,
                              response['_id']['$oid'],
                              response['frame_annotations']
                            )
                          ">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>

              <div class="form-group">
                <app-markdown-editor
                  #replyEditor
                  [canvasComponent]="canvasComponent"
                  (addedFrameAnnotations)="frameAnnotations.add($event)"
                  (viewedFrameAnnotations)="
                    viewAnnotations($event, note, $any(note['media_version']))
                  ">
                </app-markdown-editor>
              </div>

              <div class="container my-2 mx-0 text-right">
                <button
                  type="button"
                  class="btn btn-primary btn-sm align-text-bottom"
                  (click)="
                    reply(
                      replyEditor.writeTextArea.nativeElement.value,
                      note['_id']['$oid'],
                      replyEditor
                    )
                  ">
                  Reply
                </button>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<div
  *ngIf="willShowAddNote"
  [ngClass]="{ 'show d-block': willShowAddNote, modal: willShowAddNote && willShowModal }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addNoteModal"
  aria-hidden="true"
  (exited)="willShowAddNote = !$event; attachedFiles = $event ? [] : attachedFiles"
  [shouldFireExit]="willShowModal"
  appModalExit>
  <div class="modal-dialog modal-lg" [ngClass]="{ 'mt-0': !willShowModal }">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addNoteModal">
          New note for:
          {{
            entity.context === "assets" ? entity.entityCode : entity.group + " " + entity.entityCode
          }}
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="willShowAddNote = false; attachedFiles = []">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="d-flex flex-wrap">
            <div *ngIf="entity.context === 'shots'" class="form-group max-narrow-input-width">
              <label class="on-border-of-input" for="episode">Episode:</label>
              <input
                type="text"
                class="form-control on-label"
                id="episode"
                name="episode"
                [value]="entity.group" />
            </div>

            <div
              class="form-group"
              [ngClass]="{
                'max-narrow-input-width': entity.context === 'shots',
                'max-wide-input-width': entity.context !== 'shots'
              }">
              <label class="on-border-of-input" for="entity"
                >{{ entity.context.slice(0, -1) }}:</label
              >
              <input
                type="text"
                class="form-control on-label"
                id="entity"
                name="entity"
                [value]="entity.entityCode" />
            </div>

            <div class="d-flex form-group">
              <div>
                <label class="on-border-of-input" for="note-origin">Note origin:</label>
                <input
                  #noteOrigin
                  type="text"
                  class="form-control on-label"
                  id="origin"
                  name="origin"
                  [value]="selectedNoteOrigin || ''" />
              </div>
              <div class="dropdown align-self-end on-label">
                <button
                  class="btn btn-outline-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"></button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <ul>
                    <li
                      *ngFor="let origin of origins"
                      class="dropdown-item"
                      (click)="updateNoteOrigin(origin)">
                      <span>{{ origin }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="on-border-of-input" for="status">Status:</label>
              <app-dropdown
                class="on-label"
                (selectionChanged)="status = $event"
                [selectedItem]="statuses[0].text"
                [choices]="statuses"
                [choiceKey]="'text'"
                [showIconOnlyForSelectedItem]="true"
                [applyBorder]="true"></app-dropdown>
            </div>

            <div class="form-group">
              <label class="on-border-of-input" for="version">Version:</label>
              <app-dropdown
                class="text-monospace on-label"
                (selectionChanged)="onChangeVersion($event)"
                [emitChoiceObj]="true"
                [selectedItem]="mediaVersion || media[0]"
                [choices]="media || []"
                [itemTransformer]="mediaToString"
                [applyBorder]="true"
                [showDropArrow]="true"></app-dropdown>
            </div>

            <div class="form-group">
              <label class="on-border-of-input" for="due-date">Due date:</label>
              <input
                #dueDate
                class="form-control on-label"
                type="date"
                id="due-date"
                name="due-date" />
            </div>

            <div class="form-group">
              <label class="on-border-of-input" for="time">Time:</label>
              <input #dueTime class="form-control on-label" type="time" id="time" name="time" />
            </div>

            <div class="form-group flex-grow-1 align-self-start">
              <label class="on-border-of-input" for="depts">{{
                entity.context === "scripts" ? "Stage:" : "Departments:"
              }}</label>
              <div class="on-label p-2">{{ mediaVersion?.stage ?? "" }}</div>
              <div #depts class="d-flex flex-row flex-wrap on-label" id="depts">
                <div *ngFor="let dept of departments" class="mt-1 mr-1">
                  <div class="form-check">
                    <input
                      *ngIf="mediaVersion"
                      class="form-check-input department"
                      type="checkbox"
                      [value]="dept.text"
                      [id]="dept.text"
                      (change)="updateUsers(getCheckedDepts(depts))"
                      [checked]="mediaVersion.dept === dept.text" />
                    <input
                      *ngIf="!mediaVersion"
                      class="form-check-input department"
                      type="checkbox"
                      [value]="dept.text"
                      [id]="dept.text"
                      (change)="updateUsers(getCheckedDepts(depts))" />
                    <label class="on-border-of-input" class="form-check-label" [for]="dept.text">
                      {{ dept.text }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <app-typeahead
            #artists
            [preDeterminedValue]="mediaVersion ? entity[mediaVersion.dept + '_assigned'] || '' : ''"
            [items$]="assignableUsernames"
            [labelString]="'Artist(s):'"
            [idString]="'artists'"
            [applyBorderWarningOnBlur]="false"
            [showDropdownWhenAutofocused]="false"></app-typeahead>
          <app-typeahead
            #cc
            [items$]="assignableUsernamesCC"
            [labelString]="'CC:'"
            [idString]="'cc'"
            [applyBorderWarningOnBlur]="false"
            [showDropdownWhenAutofocused]="false"></app-typeahead>

          <div class="form-group">
            <app-markdown-editor
              #createNoteEditor
              [canvasComponent]="canvasComponent"
              (addedFrameAnnotations)="frameAnnotations.add($event)"
              (viewedFrameAnnotations)="viewAnnotations($event, undefined, mediaVersion)">
            </app-markdown-editor>
          </div>

          <div class="form-group text-right">
            <div class="container mt-2 mx-0">
              <button
                type="button"
                class="btn btn-primary"
                (click)="
                  add(
                    dueDate.value,
                    dueTime.value,
                    depts,
                    noteOrigin.value,
                    createNoteEditor.writeTextArea.nativeElement.value
                  )
                ">
                Create
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="willShowAddNote"
  [ngClass]="{
    'show d-block': willShowAddNote,
    'modal-backdrop': willShowAddNote && willShowModal
  }"></div>

<!-- Bigger view of attached image-->
<ng-container *ngIf="willShowAttachment">
  <div
    class="modal show d-block"
    tabindex="-1"
    role="dialog"
    aria-labelledby="attachmentModal"
    aria-hidden="true"
    (exited)="willShowAttachment = !$event"
    appModalExit>
    <button
      type="button"
      class="close-button"
      data-dismiss="modal"
      aria-label="Close"
      (click)="$event.stopPropagation(); willShowAttachment = false">
      <i class="material-icons md-24 align-text-bottom font-weight-bold">close</i>
    </button>
    <div class="container d-flex justify-content-center align-items-center" style="height: 100%">
      <img class="img-fluid" [src]="attachmentSrc" />
      <span class="download" (click)="handleDownloadFile(annotationId)" title="Download">
        <i class="material-icons md-24 align-text-bottom">get_app</i>
      </span>
    </div>
  </div>
  <div class="modal-backdrop show d-block"></div>
</ng-container>

<!-- Confirm delete-->
<ng-container *ngIf="willShowConfirmDelete">
  <div
    class="modal show d-block"
    tabindex="-1"
    role="dialog"
    aria-labelledby="confirmDelete"
    aria-hidden="true"
    (exited)="willShowConfirmDelete = !$event"
    appModalExit>
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirm Delete Note/Response</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="$event.stopPropagation(); willShowConfirmDelete = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span
            >Are you sure you want to delete this note/response? This operation cannot be
            reversed.</span
          >
          <div class="pt-2 text-right">
            <button
              type="button"
              class="btn btn-outline-secondary mr-1"
              (click)="confirmDeleteCancelOnCLick()">
              Cancel
            </button>
            <button type="button" class="btn btn-danger" (click)="delete()">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="show d-block modal-backdrop"></div>
</ng-container>
