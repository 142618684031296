<div *ngIf="role">
  <div class="d-flex flex-row align-items-flex-start flex-wrap">
    <span><i class="material-icons md-48 light">account_circle</i></span>
    <div class="d-block flex-grow-1">
      <span class="d-block">{{ role.name | titlecase }} </span>
    </div>
    <div class="d-block">
      <span *ngIf="hasAccess" class="d-block text-right"
        ><i
          class="material-icons hover-pointer secondary"
          title="Click to edit"
          (click)="edit = !edit"
          >edit</i
        ></span
      >
    </div>
  </div>
  <hr />
  <div class="d-flex flex-row align-items-center flex-wrap">
    <span class="list">Permissions:&nbsp;</span>
    <span *ngFor="let permission of role.permissions" class="badge badge-pill badge-info">
      {{ permission }}
    </span>
  </div>
  <div *ngIf="edit && hasAccess">
    <hr />
    <form #editForm>
      <span class="list">Permissions:</span>
      <div *ngFor="let permission of permissionsList" class="row list">
        <div *ngIf="isDifferentCategory(permission.category)" class="w-100">
          <strong>{{ permission.category }}</strong>
        </div>
        <div class="form-check">
          <input
            class="form-check-input permission"
            type="checkbox"
            [value]="permission.code"
            [id]="permission.code"
            [checked]="checkedPermissions[permission.code]" />
          <label class="form-check-label" [for]="permission.code">
            {{ permission.label }}
          </label>
        </div>
      </div>

      <div class="text-right">
        <button type="button" class="btn btn-primary list" (click)="save(editForm)">Save</button>
      </div>
    </form>
  </div>
</div>
