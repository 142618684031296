import { Component, OnInit, Input } from "@angular/core";

export interface Job {
  status: string;
  name: string;
}

export interface Batch {
  status: string;
  name: string;
  jobs: {
    [id: string]: Job;
  };
}

@Component({
  selector: "app-farm-status",
  templateUrl: "./farm-status.component.html",
  styleUrls: ["./farm-status.component.css"],
})
export class FarmStatusComponent implements OnInit {
  @Input() deptRenders: { [batchType: string]: Batch };

  constructor() {}

  ngOnInit() {}
}
