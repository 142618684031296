import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription, Observable, forkJoin, of } from "rxjs";
import { map } from "rxjs/operators";
import { ProjectService } from "../project.service";
import { EntityService, PlayMedia } from "../entity.service";
import { TableConfigColumnItem } from "../../types/table-config";
import { UserService } from "../user.service";
import { NoteService } from "../note.service";
import { User, GroupByDept } from "../user";
import { Entity, IEntity, Context, IMedia, IStatus } from "../entity";
import { createEmptyUser, columnTrackBy } from "../utils";

@Component({
  selector: "app-entity-detail-table-view",
  templateUrl: "./entity-detail-table-view.component.html",
  styleUrls: ["./entity-detail-table-view.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityDetailTableViewComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];
  entity: IEntity;
  columns$: Observable<TableConfigColumnItem[]>;
  columns: TableConfigColumnItem[];
  statuses$: Observable<IStatus[]>;
  users$: Observable<{ [key: string]: User[] }>;
  obs$: Observable<{
    statuses: IStatus[];
    users: { [key: string]: User[] };
    usersInProject: User[];
  }>;
  usersInProject$: Observable<User[]>;
  projectCode: string;
  context: Context;
  viewMedia: PlayMedia;
  columnTrackBy = columnTrackBy;
  openAddEntity: boolean;
  openDuplicateEntity: boolean;
  openMediaPlayer: boolean;

  constructor(
    private projectService: ProjectService,
    private entityService: EntityService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private noteService: NoteService,
    private cdr: ChangeDetectorRef
  ) {
    const parentURL = this.activatedRoute.parent?.snapshot.url;
    const urlSegments = this.activatedRoute.snapshot.url;
    this.projectCode = parentURL ? parentURL[1].path : "";
    this.context = urlSegments[0].path as Context;
    this.entity = {
      projectCode: this.projectCode,
      context: this.context,
      group: urlSegments[1].path,
      entityCode: urlSegments[2].path,
    };
  }

  ngOnInit() {
    this.columns$ = this.projectService
      .getTableConfig(this.projectCode, this.context.slice(0, this.context.length - 1))
      .pipe(map((columns) => (this.columns = columns)));
    this.statuses$ = this.entityService.getStatuses(this.projectCode, this.context);
    this.users$ = (
      this.userService.getUsersByProject(this.projectCode, this.context) as Observable<{
        [key: string]: User[];
      }>
    ).pipe(
      map((users) => {
        for (const dept of Object.keys(users)) {
          users[dept] = [createEmptyUser()].concat(users[dept]);
        }
        return users;
      })
    );

    this.usersInProject$ = this.userService.getUsersByProject(
      this.projectCode,
      this.context,
      GroupByDept.no
    ) as Observable<User[]>;

    this.obs$ = forkJoin([this.statuses$, this.users$, this.usersInProject$]).pipe(
      map((values) => {
        return { statuses: values[0], users: values[1], usersInProject: values[2] };
      })
    );

    this.subs.push(
      this.entityService.playMedia$.subscribe((viewMedia) => {
        viewMedia["media"].subscribe((media: IMedia[]) => {
          if (media.length > 0) {
            this.viewMedia = viewMedia;
            this.openMediaPlayer = true;
            this.cdr.markForCheck();
          } else {
            alert(
              `No items in ${viewMedia["src"].toLowerCase()} for ${viewMedia["entity"].toString()}.`
            );
          }
        });
      })
    );

    this.play();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  play() {
    const play = this.activatedRoute.snapshot.queryParams["play"];
    const version = this.activatedRoute.snapshot.queryParams["version"];
    const context = this.activatedRoute.snapshot.queryParams["play-ctx"];
    const src = this.activatedRoute.snapshot.queryParams["play-src"];
    const compare = this.activatedRoute.snapshot.queryParams["compare"];
    const audio = this.activatedRoute.snapshot.queryParams["audio"] || "left";
    if (!play && !version && !context && !src) {
      return;
    }

    const _entity = {
      projectCode: this.projectCode,
      context: context as Context,
      group: play.split("_")[0],
      entityCode: play.split("_").slice(1).join("_"),
    } as IEntity;
    this.entityService
      .findOne(
        this.projectCode,
        this.context as Context,
        _entity.group,
        _entity.entityCode,
        "fields=episode_code,asset_type,asset_code,shot_code,media,catalog,plate,spp,default_thumbnail&exclude_fields=_id"
      )
      .subscribe((entity) => {
        let media: IMedia[] = [];
        const mediaOrCatalog = src.toLowerCase();

        let sppMedia;
        try {
          sppMedia = entity["spp"]["plate"]["media"];
        } catch {}

        let plateMedia;
        try {
          plateMedia = entity["plate"]["media"];
        } catch {}

        if (entity && entity[mediaOrCatalog] && sppMedia) {
          // inject the spp media (if it exists) into the entity's media
          entity[mediaOrCatalog].splice(0, 0, sppMedia);
          media = entity[mediaOrCatalog];
        }

        if (entity && entity[mediaOrCatalog] && plateMedia) {
          // inject the plate media (if it exists) into the entity's media
          entity[mediaOrCatalog].splice(0, 0, plateMedia);
          media = entity[mediaOrCatalog];
        } else if (entity && entity[mediaOrCatalog] && !entity["plate"]) {
          media = entity[mediaOrCatalog];
        }

        entity = new Entity({ ...entity, projectCode: this.projectCode, context: context });
        this.noteService.getNotes(entity).subscribe((notes) => {
          this.entityService.getStatuses(this.projectCode, this.context).subscribe((statuses) => {
            // trigger for media component to open
            this.entityService.playMedia.next({
              notes: notes || [],
              media: of(media),
              selectedVersion: version,
              compare: compare,
              audio: audio,
              src: src,
              entity: entity as Entity,
              defaultThumbnail: entity["default_thumbnail"],
              statuses: statuses,
            });
          });
        });
      });
  }

  onChangeColumns(columns: { name: string }[]) {
    this.columns = [...(columns as TableConfigColumnItem[])];
  }

  onSavedColumns(columns: { name: string }[]) {
    this.userService
      .saveTableConfig(
        this.projectCode,
        this.context.slice(0, this.context.length - 1),
        columns as TableConfigColumnItem[]
      )
      .subscribe();
  }
}
