<div *ngIf="showThisPage" class="container-fluid">
  <div *ngIf="!forgotPassword" class="card w-25">
    <div class="card-body">
      <div *ngIf="error" class="alert alert-danger alert-dismissible fade show" role="alert">
        {{ error }}
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          (click)="error = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form>
        <div class="form-group">
          <label for="username">Username</label>
          <input #username type="text" class="form-control" id="username" appAutofocus />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input #password type="password" class="form-control" id="password" />
        </div>
        <div class="d-flex">
          <div class="flex-fill w-50">
            <a [routerLink]="[]" (click)="forgotPassword = true; error = false; sentEmail = false"
              >Forgot Password?</a
            >
          </div>
          <div class="flex-fill w-50 text-right">
            <button
              type="submit"
              class="btn btn-primary"
              (click)="login(username.value, password.value)">
              Login
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="forgotPassword" class="card">
    <div class="card-body">
      <div *ngIf="error" class="alert alert-danger alert-dismissible fade show" role="alert">
        {{ error }}
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          (click)="error = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="sentEmail" class="alert alert-success alert-dismissible fade show" role="alert">
        Email to reset your password has been sent.
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          (click)="sentEmail = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form>
        <div class="form-group">
          <label for="email">Email</label>
          <input #email type="email" class="form-control" id="email" appAutofocus />
        </div>
        <div class="d-flex">
          <div class="flex-fill w-50">
            <a [routerLink]="[]" (click)="forgotPassword = false; error = false; sentEmail = false"
              >Back to login</a
            >
          </div>
          <div class="flex-fill w-50 text-right">
            <button type="submit" class="btn btn-primary" (click)="sendEmail(email.value)">
              Send email
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
