import { Component, OnInit, OnDestroy, ChangeDetectorRef, OnChanges } from "@angular/core";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";

import { formatDateTime, compareDate } from "../utils";
import { MeetingNoteService, Meeting } from "../meeting-note.service";

@Component({
  selector: "app-meeting-notes",
  templateUrl: "./meeting-notes.component.html",
  styleUrls: ["./meeting-notes.component.css"],
})
export class MeetingNotesComponent implements OnInit, OnChanges, OnDestroy {
  projectCode: string;
  willShowUploadMeeting: boolean;
  openUploadMeeting: boolean;
  meetings: Meeting[];

  subs: Subscription[] = [];
  initialized: boolean = false;
  editMode: { [key: string]: string } = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private meetingService: MeetingNoteService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const parentParams = this.activatedRoute.snapshot.parent?.params;
    this.projectCode = parentParams?.projectCode;
    this.getMeetings();
  }

  ngOnChanges() {
    //Perform initialization steps that depend on valid input.
    //This can't be done in ngOnInit because sometimes the input is not ready yet.
    if (this.initialized == false && this.meetings) {
      this.initialized = true;
    }
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  getMeetings() {
    this.subs.push(
      this.meetingService.getAllMeetings(this.projectCode).subscribe((meetings) => {
        this.meetings = meetings;
        this.meetings.sort(compareDate);
        this.initialized = true;
        this.cdr.markForCheck();
      })
    );
  }

  updateMeeting(
    newValue: string,
    meetingId: string | undefined,
    field: keyof Pick<Meeting, "title" | "episode_code" | "filepath" | "created_by">,
    index: number
  ) {
    if (this.editMode[index] !== field || !meetingId) {
      return;
    }
    let toBeUpdated = {} as Meeting;
    toBeUpdated[field] = newValue;
    this.meetingService.update(this.projectCode, meetingId, toBeUpdated).subscribe(() => {
      this.meetings[index][field] = newValue;
      this.editMode[index] = "";
      this.cdr.markForCheck();
    });
  }

  startEditMode(key: number, value: string) {
    this.editMode[key] = value;
  }

  getDate(theMeeting: Meeting): string {
    return formatDateTime(theMeeting.datetime.$date);
  }

  onClose($event: string) {
    if ($event === "Submitted" || $event === "ReloadOnClose") {
      location.reload();
    }
    this.openUploadMeeting = false;
  }

  displayDataArray(dataArray: undefined | string[]): string {
    let dataString = "";
    if (dataArray !== undefined) {
      dataString = dataArray.join(", ");
    }
    return dataString;
  }
}
