import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { Entity } from "../entity";
import { EntityService } from "../entity.service";

@Component({
  selector: "app-add-child",
  templateUrl: "./add-child.component.html",
  styleUrls: ["./add-child.component.css"],
})
export class AddChildComponent implements OnInit {
  @Input() projectCode: string;
  @Input() entity: Entity;
  @Output() closed = new EventEmitter<boolean>();

  levels: string[];
  types: string[];
  episodes: string[];

  constructor(private entityService: EntityService) {}

  ngOnInit() {
    this.entityService.getAssetLevels(this.projectCode).subscribe((levels) => {
      this.levels = levels;
    });

    this.entityService.getGroups(this.projectCode, this.entity.context).subscribe((types) => {
      this.types = Object.values(types);
    });

    this.entityService
      .getAllEntitiesWithCodesOnly(this.projectCode, "episodes")
      .subscribe((episodes) => {
        this.episodes = episodes.map((episode) => {
          episode.projectCode = this.projectCode;
          episode.context = this.entity.context;
          const ep = new Entity(episode);
          return ep.toString().concat(` · ${ep["episode_name"] || "<No Name>"}`);
        });
      });
  }

  closeModal() {
    this.closed.emit(true);
  }

  shouldExit($event: boolean) {
    if ($event) {
      this.closeModal();
    }
  }
}
