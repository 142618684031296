import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { BundleService, IBundle } from "../bundle.service";
import { EntityService } from "../entity.service";
import { difference } from "../utils";

@Component({
  selector: "app-bundles",
  templateUrl: "./bundles.component.html",
  styleUrls: ["./bundles.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BundlesComponent implements OnInit {
  bundles: IBundle[];
  episodes: string[];
  projectCode: string;
  editMode: string;
  episodesToNiceNames: { [key: string]: string } = {};
  niceNamesToEpisodes: { [key: string]: string } = {};

  constructor(
    private entityService: EntityService,
    private bundleService: BundleService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const parentParams = this.activatedRoute.snapshot.parent?.params;
    this.projectCode = parentParams?.projectCode;
    this.bundleService.getAllBundles(this.projectCode).subscribe((bundles) => {
      this.bundles = bundles;

      this.entityService
        .getUnGroupedEntities(this.projectCode, "episodes", "episode_code,episode_name")
        .subscribe((episodes) => {
          this.episodes = episodes.map((e) => {
            const niceName = `${e["episode_code"]} - ${e["episode_name"]}`;
            this.niceNamesToEpisodes[niceName] = e["episode_code"];
            this.episodesToNiceNames[e["episode_code"]] = niceName;
            return niceName;
          });
          this.cdr.markForCheck();
        });
    });
  }

  add() {
    this.bundleService.add(this.projectCode).subscribe((bundle) => {
      this.bundles.push(bundle);
      alert(`Bundle #${bundle["bundle_code"]} has been created`);
      this.cdr.markForCheck();
    });
  }

  updateBundleEpisodes(i: number, episodes: string | string[] | undefined, bundleCode: string) {
    // bundle's episodes field should always be as string[]
    // but the typeahead.component.added emits a string | string[] | undefined, we ignore if its undefined/string
    if (!Array.isArray(episodes)) {
      this.editMode = "";
      this.cdr.markForCheck();
      return;
    }

    episodes = episodes.map((ep) => this.niceNamesToEpisodes[ep]).filter((ep) => ep);
    const updates = {
      remove: Array.from(difference(new Set(this.bundles[i]["episodes"]), new Set(episodes))),
      add: Array.from(difference(new Set(episodes), new Set(this.bundles[i]["episodes"]))),
    };
    this.bundleService
      .updateBundleEpisodes(this.projectCode, bundleCode, updates)
      .subscribe((eps) => {
        this.bundles[i]["episodes"] = eps["episodes"] || [];
        this.editMode = "";
        this.cdr.markForCheck();
      });
  }

  transform(episodes: string[]) {
    return episodes.map((ep) => this.episodesToNiceNames[ep]).join(", ");
  }
}
