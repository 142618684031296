<div class="card-body">
  <div class="row">
    <section class="col position-sticky">
      <div
        *ngIf="context !== 'plates'"
        class="d-flex position-relative"
        style="max-width: min-content">
        <div class="d-inline-block img-btn-container" style="width: 57px; height: 64px">
          <div class="img-btn" (click)="play(entity, notes, 'MEDIA')">View Media</div>
        </div>
        <div class="d-inline-block img-btn-container" style="width: 57px; height: 64px">
          <div class="img-btn" (click)="play(entity, notes, 'CATALOG')">View Catalog</div>
        </div>
        <img
          class="img-thumb img-behind-view-btns"
          [src]="entity | thumbnailURL"
          (click)="play(entity, notes, 'MEDIA')"
          width="114"
          height="64" />
      </div>
      <h5 class="card-title">
        {{ entity.context === "shots" ? entity.toString() : entity.entityCode }}
      </h5>

      <div class="d-flex">
        <span class="upload" (click)="openUpload = true" title="Upload">
          <i class="material-icons md-24 align-text-bottom">cloud_upload</i>
        </span>
        <span class="download" (click)="openFileBrowser = true" title="Download"
          ><i class="material-icons md-24 align-text-bottom">folder</i></span
        >
        <span class="download-renders" (click)="downloadRenders()" title="Download renders"
          ><i class="material-icons md-24 align-text-bottom">move_down</i>
          <span *ngIf="status === 'Processing'">Preparing your download, please wait.</span>
          <span *ngIf="status && progress">{{ status }} {{ progress }}%</span>
        </span>
      </div>
      <div class="my-2">
        <div *ngIf="context === 'assets'">
          <span class="font-weight-bold">Level:</span> {{ entity["asset_level"] }}
        </div>
        <div *ngIf="context === 'assets'">
          <span class="font-weight-bold">Type:</span> {{ entity["group"] }}
        </div>
        <div
          *ngIf="context === 'assets' && !inEditMode(entity.toString(), 'name')"
          title="Click to edit"
          (click)="startEditMode(entity.toString(), 'name')">
          <span class="font-weight-bold">Name:</span> {{ entity["name"] }}
        </div>
        <div *ngIf="inEditMode(entity.toString(), 'name')" class="d-flex align-items-center">
          <span class="font-weight-bold">Name:</span
          ><textarea class="form-control" [(ngModel)]="entity['name']" autofocus></textarea>
          <button
            type="button"
            class="btn btn-outline-success btn-sm"
            (click)="onSave(entity, 'name', entity['name'])">
            Save
          </button>
          <button
            type="button"
            class="btn btn-outline-danger btn-sm"
            (click)="endEditMode(entity.toString(), 'name')">
            Cancel
          </button>
        </div>

        <div
          *ngIf="context === 'assets' && !inEditMode(entity.toString(), 'description')"
          title="Click to edit"
          (click)="startEditMode(entity.toString(), 'description')">
          <span class="font-weight-bold">Description:</span> {{ entity["description"] }}
        </div>
        <div *ngIf="inEditMode(entity.toString(), 'description')" class="d-flex align-items-center">
          <span class="font-weight-bold">Description:</span
          ><textarea class="form-control" [(ngModel)]="entity['description']" autofocus></textarea>
          <button
            type="button"
            class="btn btn-outline-success btn-sm"
            (click)="onSave(entity, 'description', entity['description'])">
            Save
          </button>
          <button
            type="button"
            class="btn btn-outline-danger btn-sm"
            (click)="endEditMode(entity.toString(), 'description')">
            Cancel
          </button>
        </div>

        <span
          *ngIf="context === 'shots' && !inEditMode(entity.toString(), 'frames')"
          title="Click to edit"
          (click)="startEditMode(entity.toString(), 'frames')"
          ><span class="font-weight-bold">Frames:</span> {{ entity["frame_in"] }}-{{
            entity["frame_out"]
          }}</span>
        <div *ngIf="inEditMode(entity.toString(), 'frames')" class="d-flex align-items-center">
          <span class="font-weight-bold">Frames:</span
          ><input
            type="number"
            class="form-control w-20"
            [(ngModel)]="entity['frame_in']"
            autofocus /><span>-</span
          ><input type="number" class="form-control w-20" [(ngModel)]="entity['frame_out']" />
          <button
            type="button"
            class="btn btn-outline-success btn-sm"
            (click)="
              onSave(entity, 'frame_in', entity['frame_in']);
              onSave(entity, 'frame_out', entity['frame_out'])
            ">
            Save
          </button>
          <button
            type="button"
            class="btn btn-outline-danger btn-sm"
            (click)="endEditMode(entity.toString(), 'frames')">
            Cancel
          </button>
        </div>

        <div *ngIf="entity['media'] && entity['media'].length > 0">
          <span class="mr-2 d-block font-weight-bold"
            >Rendered:
            <span class="font-weight-normal"
              >{{formatDateTime(entity['media'][0]['upload_date']?.['$date'] || '')}} -
              {{ entity["media"][0]["submitted_by"] }}</span
            ></span
          >
          <span class="mr-2 d-block font-weight-bold"
            >Department:
            <span class="font-weight-normal">{{ entity["media"][0]["dept"] }}</span></span
          >
          <span class="mr-2 d-block font-weight-bold"
            >Comment:
            <span class="font-weight-normal">{{ entity["media"][0]["comment"] }}</span></span
          >
          <span class="mr-2 d-block font-weight-bold"
            >Version:
            <span class="font-weight-normal">{{ entity["media"][0]["version"] }}</span></span
          >
        </div>

        <div (click)="startEditMode(entity.toString(), 'tags')">
          <span class="mr-2 font-weight-bold">Tags:</span>
          <span *ngIf="!inEditMode(entity.toString(), 'tags')" title="Click to edit">{{
            (entity["tags"] || []).join(", ")
          }}</span>
          <app-typeahead
            *ngIf="inEditMode(entity.toString(), 'tags')"
            (added)="
              $event ? onSave(entity, 'tags', $event) : endEditMode(entity.toString(), 'tags')
            "
            [emitOnBlur]="true"
            [items$]="'tags' | typeaheadItems : entity.toString() : projectCode : context"
            [idString]="'tags'"
            [preDeterminedValue]="entity['tags']"
            [isSingleSelection]="false"
            [isAutofocused]="true">
          </app-typeahead>
        </div>

        <span
          class="d-block"
          [ngClass]="{
            'hover-pointer hover-underline text-primary':
              entity.children && entity.children.length > 0
          }"
          (click)="toggleViewChildren()"
          ><span class="font-weight-bold">Show Children:</span>
          {{ entity.children ? entity.children.length : 0 }}</span
        >
        <span
          class="d-block hover-pointer hover-underline text-primary"
          (click)="openAddChild = true"
          >Add Child</span
        >
        <span class="d-block hover-pointer hover-underline text-danger" (click)="delete()"
          >Delete {{ context.substring(0, context.length - 1) }}</span
        >
      </div>
    </section>

    <section class="col">
      <ul *ngFor="let col of columns" class="mb-0">
        <li class="d-flex align-items-center">
          <span class="text-right m-1" style="min-width: 8rem">{{ col.name }}</span>
          <app-dropdown
            class="m-1"
            [selectedItem]="entity[col['short-code'] + '_status']"
            [choices]="statuses"
            [choiceKey]="'text'"
            [showIconOnlyForSelectedItem]="true"
            [emptyChoice]="statuses[0]"
            [showIconOnlyForSelectedItem]="true"
            [applyBgColor]="showStatusColors"
            [callback]="save(entity, col['short-code'] + '_status')"></app-dropdown>
          <app-dropdown
            class="m-1"
            [selectedItem]="entity[col['short-code'] + '_assigned']"
            [choices]="users[col['short-code'] ?? ''] || []"
            [choiceKey]="'username'"
            [emptyChoice]="{ username: '-- unassigned --' }"
            [callback]="save(entity, col['short-code'] + '_assigned')"></app-dropdown>
        </li>
      </ul>
      <ul class="mb-0">
        <li class="d-flex align-items-center">
          <span class="text-right m-1" style="min-width: 8rem">Shot status</span>
          <app-dropdown
            class="m-1"
            [selectedItem]="entity['shot_status']"
            [choices]="statuses"
            [choiceKey]="'text'"
            [showIconOnlyForSelectedItem]="true"
            [emptyChoice]="statuses[0]"
            [showIconOnlyForSelectedItem]="true"
            [applyBgColor]="showStatusColors"
            [callback]="save(entity, 'shot_status')"></app-dropdown>
        </li>
      </ul>
    </section>

    <section class="col">
      <app-notes
        *ngIf="notes"
        [notes]="notes"
        [statuses]="statuses"
        [updatedNote$]="updatedNote$"
        [entity]="entity"
        [users]="usersInProject"
        [showStatusColors]="showStatusColors"
        (changedVersion)="play(entity, notes, 'MEDIA', $event['_id']['$oid'])"></app-notes>
    </section>
    <section class="col"></section>
    <section class="col"></section>
  </div>
</div>

<app-upload
  *ngIf="openUpload"
  [primarySoftware]="primarySoftware"
  [entity]="entity"
  [usersInProject]="usersInProject"
  (closed)="openUpload = false; $event === 'Submitted' ? reload() : null">
</app-upload>

<app-file-browser
  *ngIf="openFileBrowser"
  [projectCode]="projectCode"
  [entity]="entity"
  [allowUploadInFileBrowser]="allowUploadInFileBrowser"
  (closed)="openFileBrowser = false">
</app-file-browser>

<app-add-child
  *ngIf="openAddChild"
  [entity]="entity"
  [projectCode]="projectCode"
  (closed)="openAddChild = false">
</app-add-child>
