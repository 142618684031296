<div class="dropdown d-inline-block">
  <button
    class="btn btn-secondary dropdown-toggle"
    type="button"
    id="dropdownMenu"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    title="Drag to re-order"
    (click)="showDropdown = !showDropdown">
    Re-order Columns
  </button>
  <div *ngIf="showDropdown" class="show dropdown-menu" id="parent" aria-labelledby="dropdownMenu">
    <ng-container *ngFor="let order of ordering">
      <div
        *ngIf="order.name"
        class="dropdown-item"
        [id]="order.name"
        draggable="true"
        (dragstart)="onDragstart($event)"
        (drop)="onDrop($event)"
        (dragover)="onDragover($event)"
        (click)="$event.stopPropagation()">
        {{ order.name }}
      </div>
    </ng-container>
    <div class="container text-right">
      <button
        type="button"
        class="btn btn-primary"
        (click)="saved.emit(ordering); showDropdown = false">
        Save
      </button>
    </div>
  </div>
</div>
