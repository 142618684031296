<div *ngIf="user">
  <div class="d-flex flex-row align-items-flex-start flex-wrap">
    <span><i class="material-icons md-48 light">account_circle</i></span>
    <div class="d-block flex-grow-1" [ngClass]="{ 'text-secondary': !user.is_active }">
      <span class="d-block"
        >{{ user.firstname | titlecase }} {{ user.lastname | titlecase }}
        <i *ngIf="user.is_active" class="material-icons md-18 text-success font-weight-bold"
          >check</i
        ></span
      >
      <span class="d-block font-small">{{ user.username }}</span>
      <span class="d-block font-small">{{ user.email }}</span>
    </div>
    <div class="d-block">
      <span *ngIf="hasAccess" class="d-block text-right"
        ><i
          class="material-icons hover-pointer secondary"
          title="Click to edit"
          (click)="edit = !edit"
          >edit</i
        ></span
      >
      <span
        class="badge badge-pill d-block"
        [ngClass]="{ 'badge-danger': user.is_active, 'badge-secondary': !user.is_active }"
        >{{ user.role === undefined ? "Unassigned" : user.role }}</span
      >
    </div>
  </div>
  <hr />
  <div class="d-flex flex-row align-items-center flex-wrap">
    <span class="list">Projects: </span>
    <span
      *ngFor="let proj of getProjectsName(user['assigned_projects'] || [])"
      class="badge badge-pill"
      [ngClass]="{ 'badge-info': user.is_active, 'badge-secondary': !user.is_active }"
      >{{ proj }}</span
    >
  </div>
  <div class="d-flex flex-row align-items-center flex-wrap">
    <span class="list">Departments: </span>
    <span
      *ngFor="let dept of user.departments"
      class="badge badge-pill"
      [ngClass]="{ 'badge-success': user.is_active, 'badge-secondary': !user.is_active }"
      >{{ dept }}</span
    >
  </div>
  <div *ngIf="edit && hasAccess">
    <hr />
    <form #editForm>
      <span class="list">Projects:</span>
      <div *ngFor="let proj of projects" class="row list">
        <div class="form-check">
          <input
            class="form-check-input project"
            type="checkbox"
            [value]="proj.name"
            [id]="proj.code"
            [checked]="checkedProjects[proj.code]" />
          <label class="form-check-label" [for]="proj.code">
            {{ proj.name }}
          </label>
        </div>
      </div>

      <span class="list">Departments:</span>
      <div class="d-flex flex-row flex-wrap">
        <div *ngFor="let dept of checkedDepts | keyvalue" class="row list">
          <div class="form-check">
            <input
              class="form-check-input department"
              type="checkbox"
              [value]="dept.key"
              [id]="user.username + '-' + dept.key"
              [checked]="dept.value" />
            <label class="form-check-label" [for]="user.username + '-' + dept.key">
              {{ dept.key }}
            </label>
          </div>
        </div>
      </div>
      <span class="list">Roles:</span>
      <div *ngFor="let role of roles" class="row list">
        <div class="form-check">
          <input
            #roleInput
            class="form-check-input role"
            type="radio"
            name="roles"
            [value]="role.name"
            [id]="role.name"
            [checked]="checkedRoles[role.name]"
            (change)="selectedRole = role.name"
            required />
          <label class="form-check-label" [for]="role.name">
            {{ role.name }}
          </label>
        </div>
      </div>

      <span class="list">Account Status:</span>
      <div class="list form-check">
        <input
          class="form-check-input active"
          type="checkbox"
          [id]="'active-' + user.username"
          [checked]="user.is_active" />
        <label class="form-check-label" [for]="'active-' + user.username">Active</label>
      </div>

      <div class="text-right">
        <button type="button" class="btn btn-info list" (click)="saveAll(editForm)">
          Apply to All Selected Users
        </button>
        <button type="button" class="btn btn-primary list" (click)="save(editForm)">Save</button>
      </div>
    </form>
  </div>
</div>
