<div
  class="fade show d-block modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mediaPlayer"
  aria-hidden="true"
  appModalExit>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <div class="d-block">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="closeViewer()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body row">
        <div class="container-small-padding alert-info mb-0 mr-2">
          Please make sure to be signed into your Google account to view/edit the Spreadsheets.
          Access to certain spreadsheets have been restricted to your email address.
        </div>
        <iframe #scheduleFrame id="scheduleFrame" src=""></iframe>
      </div>
    </div>
  </div>
</div>
<div class="fade show d-block modal-backdrop"></div>
