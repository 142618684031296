<div *ngIf="userIDList$ | async as userIDList" class="container" id="parent">
  <div class="ml-4">
    <input
      class="form-check-input"
      type="checkbox"
      id="completed"
      [(ngModel)]="showCompletedTasks" />
    <label for="completed">Show completed tasks</label>
  </div>

  <ng-container *ngFor="let item of list; index as i">
    <ng-container *ngIf="isVisible(item)">
      <div
        class="card card-body d-flex flex-row align-items-center"
        [id]="i"
        (dragstart)="onDragStart($event)"
        (drop)="onDrop($event)"
        (dragover)="onDragover($event)">
        <i
          #handle
          [id]="i"
          (mousedown)="onHandleMouseEvent($event, handle, true)"
          (mouseup)="onHandleMouseEvent($event, handle, false)"
          class="mr-2 hover-move material-icons md-18 align-self-center text-secondary"
          >drag_indicator</i
        >
        <app-dropdown
          [selectedItem]="item.status"
          [choices]="statuses"
          [choiceKey]="'text'"
          (selectionChanged)="edit('status', $event, item, i)"></app-dropdown>
        <div class="border-wrap">
          <!--          TODO: not sure how to fix the type passed into choices... -->
          <app-dropdown
            [selectedItem]="item.assigned"
            [choices]="userIDList"
            [choiceKey]="'username'"
            [emptyChoice]="'-- unassigned --'"
            (selectionChanged)="edit('assigned', $event, item, i)"></app-dropdown>
        </div>

        <div *ngIf="editMode !== i" class="d-flex flex-column flex-grow-1" (click)="editMode = i">
          <label class="font-smaller m-0">Description:</label>
          <span>{{ item.name || "n/a" }}</span>
        </div>
        <div *ngIf="editMode !== i" class="d-flex flex-column" (click)="editMode = i">
          <label class="font-smaller m-0">Estimated completion:</label>
          <span *ngIf="item.completion_date">{{ item.completion_date | date }}</span>
          <span *ngIf="!item.completion_date">{{ "n/a" }}</span>
        </div>

        <div *ngIf="editMode === i" class="ml-2 d-flex input-group">
          <div class="d-flex flex-column flex-grow-1 mr-1">
            <label class="font-smaller m-0" for="description">Description:</label>
            <input
              #nameEdit
              type="text"
              class="form-control"
              id="description"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              value="{{ item.name }}"
              (keyup.enter)="edit('name', nameEdit.value, item, i)"
              (keyup.escape)="editMode = ''"
              appAutofocus />
          </div>
          <div class="d-flex flex-column mr-1">
            <label class="font-smaller m-0" for="date">Estimated completion:</label>
            <input
              #date
              class="form-control"
              type="date"
              id="date"
              name="date"
              [value]="item.completion_date" />
          </div>
          <div class="d-flex flex-column align-self-end">
            <label class="m-0"></label>
            <button
              class="input-group-text btn btn btn-outline-danger"
              id="inputGroup-sizing-default"
              (click)="$event.stopPropagation(); editMode = ''">
              Cancel
            </button>
          </div>
          <div class="d-flex flex-column align-self-end">
            <label class="m-0"></label>
            <button
              class="input-group-text btn btn btn-outline-primary"
              id="inputGroup-sizing-default"
              (click)="save(nameEdit.value, date.value, item, i)">
              Save
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="input-group mb-3">
    <div class="d-flex flex-column flex-grow-1 mr-1">
      <label class="font-smaller m-0" for="description">Description:</label>
      <input
        #newName
        type="text"
        class="form-control"
        aria-label="Sizing example input"
        aria-describedby="inputGroup-sizing-default"
        (keyup.escape)="newName.value = ''"
        appAutofocus />
    </div>
    <div class="d-flex flex-column mr-1">
      <label class="font-smaller m-0" for="date">Estimated completion:</label>
      <input
        #newDate
        class="form-control"
        type="date"
        id="date"
        name="date"
        (keyup.escape)="newDate.value = ''" />
    </div>
    <div class="d-flex flex-column align-self-end">
      <label class="m-0"></label>
      <button
        class="input-group-text btn btn btn-outline-primary"
        id="inputGroup-sizing-default"
        (click)="add(newName.value, newDate.value)">
        Add
      </button>
    </div>
  </div>
</div>
