import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
} from "@angular/core";

@Component({
  selector: "app-color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.css"],
})
export class ColorPickerComponent implements OnInit, AfterViewInit {
  canvasRef: ElementRef;
  @ViewChild("canvas") set setCanvasRef(ref: ElementRef) {
    this.canvasRef = ref;
  }
  @Output() changedColor = new EventEmitter<string>();
  @Output() okayed = new EventEmitter<void>();

  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  showColorPicker: boolean = true;
  dragging = false;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.canvas = this.canvasRef.nativeElement;
    this.context = this.canvas.getContext("2d") ?? ({} as CanvasRenderingContext2D);
    this.canvas.width = 200;
    this.canvas.height = 120;

    this.draw();
    this.context.strokeRect(0, 0, 5, 5);
  }

  draw() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.beginPath();

    let gradient = this.context.createLinearGradient(0, 0, this.canvas.width, 0);
    gradient.addColorStop(0, "rgb(255,   0,   0)");
    gradient.addColorStop(0.2, "rgb(255, 255,   0)");
    gradient.addColorStop(0.4, "rgb(0,   255,   0)");
    gradient.addColorStop(0.6, "rgb(0,   255, 255)");
    gradient.addColorStop(0.8, "rgb(0,   0,   255)");
    gradient.addColorStop(1, "rgb(255, 0,   255)");

    this.context.fillStyle = gradient;
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);

    gradient = this.context.createLinearGradient(0, 0, 0, this.canvas.height);
    gradient.addColorStop(0, "rgba(255, 255, 255, 1)");
    gradient.addColorStop(0.3, "rgba(255, 255, 255, 0)");
    gradient.addColorStop(0.7, "rgba(0,     0,   0, 0)");
    gradient.addColorStop(1, "rgba(0,     0,   0, 1)");

    this.context.fillStyle = gradient;
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }

  onMouseDown($event: MouseEvent) {
    this.draw();
    this.context.strokeRect($event.offsetX - 2.5, $event.offsetY - 2.5, 5, 5);
    this.dragging = true;
  }

  onMouseMove($event: MouseEvent) {
    if (!this.dragging) {
      return;
    }

    this.draw();
    this.context.strokeRect($event.offsetX - 2.5, $event.offsetY - 2.5, 5, 5);
    this.emitColor($event.offsetX, $event.offsetY);
  }

  onMouseUp($event: MouseEvent) {
    this.dragging = false;
    this.emitColor($event.offsetX, $event.offsetY);
  }

  emitColor(x: number, y: number) {
    const imageData = this.context.getImageData(x, y, 1, 1).data;
    const color = `rgba(${imageData[0]}, ${imageData[1]}, ${imageData[2]}, 1)`;
    this.changedColor.emit(color);
  }

  ok() {
    this.okayed.emit();
  }
}
