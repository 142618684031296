<div class="dropdown">
  <button
    #btn
    class="btn"
    type="button"
    id="dropdownMenuButton"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    [ngClass]="{
      'btn-secondary': !applyBorder,
      'btn-outline-secondary': applyBorder,
      'dropdown-toggle': showDropArrow
    }"
    (keydown.arrowdown)="$event.preventDefault(); focusedItem = arrowSelect(1, choices.length)"
    (keydown.arrowup)="$event.preventDefault(); focusedItem = arrowSelect(-1, choices.length)"
    (keydown.escape)="showDropdown = false; clearTimeoutID()"
    (keydown.enter)="$event.preventDefault(); onEnter()"
    (click)="showDropdown = !showDropdown; showDropdown ? scrollTo() : clearTimeoutID()"
    (blur)="showDropdown = false; clearTimeoutID()"
    [attr.title]="selectedItem || emptyChoice | runner : itemTransformer!"
    [style.background-color]="getColor()">
    <i class="material-icons md-18 font-weight-bold align-text-bottom">{{
      selectedItem || emptyChoice | materialIcon
    }}</i>
    <ng-container *ngIf="!showIconOnlyForSelectedItem">
      {{ selectedItem || emptyChoice | runner : itemTransformer! }}
    </ng-container>
  </button>

  <div
    *ngIf="showDropdown"
    class="show dropdown-menu"
    aria-labelledby="dropdownMenuButton"
    (mousedown)="$event.preventDefault()">
    <ul #dropdown class="p-0 m-0" [style.max-height]="maxHeight + 'px'">
      <li
        *ngFor="let choice of $any(choices); index as i"
        class="dropdown-item"
        [style.height]="liHeight + 'px'"
        [style.background-color]="i === focusedItem ? '#e9ecef' : '#fff'"
        (mousedown)="focusedItem = i; onEnter()">
        <i class="material-icons md-18 font-weight-bold align-text-bottom">{{
          choice | materialIcon
        }}</i>
        <span [ngClass]="getCssClassesFromChoice(choice)">{{
          choice | runner : itemTransformer!
        }}</span>
      </li>
    </ul>
  </div>
</div>
