<div
  *ngIf="srcType === 'video' && useAdvancedControls && frameTimestamps === undefined"
  class="alert alert-warning"
  role="alert">
  This video is still being encoded.
</div>
<div
  #overlay
  (mousemove)="onMouseMoveOverlay()"
  (keydown)="onKeyDown($event)"
  [ngClass]="{ 'idle-pointer': isFullscreen && idle }"
  id="overlay"
  tabindex="0">
  <div
    #wrapper
    [ngClass]="{
      'embed-responsive embed-responsive-16by9': srcType === 'video',
      'theater-mode': !useAdvancedControls && !isFullscreen
    }"
    style="display: grid"
    (click)="!useAdvancedControls ? togglePlayOrPause() : null">
    <img
      #rightMedia
      *ngIf="isComparing && srcType === 'image'"
      id="right-media"
      class="img-fluid m-auto text-center"
      [src]="src2"
      crossorigin="use-credentials"
      (load)="setCanvasAndClipBasedOnMediaSizes(0.5)" />
    <img
      #leftMedia
      *ngIf="srcType === 'image'"
      id="left-media"
      class="img-fluid m-auto text-center"
      [src]="src"
      crossorigin="use-credentials"
      (load)="isComparing ? setCanvasAndClipBasedOnMediaSizes(0.5) : null" />

    <video
      #rightMedia
      *ngIf="isComparing && srcType === 'video'"
      id="right-media"
      class="embed-responsive-item"
      crossorigin="use-credentials"
      [attr.loop]="isLooping ? isLooping : null"
      (loadeddata)="onLoadedRightVideo(); setCanvasAndClipBasedOnMediaSizes(0.5)">
      <source [src]="src2" type="video/webm" />
    </video>
    <video
      #leftMedia
      *ngIf="srcType === 'video'"
      id="left-media"
      class="embed-responsive-item"
      crossorigin="use-credentials"
      [attr.loop]="isLooping ? isLooping : null"
      (loadeddata)="onLoadedLeftVideo()"
      (timeupdate)="onTimeUpdateLeftVideo()">
      <source
        [src]="useAdvancedControls && frameTimestamps === undefined ? '' : src"
        type="video/webm" />
    </video>

    <canvas
      #targetCanvas
      *ngIf="useAdvancedControls"
      [ngClass]="{
        'embed-responsive-item': srcType === 'video',
        'img-fluid d-flex m-auto': srcType === 'image'
      }"></canvas>
    <canvas
      #canvas
      *ngIf="useAdvancedControls"
      [ngClass]="{
        'embed-responsive-item': srcType === 'video',
        'img-fluid d-flex m-auto': srcType === 'image'
      }"></canvas>
  </div>

  <div
    class="controls justify-content-between align-items-center d-flex"
    [ngClass]="{ 'fullscreen-controls': isFullscreen && !idle }">
    <div class="w-100" style="background: black">
      <ng-container *ngIf="useAdvancedControls">
        <input
          #clip
          [ngClass]="{ 'd-none': !isComparing, 'd-block': isComparing }"
          type="range"
          class="form-control-range m-auto"
          id="clip"
          value="1"
          min="0"
          (input)="clipMedia(clip.valueAsNumber)" />
        <ng-container *ngIf="srcType === 'video'">
          <input
            #currentFrame
            type="range"
            class="form-control-range w-100"
            id="progress-bar"
            value="1"
            min="1"
            [max]="(frameTimestamps || []).length - 1"
            (input)="
              _currentFrame = currentFrame.valueAsNumber; seek(currentFrame.valueAsNumber)
            " />
          <i
            *ngIf="!isPlaying"
            class="material-icons md-24 align-text-bottom mr-1"
            (click)="exitCanvas(); play()"
            >play_arrow</i
          >
          <i *ngIf="isPlaying" class="material-icons md-24 align-text-bottom mr-1" (click)="pause()"
            >pause</i
          >
          <input
            #userCurrentFrame
            type="number"
            class="control-number position-absolute text-right"
            style="max-width: 2.25rem"
            [value]="currentFrame.valueAsNumber"
            min="1"
            [max]="(frameTimestamps || []).length - 1"
            (change)="
              userCurrentFrame.validity.valid
                ? changeCurrentFrame(userCurrentFrame.valueAsNumber)
                : null
            " />
          <span #totalFrames class="d-inline-flex time position-relative">
            / {{ (frameTimestamps || []).length - 1 }}</span
          >
          <span class="d-inline-flex time">{{ fps }} FPS</span>
        </ng-container>
        <ng-container *ngIf="srcType === 'image'">
          <input #currentFrame type="range" class="d-none" value="1" min="1" max="1" />
        </ng-container>
        <div
          class="d-inline-flex justify-content-center align-items-center"
          [ngClass]="{ 'm-auto': srcType === 'image' }"
          [ngStyle]="{ width: srcType === 'video' ? '80%' : '100%' }">
          <input
            type="checkbox"
            class="mr-1"
            name="view-annotations"
            id="view-annotations"
            [checked]="viewAnnotations"
            (change)="viewAnnotations = !viewAnnotations; clear(); reDraw()" />
          <label class="mr-1 mb-0 p-0" for="view-annotations"
            >View annotations: {{ Object.keys(frameAnnotations || {}).length }}</label
          >
          <i
            class="material-icons md-24 align-text-bottom mx-1"
            title="Next Frame annotation"
            (click)="prevFrameAnnotation()"
            >chevron_left</i
          >
          <i
            class="material-icons md-24 align-text-bottom mx-1"
            title="Prev Frame annotation"
            (click)="nextFrameAnnotation()"
            >chevron_right</i
          >
          <app-color-picker
            [ngClass]="{ 'd-flex': showColorPicker, 'd-none': !showColorPicker }"
            (okayed)="showColorPicker = false"
            (changedColor)="onChangeColor($event)"></app-color-picker>
          <i
            class="material-icons md-24 align-text-bottom mx-1 border rounded"
            [ngClass]="{ 'border-success': mode === 'draw', 'border-black': mode !== 'draw' }"
            [ngStyle]="{ color: color ? color : 'white' }"
            (click)="annotate()"
            >create</i
          >
          <i
            class="material-icons md-24 align-text-bottom mx-1 border rounded"
            [ngClass]="{
              'border-success': mode === 'rectangle',
              'border-black': mode !== 'rectangle'
            }"
            [ngStyle]="{ color: color ? color : 'white' }"
            (click)="drawRectangle()"
            >crop_16_9</i
          >
          <span
            class="align-text-bottom mx-1 border rounded"
            [ngClass]="{ 'border-success': mode === 'erase', 'border-black': mode !== 'erase' }"
            (click)="startErase()"
            ><img src="assets/img/erase.svg"
          /></span>
          <label for="brush-size" class="p-0 m-0">Pen/Eraser size:</label>
          <input
            #brushRange
            type="range"
            class="form-control-range"
            id="brush-size"
            [value]="brushSize"
            min="1"
            max="100"
            (input)="changeBrushSize(brushRange.valueAsNumber)" />
          <input
            #brushRangeNum
            type="number"
            class="control-number"
            style="max-width: 2rem"
            [value]="brushSize"
            min="1"
            max="100"
            (change)="
              brushRangeNum.validity.valid ? changeBrushSize(brushRangeNum.valueAsNumber) : null
            " />
          <i class="material-icons md-24 align-text-bottom mx-1" (click)="undo()" title="Undo"
            >undo</i
          >
          <i class="material-icons md-24 align-text-bottom mx-1" (click)="redo()" title="Redo"
            >redo</i
          >
        </div>
      </ng-container>

      <ng-container *ngIf="!useAdvancedControls && srcType === 'video'">
        <input
          #currentFrame
          type="range"
          class="form-control-range w-100"
          id="progress-bar"
          value="1"
          min="1"
          (input)="seekByTime(currentFrame.valueAsNumber)" />
        <i
          *ngIf="!isPlaying"
          class="material-icons md-24 align-text-bottom mr-1"
          (click)="exitCanvas(); play()"
          >play_arrow</i
        >
        <i *ngIf="isPlaying" class="material-icons md-24 align-text-bottom mr-1" (click)="pause()"
          >pause</i
        >
        <span #timeOverDuration class="d-inline-flex time position-relative"></span>
      </ng-container>

      <div *ngIf="srcType === 'video'" class="d-inline-flex" style="position: absolute; right: 2%">
        <div class="form-check form-check-inline">
          <ng-container [ngSwitch]="volumeLevel">
            <i
              *ngSwitchCase="_volumeLevel.OFF"
              class="material-icons md-24 align-text-bottom"
              (click)="changeVolume(volume.valueAsNumber)"
              >volume_off</i
            >
            <i
              *ngSwitchCase="_volumeLevel.DOWN"
              class="material-icons md-24 align-text-bottom"
              (click)="changeVolume(0)"
              >volume_down</i
            >
            <i
              *ngSwitchDefault
              class="material-icons md-24 align-text-bottom"
              (click)="changeVolume(0)"
              >volume_up</i
            >
          </ng-container>
          <input
            #volume
            class="form-check-input"
            type="range"
            id="volume"
            min="0"
            max="100"
            value="100"
            (change)="changeVolume(volume.valueAsNumber)" />
        </div>
        <div *ngIf="canLoop" class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="isLooping" [(ngModel)]="isLooping" />
          <label class="form-check-label" for="isLooping">Loop</label>
        </div>
        <i
          *ngIf="isFullscreen"
          class="material-icons md-24 align-text-bottom"
          (click)="toggleFullscreen()"
          >fullscreen_exit</i
        >
        <i
          *ngIf="!isFullscreen"
          class="material-icons md-24 align-text-bottom"
          (click)="toggleFullscreen()"
          >fullscreen</i
        >
      </div>
    </div>
  </div>

  <div *ngIf="useAdvancedControls && srcType === 'video'" class="text-center">
    <span class="font-smaller"
      >Keyboard shortcuts: <i class="material-icons md-18 align-text-bottom text-body">west</i
      ><i class="material-icons md-18 align-text-bottom text-body">east</i> prev/next frame
      <i class="material-icons md-18 align-text-bottom text-body">north</i
      ><i class="material-icons md-18 align-text-bottom text-body">south</i> next/prev frame
      annotation</span
    >
  </div>
</div>
