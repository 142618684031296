import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { forkJoin } from "rxjs";
import { contentsTheSame, getUpdatedItems } from "../utils";
import { UserService } from "../user.service";

import { Role } from "../role";
import { Permission } from "../permission";

@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.css"],
})
export class RolesComponent implements OnInit {
  @Input() role: Role;
  @Input() hasAccess: boolean;
  @Input() permissions: any[]; // TODO: unused input.

  // TODO: This EventEmitter is never actually emitted!
  @Output() updatedRolePermissions = new EventEmitter<any>();

  @Output() updatedOneRole = new EventEmitter<{ role: string; permissions: string[] }>();

  checkedPermissions: { [permissionCode: string]: boolean } = {};
  edit = false;
  permissionsList: Permission[] = [];
  currentCategory: string = "";

  constructor(private userService: UserService) {}

  ngOnInit() {
    const rolePermissions = this.role.permissions || [];

    this.userService.getPermissionsList().subscribe((permissions) => {
      this.permissionsList = permissions;
      if (this.permissionsList) {
        for (const p of this.permissionsList) {
          this.checkedPermissions[p.code] = rolePermissions.includes(p.code);
        }
      }
    });
  }

  save(formElement: Element) {
    if (!this.hasAccess) {
      return;
    }

    const permissionCheckboxes = formElement.querySelectorAll("input[type=checkbox].permission");

    const updatedPermissions = getUpdatedItems(permissionCheckboxes, this.checkedPermissions);

    const rolePermissions = this.role.permissions || [];
    const requests = [];

    if (!contentsTheSame(updatedPermissions, rolePermissions)) {
      requests.push(
        this.userService.updateRole(this.role.name, { permissions: updatedPermissions })
      );
    }

    forkJoin(requests).subscribe(() => {
      this.edit = false;
      this.userService.getRoleByRolename(this.role.name).subscribe((role) => {
        this.role = role;
        this.updatedOneRole.emit({ role: this.role.name, permissions: updatedPermissions });
      });
    });
  }

  isDifferentCategory(category: string) {
    if (this.currentCategory !== category) {
      this.currentCategory = category;
      return true;
    }
    return false;
  }
}
