import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  AfterViewChecked,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";

import { fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { Mode, IPointXY, IPointStrokeRect, IFrameAnnotations } from "../canvas";

export enum VolumeLevel {
  OFF = "off",
  DOWN = "down",
  UP = "up",
}

// https://github.com/microsoft/TypeScript/issues/18756#issuecomment-332064677
// make typescript have a `PointerEvent` on `window` so we can check if the browser supports PointerEvents
declare global {
  interface Window {
    PointerEvent: typeof PointerEvent;
  }
}

@Component({
  selector: "app-canvas",
  templateUrl: "./canvas.component.html",
  styleUrls: ["./canvas.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanvasComponent
  implements OnInit, OnChanges, AfterViewChecked, AfterViewInit, OnDestroy
{
  @Input() src: string;
  @Input() src2?: string;
  @Input() srcType: "video" | "image" | "pdf";
  @Input() useAdvancedControls?: boolean;
  @Input() frameTimestamps?: number[] = [];
  @Input() isComparing?: boolean;
  @Input() activeAudio?: "left" | "right" = "left";

  canvasRef: ElementRef;
  @ViewChild("canvas") set setCanvasRef(ref: ElementRef) {
    this.canvasRef = ref;
  }
  clip: ElementRef;
  @ViewChild("clip") set setClip(ref: ElementRef) {
    this.clip = ref;
  }
  targetCanvasRef: ElementRef;
  @ViewChild("targetCanvas") set setDraftCanvasRef(ref: ElementRef) {
    this.targetCanvasRef = ref;
  }
  leftMedia: ElementRef;
  @ViewChild("leftMedia") set setVidRef(ref: ElementRef) {
    this.leftMedia = ref;
  }
  rightMedia: ElementRef;
  @ViewChild("rightMedia") set setVid2Ref(ref: ElementRef) {
    this.rightMedia = ref;
  }
  currentFrame: ElementRef;
  // Used to keep track of current frame since this.currentFrame has a max and min.
  // We need to go over this max and min so we can loop around i.e. when going next frame on max frame, we loop back to frame 1
  _currentFrame: number;
  @ViewChild("currentFrame") set _setCurrentFrame(ref: ElementRef) {
    this.currentFrame = ref;
  }
  userCurrentFrame: ElementRef;
  @ViewChild("userCurrentFrame") set _setUserCurrentFrame(ref: ElementRef) {
    this.userCurrentFrame = ref;
  }
  @ViewChild("timeOverDuration") timeOverDuration: ElementRef<HTMLSpanElement>;
  @ViewChild("wrapper", { static: true }) vidWrapper: ElementRef<HTMLDivElement>;
  @ViewChild("overlay", { static: true }) overlay: ElementRef;
  @Output() fullScreened = new EventEmitter<boolean>();

  fps: number;
  canvas: HTMLCanvasElement;
  targetCanvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  targetContext: CanvasRenderingContext2D;
  media: HTMLImageElement | HTMLVideoElement;
  pointerType: string;
  color = "white";
  isDrawing: boolean;
  isPlaying = false; // no autoplay
  isFullscreen: boolean;
  idle: boolean;
  widthBeforeFullScreen: number;
  heightBeforeFullScreen: number;
  timeoutID: number;
  secondsPerFrame: number;
  paths = [];
  points: IPointXY[] | IPointStrokeRect[] = [];
  showColorPicker: boolean;
  erasedPathIndices = [];
  brushSize: number = 5;
  canLoop: boolean = true;
  isLooping: boolean;
  _volumeLevel = VolumeLevel;
  volumeLevel: VolumeLevel = VolumeLevel.UP;
  muted: boolean = false;
  document: Document;
  mode: Mode = "draw";
  frameAnnotations: IFrameAnnotations;
  intervalID: number;
  viewAnnotations: boolean;
  prevSrcType: string;
  Object = Object;
  startingPoints: { x: number; y: number };
  longerFrames?: "left" | "right" = "left";

  constructor(private cdr: ChangeDetectorRef) {}

  addCanvasListeners() {
    this.canvas = this.canvasRef.nativeElement;
    this.targetCanvas = this.targetCanvasRef.nativeElement;
    this.context = this.canvas.getContext("2d") ?? ({} as CanvasRenderingContext2D);
    this.targetContext = this.targetCanvas.getContext("2d") ?? ({} as CanvasRenderingContext2D);

    // check if PointerEvents is supported
    if (window.PointerEvent) {
      this.canvas.addEventListener("pointerdown", ($event) => this.onMouseDown($event));
      this.canvas.addEventListener("pointermove", ($event) => this.onMouseMove($event));
      this.canvas.addEventListener("pointerup", ($event) => this.onMouseUp($event));
      this.canvas.addEventListener("pointerout", ($event) => this.onMouseUp($event));
      this.canvas.addEventListener("pointerleave", ($event) => this.onMouseUp($event));
      this.canvas.addEventListener("pointercancel", ($event) => this.onMouseUp($event));
    } else {
      this.canvas.addEventListener("mousedown", ($event) => this.onMouseDown($event));
      this.canvas.addEventListener("mousemove", ($event) => this.onMouseMove($event));
      this.canvas.addEventListener("mouseup", ($event) => this.onMouseUp($event));
      this.canvas.addEventListener("mouseleave", ($event) => this.onMouseUp($event));
    }

    this.updateCursor();
  }

  addImgAndVideoListeners() {
    if (this.srcType === "image") {
      this.media.onload = () => {
        this.canvas.width = this.leftMedia.nativeElement.clientWidth;
        this.canvas.height = this.leftMedia.nativeElement.clientHeight;
        this.targetCanvas.width = this.leftMedia.nativeElement.clientWidth;
        this.targetCanvas.height = this.leftMedia.nativeElement.clientHeight;
      };
    } else {
      this.media.onloadeddata = () => {
        this.canvas.width = this.leftMedia.nativeElement.clientWidth;
        this.canvas.height = this.leftMedia.nativeElement.clientHeight;
        this.targetCanvas.width = this.leftMedia.nativeElement.clientWidth;
        this.targetCanvas.height = this.leftMedia.nativeElement.clientHeight;
      };
    }
  }

  setMedia() {
    if (this.srcType === "image") {
      this.media = this.leftMedia.nativeElement;
    } else if (this.srcType === "video") {
      this.media =
        this.longerFrames === "left" ? this.leftMedia.nativeElement : this.rightMedia.nativeElement;
    }
  }

  ngAfterViewInit() {
    if (!this.useAdvancedControls) {
      return;
    }

    // these are only ran once
    this._currentFrame = this.currentFrame.nativeElement.valueAsNumber;
    this.addCanvasListeners();
    this.onResize();
    this.setMedia();
    this.addImgAndVideoListeners();
    this.overlay.nativeElement.focus();
  }

  ngAfterViewChecked() {
    if (!this.useAdvancedControls) {
      return;
    }

    if (this.srcType !== this.prevSrcType) {
      this.setMedia();
      this.addImgAndVideoListeners();
      this.prevSrcType = this.srcType;
    }
  }

  ngOnDestroy() {
    if (this.intervalID) {
      clearInterval(this.intervalID);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.useAdvancedControls) {
      return;
    }

    if (
      changes["srcType"] !== undefined &&
      changes["srcType"].previousValue !== changes["srcType"].currentValue
    ) {
      this.prevSrcType = changes["srcType"].previousValue;
      if (this.intervalID) {
        clearInterval(this.intervalID);
        this.intervalID = 0;
      }
    }

    if (
      changes["src"] !== undefined &&
      changes["src"].previousValue !== changes["src"].currentValue
    ) {
      if (this.srcType === "image") {
        return;
      }

      if (
        changes["frameTimestamps"] &&
        !changes["frameTimestamps"].firstChange &&
        this.frameTimestamps
      ) {
        this.fps = this.frameTimestamps[this.frameTimestamps.length - 1];
        this.secondsPerFrame = 1 / this.fps;
      }
    }

    if (
      changes["isComparing"] &&
      !changes["isComparing"].firstChange &&
      changes["isComparing"] !== undefined &&
      !changes["isComparing"].currentValue
    ) {
      this.media.style.clipPath = "";
      this.clip.nativeElement.value = this.clip.nativeElement.max;
    }

    if (
      changes["src2"] &&
      !changes["src2"].firstChange &&
      changes["src2"].previousValue !== changes["src2"].currentValue
    ) {
      this.src2 = changes["src2"].currentValue;
    }

    if (changes["activeAudio"] && this.isComparing) {
      if (changes["activeAudio"].currentValue === "left") {
        this.leftMedia.nativeElement.muted = false;
        this.rightMedia.nativeElement.muted = true;
      } else {
        this.leftMedia.nativeElement.muted = true;
        this.rightMedia.nativeElement.muted = false;
      }
    }
  }

  ngOnInit() {
    this.isLooping = this.canLoop;

    if (!this.useAdvancedControls) {
      return;
    }

    if (this.frameTimestamps) {
      this.fps = this.frameTimestamps[this.frameTimestamps.length - 1];
      this.secondsPerFrame = 1 / this.fps;
    }

    // user pressed Esc to exit fullscreen
    document.onfullscreenchange = () => {
      if (!document.fullscreenElement) {
        this.isFullscreen = false;
        this.fullScreened.emit(false);
      } else {
        this.fullScreened.emit(true);
      }

      if (!this.isFullscreen) {
        this.fullScreened.emit(false);
      }
    };

    this.prevSrcType = this.srcType;
  }

  onKeyDown($event: KeyboardEvent) {
    if (
      this.isFullscreen &&
      [" ", "ArrowLeft", "ArrowUp", "ArrowRight", "ArrowDown"].includes($event.key)
    ) {
      $event.preventDefault();
      this.idleToHideControls();
    }

    if ($event.key === " ") {
      $event.preventDefault();
      if (this.isPlaying) {
        this.exitCanvas();
      }
      this.togglePlayOrPause();
      return;
    }

    if (this.isPlaying) {
      return;
    }

    if ($event.key === "ArrowUp") {
      $event.preventDefault(); // prevents from scrolling
      this.nextFrameAnnotation();
      return;
    } else if ($event.key === "ArrowDown") {
      $event.preventDefault(); // prevents from scrolling
      this.prevFrameAnnotation();
      return;
    }

    if ($event.key === "ArrowRight") {
      $event.preventDefault(); // prevents from scrolling
      this.nextFrame();
      this.seek(this._currentFrame);
    } else if ($event.key === "ArrowLeft") {
      $event.preventDefault(); // prevents from scrolling
      this.prevFrame();
      this.seek(this._currentFrame);
    }
  }

  findNearestFrameAnnotation(frame: number, direction: "next" | "prev") {
    const frames = Object.keys(this.frameAnnotations || {});

    if (direction === "next") {
      for (let i = 0; i < frames.length; i++) {
        if (frame < parseInt(frames[i])) {
          return parseInt(frames[i]);
        } else if (frame === parseInt(frames[i])) {
          if (i + 1 < frames.length) {
            return parseInt(frames[i + 1]);
          } else {
            return parseInt(frames[0]);
          }
        } else if (i === frames.length - 1 && frame > parseInt(frames[i])) {
          return parseInt(frames[0]);
        }
      }
    } else {
      for (let i = frames.length - 1; i > -1; i--) {
        if (frame > parseInt(frames[i])) {
          return parseInt(frames[i]);
        } else if (frame === parseInt(frames[i])) {
          if (i - 1 > -1) {
            return parseInt(frames[i - 1]);
          } else {
            return parseInt(frames[frames.length - 1]);
          }
        } else if (i === 0 && frame < parseInt(frames[i])) {
          return parseInt(frames[frames.length - 1]);
        }
      }
    }
    return -1;
  }

  nextFrame() {
    if (!this.frameTimestamps) {
      return;
    }

    this.currentFrame.nativeElement.valueAsNumber += 1;
    this._currentFrame += 1;

    if (this._currentFrame === this.frameTimestamps.length) {
      this._currentFrame = 1;
      this.currentFrame.nativeElement.valueAsNumber = 1;
    }
  }

  prevFrame() {
    if (!this.frameTimestamps) {
      return;
    }

    this.currentFrame.nativeElement.valueAsNumber -= 1;
    this._currentFrame -= 1;

    if (this._currentFrame === 0) {
      this._currentFrame = this.frameTimestamps.length - 1;
      this.currentFrame.nativeElement.valueAsNumber = this._currentFrame;
    }
  }

  nextFrameAnnotation() {
    const frame = this.findNearestFrameAnnotation(
      this.currentFrame.nativeElement.valueAsNumber,
      "next"
    );
    if (frame < 0) {
      return;
    }

    this.currentFrame.nativeElement.valueAsNumber = frame;
    this._currentFrame = frame;
    this.seek(frame);
  }

  prevFrameAnnotation() {
    const frame = this.findNearestFrameAnnotation(
      this.currentFrame.nativeElement.valueAsNumber,
      "prev"
    );
    if (frame < 0) {
      return;
    }

    this.currentFrame.nativeElement.valueAsNumber = frame;
    this._currentFrame = frame;
    this.seek(frame);
  }

  annotate() {
    this.showColorPicker = !this.showColorPicker;
    this.mode = "draw";
  }

  drawRectangle() {
    this.mode = "rectangle";
  }

  exitFullscreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    this.isFullscreen = false;
  }

  toggleFullscreen() {
    if (this.isFullscreen) {
      this.exitFullscreen();
    } else {
      this.widthBeforeFullScreen = this.vidWrapper.nativeElement.clientWidth;
      this.heightBeforeFullScreen = this.vidWrapper.nativeElement.clientHeight;
      this.isFullscreen = true;

      this.overlay.nativeElement.requestFullscreen();
    }
  }

  togglePlayOrPause() {
    if (this.srcType === "video") {
      this.overlay.nativeElement.focus();
      if (this.isPlaying) {
        this.pause();
      } else {
        this.play();
      }
    }
  }

  play() {
    if (this.useAdvancedControls && this.frameTimestamps === undefined) {
      return;
    }

    if (!this.useAdvancedControls) {
      this.leftMedia.nativeElement.play();

      this.leftMedia.nativeElement.onended = () => {
        this.isPlaying = false;
        if (this.intervalID) {
          clearInterval(this.intervalID);
          this.intervalID = 0;
        }
        this.cdr.markForCheck();
      };

      this.intervalID = window.setInterval(() => {
        // we multiplied the duration by 10 so multiply this also by 10
        this.currentFrame.nativeElement.value = this.leftMedia.nativeElement.currentTime * 10;
      }, 1000 / 24);

      this.isPlaying = true;
      return;
    }

    if (this.isComparing) {
      const prev = this.longerFrames === "left" ? this.rightMedia : this.leftMedia;
      prev.nativeElement.onended = null;
    }

    const longerVideo = this.longerFrames === "left" ? this.leftMedia : this.rightMedia;
    longerVideo.nativeElement.onended = () => {
      this.isPlaying = false;
      if (this.intervalID) {
        clearInterval(this.intervalID);
        this.intervalID = 0;
      }
      this.cdr.markForCheck();
    };

    if (!this.isPlaying) {
      if (this.isComparing) {
        if (this.rightMedia.nativeElement.ended && !longerVideo.nativeElement.ended) {
          this.leftMedia.nativeElement.play();
        } else if (this.leftMedia.nativeElement.ended && !longerVideo.nativeElement.ended) {
          this.rightMedia.nativeElement.play();
        } else {
          this.leftMedia.nativeElement.play();
          this.rightMedia.nativeElement.play();
        }
      } else {
        this.leftMedia.nativeElement.play();
      }

      this.intervalID = window.setInterval(() => {
        this.setCurrentFrame(longerVideo.nativeElement.currentTime);
        this.clear();

        if (
          this.frameAnnotations &&
          this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber]
        ) {
          this.reDraw();
        }
      }, this.secondsPerFrame * 1000);

      this.isPlaying = true;
    }
  }

  pause() {
    if (!this.useAdvancedControls) {
      this.leftMedia.nativeElement.pause();
      if (this.intervalID) {
        clearInterval(this.intervalID);
        this.intervalID = 0;
      }
      this.isPlaying = false;
      return;
    }

    if (this.isPlaying) {
      // Record the current time before pause and set it after pause.
      // This fixes the issue of sometimes we are off by one if we go to the next/prev frames
      // using arrow keys
      const video = this.longerFrames === "left" ? this.leftMedia : this.rightMedia;
      const t = video.nativeElement.currentTime;

      if (this.isComparing) {
        this.rightMedia.nativeElement.pause();

        if (t > this.rightMedia.nativeElement.currentTime) {
          this.rightMedia.nativeElement.currentTime = this.rightMedia.nativeElement.duration;
        } else {
          this.rightMedia.nativeElement.currentTime = t;
        }
      }

      this.leftMedia.nativeElement.pause();
      if (t > this.leftMedia.nativeElement.currentTime) {
        this.leftMedia.nativeElement.currentTime = this.leftMedia.nativeElement.duration;
      } else {
        this.leftMedia.nativeElement.currentTime = t;
      }

      this.setCurrentFrame(t);

      if (this.intervalID) {
        clearInterval(this.intervalID);
        this.intervalID = 0;
      }

      this.isPlaying = false;
    }
  }

  setCurrentFrame(currentVideoTime: number) {
    let i = 1;
    const frameTimestamps = this.frameTimestamps ?? [];

    for (; i < frameTimestamps.length - 1; i++) {
      if (currentVideoTime < frameTimestamps[i]) {
        this.userCurrentFrame.nativeElement.value = i;
        this.currentFrame.nativeElement.value = i;
        this._currentFrame = i;
        break;
      }
    }

    if (i + 1 === frameTimestamps.length - 1) {
      this.userCurrentFrame.nativeElement.value = i + 1;
      this.currentFrame.nativeElement.value = i + 1;
      this._currentFrame = i + 1;
    }
  }

  exitCanvas() {
    if (this.srcType === "image") {
      this.media.style.display = "flex";
    } else if (this.srcType === "video") {
    }

    this.showColorPicker = false;
    this.clear();
    //this.frameAnnotations[this.currentFrame] = [];
  }

  clear() {
    if (this.context && this.targetContext) {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.targetContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
  }

  reDraw(movedPointer: boolean = false) {
    if (
      !this.viewAnnotations ||
      this.frameAnnotations === undefined ||
      this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber] === undefined
    ) {
      return;
    }

    let exclude: number = -1;
    const pointer =
      this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].paths.length -
      1 +
      this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].undoRedoPointer;

    // UNDO
    // [drawn, drawn, drawn, erased]
    //                          ^
    // [drawn, drawn, drawn, erased]
    //                  ^
    //
    if (
      movedPointer &&
      pointer + 1 <
        this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].paths.length &&
      this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].paths[pointer + 1][0][
        "mode"
      ] === "erase"
    ) {
      exclude = pointer + 1;
    }

    for (let i = 0; i <= pointer; i++) {
      if (exclude === i) {
        continue;
      }
      let mode =
        this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].paths[i][0]["mode"];
      this.targetContext.globalCompositeOperation =
        mode === "erase" ? "destination-out" : "source-over";
      this.targetContext.lineWidth =
        this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].paths[i][0][
          "brushSize"
        ];
      this.targetContext.lineJoin = "round";
      this.targetContext.lineCap = "round";
      this.targetContext.strokeStyle =
        this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].paths[i][0]["color"];

      this.targetContext.beginPath();
      if (mode === "draw" || mode === "erase") {
        let paths = this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].paths[
          i
        ] as IPointXY[];
        this.targetContext.moveTo(
          paths[0]["x"] * this.canvas.width,
          paths[0]["y"] * this.canvas.height
        );

        for (let j = 0; j < paths.length; j++) {
          this.targetContext.lineTo(
            paths[j]["x"] * this.canvas.width,
            paths[j]["y"] * this.canvas.height
          );
          this.targetContext.stroke();

          // this mimics onMouseMove
          if (j + 1 < paths.length) {
            if (paths[j + 1]["brushSize"] > paths[j]["brushSize"]) {
              this.targetContext.closePath();
              this.targetContext.beginPath();
              this.targetContext.moveTo(
                paths[j]["x"] * this.canvas.width,
                paths[j]["y"] * this.canvas.height
              );
            }

            if (paths[j + 1]["brushSize"] !== paths[j]["brushSize"]) {
              this.targetContext.lineWidth = paths[j + 1]["brushSize"];
            }
          }
        }
      } else {
        let strokeRect = (
          this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].paths[
            i
          ][0] as IPointStrokeRect
        )["strokeRect"];
        this.targetContext.moveTo(
          strokeRect.x * this.canvas.width,
          strokeRect.y * this.canvas.height
        );
        this.targetContext.strokeRect(
          strokeRect.x * this.canvas.width,
          strokeRect.y * this.canvas.height,
          strokeRect.width * this.canvas.width,
          strokeRect.height * this.canvas.height
        );
      }

      this.targetContext.stroke();
      this.targetContext.closePath();
    }

    const clip = this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].clip;
    if (clip !== undefined && this.isComparing) {
      this.clip.nativeElement.value = clip * this.clip.nativeElement.max;
      this.clipMedia(clip * this.clip.nativeElement.max);
    } else {
      this.clip.nativeElement.value = this.clip.nativeElement.max;
      this.media.style.clipPath = "";
    }
  }

  undo() {
    if (
      this.frameAnnotations === undefined ||
      this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber] === undefined
    ) {
      return;
    }

    if (
      Math.abs(
        this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].undoRedoPointer
      ) === this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].paths.length
    ) {
      return;
    }

    if (
      Math.abs(
        this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].undoRedoPointer
      ) < this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].paths.length
    ) {
      this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].undoRedoPointer--;
    }

    this.clear();
    this.reDraw(true);
  }

  redo() {
    if (
      this.frameAnnotations === undefined ||
      this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber] === undefined
    ) {
      return;
    }

    if (
      this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].undoRedoPointer === 0
    ) {
      return;
    }

    if (this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].undoRedoPointer < 0) {
      this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].undoRedoPointer++;
    }

    this.clear();
    this.reDraw(true);
  }

  startErase() {
    this.mode = "erase";
    this.showColorPicker = false;
    this.erasedPathIndices = [];
  }

  onMouseDown($event: PointerEvent | MouseEvent) {
    if (this.srcType === "video") {
      this.pause();
    }

    this.viewAnnotations = true;
    if ("pointerType" in $event) {
      this.pointerType = $event.pointerType;
    }

    if (!this.frameAnnotations) {
      this.frameAnnotations = {
        [this.currentFrame.nativeElement.valueAsNumber]: { paths: [], undoRedoPointer: 0 },
      };
    } else {
      if (this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber] === undefined) {
        this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber] = {
          paths: [],
          undoRedoPointer: 0,
        };
      }
    }

    // if user started drawing again after x undos
    // remove paths[x:paths.length] and reset the undo pointer
    if (
      Math.abs(
        this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].undoRedoPointer
      ) > 0
    ) {
      for (
        let i = 0;
        i <
        Math.abs(
          this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].undoRedoPointer
        );
        i++
      ) {
        this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].paths.pop();
      }
      this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].undoRedoPointer = 0;
    }

    // if user started drawing again after undoing everything which means the user started over
    if (
      Math.abs(
        this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].undoRedoPointer
      ) === this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].paths.length
    ) {
      this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber] = {
        paths: [],
        undoRedoPointer: 0,
      };
    }

    if (this.mode === "erase") {
      this.context.globalCompositeOperation = "destination-out";
      this.targetContext.globalCompositeOperation = "destination-out";
    } else {
      this.context.globalCompositeOperation = "source-over";
      this.targetContext.globalCompositeOperation = "source-over";
    }

    if ("pressure" in $event && this.mode !== "erase") {
      this.context.lineWidth = this.brushSize * $event["pressure"];
      this.targetContext.lineWidth = this.brushSize * $event["pressure"];
    } else {
      this.context.lineWidth = this.brushSize;
      this.targetContext.lineWidth = this.brushSize;
    }

    if (this.mode === "erase") {
      this.context.lineWidth /= 2;
      this.targetContext.lineWidth /= 2;
    }

    this.context.strokeStyle = this.color;
    this.context.lineJoin = "round";
    this.context.lineCap = "round";
    this.targetContext.strokeStyle = this.color;
    this.targetContext.lineJoin = "round";
    this.targetContext.lineCap = "round";

    this.context.beginPath();
    this.targetContext.beginPath();
    this.context.moveTo($event.offsetX, $event.offsetY);
    this.targetContext.moveTo($event.offsetX, $event.offsetY);

    this.startingPoints = { x: $event.offsetX, y: $event.offsetY };
    this.isDrawing = true;

    this.points = [] as IPointXY[];
    if (this.mode === "draw" || this.mode === "erase") {
      this.points.push({
        x: $event.offsetX / this.canvas.width,
        y: $event.offsetY / this.canvas.height,
        color: this.color,
        brushSize: this.context.lineWidth,
        mode: this.mode,
        state: "mousedown",
      });
    }
  }

  onMouseMove($event: PointerEvent | MouseEvent) {
    if (!this.isDrawing) {
      return;
    }

    if (this.mode === "draw" || this.mode === "erase") {
      (this.points as IPointXY[]).push({
        x: $event.offsetX / this.canvas.width,
        y: $event.offsetY / this.canvas.height,
        color: this.color,
        brushSize: this.context.lineWidth,
        mode: this.mode,
        state: "mousemove",
      });
      this.targetContext.lineTo($event.offsetX, $event.offsetY);
      this.targetContext.stroke();
    } else {
      const height16by9 = ((this.startingPoints.x - $event.offsetX) * 9) / 16;
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.context.strokeRect(
        this.startingPoints.x,
        this.startingPoints.y,
        -1 * (this.startingPoints.x - $event.offsetX),
        -1 * height16by9
      );
    }

    if ("pressure" in $event && this.mode === "draw") {
      const newSize = this.brushSize * $event["pressure"];

      // This fixes when pen pressure is increasing, the previous lines/strokes follow the new increased brush size.
      if (newSize > this.targetContext.lineWidth) {
        this.targetContext.closePath();
        this.targetContext.beginPath();
        this.targetContext.moveTo($event.offsetX, $event.offsetY);
      }

      this.targetContext.lineWidth = newSize;
    }
  }

  onMouseUp($event: PointerEvent | MouseEvent) {
    if (!this.isDrawing) {
      return;
    }

    if (this.mode === "draw" || this.mode === "erase") {
      (this.points as IPointXY[]).push({
        x: $event.offsetX / this.canvas.width,
        y: $event.offsetY / this.canvas.height,
        color: this.color,
        brushSize: this.context.lineWidth,
        mode: this.mode,
        state: "mouseup",
      } as IPointXY);
      this.targetContext.lineTo($event.offsetX, $event.offsetY);
      this.targetContext.stroke();
    } else {
      const height16by9 = ((this.startingPoints.x - $event.offsetX) * 9) / 16;
      (this.points as IPointStrokeRect[]).push({
        strokeRect: {
          x: this.startingPoints.x / this.canvas.width,
          y: this.startingPoints.y / this.canvas.height,
          width: (-1 * (this.startingPoints.x - $event.offsetX)) / this.canvas.width,
          height: (-1 * height16by9) / this.canvas.height,
        },
        color: this.color,
        brushSize: this.context.lineWidth,
        mode: this.mode,
        state: "mouseup",
      });
      this.targetContext.strokeRect(
        this.startingPoints.x,
        this.startingPoints.y,
        -1 * (this.startingPoints.x - $event.offsetX),
        -1 * height16by9
      );
    }

    this.context.closePath();
    this.targetContext.closePath();

    this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber].paths.push(this.points);

    if (this.isComparing) {
      this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber]["clip"] =
        this.clip.nativeElement.valueAsNumber / this.clip.nativeElement.max;
    }

    this.isDrawing = false;
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  seekByTime(time: number) {
    // value of progress bar was multiplied by 10 to make it less jarring when it moves
    this.leftMedia.nativeElement.currentTime = time / 10;
  }

  seek(frame: number) {
    if (this.frameTimestamps === undefined) {
      return;
    }

    const video = this.longerFrames === "left" ? this.leftMedia : this.rightMedia;

    if (frame > 0 && frame < this.frameTimestamps.length) {
      // set current time to the halfway point between two timestamps (avoids off by 1 error)
      video.nativeElement.currentTime = this.frameTimestamps[frame - 1] + this.secondsPerFrame / 2;
    } else if (frame >= this.frameTimestamps.length) {
      video.nativeElement.currentTime = this.frameTimestamps[0];
    } else if (frame === 0) { // I'm pretty sure this case is impossible, and never happens.
      // if we use .nativeElement.duration, we go back to frame 1
      // set's current time to NaN.
      video.nativeElement.currentTime = this.frameTimestamps[frame - 1] + this.secondsPerFrame / 2;
    }

    if (this.isComparing) {
      if (this.longerFrames === "left") {
        if (video.nativeElement.currentTime < this.rightMedia.nativeElement.duration) {
          this.rightMedia.nativeElement.currentTime = video.nativeElement.currentTime;
        } else {
          this.rightMedia.nativeElement.currentTime =
            this.rightMedia.nativeElement.duration - this.secondsPerFrame / 2;
        }
      } else {
        if (video.nativeElement.currentTime < this.leftMedia.nativeElement.duration) {
          this.leftMedia.nativeElement.currentTime = video.nativeElement.currentTime;
        } else {
          this.leftMedia.nativeElement.currentTime =
            this.leftMedia.nativeElement.duration - this.secondsPerFrame / 2;
        }
      }
    }

    this.clear();
    if (
      this.frameAnnotations &&
      this.frameAnnotations[this.currentFrame.nativeElement.valueAsNumber]
    ) {
      this.reDraw();
    }
  }

  onMouseMoveOverlay() {
    if (!this.isFullscreen) {
      return;
    }
    this.idleToHideControls();
  }

  idleToHideControls() {
    this.idle = false;

    if (this.timeoutID) {
      window.clearTimeout(this.timeoutID);
    }

    this.timeoutID = window.setTimeout(() => {
      this.idle = true;
    }, 3000);
  }

  reset() {
    this.exitCanvas();
    this.color = "white";
    this.isDrawing = false;
    this.isPlaying = false;
    this.isFullscreen = false;
    this.idle = false;
    this.widthBeforeFullScreen = 0;
    this.heightBeforeFullScreen = 0;

    this.currentFrame.nativeElement.value = 1;
    this._currentFrame = 1;
    this.frameAnnotations = {};
    // this.frameTimestamps = [];
    this.fps = 0;
    this.timeoutID = 0;
    this.showColorPicker = false;
    this.mode = "draw";

    if (this.intervalID) {
      clearInterval(this.intervalID);
      this.intervalID = 0;
    }

    if (this.isComparing) {
      this.clip.nativeElement.value = this.canvas.width / 2;
      this.leftMedia.nativeElement.style["clip-path"] = `inset(0 50% 0 0)`;
    }
  }

  onChangeColor($event: string) {
    this.color = $event;
  }

  changeVolume(volume: number) {
    if (volume > 49 && volume <= 100) {
      this.volumeLevel = this._volumeLevel.UP;
    } else if (volume > 0 && volume < 50) {
      this.volumeLevel = this._volumeLevel.DOWN;
    } else {
      this.volumeLevel = this._volumeLevel.OFF;
    }

    this.leftMedia.nativeElement.volume = volume / 100;
  }

  onResize() {
    fromEvent(window, "resize")
      .pipe(
        map((_) => {
          const longerWidth = Math.max(
            this.leftMedia.nativeElement.clientWidth,
            this.rightMedia ? this.rightMedia.nativeElement.clientWidth : 0
          );
          let height: number;
          if (longerWidth === this.leftMedia.nativeElement.clientWidth) {
            height = this.leftMedia.nativeElement.clientHeight;
          } else {
            height = this.rightMedia.nativeElement.clientHeight;
          }
          return {
            newWidth: longerWidth,
            newHeight: height,
          };
        }),
        debounceTime(250), // TODO: these may not be the most correct operators to use
        distinctUntilChanged()
      )
      .subscribe((e) => {
        this.canvas.width = e["newWidth"];
        this.canvas.height = e["newHeight"];
        this.targetCanvas.width = e["newWidth"];
        this.targetCanvas.height = e["newHeight"];

        if (this.isComparing) {
          const newClipValue =
            (this.clip.nativeElement.value / this.clip.nativeElement.max) * this.canvas.width;
          this.clip.nativeElement.max = this.canvas.width;
          this.clip.nativeElement.value = newClipValue;
          this.clip.nativeElement.style.width = `${this.clip.nativeElement.max}px`;
          const wPct = 100 - (newClipValue / this.canvas.width) * 100;
          this.leftMedia.nativeElement.style["clip-path"] = `inset(0 ${wPct}% 0 0)`;
        }

        this.reDraw();
      });
  }

  updateCursor() {
    const size = this.brushSize / 2 + 4;
    this.canvasRef.nativeElement.style.cursor = `url('data:image/svg+xml;utf8, <svg id="svg" xmlns="http://www.w3.org/2000/svg" version="1.1" width="${size}" height="${size}"> <circle cx="${
      size / 2
    }" cy="${size / 2}" r="${
      this.brushSize / 4
    }" stroke-width="2" style="stroke: black; fill: transparent;"/> <circle cx="${size / 2}" cy="${
      size / 2
    }" r="${this.brushSize / 4 - 1}" stroke-width="1" style="stroke: ${
      this.color
    }; fill: transparent;"/> <circle cx="${size / 2}" cy="${size / 2}" r="${
      this.brushSize / 4 - 2
    }" stroke-width="1" style="stroke: black; fill: transparent;"/> </svg>') ${size / 2} ${
      size / 2
    }, crosshair`;
  }

  changeCurrentFrame(frame: number) {
    this.currentFrame.nativeElement.value = frame;
    this._currentFrame = frame;
    this.seek(frame);
  }

  changeBrushSize(size: number) {
    this.brushSize = size;
    this.updateCursor();
  }

  clipMedia(w: number) {
    const wPct = 100 - (w / this.media.clientWidth) * 100;
    this.leftMedia.nativeElement.style["clip-path"] = `inset(0 ${wPct}% 0 0)`;
  }

  onLoadedRightVideo() {
    this.rightMedia.nativeElement.muted = true;
    this.leftMedia.nativeElement.pause();
  }

  onLoadedLeftVideo() {
    if (this.rightMedia) {
      this.rightMedia.nativeElement.pause();
    }

    if (!this.useAdvancedControls) {
      // multiply by 10 so that the progress bar has more 'sections' i.e. less jarring when it moves
      this.currentFrame.nativeElement.max = this.leftMedia.nativeElement.duration * 10;

      const duration = this.leftMedia.nativeElement.duration;
      const mins = Math.floor(duration / 60)
        .toString()
        .padStart(2, "0");
      const secs = Math.floor(duration % 60)
        .toString()
        .padStart(2, "0");
      this.timeOverDuration.nativeElement.innerHTML = `00:00 / ${mins}:${secs}`;
    }
  }

  onTimeUpdateLeftVideo() {
    if (!this.timeOverDuration) {
      return;
    }

    const currentTime = this.leftMedia.nativeElement.currentTime;
    const curMins = Math.floor(currentTime / 60)
      .toString()
      .padStart(2, "0");
    const curSecs = Math.floor(currentTime % 60)
      .toString()
      .padStart(2, "0");
    const re = /[0-9]{2,}:[0-9]{2} \//g;
    this.timeOverDuration.nativeElement.innerHTML =
      this.timeOverDuration.nativeElement.innerHTML.replace(re, `${curMins}:${curSecs} /`);
  }

  setCanvasAndClipBasedOnMediaSizes(clip: number) {
    this.leftMedia.nativeElement.style["clip-path"] = "";
    this.rightMedia.nativeElement.style["clip-path"] = "";

    // make the canvas's width the wider of the two medias
    const longer = Math.max(
      this.leftMedia.nativeElement.clientWidth,
      this.rightMedia.nativeElement.clientWidth
    );
    this.canvas.width = longer;
    this.targetCanvas.width = longer;
    if (longer === this.rightMedia.nativeElement.clientWidth) {
      this.canvas.height = this.rightMedia.nativeElement.clientHeight;
    } else {
      this.canvas.height = this.leftMedia.nativeElement.clientHeight;
    }
    this.targetCanvas.height = this.canvas.height;
    this.setMedia();

    /* If we want to use the media with shorter width as the max clip length
    const shorter = Math.min(this.leftMedia.nativeElement.clientWidth, this.rightMedia.nativeElement.clientWidth);
    if (shorter === this.rightMedia.nativeElement.clientWidth) {
      this.media = this.rightMedia.nativeElement;
      this.rightMedia.nativeElement.style.zIndex = -1;
      this.leftMedia.nativeElement.style.zIndex = -2;
    } else {
      this.media = this.leftMedia.nativeElement;
      this.rightMedia.nativeElement.style.zIndex = -2;
      this.leftMedia.nativeElement.style.zIndex = -1;
    }
    this.clip.nativeElement.style.width = `${shorter}px`;
    this.clip.nativeElement.max = shorter;
    */

    this.clip.nativeElement.max = this.media.clientWidth;
    this.clip.nativeElement.style.width = `${this.media.clientWidth}px`;

    if (clip > 0 && clip < 1) {
      this.clip.nativeElement.value = clip * this.clip.nativeElement.max;
      this.leftMedia.nativeElement.style["clip-path"] = `inset(0 ${clip * 100}% 0 0)`;
    }

    setTimeout(() => this.reDraw(), 0);
  }

  // this is an .onload callback
  seekClipAndUpdateAudio(clip: number, frame: number, audio: "left" | "right") {
    this.currentFrame.nativeElement.value = frame;
    this._currentFrame = frame;
    this.clip.nativeElement.max = this.canvas.width;
    this.clip.nativeElement.style.width = `${this.clip.nativeElement.max}px`;

    if (clip > 0 && clip < 1) {
      clip = clip * this.clip.nativeElement.max;
    }
    this.clip.nativeElement.value = clip;
    this.clipMedia(clip);

    if (audio === "left") {
      this.leftMedia.nativeElement.muted = false;
      this.rightMedia.nativeElement.muted = true;
    } else {
      this.leftMedia.nativeElement.muted = true;
      this.rightMedia.nativeElement.muted = false;
    }

    this.cdr.markForCheck();
  }
}
