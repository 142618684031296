import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";
import { environment } from "../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

export interface IBundle {
  bundle_code: string;
  episodes: string[];
  _id: { $oid: string };
}

@Injectable({
  providedIn: "root",
})
export class BundleService {
  constructor(private http: HttpClient) {}

  getAllBundles(projectCode: string): Observable<IBundle[]> {
    let url = `${environment.projectsURL}/${projectCode}/bundles`;
    return this.http.get<IBundle[]>(url);
  }

  add(projectCode: string): Observable<IBundle> {
    const url = `${environment.projectsURL}/${projectCode}/bundles`;
    return this.http.post<IBundle>(url, JSON.stringify({}), httpOptions);
  }

  updateBundleEpisodes(
    projectCode: string,
    bundleCode: string,
    episodes: { add: string[]; remove: string[] }
  ): Observable<IBundle> {
    const url = `${environment.projectsURL}/${projectCode}/bundles/${bundleCode}`;
    return this.http.put<IBundle>(url, JSON.stringify(episodes), httpOptions);
  }
}
