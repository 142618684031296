<div #cardsContainer *ngIf="obs$ | async as obs" class="container-fluid">
  <div class="d-flex flex-wrap align-items-center py-1">
    <div class="d-inline-block mr-4" style="width: 10.25rem">
      <app-typeahead
        #searchTypeahead
        [placeholder]="
          searchByOption === 'tags' ? 'a, b -> a or b | a + b -> a and b' : 'Search example: e0123↵'
        "
        [items$]="searchByOption | typeaheadItems : '' : projectCode : context"
        [isSingleSelection]="searchByOption === 'entityCode'"
        (added)="onSearch($event)"
        [isAutofocused]="true"
        [showDropdownWhenAutofocused]="false"
        [applyBorderWarningOnBlur]="false"
        [emitStringArrayAsString]="searchByOption === 'tags'">
      </app-typeahead>
    </div>
    <div class="d-inline-block">
      <input
        class="form-check-input"
        type="checkbox"
        name="search-tags"
        id="search-tags"
        [checked]="searchByOption === 'tags'"
        (change)="searchByOption = searchByOption === 'tags' ? 'entityCode' : 'tags'" />
      <label class="form-check-label" for="search-tags">Search tags</label>
    </div>
    <div class="ml-1 form-check">
      <input
        class="form-check-input"
        type="checkbox"
        name="show-approved"
        id="show-approved"
        [checked]="showApproved"
        (change)="onChangeShowApproved()" />
      <label class="form-check-label" for="show-approved">Show approved shots</label>
    </div>
    <app-filters
      [filters$]="filters$"
      [context]="context"
      [projectCode]="projectCode"
      (toggledFiltersOnOrOff)="onToggledFilters($event)"
      (filtered)="searchAndFilter()"></app-filters>
    <button
      type="button"
      class="btn btn-outline-primary btn-sm ml-1"
      (click)="openAddEntity = true">
      Create {{ context.substring(0, context.length - 1) | titlecase }}
    </button>
    <a class="px-2" [routerLink]="['/docs']" fragment="blender-rtracker-guide" target="_blank"
      >View guide</a
    >
  </div>

  <ng-container *ngIf="entities$ | async as entities">
    <ng-container *ngFor="let entity of entities; index as i">
      <div *ngIf="showApproved || entity['shot_status'] !== 'Approved'" class="w-100 card">
        <app-entity-card-view
          [primarySoftware]="primarySoftware"
          [allowUploadInFileBrowser]="allowUploadInFileBrowser"
          [projectCode]="projectCode"
          [context]="context"
          [entity]="entity"
          [statuses]="obs.statuses"
          [users]="obs.users"
          [usersInProject]="obs.usersInProject"
          [columns]="obs.columns"
          [inChildView]="inChildView"
          [editMode]="editMode"
          [showStatusColors]="showStatusColors">
<!--          (turnOffPlaySubscription)="onTurnOffPlaySubscription($event)">-->
        </app-entity-card-view>
      </div>
    </ng-container>
  </ng-container>

  <app-media-player
    *ngIf="openMediaPlayer && viewMedia && obs"
    [allowDownloadWorkfileInMediaPlayer]="allowDownloadWorkfileInMediaPlayer"
    [play$]="viewMedia.media"
    [entity]="viewMedia.entity"
    [notes]="viewMedia.notes"
    [mediaSource]="viewMedia.src"
    [selectedVersionID1]="viewMedia.selectedVersion"
    [selectedDefaultThumbnail]="viewMedia.defaultThumbnail"
    [statuses]="viewMedia.statuses || obs.statuses"
    [users]="obs.usersInProject"
    (closed)="openMediaPlayer = $event">
  </app-media-player>
</div>

<!-- ----TODO----
<app-child-view *ngIf="selectedEntity && !inChildView"
  [entity]="selectedEntity"
  [groupedUsers]="groupedUsers"
  [users]="users"
  [statuses]="statuses"
  [columns]="columns"
  [editMode]="editMode"
  (closed)="selectedEntity = false;">
</app-child-view>
-->

<app-add-entity
  *ngIf="openAddEntity"
  [projectCode]="projectCode"
  [context]="context"
  (closed)="openAddEntity = false; $event ? ngOnInit() : null">
</app-add-entity>
