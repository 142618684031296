import { Pipe, PipeTransform } from "@angular/core";
import { Entity, IEntity } from "./entity";

@Pipe({
  name: "isIentity",
  pure: true,
})
export class IsIentityPipe implements PipeTransform {
  transform(obj: IEntity | Entity | string): obj is IEntity {
    return (
      (obj as IEntity).asset_code !== undefined ||
      (obj as IEntity).shot_code !== undefined ||
      (obj as IEntity).plate_code !== undefined ||
      (obj as IEntity).entityCode !== undefined
    );
  }
}
