<div #tableContainer *ngIf="obs$ | async as obs" class="table-responsive">
  <ng-container *ngIf="columns$ | async">
    <div
      class="position-sticky left-0 top-0 bg-white d-flex flex-wrap py-1 align-items-center"
      id="table-options">
      <app-drag-n-drop
        [ordering]="columns"
        (changedOrder)="onChangeColumns($event)"
        (saved)="onSavedColumns($event)"></app-drag-n-drop>
      <app-display-columns
        [columns]="columns"
        (changedVisibility)="onChangeColumns($event)"
        (saved)="onSavedColumns($event)"></app-display-columns>
      <button
        type="button"
        class="btn btn-outline-primary btn-sm ml-1"
        (click)="openAddEntity = true">
        Create {{ context.substring(0, context.length - 1) | titlecase }}
      </button>
      <button
        type="button"
        class="btn btn-outline-primary btn-sm ml-1"
        (click)="openDuplicateEntity = true">
        Duplicate {{ context.substring(0, context.length - 1) | titlecase }}
      </button>
    </div>
    <table *ngIf="!openMediaPlayer" class="table table-bordered table-hover table-sm">
      <thead class="thead-light">
        <tr>
          <ng-container *ngFor="let col of columns; trackBy: columnTrackBy; index as i">
            <th
              *ngIf="col.visible"
              style="top: 50.88px"
              class="position-sticky text-center"
              [attr.colspan]="col.colspan"
              [attr.rowspan]="col.rowspan"
              [style.border]="col.styles.border"
              [style.min-width]="i === 0 ? col.styles['min-width'] : null"
              [style.max-width]="i === 0 ? col.styles['min-width'] : null"
              [style.left]="i === 0 ? 0 : null"
              [style.z-index]="i === 0 ? 120 : 100">
              {{ col.name }}
            </th>
          </ng-container>
        </tr>
        <tr>
          <ng-container *ngFor="let col of columns; trackBy: columnTrackBy">
            <ng-container *ngFor="let subcol of col.cols; trackBy: columnTrackBy; index as i">
              <th
                *ngIf="col.visible && subcol"
                class="text-center position-sticky"
                style="z-index: 100; top: 84.26px"
                [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
                [style.border-right]="
                  i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
                ">
                {{ subcol.name }}
              </th>
            </ng-container>
          </ng-container>
        </tr>
      </thead>
      <tbody *ngIf="entity">
        <tr
          style="height: 118px"
          app-entity-table-view
          [columns]="columns"
          [statuses]="obs.statuses"
          [users]="obs.users"
          [usersInProject]="obs.usersInProject"
          [_entity]="entity"
          [isIntersecting]="true"></tr>
      </tbody>
    </table>
  </ng-container>

  <app-media-player
    *ngIf="openMediaPlayer && viewMedia && obs"
    [play$]="viewMedia.media"
    [entity]="viewMedia.entity"
    [notes]="viewMedia.notes"
    [mediaSource]="viewMedia.src"
    [selectedVersionID1]="viewMedia.selectedVersion"
    [selectedVersionID2]="viewMedia.compare"
    [audio]="viewMedia.audio"
    [selectedDefaultThumbnail]="viewMedia.defaultThumbnail"
    [statuses]="obs.statuses"
    [users]="obs.usersInProject"
    (closed)="openMediaPlayer = $event">
  </app-media-player>
  <app-duplicate-entity
    *ngIf="openDuplicateEntity"
    [projectCode]="projectCode"
    [context]="context"
    (closed)="openDuplicateEntity = false"></app-duplicate-entity>
  <app-add-entity
    *ngIf="openAddEntity"
    [projectCode]="projectCode"
    [context]="context"
    (closed)="openAddEntity = false; $event ? ngOnInit() : null"></app-add-entity>
</div>
