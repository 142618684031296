<ng-container *ngIf="!editMode">
  <ng-container [ngSwitch]="dateCategory">
    <div
      *ngSwitchCase="DateTime.dateWithTime"
      style="min-width: max-content"
      (click)="editMode = true"
      title="Click to edit"
      class="">
      {{ getDueDate() | date : "MMM dd, `yy, hh:mma" }}
    </div>

    <div
      *ngSwitchCase="DateTime.dateOnly"
      style="min-width: max-content"
      (click)="editMode = true"
      title="Click to edit">
      {{ getDueDate() | date : "MMM dd, `yy" }}
    </div>

    <div
      *ngSwitchDefault
      (click)="editMode = true"
      style="height: 36px"
      title="Click to edit"></div>
  </ng-container>
</ng-container>

<input
  #date
  *ngIf="editMode"
  class="form-control"
  type="date"
  [attr.value]="transformDate()"
  appAutofocus />
<input #time *ngIf="editMode" class="form-control" type="time" [attr.value]="transformTime()" />
<button
  *ngIf="editMode"
  type="button"
  class="btn btn-outline-primary btn-sm"
  (click)="updateDate(); $event.stopPropagation()">
  Save
</button>
<button
  *ngIf="editMode"
  type="button"
  class="btn btn-outline-dark btn-sm"
  (click)="editMode = false; $event.stopPropagation()">
  Cancel
</button>
