import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from ".//app-routing.module";
import { DropdownComponent } from "./dropdown/dropdown.component";
import { DisplayColumnsComponent } from "./display-columns/display-columns.component";
import { AutofocusDirective } from "./autofocus.directive";
import { DragNDropComponent } from "./drag-n-drop/drag-n-drop.component";
import { UsersComponent } from "./users/users.component";
import { LoginComponent } from "./login/login.component";
import { AuthInterceptor } from "./auth.interceptor";
import { ProjectsComponent } from "./projects/projects.component";
import { ProjectDetailComponent } from "./project-detail/project-detail.component";
import { ProjectDetailTabComponent } from "./project-detail-tab/project-detail-tab.component";
import { EpisodeComponent } from "./episode/episode.component";
import { SearchComponent } from "./search/search.component";
import { ShotAssetListComponent } from "./shot-asset-list/shot-asset-list.component";
import { UserListComponent } from "./user-list/user-list.component";
import { AddUserComponent } from "./add-user/add-user.component";
import { ActivateAccountComponent } from "./activate-account/activate-account.component";
import { RefreshComponent } from "./refresh/refresh.component";
import { NotesComponent } from "./notes/notes.component";
import { TypeaheadComponent } from "./typeahead/typeahead.component";
import { MediaPlayerComponent } from "./media-player/media-player.component";
import { CanvasComponent } from "./canvas/canvas.component";
import { ColorPickerComponent } from "./color-picker/color-picker.component";
import { UploadComponent } from "./upload/upload.component";
import { DueDateComponent } from "./due-date/due-date.component";
import { ModalExitDirective } from "./modal-exit.directive";
import { FileBrowserComponent } from "./file-browser/file-browser.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { FiltersComponent } from "./filters/filters.component";
import { EntityContainerComponent } from "./entity-container/entity-container.component";
import { EntitiesTableViewComponent } from "./entities-table-view/entities-table-view.component";
import { EntitiesCardViewComponent } from "./entities-card-view/entities-card-view.component";
import { EntityCardViewComponent } from "./entity-card-view/entity-card-view.component";
import { EntityTableViewComponent } from "./entity-table-view/entity-table-view.component";
import { EntityDetailTableViewComponent } from "./entity-detail-table-view/entity-detail-table-view.component";
import { ChildViewComponent } from "./child-view/child-view.component";
import { AddChildComponent } from "./add-child/add-child.component";
import { SimpleChecklistComponent } from "./simple-checklist/simple-checklist.component";
import { AddEntityComponent } from "./add-entity/add-entity.component";
import { DuplicateEntityComponent } from "./duplicate-entity/duplicate-entity.component";
import { PlatesComponent } from "./plates/plates.component";
import { AddRoleComponent } from "./add-role/add-role.component";
import { RoleListComponent } from "./role-list/role-list.component";
import { RolesComponent } from "./roles/roles.component";
import { ScriptsComponent } from "./scripts/scripts.component";
import { MarkedPipe } from "./marked.pipe";
import { MarkdownEditorComponent } from "./markdown-editor/markdown-editor.component";
import { HdriComponent } from "./hdri/hdri.component";
import { ScheduleComponent } from "./schedule/schedule.component";
import { ScheduleViewerComponent } from "./schedule-viewer/schedule-viewer.component";
import { DocsComponent } from "./docs/docs.component";
import { MeetingNotesComponent } from "./meeting-notes/meeting-notes.component";
import { UploadFileComponent } from "./upload-file/upload-file.component";
import { IsIentityPipe } from "./is-ientity.pipe";
import { IntersectionObserverDirective } from "./intersection-observer.directive";
import {
  MediaToStringPipe,
  RunnerPipe,
  MaterialIconPipe,
  TypeaheadItemsPipe,
  ValueOfEntityFieldPipe,
  KeysPipe,
  EntityPipe,
  ThumbnailURLPipe,
  EntityToStringPipe,
  DepthAsPaddingPipe,
  RefImgURLPipe,
  FullCGURLPipe,
  GetLatestScriptOrStoryBoardPipe,
} from "./utils.pipe";
import { BundlesComponent } from "./bundles/bundles.component";
import { VaultLogsComponent } from "./vault-logs/vault-logs.component";
import { DatePipe } from "@angular/common";
import { RenderLayersComponent } from "./render-layers/render-layers.component";
import { ImgListWithUploadComponent } from "./img-list-with-upload/img-list-with-upload.component";
import { FarmStatusComponent } from "./farm-status/farm-status.component";
import { SwaggerApiReferenceComponent } from './swagger-api-reference/swagger-api-reference.component';
import { DocLinksPageComponent } from './doc-links-page/doc-links-page.component';
// import { SphinxDocsComponent } from './sphinx-docs/sphinx-docs.component';

@NgModule({
  declarations: [
    AppComponent,
    DropdownComponent,
    DisplayColumnsComponent,
    AutofocusDirective,
    DragNDropComponent,
    UsersComponent,
    LoginComponent,
    ProjectsComponent,
    ProjectDetailComponent,
    ProjectDetailTabComponent,
    EpisodeComponent,
    SearchComponent,
    ShotAssetListComponent,
    UserListComponent,
    AddUserComponent,
    ActivateAccountComponent,
    RefreshComponent,
    NotesComponent,
    TypeaheadComponent,
    MediaPlayerComponent,
    CanvasComponent,
    ColorPickerComponent,
    UploadComponent,
    DueDateComponent,
    ModalExitDirective,
    FileBrowserComponent,
    NotFoundComponent,
    FiltersComponent,
    EntityContainerComponent,
    EntitiesTableViewComponent,
    EntitiesCardViewComponent,
    EntityCardViewComponent,
    EntityTableViewComponent,
    EntityDetailTableViewComponent,
    ChildViewComponent,
    AddChildComponent,
    SimpleChecklistComponent,
    AddEntityComponent,
    DuplicateEntityComponent,
    PlatesComponent,
    AddRoleComponent,
    RoleListComponent,
    RolesComponent,
    ScriptsComponent,
    MarkedPipe,
    MarkdownEditorComponent,
    HdriComponent,
    ScheduleComponent,
    ScheduleViewerComponent,
    DocsComponent,
    MeetingNotesComponent,
    UploadFileComponent,
    IsIentityPipe,
    IntersectionObserverDirective,
    TypeaheadItemsPipe,
    RunnerPipe,
    MaterialIconPipe,
    MediaToStringPipe,
    ValueOfEntityFieldPipe,
    KeysPipe,
    EntityPipe,
    ThumbnailURLPipe,
    EntityToStringPipe,
    DepthAsPaddingPipe,
    RefImgURLPipe,
    FullCGURLPipe,
    GetLatestScriptOrStoryBoardPipe,
    BundlesComponent,
    VaultLogsComponent,
    RenderLayersComponent,
    ImgListWithUploadComponent,
    FarmStatusComponent,
    SwaggerApiReferenceComponent,
    DocLinksPageComponent,
    // SphinxDocsComponent,
  ],
  imports: [BrowserModule, HttpClientModule, AppRoutingModule, FormsModule, ReactiveFormsModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    IsIentityPipe,
    TypeaheadItemsPipe,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
