import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DepartmentService {
  constructor(private http: HttpClient) {}

  getAllDepartments(): Observable<string[]> {
    return this.http.get<string[]>(environment.deptsURL);
  }

  getAssetDepartments(projectCode: string): Observable<string[]> {
    const url = `${environment.projectsURL}/${projectCode}/departments/assets`;
    return this.http.get<string[]>(url);
  }

  getShotDepartments(projectCode: string): Observable<string[]> {
    const url = `${environment.projectsURL}/${projectCode}/departments/shots`;
    return this.http.get<string[]>(url);
  }
}
