import { Injectable } from "@angular/core";
import { HttpResponse, HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";

import { environment } from "../environments/environment";
import { UserToken } from "./user";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // observable boolean sources
  private isLoggedInSource = new BehaviorSubject<boolean>(false);

  // observable boolean streams
  isLoggedIn$ = this.isLoggedInSource.asObservable();

  loggedInResolved = new BehaviorSubject<boolean>(false);
  loggedInResolved$ = this.loggedInResolved.asObservable();

  redirectUrl: string;

  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<{ user: string }> {
    const toEncode = `${username}:${password}`;
    const encodedData = btoa(toEncode);

    const httpOptions = {
      headers: new HttpHeaders({ Authorization: `Basic ${encodedData}` }),
    };

    return this.http.post<{ user: string }>(environment.loginURL, {}, httpOptions);
  }

  getUserRole(): string {
    return UserToken._decodeToken()["role"];
  }

  toggleLogin(state: boolean) {
    this.isLoggedInSource.next(state);
  }

  logout() {
    return this.http.get(environment.logoutURL);
  }

  sendResetPasswordMail(email: string): Observable<{ message: string }> {
    const _email = { email: email };
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.post<{ message: string }>(environment.forgotPasswordURL, _email, httpOptions);
  }

  checkLoggedIn(): Observable<HttpResponse<any>> {
    return this.http.get<HttpResponse<any>>(environment.loginURL, { observe: "response" });
  }
}
