<div class="container-fluid table-responsive">
  <div class="flex-grow-1">
    <button
      type="button"
      class="btn btn-outline-primary btn-sm ml-1"
      (click)="openUploadMeeting = true">
      Upload Meeting Notes
    </button>
  </div>
  <div style="text-align: right">
    <div>
      <app-refresh></app-refresh>
    </div>
  </div>

  <!--=========================== Meetings list ======================================-->
  <table class="table table-bordered table-hover table-sm w-auto">
    <thead class="thead-light">
      <tr>
        <th>Meeting Title</th>
        <th>Episode Code</th>
        <th>Uploaded file</th>
        <th style="width: 10%">Created by</th>
        <th style="width: 10%">Date</th>
        <th style="width: 17%">Skipped Shot Codes</th>
      </tr>
    </thead>
    <tbody *ngIf="initialized && meetings.length > 0; else placeholder">
      <tr *ngFor="let meeting of meetings; let i = index">
        <td id="meeting-title">
          <span class="d-flex">
            <span
              *ngIf="editMode[i] !== 'title'"
              class="font-weight-normal px-1"
              title="Click to Edit"
              style="cursor: text"
              (click)="startEditMode(i, 'title')">
              {{ meeting["title"] }}
            </span>
            <input
              #thisInput
              *ngIf="editMode[i] === 'title'"
              class="form-control meeting-title"
              type="text"
              style="width: 220px"
              [ngModel]="meeting['title']"
              [placeholder]="'<Click to Edit>'"
              (keyup.enter)="updateMeeting(thisInput.value, meeting._id?.$oid, 'title', i)"
              (blur)="updateMeeting(thisInput.value, meeting._id?.$oid, 'title', i)"
              appAutofocus />
          </span>
        </td>
        <td>
          {{ meeting["episode_code"] }}
        </td>
        <td>
          {{ meeting["filepath"].split("/").pop() }}
        </td>
        <td>
          {{ meeting["created_by"] }}
        </td>
        <td>
          {{ getDate(meeting) }}
        </td>
        <td>
          {{ displayDataArray(meeting["invalid_shot_code"]) }}
        </td>
      </tr>
    </tbody>

    <ng-template #placeholder>
      <!-- this will show Fetching data after the table headings are rendered -->
      <tbody>
        <tr>
          {{
            meetings === undefined ? "Fetching data..." : "No meetings found."
          }}
        </tr>
      </tbody>

      <tbody></tbody
    ></ng-template>
  </table>
</div>

<app-upload-file
  *ngIf="openUploadMeeting"
  [context]="'meeting-notes'"
  [projectCode]="projectCode"
  (closed)="onClose($event)">
</app-upload-file>
