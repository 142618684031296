import { tap } from "rxjs/operators";
import { UserService } from "./user.service";
import { createEmptyUser } from "./utils";
import { Project } from "./project";

// Where is filter in this?
export interface User {
  email: string;
  username: string;
  firstname: string;
  lastname: string;
  role: string;
  profile_picture: string;
  is_admin: boolean;
  projects: Project[];
  assigned_projects: string[];
  departments: string[];
  is_active: boolean;
  is_dev: boolean;
  initials_colour?: string;
}

export enum GroupByDept {
  no = "false",
  yes = "true",
}

export enum AddEmptyUser {
  no,
  yes,
}

export class UserMixin {
  users: User[];
  usersInProject: User[];
  filteredUsers: User[];
  groupedUsers = {};
  userService: UserService;

  // getUsers(): void {
  //   this.userService.getUsers().subscribe((users) => {
  //     this.users = users;
  //     this.filteredUsers = [...users];
  //   });
  // }

  getUsersByProject(
    projectCode: string,
    context: string,
    groupByDept = GroupByDept.no,
    addEmptyUser = AddEmptyUser.no
  ) {
    return this.userService.getUsersByProject(projectCode, context, groupByDept).pipe(
      tap((users) => {
        if (groupByDept === GroupByDept.yes && !Array.isArray(users)) {
          if (addEmptyUser) {
            for (const dept of Object.keys(users)) {
              users[dept] = [createEmptyUser()].concat(users[dept]);
            }
          }
          this.groupedUsers = users;
        } else {
          if (addEmptyUser) {
            if (context === "") {
              this.usersInProject = [createEmptyUser()].concat(users as User[]);
            } else {
              this.users = [createEmptyUser()].concat(users as User[]);
            }
          } else {
            if (context === "") {
              this.usersInProject = users as User[];
            } else {
              this.users = users as User[];
            }
          }
        }
      })
    );
  }

  static getUsersInDept(users: User[], dept: string): User[] {
    let usersInDept = [] as User[];

    for (const user of users) {
      if (dept === "plt") {
        if (
          user.departments.includes(dept) ||
          user.departments.includes("cmp") ||
          user.departments.includes("art")
        ) {
          usersInDept.push(user);
        }
      } else {
        if (user.departments.includes(dept)) {
          usersInDept.push(user);
        }
      }
    }

    return usersInDept;
  }
}

export class UserToken {
  static storeToken(value: string) {
    localStorage.setItem("user", value);
  }

  static removeToken() {
    localStorage.removeItem("user");
  }

  static retrieveToken(): string | null {
    return localStorage.getItem("user");
  }

  static _decodeToken() {
    const token = this.retrieveToken();
    if (token) {
      const encodedPayload = atob(token.split(".")[1]);
      return JSON.parse(encodedPayload);
    }
    return {};
  }

  static getUsername() {
    return this._decodeToken()["username"];
  }
}
