<div class="container-fluid">
  <div *ngIf="success" class="alert alert-success alert-dismissible" role="alert">
    <div class="text-center"><strong>Update summary:</strong></div>
    <div><strong>Roles:</strong> {{ updatedRoles }}</div>
    <div><strong>Permissions:</strong> {{ updatedPermissions }}</div>
    <button
      type="button"
      class="close"
      data-dismiss="alert"
      aria-label="Close"
      (click)="success = false">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="container">
    <div id="leftbox">
      <div class="card w-100" *ngFor="let role of roles">
        <div class="card-body">
          <app-roles
            [role]="role"
            [hasAccess]="hasAccess"
            [permissions]="permissionsList"
            (updatedRolePermissions)="setChanges($event)"
            (updatedOneRole)="setChanges($event)">
          </app-roles>
        </div>
      </div>
    </div>
    <div id="rightbox">
      <div *ngIf="hasAccess">
        <app-add-role (createdRole)="setNewRole($event)"></app-add-role>
      </div>
    </div>
  </div>
</div>
