import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
//import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from "../environments/environment";
import MongoDocument from "./mongo-document";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

export interface ISchedule extends MongoDocument {
  schedule_name: string;
  schedule_link: string;
  created_by: string;
  datetime: { $date: string };
}

export type ScheduleToBeAdded = Omit<ISchedule, "datetime" | "_id">;

@Injectable({
  providedIn: "root",
})
export class ScheduleService {
  constructor(private http: HttpClient) {}

  addSchedule(projectCode: string, scheduleObject: ScheduleToBeAdded): Observable<ISchedule> {
    const url = `${environment.projectsURL}/${projectCode}/schedule`;
    return this.http.post<ISchedule>(url, JSON.stringify(scheduleObject), httpOptions);
  }

  getAllSchedules(projectCode: string): Observable<ISchedule[]> {
    const url = `${environment.projectsURL}/${projectCode}/schedule`;
    return this.http.get<ISchedule[]>(url);
  }

  update(
    projectCode: string,
    scheduleId: string,
    itemToBeUpdated: Partial<ISchedule>
  ): Observable<ISchedule> {
    const url = `${environment.projectsURL}/${projectCode}/schedule/${scheduleId}`;
    return this.http.put<ISchedule>(url, JSON.stringify(itemToBeUpdated), httpOptions);
  }

  deleteSchedule(projectCode: string, scheduleId: string) {
    const url = `${environment.projectsURL}/${projectCode}/schedule/${scheduleId}/delete`;
    return this.http.delete<number>(url);
  }
}
