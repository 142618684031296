import { Component, OnInit, Input } from "@angular/core";
//import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges } from '@angular/core';

import { Entity } from "../entity";
import { HdriService } from "../hdri.service";

@Component({
  selector: "app-hdri",
  templateUrl: "./hdri.component.html",
  styleUrls: ["./hdri.component.css"],
})
export class HdriComponent implements OnInit {
  @Input() hdris: Entity[];
  @Input() projectCode: string;

  loaded = false;
  willShowLargeImage: boolean;
  displayImageSrc = "";

  constructor(private hdriService: HdriService) {}

  ngOnInit() {}

  getThumbnail(hdri: Entity): string {
    return this.hdriService.getThumbnailURL(this.projectCode, hdri.entityCode);
  }

  showLargeImage(hdri: Entity) {
    this.displayImageSrc = this.hdriService.getImageURL(this.projectCode, hdri.entityCode);
    this.willShowLargeImage = true;
  }
}
