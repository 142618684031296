<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <h4 class="card-title text-center">{{ title }}</h4>
      <h5 *ngIf="subtitle" class="card-title text-center">{{ subtitle }}</h5>
      <div *ngIf="activated" class="container alert alert-success" role="alert">
        {{ message }}. You may now login using your username and password. You will be redirected
        shortly.
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div *ngIf="!validated" class="container alert alert-danger" role="alert">
        Minimum password length is 8 characters.
      </div>

      <div *ngIf="!confirmed" class="container alert alert-danger" role="alert">
        Passwords must match.
      </div>

      <form>
        <div class="form-group">
          <label for="password">Password (minimum 8 characters)</label>
          <input
            #password
            type="password"
            class="form-control"
            (keyup)="validate(password.value)"
            [ngClass]="{
              'border border-danger': !validated,
              'border border-success': validated,
              'border border-dark': activated
            }"
            id="password" />
        </div>
        <div class="form-group">
          <label for="confirm">Confirm Password</label>
          <input
            #confirm
            type="password"
            class="form-control"
            (keyup)="validateConfirmPassword(confirm.value, password.value)"
            [ngClass]="{
              'border border-danger': !confirmed,
              'border border-success': confirmed,
              'border border-dark': activated
            }"
            id="confirm" />
        </div>

        <button
          type="button"
          class="btn btn-primary"
          (click)="activate(password.value); password.value = ''; confirm.value = ''">
          Submit
        </button>
      </form>
    </div>
  </div>
</div>
