import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  Input,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription, Observable, forkJoin, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { IntersectionObserverService } from "../intersection-observer.service";
import { ProjectService } from "../project.service";
import { QueryParamService } from "../query-param.service";
import { EntityService, PlayMedia } from "../entity.service";
import { TableConfigColumnItem } from "../../types/table-config";
import { Filters } from "../../types/filters";
import { UserService } from "../user.service";
import { NoteService } from "../note.service";
import { NavigationService } from "../navigation.service";
import { User, GroupByDept } from "../user";
import { Entity, IEntity, Context, IMedia, IStatus } from "../entity";
import { createEmptyUser, entityTrackBy, columnTrackBy } from "../utils";
import { TypeaheadComponent } from "../typeahead/typeahead.component";

@Component({
  selector: "app-entities-table-view",
  templateUrl: "./entities-table-view.component.html",
  styleUrls: ["./entities-table-view.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntitiesTableViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() allowUploadInFileBrowser: boolean;
  @Input() allowDownloadWorkfileInMediaPlayer: boolean;

  subs: Subscription[] = [];
  entities$: Observable<IEntity[]>;
  entities: IEntity[];
  columns$: Observable<TableConfigColumnItem[]>;
  columns: TableConfigColumnItem[];
  statuses$: Observable<IStatus[]>;
  users$: Observable<{ [key: string]: User[] }>;
  obs$: Observable<{
    statuses: IStatus[];
    users: { [key: string]: User[] };
    usersInProject: User[];
  }>;
  usersInProject$: Observable<User[]>;
  projectCode: string;
  context: Context;
  viewMedia: PlayMedia;
  showOOP: boolean;
  showCompleted: boolean;
  inShotsByEpisodePage: boolean;
  openMediaPlayer: boolean;

  searchTypeahead: TypeaheadComponent;

  @ViewChild("searchTypeahead") set setSearchTypeahead(ref: TypeaheadComponent) {
    this.searchTypeahead = ref;
  }

  @ViewChildren("entityRows") entityRows: QueryList<ElementRef>;

  expanded: Set<string> = new Set<string>();
  editMode: { [key: string]: string } = {};

  searchByOption: "tags" | "entityCode" | "";
  searching: boolean;

  filters$: Observable<Filters>;
  isFiltersEnabled: boolean;

  entityTrackBy = entityTrackBy;
  columnTrackBy = columnTrackBy;

  @ViewChild("tableContainer", { static: true }) tableContainer: ElementRef;
  scrollPosition: { x: number; y: number } = { x: 0, y: 0 };
  timeoutID: number;

  showStatusColors = true;

  openAddEntity: boolean;
  openDuplicateEntity: boolean;

  constructor(
    private projectService: ProjectService,
    private entityService: EntityService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private queryParamService: QueryParamService,
    private userService: UserService,
    private noteService: NoteService,
    private iso: IntersectionObserverService,
    private navigationService: NavigationService
  ) {
    const parentURL = this.activatedRoute.parent?.snapshot.url;
    const urlSegments = this.activatedRoute.snapshot.url;
    this.projectCode = parentURL ? parentURL[1].path : "";
    this.context = urlSegments[0].path as Context;

    this.expanded = this.queryParamService.queryParamsAsSet("expand");
  }

  default() {
    return this.entityService.getChildrenOfContext(this.projectCode, this.context as Context).pipe(
      switchMap((entities) => {
        if (this.expanded.size === 0) {
          return of(entities);
        }

        // get entities under each expanded group
        const expanded: string[] = Array.from(this.expanded);
        const groups = expanded.map((group) => {
          return this.entityService.getEntityCodesForGroup(
            this.projectCode,
            this.context as Context,
            group
          );
        });

        return forkJoin(groups).pipe(
          map((values) => {
            for (let i = 0; i < entities.length; i++) {
              if (!this.expanded.has(entities[i]["__code"])) {
                continue;
              }

              // insert the entities into place under the expanded group
              const toInsert = values.shift() || [];
              entities.splice(i + 1, 0, ...toInsert);
            }

            return entities;
          })
        );
      })
    );
  }

  getEntities() {
    this.filters$ = this.projectService.getFilters(this.projectCode, this.context).pipe(
      tap((filters) => {
        this.subs.push(
          this.navigationService.clickedEpisodeLinkInEpisodeTab$.subscribe((state) => {
            this.inShotsByEpisodePage = state;

            if (this.inShotsByEpisodePage) {
              this.isFiltersEnabled = false;
            } else {
              this.isFiltersEnabled = filters["is_enabled"];
            }

            // if user has a filter enabled, we only grab the entities with that filter applied
            if (this.isFiltersEnabled) {
              this.entities$ = this.entityService
                .getGroupedEntities(this.projectCode, this.context)
                .pipe(map((entities) => this.showExpanded(entities)));
            } else {
              this.entities$ = this.default();
            }
          })
        );
      })
    );
  }

  ngAfterViewInit() {
    this.entityRows.changes.subscribe((changes) => {
      const routeFragment = this.activatedRoute.snapshot.fragment;

      if (routeFragment && changes.length) {
        const fragment = document.getElementById(routeFragment);
        if (fragment) {
          this.tableContainer.nativeElement.scrollTop = fragment.offsetTop - 110;
        }
      }

      if (
        (changes.length && !this.openMediaPlayer && this.scrollPosition.x !== 0) ||
        this.scrollPosition.y !== 0
      ) {
        this.scrollBack();
      }
    });
  }

  ngOnInit() {
    this.searchByOption = "entityCode";

    this.columns$ = this.projectService
      .getTableConfig(this.projectCode, this.context.slice(0, this.context.length - 1))
      .pipe(map((columns) => (this.columns = columns)));

    this.getEntities();
    this.statuses$ = this.entityService.getStatuses(this.projectCode, this.context);

    const showCompleted: string = window.localStorage.getItem("showCompleted") ?? "";
    if (showCompleted) {
      this.showCompleted = showCompleted === "true";
    }

    const showStatusColors: string = window.localStorage.getItem("showStatusColors") ?? "";
    if (showStatusColors) {
      this.showStatusColors = showStatusColors === "true";
    }

    this.users$ = (
      this.userService.getUsersByProject(this.projectCode, this.context) as Observable<{
        [key: string]: User[];
      }>
    ).pipe(
      map((users) => {
        for (const dept of Object.keys(users)) {
          users[dept] = [createEmptyUser()].concat(users[dept]);
        }
        return users;
      })
    );

    this.usersInProject$ = this.userService.getUsersByProject(
      this.projectCode,
      this.context,
      GroupByDept.no
    ) as Observable<User[]>;

    this.obs$ = forkJoin([this.statuses$, this.users$, this.usersInProject$]).pipe(
      map((values) => {
        return { statuses: values[0], users: values[1], usersInProject: values[2] };
      })
    );

    this.subs.push(
      this.entityService.playMedia$.subscribe((viewMedia) => {
        this.scrollPosition = {
          x: this.tableContainer.nativeElement.scrollLeft,
          y: this.tableContainer.nativeElement.scrollTop,
        };

        // TODO: This gets the media twice. Once here and in media-player.component ngOnInit()
        viewMedia["media"].subscribe((media: IMedia[]) => {
          if (media.length > 0) {
            this.viewMedia = viewMedia;
            this.openMediaPlayer = true;
            this.cdr.markForCheck();
          } else {
            alert(
              `No items in ${viewMedia["src"].toLowerCase()} for ${viewMedia["entity"].toString()}.`
            );
          }
        });
      })
    );

    this.play();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.iso.childObservers.clear();

    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }
  }

  getChildren(i: number, entity: IEntity, entities: IEntity[]) {
    if (this.searching || this.isFiltersEnabled) {
      let k = 0;
      for (const item of this.entities) {
        if (item["__code"] === entity["__code"]) {
          /* example of this.entities:
           * [
           *  {__code: e900, __children: 2, __depth: 0},
           *    {shot_code: s0010, ...},
           *    {shot_code: s0020, ...},
           *  {__code: e910, __children: 1, __depth: 0},
           *    {shot_code: s0010, ...}
           * ]
           */
          let children = [];
          const n = item["__children"];
          for (let j = k + 1; j < this.entities.length; j++) {
            if (children.length === n) {
              break;
            }
            children.push(this.entities[j]);
          }

          entities.splice(i + 1, 0, ...children);
          this.entities$ = of(entities);
          break;
        }
        k++;
      }
      this.cdr.markForCheck();
    } else {
      this.entityService
        .getEntityCodesForGroup(this.projectCode, this.context as Context, entity["__code"])
        .subscribe((children: IEntity[]) => {
          entities.splice(i + 1, 0, ...children);
          this.entities$ = of(entities);
          this.cdr.markForCheck();
        });
    }
  }

  toggleChildVisibility(i: number, entity: IEntity, entities: IEntity[]) {
    if (this.expanded.has(entity["__code"])) {
      // find next entity with same depth
      let splice_count = 0;
      for (let j = i + 1; j < entities.length; j++) {
        if ("__depth" in entities[j] && entities[j]["__depth"] === entity["__depth"]) {
          break;
        }
        splice_count++;
      }
      entities.splice(i + 1, splice_count);

      // remove the group from `expanded` and update the query params
      const expanded = Array.from(this.expanded).filter((e) => !e.startsWith(entity["__code"]));
      this.expanded = new Set(expanded);

      if (expanded.length === 0) {
        // reset each one manually
        for (const key of ["expand", "show-all-notes", "note", "show-completed-notes"]) {
          this.queryParamService.updateQueryParams({ [key]: null });
        }
      }
      this.queryParamService.updateQueryParams({
        expand: expanded,
        play: null,
        note: null,
        version: null,
        "show-all-notes": null,
        "show-completed-notes": null,
      });
    } else {
      this.getChildren(i, entity, entities);
      this.expanded.add(entity["__code"]);
      this.queryParamService.updateQueryParams({ expand: Array.from(this.expanded) });
    }
  }

  play() {
    const play = this.activatedRoute.snapshot.queryParams["play"];
    const version = this.activatedRoute.snapshot.queryParams["version"];
    const context = this.activatedRoute.snapshot.queryParams["play-ctx"];
    const src = this.activatedRoute.snapshot.queryParams["play-src"];
    const compare = this.activatedRoute.snapshot.queryParams["compare"];
    const audio = this.activatedRoute.snapshot.queryParams["audio"] || "left";
    if (!play && !version && !context && !src) {
      return;
    }

    if (this.tableContainer) {
      this.scrollPosition = {
        x: this.tableContainer.nativeElement.scrollLeft,
        y: this.tableContainer.nativeElement.scrollTop,
      };
    }

    const _entity = {
      projectCode: this.projectCode,
      context: context as Context,
      group: play.split("_")[0],
      entityCode: play.split("_").slice(1).join("_"),
    } as IEntity;
    this.entityService
      .findOne(
        this.projectCode,
        this.context as Context,
        _entity.group,
        _entity.entityCode,
        "fields=episode_code,asset_type,asset_code,shot_code,media,catalog,plate,spp,default_thumbnail&exclude_fields=_id"
      )
      .subscribe((entity) => {
        let media: IMedia[] = [];
        const mediaOrCatalog = src.toLowerCase();

        let sppMedia;
        try {
          sppMedia = entity["spp"]["plate"]["media"];
        } catch {}

        let plateMedia;
        try {
          plateMedia = entity["plate"]["media"];
        } catch {}

        if (entity && entity[mediaOrCatalog] && sppMedia) {
          // inject the spp media (if it exists) into the entity's media
          entity[mediaOrCatalog].splice(0, 0, sppMedia);
          media = entity[mediaOrCatalog];
        }

        if (entity && entity[mediaOrCatalog] && plateMedia) {
          // inject the plate media (if it exists) into the entity's media
          entity[mediaOrCatalog].splice(0, 0, plateMedia);
          media = entity[mediaOrCatalog];
        } else if (entity && entity[mediaOrCatalog] && !entity["plate"]) {
          media = entity[mediaOrCatalog];
        }

        entity = new Entity({ ...entity, projectCode: this.projectCode, context: context });
        this.noteService.getNotes(entity).subscribe((notes) => {
          this.entityService.getStatuses(this.projectCode, this.context).subscribe((statuses) => {
            // trigger for media component to open
            this.entityService.playMedia.next({
              notes: notes || [],
              media: of(media),
              selectedVersion: version,
              compare: compare,
              audio: audio,
              src: src,
              entity: entity as Entity,
              defaultThumbnail: entity["default_thumbnail"],
              statuses: statuses,
            });
          });
        });
      });
  }

  onChangeColumns(columns: { name: string }[]) {
    this.columns = [...(columns as TableConfigColumnItem[])];
  }

  onSavedColumns(columns: { name: string }[]) {
    this.userService
      .saveTableConfig(
        this.projectCode,
        this.context.slice(0, this.context.length - 1),
        columns as TableConfigColumnItem[]
      )
      .subscribe();
  }

  showZeroDepthOnly(entities: IEntity[]) {
    let visibleItems = []; // items that are 'expanded' and visible to the table = []
    this.entities = entities;

    for (const item of entities) {
      if (item["__depth"] === 0) {
        visibleItems.push(item);
      }
    }

    return visibleItems;
  }

  // todo: better to use a visible property than to do a bunch of array manipulations
  showExpanded(entities: IEntity[]) {
    let visibleItems = []; // items that are 'expanded' and visible to the table
    this.entities = entities;
    let j = 0;

    /* example of this.entities:
     * [
     *  {__code: e900, __children: 2, __depth: 0},
     *    {shot_code: s0010, ...},
     *    {shot_code: s0020, ...},
     *  {__code: e910, __children: 1, __depth: 0},
     *    {shot_code: s0010, ...}
     * ]
     */
    for (let i = 0; i < entities.length; i++) {
      if (entities[i]["__depth"] === 0) {
        visibleItems.push(entities[i]);
        j++;
      }

      if (this.expanded.has(entities[i]["__code"])) {
        const toInsert = entities.slice(i + 1, i + 1 + entities[i]["__children"]);
        visibleItems.splice(j + 1, 0, ...toInsert);
        j += toInsert.length + 1;
      }
    }

    return visibleItems;
  }

  onSearch(searchTerm: string | string[] | undefined) {
    this.expanded = new Set([]);
    this.queryParamService.updateQueryParams({
      expand: null,
      "show-all-notes": null,
      note: null,
      "show-completed-notes": null,
    });
    this.scrollPosition = { x: 0, y: 0 };

    if (searchTerm === undefined || Array.isArray(searchTerm)) {
      // search is cancelled by pressing 'esc')
      this.searching = false;
      return;
    }

    if (searchTerm === "") {
      this.searching = false;
      this.getEntities();
      this.subs.push(this.filters$.subscribe(() => this.cdr.markForCheck()));
      return;
    }

    this.searching = true;
    let search = searchTerm;
    const plusRegexp = /\s*\+\s*/g;
    const commaRegexp = /\s*,\s*/g;
    search = search.replace(plusRegexp, "%2B").replace(commaRegexp, ",").trim();
    this.searchGroupedEntities({
      search: search,
      searchType: this.searchByOption,
      exactMatch: false,
    });
  }

  searchGroupedEntities($event: { search: string; searchType: string; exactMatch: boolean }) {
    this.entities$ = this.entityService
      .searchGroupedEntities(this.projectCode, this.context, $event)
      .pipe(map((entities) => this.showZeroDepthOnly(entities)));
  }

  searchAndFilter() {
    this.expanded = new Set([]);
    this.queryParamService.updateQueryParams({
      expand: null,
      "show-all-notes": null,
      note: null,
      "show-completed-notes": null,
    });
    this.scrollPosition = { x: 0, y: 0 };

    if (this.searchTypeahead.inputString !== "") {
      // if we are filtering and there is any text on the search box, search for that item using the filters
      this.searchGroupedEntities({
        search: this.searchTypeahead.inputString,
        searchType: this.searchByOption,
        exactMatch: false,
      });
    } else {
      this.entities$ = this.entityService
        .getGroupedEntities(this.projectCode, this.context)
        .pipe(map((entities) => this.showZeroDepthOnly(entities)));
    }
  }

  onToggledFilters($event: boolean) {
    this.isFiltersEnabled = $event;
    this.queryParamService.updateQueryParams({ expand: null });
    this.expanded = new Set([]);
    if (!this.isFiltersEnabled) {
      this.entities$ = this.default();
    }
  }

  scrollBack() {
    this.tableContainer.nativeElement.scrollLeft = this.scrollPosition.x;
    this.tableContainer.nativeElement.scrollTop = this.scrollPosition.y;
  }

  updateEntity(entities: IEntity[], $event: { entity: Pick<Entity, "toString"> }) {
    const i = entities.findIndex(
      (g) => new Entity({ ...g } as IEntity).toString() === $event.entity.toString()
    );
    if (i === -1) {
      return;
    }
    entities[i]["__updates"] = { ...$event };
  }

  showEntity(entity: IEntity) {
    let visible = false;

    if (this.showOOP) {
      visible = true;
    } else {
      if (this.context === "assets") {
        visible = !(
          entity["art_status"] === "out_of_picture" ||
          entity["mdl_status"] === "out_of_picture" ||
          entity["rig_status"] === "out_of_picture" ||
          entity["srf_status"] === "out_of_picture"
        );
      } else if (this.context === "shots") {
        visible = entity["shot_status"] !== "out_of_picture";
      }
    }

    return visible;
  }

  onToggledOOP(i: number, entities: IEntity[], $event: { field: string; value: string }) {
    entities[i][$event.field] = $event.value;
    this.cdr.markForCheck();
  }

  onChangeShowCompleted() {
    this.showCompleted = !this.showCompleted;
    window.localStorage.setItem("showCompleted", String(this.showCompleted));
  }

  onChangeShowStatusColors() {
    this.showStatusColors = !this.showStatusColors;
    window.localStorage.setItem("showStatusColors", String(this.showStatusColors));
  }
}
