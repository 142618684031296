import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpXsrfTokenExtractor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { tap, catchError } from "rxjs/operators";

import { AuthService } from "./auth.service";
import { UserToken } from "./user";
import { domain, environment } from "../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private tokenExtractor: HttpXsrfTokenExtractor,
    private router: Router,
    private authService: AuthService
  ) {}

  displayErrorMessage(error: { code: number; error: string; description: string }) {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,
    if (error.code === 401) {
      const errorMessage = error.error || "";
      const description = error.description || "";
      alert(`${errorMessage}. ${description}`);
      console.error(error);
      UserToken.removeToken();
      this.authService.toggleLogin(false);
      this.authService.loggedInResolved.next(true);
      this.router.navigate(["login"]);
    } else {
      const errorMessage = error.error || "";
      const description = error.description || errorMessage;
      alert(`Error ${error.code}. ${description}`);
      console.error(error);
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const xsrfToken = this.tokenExtractor.getToken() as string;

    // no need to intercept for /api/login
    // adding the Authorization header is done in auth.service
    if (!request.url.endsWith("/api/login") || !request.url.endsWith("/api/login/")) {
      // add the csrf token in the headers on POST and PUT request
      if ((xsrfToken && request.method === "POST") || request.method === "PUT") {
        request = request.clone({
          setHeaders: {
            "X-XSRF-TOKEN": `${xsrfToken}`,
          },
        });
      }
    } else if (request.url.endsWith("/api/login") && request.method === "GET" && xsrfToken) {
      request = request.clone({
        setHeaders: {
          "X-XSRF-TOKEN": `${xsrfToken}`,
        },
      });
    }

    if (!environment.production) {
      request = request.clone({
        withCredentials: true,
      });
    }

    return next.handle(request).pipe(
      tap((response) => {
        if (response instanceof HttpResponse && request.url.startsWith("/api/")) {
          this.authService.toggleLogin(!request.url.startsWith("/api/logout"));
          this.authService.loggedInResolved.next(true);
        }
      }),
      catchError((error) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error("An error occurred:", error.error.message);
        } else {
          if (error.error instanceof Blob) {
            let promise = error.error.text();
            promise.then((blob: string) => this.displayErrorMessage(JSON.parse(blob)));
          } else {
            if (request.url.endsWith("/api/login") && request.method === "GET") {
              this.authService.toggleLogin(false);
              this.authService.loggedInResolved.next(true);
            } else {
              this.displayErrorMessage({ ...error.error });
            }
          }
        }

        return throwError(error);
      })
    );
  }
}
