<div class="container">
  <h4>Filters Guide</h4>
  <ol>
    <li>
      <p>To create a filter, click <img src="assets/img/filter-button.jpeg" /></p>

      <p>
        You will see the option to enable/disable filter for the particular tab that you are
        currently on: <img src="assets/img/filter-enable.jpg" />
      </p>

      <p>
        Clicking on the button "On" will bring up the filters UI. <br />
        <b>Note: </b>Initially, you will not have any saved filter, as shown:
      </p>
      <div><img src="assets/img/filter-no-saved.jpg" /></div>
    </li>
    <li><p>Select a field.</p></li>
    <li>
      <p>
        A new dropdown will appear, and will be default to 'is' or 'has' (depending on the field).
        You can choose 'is not' or 'has not' for your purposes.
      </p>
      <div><img src="assets/img/select-operator.jpeg" /></div>
    </li>
    <li>
      <p>
        Click on the text area and you will be presented with different options, you can click an
        item or start typing to give you suggestions.
      </p>
      <p>
        To add multiple items, use a comma ',' or plus '+' as a separator. Comma ',' means OR. Plus
        '+' means AND. You can use parenthesis '()' to group your items.
      </p>
      <div><img src="assets/img/typeahead.jpeg" /></div>
      <p>
        In this example, the filter means "I want shots with asset_list that have (acc_doctor_lucas
        and chr_findley) or chr_bodhi or chr_maizie".
      </p>
    </li>
    <li>
      <p>
        To add more filters, click the 'Add Filter' button and repeat the same process. Then you
        MUST select an operator in between your filters.
      </p>
      <div><img src="assets/img/logical-operators.jpeg" /></div>
    </li>
    <li>
      <p>Click 'Apply'. <img src="assets/img/apply-btn.jpg" /></p>
    </li>
    <p>
      <b>Note: </b> This will not save your filter. Applied filter that is not saved will shown as
      "No_Save" on the custom filters list: <img src="assets/img/no-save-filter.jpg" />
    </p>
    <p>
      To save your filter, click on the checkbox: <img src="assets/img/save-filter-checkbox.jpg" />
    </p>
    <li>
      <p>Save your filter by filling the required info. Example: My Shot Filter:</p>
      <div><img src="assets/img/filter-name-example.jpg" /></div>
      <p>
        <b>Note: Your new filter will automatically be set to be the default filter. </b>
        You can always change your default filter by selecting a different one from your saved
        filters list.
      </p>
      <div><img src="assets/img/filter-saved-list.jpg" /></div>
    </li>
    <li>
      <p>
        If you modify and update an exisiting by giving it the same name. You will then be asked to
        confirm:
      </p>
      <div><img src="assets/img/filter-overwrite.jpg" /></div>
      <p>
        Click "Yes" to update the exisiting filter. Click "No" if you'd like to save it as a new
        filter with a different name.
      </p>
    </li>
    <p>You can also delete your saved (custom) filter: <img src="assets/img/delete-btn.jpg" /></p>
  </ol>

  <ul>
    <b>Notes:</b>
    <li>You can enable/disable filters as you wish.</li>
    <li>The default filter will be the one last selected from your saved filters list.</li>
    <li>You can add or update inidividual filters as you wish.</li>
    <li>
      The filter icon will turn green if you have filters applied and grey if you don't have filters
      applied.
    </li>
    <li>
      Turning off your filter will automatically set your default to "No_Save" filter, i.e. no
      custom filter applied.
    </li>
  </ul>

  <h5>Difference between AND and OR</h5>
  <p>
    AND means that you want BOTH conditions on either side of the AND to be true. In the example
    below, a shot SHOULD HAVE BOTH of these conditions (anm_status is director_approved AND
    anm_assigned is jkalantar) to be true in order to be included in the results.
  </p>
  <div><img src="assets/img/and-operator.jpeg" /></div>
  <p>
    OR means that you want EITHER conditions on either side of the OR to be true. In the example
    below, a shot that either has anm_status is director_approved or anm_assigned is jakalantar will
    be included in the results. It will include shots that have anm_status is director approved
    regardless if it's anm_assigned to jkalantar or not. It will also include shots that have
    anm_assigned is jkalantar regardless if the anm_status is director_approved or not.
  </p>
  <div><img src="assets/img/or-operator.jpeg" /></div>
  <p>
    In short, AND is more restrictive and OR to be more flexible. Hopefully you can use this
    knowledge to your needs.
  </p>

  <section>
    <h4 id="blender-rtracker-guide" class="mt-5">Blender rTracker Guide</h4>

    <div class="accordion">
      <div *ngFor="let guide of blenderGuideVideos">
        <button
          [ngClass]="{ active: activeBlenderGuide === guide.id }"
          class="btn btn-link btn-block text-left"
          type="button"
          (click)="activeBlenderGuide = activeBlenderGuide !== guide.id ? guide.id : ''">
          {{ guide.title }}
        </button>

        <div
          *ngIf="activeBlenderGuide === guide.id"
          class="card-body embed-responsive embed-responsive-16by9">
          <video controls class="embed-responsive-item" crossorigin="use-credentials">
            <source [src]="guide.src" type="video/webm" />
          </video>
        </div>
      </div>
      <span class="font-smaller pl-3">* Click a guide to show/hide</span>
    </div>
  </section>
</div>
