import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChild,
  ViewChildren,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";

import { Entity } from "../entity";
import { EntityService } from "../entity.service";
import { ProjectService } from "../project.service";
import { TypeaheadComponent } from "../typeahead/typeahead.component";
import { Status, mapToDropdownItem } from "../utils";
import { User } from "../user";
import { IDropdownItem } from "../dropdown-item";

function isFileArray(a: File[] | (File | null)[]): a is File[] {
  return a.every((elem) => elem !== null);
}

@Component({
  selector: "app-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadComponent implements OnInit {
  @Input() entity: Entity;
  @Input() episodeName?: string;
  @Input() scriptsAndStory?: string;
  @Input() usersInProject: User[];
  @Input() primarySoftware?: string;

  @Output() closed = new EventEmitter<string>();

  @ViewChildren("deptInputs") deptInputs: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChildren("typeInputs") typeInputs: QueryList<ElementRef<HTMLInputElement>>;
  @ViewChild("progressBar", { static: true }) progressBar: ElementRef<HTMLDivElement>;
  @ViewChild("artists") artists: TypeaheadComponent;

  depts: string[];
  context: string;
  // TODO: unhardcode this
  uploadTypes: string[];
  selectedUploadType: string;
  selectedDept: string;
  errorMsg = "";
  projectCode: string;
  comment: string;
  commentSS: string;
  attachedFiles: (File | null)[] = []; // TO DO -- test upload of 3+ files
  assignableUsernames: string[] = [];
  showDepts: boolean = true;
  assemblyUpload: boolean = false;

  scriptStages = [
    { text: "Premise" },
    { text: "Outline" },
    { text: "1stDraft" },
    { text: "2ndDraft" },
    { text: "Polish" },
    { text: "Record" },
  ];

  storyboardStages = [{ text: "Rough" }, { text: "Finaling" }, { text: "Polish" }];

  // Assembly stages are Shots' depts
  assemblyStages: IDropdownItem[] = [];

  leicaStages = [
    { text: "Pre-assembly" },
    { text: "Rough Assembly" },
    { text: "Leica" },
    { text: "Leica for Client" },
  ];

  selectedStage: string = "";

  processStatus: {
    status: Status;
    msg: string;
    progress: string | number;
  } = { status: "Idle", msg: "", progress: 0 };
  showProgress: (status: { status: Status; progress: string | number }) => void;

  constructor(
    private projectService: ProjectService,
    private entityService: EntityService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.context =
      this.entity.entityCode === "assembly" && this.entity.context === "shots"
        ? "episodes"
        : this.entity.context;

    this.showProgress = (status: { status: Status; progress: string | number }) => {
      this.processStatus = { ...status, msg: "" };
      if (
        (this.processStatus.status === "Uploading" && this.processStatus.progress !== 100) ||
        this.processStatus.status === "Submitted"
      ) {
        this.processStatus.progress = `${status.progress}%`;
        this.progressBar.nativeElement.style.width = this.processStatus.progress;
      }
      this.cdr.markForCheck();
    };
    this.getDepts();

    // TODO: put this in a config
    if (this.context === "assets") {
      this.uploadTypes = ["movie", "stills", "adobe", "workfile"];
    } else if (this.context === "shots") {
      // TODO: get the primary software form the config
      this.uploadTypes = ["edt", "adobe", "audio", "chk", "full", "workfile"];
    } else if (this.context === "episodes" || this.context === "scripts") {
      this.getUsers();
      if (this.scriptsAndStory === "scripts" || this.scriptsAndStory === "storyboards") {
        this.uploadTypes = ["pdf/document"];
        this.selectedUploadType = "pdf/document";
        this.showDepts = false;
      } else if (this.scriptsAndStory === "leica") {
        this.uploadTypes = ["movie"];
        this.selectedUploadType = "movie";
        this.showDepts = false;
      } else {
        this.uploadTypes = ["assembly"];
        this.assemblyUpload = true;
      }
    }
  }

  // Might be good to rewrite this to throw an error rather than returning false.
  isGood(uploadType: ElementRef, dept: ElementRef | string | undefined, comment: string): boolean {
    if (
      !dept ||
      !uploadType ||
      this.attachedFiles.length === 0 ||
      !comment ||
      !isFileArray(this.attachedFiles)
    ) {
      this.errorMsg = "One or more fields is not filled out.";
      return false;
    }

    const filetypes = this.getSupportedTypes()["filetypes"];
    let mediaFiletypes = [];

    if ((this.primarySoftware === "blender" || this.primarySoftware === 'moho') && uploadType.nativeElement.value === "workfile") {
      if (this.attachedFiles.length < 2) {
        this.errorMsg = "One or more fields is not filled out.";
        return false;
      } else if (this.attachedFiles.length === 2) {
        // const [file1, file2] = this.attachedFiles as [File, File]
        // const attachedFiles = this.attachedFiles as [File, File]
        mediaFiletypes = filetypes.filter((filetype) => !([".blend", ".moho"].includes(filetype)));
        const ext1 = `.${this.attachedFiles[0].name.split(".").pop()?.toLowerCase()}`;
        const ext2 = `.${this.attachedFiles[1].name.split(".").pop()?.toLowerCase()}`;

        if (ext1 === ".blend" || ext1 === ".moho") {
          if (!mediaFiletypes.includes(ext2)) {
            this.errorMsg = `Must have a media file and a blend file`;
            return false;
          }
        } else if (ext2 === ".blend" || ext2 === ".moho") {
          if (!mediaFiletypes.includes(ext1)) {
            this.errorMsg = `Must have a media file and a blend file`;
            return false;
          }
        } else {
          this.errorMsg = `Must have a media file and a blend file`;
          return false;
        }
      } else { // for now I'm going to assume this will never happen for a moho file.
        const ext1 = `.${this.attachedFiles[0].name.split(".").pop()?.toLowerCase()}`;
        const ext2 = `.${this.attachedFiles[1].name.split(".").pop()?.toLowerCase()}`;
        const ext3 = `.${this.attachedFiles[2].name.split(".").pop()?.toLowerCase()}`;
        mediaFiletypes = filetypes.filter(
          (filetype) => filetype !== ".blend" && filetype !== ".zip"
        );

        let hasBlend = 0;
        let hasMedia = 0;
        let hasZip = 0;
        for (const ext of [ext1, ext2, ext3]) {
          if (ext === ".blend") {
            hasBlend = 1;
          } else if (mediaFiletypes.includes(ext)) {
            hasMedia = 1;
          } else if (ext === ".zip") {
            hasZip = 1;
          }
        }

        if (hasBlend + hasMedia + hasZip !== 3) {
          this.errorMsg = `Must have a blend file, a media file and a zip file containing textures, image sequence, etc of the blend file.`;
          return false;
        }
      }

      this.errorMsg = "";
      return true;
    }

    if (uploadType.nativeElement.value === "workfile") {
      mediaFiletypes = filetypes.filter((filetype) => filetype !== ".ma");
      if (this.attachedFiles.length !== 2 || !this.attachedFiles[0] || !this.attachedFiles[1]) {
        this.errorMsg = "One or more fields is not filled out.";
        return false;
      }

      const ext1 = `.${this.attachedFiles[0].name.split(".").pop()?.toLowerCase()}`;
      const ext2 = `.${this.attachedFiles[1].name.split(".").pop()?.toLowerCase()}`;

      if (ext1 === ".ma") {
        if (!mediaFiletypes.includes(ext2)) {
          this.errorMsg = `Must have a media file and a maya file`;
          return false;
        }
      } else if (ext2 === ".ma") {
        if (!mediaFiletypes.includes(ext1)) {
          this.errorMsg = `Must have a media file and a maya file`;
          return false;
        }
      } else {
        this.errorMsg = `Must have a media file and a maya file`;
        return false;
      }
    } else if (uploadType.nativeElement.value === "adobe") {
      mediaFiletypes = filetypes.filter((filetype) => filetype !== ".ai" && filetype !== ".psd");
      if (this.attachedFiles.length !== 2 || !this.attachedFiles[0] || !this.attachedFiles[1]) {
        this.errorMsg = "One or more fields is not filled out.";
        return false;
      }

      const ext1 = `.${this.attachedFiles[0].name.split(".").pop()?.toLowerCase()}`;
      const ext2 = `.${this.attachedFiles[1].name.split(".").pop()?.toLowerCase()}`;

      if (ext1 === ".ai" || ext1 === ".psd") {
        if (!mediaFiletypes.includes(ext2)) {
          this.errorMsg = `Must have an .ai/psd file and a preview img file`;
          return false;
        }
      } else if (ext2 === ".ai" || ext2 === ".psd") {
        if (!mediaFiletypes.includes(ext1)) {
          this.errorMsg = `Must have an .ai/psd file and a preview img file`;
          return false;
        }
      } else {
        this.errorMsg = `Must have an .ai/psd file and a preview img file`;
        return false;
      }
    } else {
      if (!this.attachedFiles[0]) {
        this.errorMsg = "One or more fields is not filled out.";
        return false;
      }
      const attached0 = this.attachedFiles[0];
      const accepted = filetypes.filter((f) => attached0.name.toLowerCase().endsWith(f));
      if (accepted.length === 0) {
        this.errorMsg = `Supported filetypes for '${
          uploadType.nativeElement.value
        }': ${filetypes.join(", ")}
          Got: '${attached0.name}'`;
        return false;
      }
    }

    this.errorMsg = "";
    return true;
  }

  inputFilesChanged(filelist: FileList | null) {
    if (!filelist) {
      return;
    }
    this.attachedFiles = Array(filelist.length);
    for (let i = 0; i < filelist.length; i++) {
      this.attachedFiles[i] = filelist[i];
    }
  }

  inputFilesChangedMark(filelist: FileList | null) {
    this.inputFilesChanged(filelist);
    this.cdr.markForCheck();
  }

  onIthInputFileChanged(fileInput: HTMLInputElement, i: number) {
    // Where 'ith' refers to the number of the input. file1, file2 etc in the template. For the singular input case.
    if (fileInput.files) {
      this.attachedFiles[i] = fileInput.files?.item(0);
    }
  }

  isGoodSS(uploadType: ElementRef | undefined): boolean {
    if (!uploadType || this.attachedFiles.length === 0 || this.selectedStage === "") {
      this.errorMsg = "One or more fields is not filled out.";
      return false;
    }

    const filetypes = this.getSupportedTypes()["filetypes"];
    let missingPDF = true;

    if (this.selectedUploadType === "movie") {
      missingPDF = false;
    } else {
      for (const file of this.attachedFiles) {
        if (file?.name.toLowerCase().endsWith(".pdf")) {
          missingPDF = false;
          break;
        }
      }
    }

    if (missingPDF) {
      this.errorMsg = "Missing a PDF file in your upload.";
      return false;
    }

    if (!this.attachedFiles[0]) {
      this.errorMsg = "One or more fields is not filled out.";
      return false;
    }

    const accepted = filetypes.filter((f) => this.attachedFiles[0]?.name.toLowerCase().endsWith(f));
    if (accepted.length === 0) {
      this.errorMsg = `Supported filetypes for '${
        uploadType.nativeElement.value
      }': ${filetypes.join(", ")}
        Got: '${this.attachedFiles[0].name}'`;
      return false;
    }
    this.errorMsg = "";
    return true;
  }

  upload(overwrite = false) {
    const dept = this.deptInputs.toArray().find((item) => item.nativeElement.checked);
    const uploadType = this.typeInputs.toArray().find((item) => item.nativeElement.checked);
    // const comment = this.comment.nativeElement.value;
    const comment = this.comment;
    let artistsList = "";
    if (!uploadType) {
      this.errorMsg = "One or more fields is not filled out.";
      return false;
    }
    if (this.context === "assets" || this.context === "shots") {
      if (!this.isGood(uploadType, dept, comment)) {
        return;
      }
    } else {
      if (!this.isGood(uploadType, "edt", comment)) {
        return;
      }
    }
    let _dept = dept ? dept.nativeElement.value : "";
    let _uploadType = uploadType.nativeElement.value;

    if (_uploadType === "audio") {
      _dept = "audio";
    }

    if (_uploadType === "assembly") {
      artistsList = this.artists.inputString;
    }

    if (uploadType.nativeElement.value === "workfile") {
      _uploadType = "work";
    }
    // isGood asserts that attachedFiles is File[], not (File|null)[]
    this.entityService
      .upload(
        this.attachedFiles as File[],
        this.entity,
        _dept,
        _uploadType,
        this.showProgress,
        comment,
        overwrite,
        artistsList
      )
      .subscribe(
        () => (this.attachedFiles = []),
        (err) => {
          if (err.error.description.includes("already exist")) {
            this.errorMsg = "These files already exist, would you like to overwrite them?";
          }
          this.processStatus.status = "Started";
          this.processStatus.progress = `0%`;
          this.progressBar.nativeElement.style.width = this.processStatus.progress;

          this.cdr.markForCheck();
        }
      );
    return undefined;
  }

  upload_ss() {
    const uploadType = this.typeInputs.toArray().find((item) => item.nativeElement.checked);
    let comment = "";
    let artistsList = "";

    comment = this.commentSS === undefined ? "" : this.commentSS;
    if (this.scriptsAndStory !== "scripts") {
      artistsList = this.artists.inputString;
    }

    if (
      (this.context === "episodes" || this.context === "scripts") &&
      this.scriptsAndStory !== undefined
    ) {
      if (!this.isGoodSS(uploadType)) {
        return;
      }
    }

    if (isFileArray(this.attachedFiles)) {
      this.entityService
        .uploadScriptsStoryboards(
          this.attachedFiles,
          this.entity,
          this.showProgress,
          this.scriptsAndStory,
          this.selectedStage,
          comment,
          artistsList
        )
        .subscribe(
          () => (this.attachedFiles = []),
          (err) => {
            if (err.error.description.includes("already exist")) {
              this.errorMsg = "These files already exist, would you like to overwrite them?";
            }
            this.processStatus.status = "Started";
            this.processStatus.progress = `0%`;
            this.progressBar.nativeElement.style.width = this.processStatus.progress;

            this.cdr.markForCheck();
          }
        );
    }
  }

  getSupportedTypes() {
    if (this.primarySoftware === "blender" && this.selectedUploadType === "workfile") {
      return {
        filetypes: [
          ".blend",
          ".zip",
          ".mp4",
          ".mov",
          ".m4v",
          ".mkv",
          ".mpg",
          ".mpeg",
          ".mpe",
          ".mpv",
          ".mp2",
          ".m2v",
          ".wav",
          ".webm",
          ".ogg",
          ".ogv",
          ".ogv",
          ".avi",
          ".wmv",
          ".yuv",
          ".png",
          ".jpg",
          ".jpeg",
          ".svg",
        ],
        accepts: [
          ".blend",
          ".zip",
          "video/quicktime",
          "video/mp4",
          "video/mov",
          "video/m4v",
          "video/mkv",
          "video/mpg",
          "video/mpeg",
          "video/mpe",
          "video/mpv",
          "video/mp2",
          "video/m2v",
          "video/wav",
          "video/webm",
          "video/ogg",
          "video/ogv",
          "video/ogv",
          "video/avi",
          "video/wmv",
          "video/yuv",
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/svg+xml",
        ],
      };
    }

    if (["movie", "chk", "full", "assembly", "edt"].includes(this.selectedUploadType)) {
      // TODO: grab this from the server
      return {
        filetypes: [
          ".mp4",
          ".mov",
          ".m4v",
          ".mkv",
          ".mpg",
          ".mpeg",
          ".mpe",
          ".mpv",
          ".mp2",
          ".m2v",
          ".wav",
          ".webm",
          ".ogg",
          ".ogv",
          ".ogv",
          ".avi",
          ".wmv",
          ".yuv",
        ],
        accepts: [
          "video/quicktime",
          "video/mp4",
          "video/mov",
          "video/m4v",
          "video/mkv",
          "video/mpg",
          "video/mpeg",
          "video/mpe",
          "video/mpv",
          "video/mp2",
          "video/m2v",
          "video/wav",
          "video/webm",
          "video/ogg",
          "video/ogv",
          "video/ogv",
          "video/avi",
          "video/wmv",
          "video/yuv",
        ],
      };
    } else if (this.selectedUploadType === "audio") {
      return { filetypes: [".wav"], accepts: ["audio/wav"] };
    } else if (this.selectedUploadType === "stills") {
      return {
        filetypes: [".png", ".jpg", ".jpeg", ".svg"],
        accepts: ["image/png", "image/jpeg", "image/jpg", "image/svg+xml"],
      };
    } else if (this.selectedUploadType === "adobe") {
      return {
        filetypes: [".ai", ".psd", ".png", ".jpg", ".jpeg"],
        accepts: [".ai", ".psd", "image/png", "image/jpeg", "image/jpg"],
      };
    } else if (this.selectedUploadType === "workfile") {
      return {
        filetypes: [
          ".ma",
          ".blend",
          ".moho", // todo: possibly support anime studio files as well (.anme)
          ".mp4",
          ".mov",
          ".m4v",
          ".mkv",
          ".mpg",
          ".mpeg",
          ".mpe",
          ".mpv",
          ".mp2",
          ".m2v",
          ".wav",
          ".webm",
          ".ogg",
          ".ogv",
          ".ogv",
          ".avi",
          ".wmv",
          ".yuv",
          ".png",
          ".jpg",
          ".jpeg",
          ".svg",
        ],
        accepts: [
          ".ma",
          ".blend",
          ".moho", // todo: possibly support anime studio files as well (.anme)
          "video/quicktime",
          "video/mp4",
          "video/mov",
          "video/m4v",
          "video/mkv",
          "video/mpg",
          "video/mpeg",
          "video/mpe",
          "video/mpv",
          "video/mp2",
          "video/m2v",
          "video/wav",
          "video/webm",
          "video/ogg",
          "video/ogv",
          "video/ogv",
          "video/avi",
          "video/wmv",
          "video/yuv",
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/svg+xml",
        ],
      };
    } else if (this.selectedUploadType === "pdf/document") {
      return {
        filetypes: [".pdf", ".doc", ".docx", ".docm", ".odt", ".dot", ".rtf", ".sbpz"],
        accepts: [".pdf", ".doc", ".docx", ".docm", ".odt", ".dot", ".rtf", ".sbpz"],
      };
    }
    return { filetypes: [], accepts: [] };
  }

  getUsers() {
    for (const user of this.usersInProject) {
      if (!this.assignableUsernames.includes(user.username)) {
        this.assignableUsernames.push(user.username);
      }
    }
    this.assignableUsernames.sort();
  }

  displayFilename(file: File | null | undefined): string {
    if (file) {
      return file["name"];
    }
    return "";
  }

  getDepts() {
    if (this.context === "episode" || this.context === "scripts") {
      this.projectService.getDepts(this.entity.projectCode, "shots").subscribe((depts) => {
        this.assemblyStages = mapToDropdownItem(depts);
        this.depts = depts;
        this.cdr.markForCheck();
      });
    } else {
      this.projectService
        .getDepts(this.entity.projectCode, this.context, true)
        .subscribe((depts) => {
          this.depts = depts;
          this.cdr.markForCheck();
        });
    }
  }
}
