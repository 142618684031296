<ng-container *ngIf="isIntersecting && (entityNotes$ | async)">
  <ng-container *ngFor="let col of columns; index as i" [ngSwitch]="col.td">
    <ng-container *ngIf="col.visible">
      <td
        *ngIf="!col.td && !col.cols"
        class="bg-white"
        [style.left]="i === 0 ? '0px' : null"
        [style.z-index]="$any(col.styles['z-index']) - 2"
        [style.position]="i === 0 ? 'sticky' : null">
        {{ entityNotes.entity[col["short-code"] ?? ""] }}
      </td>
      <td
        *ngSwitchCase="'notes'"
        class="notes"
        [attr.colspan]="col.colspan"
        [style.border]="col.styles.border"
        [style.border-top]="'1px solid #dee2e6'"
        [style.border-bottom]="'1px solid #dee2e6'">
        <app-notes
          [notes]="entityNotes.notes"
          [statuses]="statuses"
          [updatedNote$]="updatedNote$"
          [entity]="entityNotes.entity"
          [users]="usersInProject"
          [showStatusColors]="showStatusColors"
          (changedVersion)="
            play(entityNotes.entity, entityNotes.notes, getSrc($event), $event['_id']['$oid'])
          "></app-notes>
      </td>
      <td *ngSwitchCase="'checkbox'" class="text-center">
        <input
          type="checkbox"
          [ngModel]="entityNotes.entity[col['short-code'] || '']"
          (change)="
            editMode = col['short-code'] || '';
            onSave(
              entityNotes.entity,
              col['short-code'] || '',
              getEventTargetAsHTMLInputElement($event).checked
            )
          " />
      </td>
      <td
        *ngSwitchCase="'img'"
        class="align-top position-sticky bg-white"
        style="left: 0px"
        [style.z-index]="$any(col.styles['z-index']) - 2"
        [style.border-left]="
          context === 'plates' && entityNotes.entity['circled']
            ? '4px solid #8bc483'
            : '1px solid #dee2e6'
        ">
        <div
          *ngIf="context !== 'plates'"
          class="d-flex position-relative"
          style="left: 25%; max-width: min-content">
          <div class="d-inline-block img-btn-container" style="width: 57px; height: 64px">
            <div class="img-btn" (click)="play(entityNotes.entity, entityNotes.notes, 'MEDIA')">
              View Media
            </div>
          </div>
          <div class="d-inline-block img-btn-container" style="width: 57px; height: 64px">
            <div class="img-btn" (click)="play(entityNotes.entity, entityNotes.notes, 'CATALOG')">
              View Catalog
            </div>
          </div>
          <img
            class="img-thumb img-behind-view-btns"
            [src]="entityNotes.entity | thumbnailURL"
            (click)="play(entityNotes.entity, entityNotes.notes, 'MEDIA')"
            width="114"
            height="64" />
        </div>

        <div
          *ngIf="context === 'plates'"
          class="text-center"
          [style.margin-left]="_entity['__depth'] | depthAsPadding">
          <img
            class="img-thumb"
            [src]="entityNotes.entity | thumbnailURL"
            (click)="play(entityNotes.entity, entityNotes.notes, 'MEDIA')"
            width="114"
            height="64" />
        </div>

        <div
          [style.margin-left]="context === 'plates' ? (_entity['__depth'] | depthAsPadding) : 0"
          class="text-center">
          <ng-container *ngIf="context !== 'plates'">
            <a
              *ngIf="!inShotsByEpisodePage"
              [routerLink]="['./' + entityNotes.entity.group + '/' + entityNotes.entity.entityCode]"
              >{{
                entityNotes.entity.context === "shots"
                  ? (entityNotes.entity | entityToString)
                  : entityNotes.entity.entityCode
              }}</a
            >
            <a
              *ngIf="inShotsByEpisodePage"
              [routerLink]="[
                '../../../' +
                  'shots' +
                  '/' +
                  entityNotes.entity.group +
                  '/' +
                  entityNotes.entity.entityCode
              ]"
              >{{
                entityNotes.entity.context === "shots"
                  ? (entityNotes.entity | entityToString)
                  : entityNotes.entity.entityCode
              }}</a
            >
          </ng-container>
          <ng-container *ngIf="context === 'plates'"
            ><span>{{ entityNotes.entity | entityToString }}</span></ng-container
          >
        </div>
        <div *ngIf="context !== 'plates'" class="d-flex justify-content-center align-items-center">
          <span class="reload" (click)="ngOnInit()" title="Reload">
            <i class="material-icons md-24 align-text-bottom">refresh</i>
          </span>
          <span class="upload" (click)="openUpload = true" title="Upload">
            <i class="material-icons md-24 align-text-bottom">cloud_upload</i>
          </span>
          <span class="download" (click)="openFileBrowser = true" title="Download"
            ><i class="material-icons md-24 align-text-bottom">get_app</i></span
          >
        </div>
      </td>
      <td *ngSwitchCase="'shot-content'">{{ entityNotes.entity["shot_content"] | keys }}</td>
      <td
        *ngSwitchCase="'status-select'"
        class="text-center"
        [style.background-color]="
          showStatusColors
            ? findStatusColor(entityNotes.entity[col['short-code'] || ''], statuses)
            : null
        ">
        <app-dropdown
          [selectedItem]="entityNotes.entity[col['short-code'] || '']"
          [choices]="statuses"
          [choiceKey]="'text'"
          [emptyChoice]="statuses[0]"
          [showIconOnlyForSelectedItem]="true"
          [applyBgColor]="showStatusColors"
          [callback]="save(entityNotes.entity, col['short-code'] || '')"></app-dropdown>
      </td>
      <td
        *ngSwitchCase="'edit'"
        title="Click to edit"
        (click)="
          $event.stopPropagation();
          editMode !== col['to-edit'] ? (editMode = col['to-edit'] || '') : null
        ">
        <div
          *ngIf="editMode !== col['to-edit'] && col['type'] !== 'due-date'"
          class="typeahead-values">
          {{ entityNotes.entity | valueOfEntityField : context : col }}
        </div>

        <app-due-date
          *ngIf="col['type'] === 'due-date'"
          [dueDate]="entityNotes.entity[col['to-edit'] || '']"
          [callback]="save(entityNotes.entity, col['to-edit'] || '')"></app-due-date>

        <input
          #thisInput
          *ngIf="
            editMode === col['to-edit'] &&
            !['type-ahead', 'due-date', 'textarea'].includes(col['type'] || '')
          "
          class="form-control text-right w-100"
          style="width: 5rem !important"
          [attr.type]="col['type']"
          [attr.pattern]="col['pattern'] || null"
          [attr.value]="entityNotes.entity[col['to-edit']]"
          (keyup.enter)="onSave(entityNotes.entity, col['to-edit'], thisInput.value)"
          (keydown.escape)="editMode = ''"
          (blur)="onSave(entityNotes.entity, col['to-edit'], thisInput.value)"
          autofocus />

        <app-typeahead
          *ngIf="col['type'] === 'type-ahead' && editMode === col['to-edit']"
          (added)="
            $event !== undefined
              ? onSave(
                  entityNotes.entity,
                  col['to-edit'],
                  $event,
                  col['to-edit'] !== 'tags'
                    ? typeaheadItemsPipe.transform(
                        col['to-edit'],
                        entityNotes.entity.toString(),
                        projectCode,
                        context
                      )
                    : []
                )
              : (editMode = '')
          "
          [emitOnBlur]="true"
          [items$]="
            col['to-edit'] | typeaheadItems : entityNotes.entity.toString() : projectCode : context
          "
          [idString]="col['to-edit']"
          [preDeterminedValue]="entityNotes.entity | valueOfEntityField : context : col"
          [isSingleSelection]="col['is-single-selection'] || false"
          [isAutofocused]="true"
          [minWidth]="col['type-ahead-min-width'] || '200'">
        </app-typeahead>
      </td>
      <td *ngSwitchCase="'render-layers'" class="w-100 align-top">
        <app-render-layers
          [renderLayers]="entityNotes.entity[col['to-edit'] || ''] || []"
          [projectCode]="projectCode"
          [context]="context"
          [group]="entityNotes.entity.group"
          [entityCode]="entityNotes.entity.entityCode"></app-render-layers>
      </td>

      <ng-container *ngFor="let subcol of col.cols; index as i" [ngSwitch]="subcol.td">
        <td
          *ngSwitchCase="'img'"
          class="align-top"
          [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
          [style.border-right]="
            i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
          ">
          <ng-container *ngIf="col['name'] === 'SPP'">
            <img
              *ngIf="!(entityNotes.entity['spp'] || {})['is_full_cg']"
              class="img-thumb position-relative left-0"
              [src]="
                (entityNotes.entity[subcol['field'] || ''] || {})['plate']
                  | entity : projectCode : subcol['context']!
                  | thumbnailURL
              "
              (click)="
                entityNotes.entity['spp']['plate']['media']
                  ? play(
                      entityNotes.entity,
                      entityNotes.notes,
                      'MEDIA',
                      entityNotes.entity['spp']['plate']['media']['_id']['$oid']
                    )
                  : null
              "
              width="114"
              height="64" />
            <img
              *ngIf="(entityNotes.entity['spp'] || {})['is_full_cg']"
              class="img-thumb position-relative left-0"
              [src]="entityNotes.entity | fullCGURL"
              (click)="
                entityNotes.entity[subcol['field'] || '']
                  ? play(
                      entityNotes.entity,
                      entityNotes.notes,
                      'MEDIA',
                      entityNotes.entity[subcol['field'] || '']['media']['_id']['$oid']
                    )
                  : null
              "
              width="114"
              height="64" />
          </ng-container>
          <ng-container *ngIf="col['name'] !== 'SPP'">
            <img
              class="img-thumb position-relative left-0"
              [src]="
                entityNotes.entity[subcol['field'] || '']
                  | entity : projectCode : subcol['context']!
                  | thumbnailURL
              "
              (click)="
                entityNotes.entity[subcol['field'] || '']
                  ? play(
                      entityNotes.entity,
                      entityNotes.notes,
                      'MEDIA',
                      entityNotes.entity[subcol['field'] || '']['media']['_id']['$oid']
                    )
                  : null
              "
              width="114"
              height="64" />
          </ng-container>
        </td>
        <td
          *ngSwitchCase="'notes'"
          class="align-top text-left"
          [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
          [style.border-right]="
            i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
          ">
          <app-notes
            [notes]="entityNotes.notes"
            [statuses]="statuses"
            [updatedNote$]="updatedNote$"
            [filterByDept]="col['short-code']"
            [mediaSource]="
              entityNotes.entity.context === 'scripts' ? $any(col['short-code']) : 'MEDIA'
            "
            [entity]="entityNotes.entity"
            [users]="usersInProject"
            [showStatusColors]="showStatusColors"
            (changedVersion)="
              play(
                entityNotes.entity,
                entityNotes.notes,
                entityNotes.entity.context === 'scripts' ? $any(col['short-code']) : 'MEDIA',
                $event['_id']['$oid']
              )
            "></app-notes>
        </td>
        <td
          *ngSwitchCase="'status-select'"
          class="text-center"
          [style.background-color]="
            showStatusColors
              ? findStatusColor(entityNotes.entity[subcol['to-edit'] || ''], statuses)
              : null
          ">
          <app-dropdown
            [selectedItem]="entityNotes.entity[subcol['to-edit'] || '']"
            [choices]="statuses"
            [choiceKey]="'text'"
            [showIconOnlyForSelectedItem]="true"
            [emptyChoice]="statuses[0]"
            [showIconOnlyForSelectedItem]="true"
            [applyBgColor]="showStatusColors"
            [callback]="save(entityNotes.entity, subcol['to-edit'] || '')"></app-dropdown>
        </td>
        <td
          *ngSwitchCase="'renders'"
          class="align-top text-left"
          [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
          [style.border-right]="
            i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
          ">
          <app-farm-status [deptRenders]="getBatches(col['short-code'] || '')"></app-farm-status>
          <!--      {{_entity.}}-->
        </td>
        <td *ngSwitchCase="'user-select'" style="text-align: center">
          <app-dropdown
            [selectedItem]="entityNotes.entity[subcol['to-edit'] || '']"
            [choices]="users[col['short-code'] || ''] || []"
            [choiceKey]="'username'"
            [emptyChoice]="{ username: '-- unassigned --' }"
            [callback]="save(entityNotes.entity, subcol['to-edit'] || '')"></app-dropdown>
        </td>
        <td
          *ngSwitchDefault
          [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
          [style.border-right]="
            i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
          "></td>
        <td
          *ngSwitchCase="'keyvalue-readonly'"
          [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
          [style.border-right]="
            i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
          ">
          <div
            *ngFor="let item of entityNotes.entity[subcol['to-edit'] || ''] | keyvalue"
            class="text-nowrap">
            {{ $any(item.key).replace("_", " ") | titlecase }}: {{ item.value }}
          </div>
        </td>
        <td
          *ngSwitchCase="'keyvalue-values-only-readonly'"
          [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
          [style.border-right]="
            i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
          ">
          <div
            *ngFor="let item of entityNotes.entity[subcol['to-edit'] || ''] | keyvalue"
            class="text-nowrap">
            {{ item.value }}
          </div>
        </td>

        <td
          *ngSwitchCase="'checkbox'"
          class="text-center"
          [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
          [style.border-right]="
            i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
          ">
          <input
            type="checkbox"
            [checked]="(entityNotes.entity | valueOfEntityField : context : subcol) === true"
            (change)="
              editMode = col['name'] + subcol['to-edit'];
              onSave(
                entityNotes.entity,
                subcol['to-edit'] || '',
                getEventTargetAsHTMLInputElement($event).checked
              )
            " />
        </td>

        <td
          *ngSwitchCase="'img-list-with-upload'"
          class="align-top"
          [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
          [style.border-right]="
            i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
          ">
          <app-img-list-with-upload
            [entity]="entityNotes.entity"
            [field]="col['short-code'] || ''"
            [subfield]="subcol['to-edit']"></app-img-list-with-upload>
        </td>

        <td
          *ngSwitchCase="'edit'"
          [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
          [style.border-right]="
            i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
          "
          style="z-index: -1000"
          title="Click to edit"
          (click)="
            $event.stopPropagation();
            editMode !== col['name'] + subcol['to-edit']
              ? (editMode = col['name'] + subcol['to-edit'])
              : null
          ">
          <div
            *ngIf="
              editMode !== col['name'] + subcol['to-edit'] &&
              subcol['type'] !== 'due-date' &&
              !subcol['show-value-as-link']
            "
            [style.width]="subcol['type'] === 'textarea' ? '20rem' : null">
            {{ entityNotes.entity | valueOfEntityField : context : subcol }}
          </div>
          <a
            *ngIf="
              editMode !== col['name'] + subcol['to-edit'] &&
              subcol['type'] !== 'due-date' &&
              subcol['show-value-as-link']
            "
            routerLink="{{ entityNotes.entity | valueOfEntityField : context : subcol : true }}"
            >{{ entityNotes.entity | valueOfEntityField : context : subcol }}</a
          >

          <app-due-date
            *ngIf="subcol['type'] === 'due-date'"
            [dueDate]="entityNotes.entity[subcol['to-edit'] || '']"
            [callback]="save(entityNotes.entity, subcol['to-edit'] || '')"></app-due-date>

          <textarea
            #thisInput
            *ngIf="editMode === col['name'] + subcol['to-edit'] && subcol['type'] === 'textarea'"
            class="form-control w-100"
            style="width: 20rem !important"
            placeholder="Press Ctrl + Enter to Save"
            [textContent]="entityNotes.entity | valueOfEntityField : context : subcol"
            (keyup.control.enter)="
              onSave(entityNotes.entity, subcol['to-edit'] || '', thisInput.value)
            "
            (keydown.escape)="editMode = ''"
            (blur)="onSave(entityNotes.entity, subcol['to-edit'] || '', thisInput.value)"
            autofocus></textarea>

          <input
            #thisInput
            *ngIf="
              editMode === col['name'] + subcol['to-edit'] &&
              !['type-ahead', 'due-date', 'textarea'].includes(subcol['type'] || '')
            "
            class="form-control w-100"
            style="width: 5rem !important"
            [attr.type]="subcol['type']"
            [attr.pattern]="subcol['pattern'] || null"
            [attr.value]="entityNotes.entity | valueOfEntityField : context : subcol"
            (keyup.enter)="onSave(entityNotes.entity, subcol['to-edit'] || '', thisInput.value)"
            (keydown.escape)="editMode = ''"
            (blur)="onSave(entityNotes.entity, subcol['to-edit'] || '', thisInput.value)"
            autofocus />

          <app-typeahead
            *ngIf="subcol['type'] === 'type-ahead' && editMode === col['name'] + subcol['to-edit']"
            (added)="
              $event !== undefined
                ? onSave(
                    entityNotes.entity,
                    subcol['to-edit'] || '',
                    $event,
                    typeaheadItemsPipe.transform(
                      subcol['to-edit'],
                      entityNotes.entity.toString(),
                      projectCode,
                      context,
                      entityNotes.entity['plate']
                    )
                  )
                : (editMode = '')
            "
            [emitOnBlur]="true"
            [items$]="
              subcol['to-edit']
                | typeaheadItems
                  : entityNotes.entity.toString()
                  : projectCode
                  : context
                  : entityNotes.entity['plate']
            "
            [idString]="subcol['to-edit'] || ''"
            [preDeterminedValue]="entityNotes.entity | valueOfEntityField : context : subcol"
            [isSingleSelection]="subcol['is-single-selection'] || false"
            [isAutofocused]="true"
            [minWidth]="subcol['type-ahead-min-width'] || '200'">
          </app-typeahead>
        </td>
        <td
          *ngSwitchCase="'upload-script'"
          [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
          [style.border-right]="
            i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
          ">
          <span
            class="upload"
            title="Upload"
            (click)="
              openUpload = true;
              uploadDirectory = col['short-code']! === 'stages' ? 'scripts' : col['short-code']!
            ">
            <i class="material-icons md-24 align-text-bottom">cloud_upload</i>
          </span>
          <span
            class="download"
            (click)="play(entityNotes.entity, entityNotes.notes, $any(col['short-code']))"
            >{{ entityNotes.entity[subcol["to-edit"]!]?.split("/").pop() }}</span
          >
        </td>
        <td
          *ngSwitchCase="'upload-media'"
          [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
          [style.border-right]="
            i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
          ">
          <span
            class="upload"
            title="Upload"
            (click)="
              openUpload = true;
              uploadDirectory = col['short-code']! === 'stages' ? 'scripts' : col['short-code']!
            ">
            <i class="material-icons md-24 align-text-bottom">cloud_upload</i>
          </span>
          <img
            class="img-thumb"
            [src]="entityNotes.entity | thumbnailURL : $any(col['short-code'])"
            (click)="play(entityNotes.entity, entityNotes.notes, $any(col['short-code']))"
            width="114"
            height="64" />
        </td>
      </ng-container>
    </ng-container>
  </ng-container>

  <app-upload
    *ngIf="openUpload"
    [entity]="entityNotes.entity"
    [scriptsAndStory]="context === 'scripts' ? uploadDirectory : undefined"
    [usersInProject]="usersInProject"
    (closed)="openUpload = false; $event === 'Submitted' ? ngOnInit() : null">
  </app-upload>

  <app-file-browser
    *ngIf="openFileBrowser"
    [projectCode]="projectCode"
    [entity]="entityNotes.entity"
    [allowUploadInFileBrowser]="allowUploadInFileBrowser"
    (closed)="openFileBrowser = false">
  </app-file-browser>

  <div
    *ngIf="hasInvalidInput"
    [ngClass]="{
      'fade show d-block': hasInvalidInput,
      'd-none': !hasInvalidInput,
      modal: hasInvalidInput
    }"
    tabindex="-1"
    role="dialog"
    aria-labelledby="invalidInputMessage"
    aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="saveFilterModal">
            Invalid Input for {{ context | titlecase }}: <br />
            <span class="text-danger">{{ fieldNameOfInvalidInput }}</span>
          </h6>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="hasInvalidInput = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-small-padding alert-danger mb-0 mr-2">
            Please make sure your input is correct or select an item from the dropdown list.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasInvalidInput" class="d-block show modal-backdrop"></div>

  <div
    *ngIf="(sameAsCameraChildren$ | async) && showOffsetForm"
    [ngClass]="{
      'fade show d-block': showOffsetForm,
      'd-none': !showOffsetForm,
      modal: showOffsetForm
    }"
    tabindex="-1"
    role="dialog"
    aria-labelledby="invalidInputMessage"
    aria-hidden="true"
    (exited)="hasInvalidInput = false"
    appModalExit>
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="saveFilterModal">{{ offsetForm.title }}</h6>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="cancelAssignPlate()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Assign offsets:</p>
          <ng-container>
            <form class="form-inline mb-3">
              <div *ngFor="let form of offsetForm.array.controls; index as i" class="form-group">
                <ng-container *ngIf="form">
                  <label
                    >{{ form.value["entity"].toString() }} = {{ form.value["startFrame"] }} +
                  </label>
                  <input
                    type="number"
                    class="form-control mx-sm-3"
                    min="0"
                    [max]="form.value['maxOffset']"
                    style="max-width: 5rem"
                    [(ngModel)]="form.value['offset']"
                    [ngModelOptions]="{ standalone: true }" />
                </ng-container>
              </div>
            </form>
            <div class="text-right">
              <button
                type="button"
                class="btn btn-primary mr-2"
                (click)="assignPlate(entityNotes.entity)">
                Assign
              </button>
              <button type="button" class="btn btn-danger" (click)="cancelAssignPlate()">
                Cancel
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div
    [ngClass]="{ 'fade show d-block': showOffsetForm, 'd-none': !showOffsetForm }"
    class="modal-backdrop"></div>
</ng-container>
