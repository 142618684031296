<div #tableContainer class="table-responsive">
  <ng-container *ngIf="columns$ | async">
    <table class="table table-bordered table-hover table-sm">
      <thead class="thead-light">
        <tr>
          <th colspan="14" class="position-sticky left-0 top-0 bg-white">
            <div class="d-flex flex-wrap py-1 align-items-center">
              <app-drag-n-drop
                [ordering]="columns"
                (changedOrder)="onChangeColumns($event)"
                (saved)="onSavedColumns($event)"></app-drag-n-drop>
              <app-display-columns
                [columns]="columns"
                (changedVisibility)="onChangeColumns($event)"
                (saved)="onSavedColumns($event)"></app-display-columns>
              <app-typeahead
                #searchTypeahead
                [placeholder]="
                  searchByOption === 'tags'
                    ? 'a, b -> a or b | a + b -> a and b'
                    : 'Search example: e0123↵'
                "
                [items$]="searchByOption | typeaheadItems : '' : projectCode : context"
                [isSingleSelection]="searchByOption === 'entityCode'"
                (added)="onSearch($event)"
                [isAutofocused]="true"
                [showDropdownWhenAutofocused]="false"
                [applyBorderWarningOnBlur]="false"
                [emitStringArrayAsString]="searchByOption === 'tags'">
              </app-typeahead>

              <div class="ml-1 form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="search-tags"
                  id="search-tags"
                  [checked]="searchByOption === 'tags'"
                  (change)="searchByOption = searchByOption === 'tags' ? 'entityCode' : 'tags'" />
                <label class="form-check-label" for="search-tags">Search tags</label>
              </div>
              <div class="ml-1 form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="show-oop"
                  id="show-oop"
                  [checked]="showOOP"
                  (change)="showOOP = !showOOP" />
                <label class="form-check-label" for="show-oop">Show out-of-picture</label>
              </div>
              <app-filters
                [filters$]="filters$"
                [context]="context"
                [projectCode]="projectCode"
                [inShotsByEpisodePage]="undefined"
                (toggledFiltersOnOrOff)="onToggledFilters($event)"
                (filtered)="searchAndFilter($event)"></app-filters>
            </div>
          </th>
          <th colspan="99" class="position-sticky top-0 bg-white"></th>
        </tr>
        <tr>
          <ng-container *ngFor="let col of columns; trackBy: columnTrackBy; index as i">
            <th
              *ngIf="col.visible"
              style="top: 50.88px"
              class="position-sticky text-center"
              [attr.colspan]="col.colspan"
              [attr.rowspan]="col.rowspan"
              [style.border]="col.styles.border"
              [style.min-width]="i === 0 ? col.styles['min-width'] : null"
              [style.max-width]="i === 0 ? col.styles['min-width'] : null"
              [style.position]="i === 0 ? 'sticky' : 'inherit'"
              [style.left]="i === 0 ? 0 : null"
              [style.z-index]="i === 0 ? 120 : 100">
              {{ col.name }}
            </th>
          </ng-container>
        </tr>
        <tr>
          <ng-container *ngFor="let col of columns; trackBy: columnTrackBy">
            <ng-container *ngFor="let subcol of col.cols; trackBy: columnTrackBy; index as i">
              <th
                *ngIf="col.visible && subcol"
                class="text-center position-sticky"
                style="z-index: 100; top: 84.26px"
                [style.border-left]="i === 0 ? col.styles.border : '1px solid #dee2e6'"
                [style.border-right]="
                  i === col.cols!.length - 1 ? col.styles.border : '1px solid #dee2e6'
                ">
                {{ subcol.name }}
              </th>
            </ng-container>
          </ng-container>
        </tr>
      </thead>
      <tbody
        *ngIf="
          !openMediaPlayer && (groupedEntities$ | async) as groupedEntities;
          else fetchingData
        ">
        <ng-container *ngIf="groupedEntities.length !== 0; else noResults"></ng-container>
        <ng-container
          #entityRows
          *ngFor="let entity of groupedEntities; trackBy: entityTrackBy; index as i">
          <tr
            *ngIf="
              showEntity(entity) &&
              entity['__nice_name'] &&
              entity['__nice_name'].startsWith('Children') &&
              entity['__code']
            "
            class="bg-white"></tr>

          <tr
            *ngIf="showEntity(entity, groupedEntities, i) && entity['__code']; else isEntity"
            class="bg-white">
            <td
              *ngIf="entity['__nice_name'].startsWith('Children'); else notChildrenRow"
              class="hover-pointer"
              [style.padding-left]="entity['__depth'] | depthAsPadding : false"
              [attr.colspan]="columns.length - 1"
              (click)="toggleChildVisibility(i, entity, groupedEntities)">
              <div class="d-flex">
                <span
                  class="text-nowrap text-truncate d-inline-block font-weight-bold"
                  [style.max-width]="columns[0].styles['min-width']">
                  <i class="material-icons md-18 align-text-bottom font-weight-bold">{{
                    expanded.has(entity["__code"]) ? "keyboard_arrow_down" : "chevron_right"
                  }}</i>
                  {{ entity["__nice_name"] }}</span
                >&nbsp;({{ entity["__direct_children"] || entity["__children"] }})
                {{ entity["__oop_count"] > 0 ? " | OOP: " + entity["__oop_count"] : "" }}
              </div>
            </td>

            <ng-template #notChildrenRow>
              <ng-container
                *ngFor="let col of columns; trackBy: columnTrackBy; index as j"
                [ngSwitch]="col['name']">
                <td
                  *ngSwitchCase="''"
                  [style.padding-left]="entity['__depth'] | depthAsPadding : false"
                  class="hover-pointer"
                  (click)="toggleChildVisibility(i, entity, groupedEntities)">
                  <div class="d-flex">
                    <span
                      class="text-nowrap text-truncate d-inline-block font-weight-bold"
                      [style.max-width]="col.styles['min-width']">
                      <i class="material-icons md-18 align-text-bottom font-weight-bold">{{
                        expanded.has(entity["__code"]) ? "keyboard_arrow_down" : "chevron_right"
                      }}</i>
                      {{ entity["__nice_name"] }}</span
                    >&nbsp;({{ entity["__direct_children"] || entity["__children"] }})
                    {{ entity["__oop_count"] > 0 ? " | OOP: " + entity["__oop_count"] : "" }}
                  </div>
                </td>

                <td
                  *ngSwitchCase="'Tags'"
                  class="bg-white"
                  (click)="editMode[entity['__code']] = col['to-edit']!"
                  title="Click to edit">
                  <span
                    *ngIf="editMode[entity['__code']] !== col['to-edit']"
                    title="Click to edit"
                    >{{ (entity["tags"] || []).join(", ") }}</span
                  >

                  <app-typeahead
                    *ngIf="editMode[entity['__code']] === col['to-edit']"
                    (added)="
                      $event === undefined
                        ? _deleteKey(entity['__code'], editMode)
                        : entity['__nice_name'].startsWith('Drive')
                        ? saveDrive(i, entity, groupedEntities, 'tags', $event)
                        : saveReel(i, entity, groupedEntities, 'tags', $event)
                    "
                    [emitOnBlur]="true"
                    [items$]="col['to-edit'] | typeaheadItems : '' : projectCode : context"
                    [idString]="col['to-edit']"
                    [preDeterminedValue]="$any(entity) | valueOfEntityField : context : $any(col)"
                    [isSingleSelection]="col['is-single-selection']!"
                    [isAutofocused]="true"
                    [minWidth]="'300'">
                  </app-typeahead>
                </td>

                <td *ngSwitchCase="'Is Out of Picture'" class="text-center bg-white">
                  <input
                    type="checkbox"
                    [checked]="entity['out_of_picture']"
                    (change)="onChangeOutOfPictureCheckbox(i, entity, groupedEntities, $event)" />
                </td>

                <td *ngSwitchDefault class="bg-white"></td>
              </ng-container>
            </ng-template>
          </tr>

          <ng-template #isEntity>
            <tr
              *ngIf="showEntity(entity, groupedEntities, i)"
              style="height: 118px"
              appIntersectionObserver
              #intersection="intersection"
              app-entity-table-view
              [columns]="columns"
              [_entity]="entity"
              [isIntersecting]="intersection.isIntersecting"
              [__updates]="entity['__updates']"
              (updatedEntity)="updateEntity(groupedEntities, $any($event))"
              (toggledOOP)="onToggledOOP(i, groupedEntities, entity, $event)"></tr>
          </ng-template>
        </ng-container>
      </tbody>

      <ng-template #fetchingData>
        <!-- this will show Fetching data after the table headings are rendered -->
        <tbody>
          <tr>
            Fetching data...
          </tr>
        </tbody>

        <tbody></tbody
      ></ng-template>

      <ng-template #noResults>
        <!-- this will show Fetching data after the table headings are rendered -->
        <tbody>
          <tr>
            No
            {{
              context
            }}
            found given the filters.
          </tr>
        </tbody>

        <tbody></tbody
      ></ng-template>
    </table>
  </ng-container>

  <app-media-player
    *ngIf="openMediaPlayer && viewMedia"
    [play$]="viewMedia.media"
    [entity]="viewMedia.entity"
    [notes]="viewMedia.notes"
    [mediaSource]="viewMedia.src"
    [selectedVersionID1]="viewMedia.selectedVersion"
    (closed)="openMediaPlayer = $event">
  </app-media-player>
</div>
