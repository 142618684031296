<div class="project-detail-tab-root" *ngIf="project$ | async as project">
  <h3>Details</h3>
  <!-- these two are required fields-->
  <p><strong>name: </strong>{{ project.name }}</p>
  <p><strong>code: </strong>{{ project.code }}</p>

  <!-- these are for custom added fields-->
  <ng-container *ngFor="let object of project | keyvalue">
    <p *ngIf="!requiredFields.includes(object.key)">
      <strong>{{ object.key }}: </strong><span>{{ object.value }}</span>
    </p>
  </ng-container>

  <p>
    <a href="javascript:void(0);" (click)="openFileBrowser = true">
      Download Software
    </a>
  </p>
  <app-file-browser
    *ngIf="openFileBrowser"
    [projectCode]="project.code"
    [entity]="'software'"
    [allowUploadInFileBrowser]="false"
    (closed)="openFileBrowser = false">
  </app-file-browser>

  <p>
    <a download="homerun_client.bat" href="/api/homerun_installer">
      Download HomeRun Client
    </a>
  </p>
  <h4>HomeRun Client install instructions.</h4>

  <p>
    The HomeRun Client requires you to have Python 3.10 or Python 3.11 installed. Please
    install them before the HomeRun Client.
    You can <a href="https://www.python.org/downloads/">download and install Python here.</a>
  </p>
  <p>
    The download link above will download a single script with a .bat extension (for windows).
    You will use this every time to run the client. It doesn't need to be in any special location,
    so feel free to put it anywhere that's convenient for you, such as your desktop, and double click it.
    The first time to you run it, it will install the client in your user folder under "elliott/EA2D". It will
    also check for updates and install them when you run it in future. To uninstall, just delete
    the "elliott" folder.
  </p>
  <p>
    Your individual copy of the HomeRun Client launch script is <i>specific to you</i>,
    so please don't share it around with your friends or coworkers, as it may stop working unexpectedly.
    You can download as many copies as you want for yourself, or copy it as many times as you want.
  </p>
</div>
