import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { AuthService } from "./auth.service";
import { UserToken } from "./user";
import { domain } from "../environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "rTracker";
  isLoggedIn: boolean;
  loggedInResolved: boolean;
  subs: Subscription[] = [];
  sphinxdocsURL = `${domain}/sphinxdocs/`;
  canViewDevDocs: boolean | undefined = undefined;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.subs.push(
      this.authService.loggedInResolved$.subscribe(
        (loggedInResolved) => (this.loggedInResolved = loggedInResolved)
      )
    );
    this.subs.push(
      this.authService.isLoggedIn$.subscribe((loggedIn) => {
        // note: this executes an unbelievable number of times. Not sure if we can cut down on the number of executions.
        this.isLoggedIn = loggedIn;
        // console.log(`logged in: ${this.isLoggedIn}`);
        if (loggedIn) {
          // I'm adding my username here, so that I can easily run the swagger api page.
          this.canViewDevDocs = ["Technical Director"].includes(
            this.authService.getUserRole()) || ["jlandy"].includes(UserToken.getUsername());
        } else {
          this.canViewDevDocs = false;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  logout() {
    this.authService.logout().subscribe((_) => {
      console.log("Logged out");
      UserToken.removeToken();
      this.canViewDevDocs = false;
      this.router.navigate(["login"]);
    });
  }

  isActive(link: string): boolean {
    if (link === 'projects') {
      const url = this.router.url;
      return this.router.url.startsWith(`/${link}`) && !url.endsWith('documentation-links');
    }
    return this.router.url.startsWith(`/${link}`);
  }

  // canViewDevDocumentation(): boolean {
  //   console.log(`canViewDevDocumentation called. user role: ${this.authService.getUserRole()}`);
  //   return ["Admin", "Technical Director"].includes(this.authService.getUserRole());
  // }
}
