import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";

import { DropdownArrowSelect } from "../dropdown-arrow-select";
import { applyMixins } from "../utils";

/*
TODO: this is a bit difficult because it allows different choice keys, and the choices Input to be a string[] or
 any object satisfying with type {[choiceKey: string]: string}. Could potentially refactor this using either inheritance
 for more clearly defined input.
 */

type ChoiceKeyedItem = { [choiceKey: string]: any };

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnInit, DropdownArrowSelect, OnDestroy, OnChanges {
  @Input() choices: string[] | ChoiceKeyedItem[]; // IDropDown item satisfies this.
  @Input() selectedItem: string | ChoiceKeyedItem;
  @Input() showIconOnlyForSelectedItem?: boolean;
  @Input() applyBorder?: boolean;
  @Input() showDropArrow?: boolean;
  @Input() choiceKey?: string = "text";
  @Input() emitChoiceObj?: boolean;
  @Input() callback?: (arg0: string | ChoiceKeyedItem) => Observable<any> | void;
  @Input() itemTransformer?: Function = (item: string | ChoiceKeyedItem | undefined) => {
    if (item === undefined || typeof item === "string") {
      return item;
    }
    return item?.[this.choiceKey!] ?? item;
  };
  @Input() emptyChoice?: string | ChoiceKeyedItem;
  @Input() applyBgColor: boolean = true;

  @Output() selectionChanged = new EventEmitter<any>();

  subs: Subscription[] = [];

  showDropdown: boolean = false;
  focusedItem: number = -1;
  initialFocusedItem: number = -1;
  initialSelectedItem: string | { [choiceKey: string]: string };

  // DropdownArrowSelect mixin
  arrowSelect: (i: number, choices: number) => number;
  @ViewChild("dropdown") dropdown: ElementRef;
  @ViewChild("btn") btn: ElementRef;
  step: number = 1;
  maxItemsShown = 10;
  liHeight = 27; // or this.dropdown.nativeElement.children[0].children[0].offsetHeight;
  maxHeight = this.liHeight * this.maxItemsShown; // or this.dropdown.nativeElement.offsetHeight;
  currentScroll = 0;

  timeoutID: number;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["selectedItem"] && !changes["selectedItem"].firstChange) {
      this.ngOnInit();
    }
  }

  ngOnInit() {
    if (this.selectedItem === undefined) {
      this.selectedItem = "";
    }

    this.initialSelectedItem = this.selectedItem;

    let i = 0;
    for (; i < this.choices.length; i++) {
      if (this.choiceKey) {
        this.choices = this.choices as ChoiceKeyedItem[];
        if (this.choices[i][this.choiceKey] === this.selectedItem) {
          this.focusedItem = i;
          this.selectedItem = this.choices[i];
          break;
        }
      } else {
        if (this.choices[i] === this.selectedItem) {
          this.focusedItem = i;
          this.selectedItem = this.choices[i];
          break;
        }
      }
    }

    // Not sure what this does..
    //if (i === this.choices.length) {
    //  if (this.choiceKey && this.selectedItem) {
    //    this.selectedItem = {[this.choiceKey]: this.selectedItem} as ChoiceKeyedItem;
    //  }
    //}

    this.initialFocusedItem = this.focusedItem;
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  onEnter() {
    if (!this.showDropdown) {
      this.showDropdown = true;
      return;
    }

    this.selectedItem = this.choices[this.focusedItem];
    let emitting: string | ChoiceKeyedItem;
    if (typeof this.selectedItem === "string") {
      emitting = this.selectedItem;
    } else {
      emitting =
        this.emitChoiceObj || !this.choiceKey
          ? this.choices[this.focusedItem]
          : (this.choices as ChoiceKeyedItem[])[this.focusedItem][this.choiceKey];
    }
    this.selectionChanged.emit(emitting);

    this.showDropdown = false;
    this.clearTimeoutID();

    if (this.callback) {
      const sub = this.callback(emitting);
      if (!sub) {
        return;
      }

      this.subs.push(
        sub.subscribe(
          () => {
            this.initialSelectedItem = this.selectedItem;
            this.initialFocusedItem = this.focusedItem;
          },
          (err: ErrorEvent) => {
            alert(err.error.description);
            this.selectedItem = this.initialSelectedItem;
            this.focusedItem = this.initialFocusedItem;
            this.cdr.markForCheck();
          }
        )
      );
    }
  }

  scrollTo() {
    this.timeoutID = window.setTimeout(() => {
      this.currentScroll = this.focusedItem * this.liHeight;
      if (this.dropdown) {
        this.step = this.focusedItem + 1;
        this.dropdown.nativeElement.scrollTop = this.currentScroll;
        this.dropdown.nativeElement.scrollTo(0, this.currentScroll);
      }
    }, 0);
  }

  clearTimeoutID() {
    clearTimeout(this.timeoutID);
  }

  getColor() {
    if (this.choiceKey && this.applyBgColor) {
      // this.selectedItem = this.selectedItem as ChoiceKeyedItem
      if (this.selectedItem && (this.selectedItem as ChoiceKeyedItem).color) {
        return (this.selectedItem as ChoiceKeyedItem)["color"];
      }

      if (this.emptyChoice && (this.emptyChoice as ChoiceKeyedItem)["color"]) {
        return (this.emptyChoice as ChoiceKeyedItem)["color"];
      }
    }
    return "#fff";
  }

  isStr(stringish: any) {
    return typeof stringish === "string";
  }

  getCssClassesFromChoice(choice: string | ChoiceKeyedItem) {
    if (typeof choice === "string") {
      return "";
    }

    return choice["css-classes"];
  }
}
applyMixins(DropdownComponent, [DropdownArrowSelect]);
