import { BehaviorSubject, Observable } from "rxjs";
import { Note } from "./notes";
import { IStage } from "./dropdown-item";
import { MongoDate } from "./mongo-document";

export type Context = "assets" | "shots" | "episodes" | "plates" | "scripts" | "hdri";

type _IEntity =
  | {
      context: "assets";
      projectCode: string;
      asset_type: string;
      asset_code: string;
      media?: IMedia[];
      catalog?: IMedia[];
      [propName: string]: any;
    }
  | {
      context: "shots";
      projectCode: string;
      episode_code: string;
      shot_code: string;
      media?: IMedia[];
      catalog?: IMedia[];
      [propName: string]: any;
    }
  | {
      context: "episodes";
      projectCode: string;
      episode_code: string;
      episode_name: string;
      script_code: string;
      [propName: string]: any;
    }
  | {
      context: "plates";
      plate_code: string;
      [propName: string]: any;
    }
  | {
      context: "scripts";
      script_code: string;
      script_name?: string;
      stages: IStage[];
      storyboards?: IStage[];
      [propName: string]: any;
    }
  | {
      context: "hdri";
      hdri_code: string;
      hdri_name: string;
      [propName: string]: any;
    }
  | {
      projectCode: string;
      context: Context;
      group: string;
      entityCode: string;
      [propName: string]: any;
    };

export type IEntity =
  | _IEntity
  | (_IEntity & { __code: string; __depth: number; __children: number; __nice_name: string });

export interface IFramesInfo {
  diff_a: number;
  diff_b: number;
  first_frame: number;
  fps: number;
  total_frames: number;
}

export interface IMedia {
  _id: { $oid: string };
  dept: string;
  res: string;
  version: string;
  extern: boolean;
  submitted_by?: string;
  movie?: string;
  original_movie?: string;
  thumbnail?: string;
  upload_date?: MongoDate;
  frames_info?: IFramesInfo;
  frame_timestamps?: number[];
  source_file?: string;
  comment?: string;
  [propName: string]: any;
}

export interface IDocument {
  version: string;
  upload_date?: MongoDate;
  stage_name: string;
  file_path: string;
  original_filename: string;
  [propName: string]: any;
}

export const emptyMedia = {
  text: "-- no specific media / version --",
  dept: "",
  res: "",
  version: "",
  extern: false,
  _id: { $oid: "" },
};
export const emptyDocument = {
  text: "-- no specific document / version --",
  version: "",
  stage_name: "",
  file_path: "",
  original_filename: "",
  _id: { $oid: "" },
};

export enum MediaType {
  THUMBNAIL = "thumbnail",
  MOVIE = "movie",
}

export class Entity {
  projectCode: string;
  context: Context;
  group: string;
  entityCode: string;
  media: IMedia[];
  catalog?: IMedia[];
  //jobIds: object; unused. now stored in `jobs2`
  children: Entity[];
  script_code?: string;
  stages?: { [key: string]: IStage[] }[];
  storyboards?: { [key: string]: IStage[] }[];
  assembly?: { [key: string]: IStage[] }[];
  leica?: { [key: string]: IStage[] }[];

  [propName: string]: any; // TODO: This makes Entity not type safe.

  // TODO: Question - do all script Entities or whatever have these defined?
  notesBuffer?: BehaviorSubject<Note[]>;
  notesBuffer$?: Observable<Note[]>;

  constructor(entity: IEntity) {
    this.projectCode = entity.projectCode || "";
    this.group = entity.asset_type || entity.episode_code || entity.drive;
    this.context = entity.context;

    // make sure to assign the plate_code as entityCode since plates also have a shot_code field
    if (this.context === "plates") {
      this.entityCode = entity.plate_code;
    } else if (this.context === "episodes") {
      this.entityCode = entity.episode_code;
      this.script_code = entity.script_code;
      this.assembly = entity.assembly;
    } else if (this.context === "scripts") {
      this.group = "";
      this.entityCode = entity.script_code;
      this.stages = entity.stages;
      this.storyboards = entity.storyBoards;
      this.assembly = entity.assembly;
      this.leica = entity.leica;
      this.script_code = entity.script_code;
      this.notesBuffer = new BehaviorSubject<Note[]>([]);
      this.notesBuffer$ = this.notesBuffer.asObservable();
    } else {
      this.entityCode =
        entity.asset_code || entity.shot_code || entity.plate_code || entity.hdri_code;
    }

    this.media = entity.media || [];
    this.catalog = entity["catalog"] || [];
    this.jobIds = entity.job_ids || [];

    delete entity.project_code;
    delete entity.asset_type;
    delete entity.asset_code;
    delete entity.plate_code;
    delete entity.hdri_code;

    // only delete this field if context is not in plates since plates also have a shot_code field
    if (this.context !== "plates") {
      delete entity.shot_code;
      delete entity.script_code;
    }
    if (this.context !== "episodes") {
      delete entity.script;
    }
    if (this.context !== "scripts") {
      delete entity.stages;
      delete entity.storyboards;
      //delete entity.assembly;
      delete entity.leica;
      delete entity.episode_code;
    }

    delete entity.job_ids;
    Object.assign(this, entity); // any additional properties on the IEntity get copied over to the Entity instance.
  }

  isLatestThumbnailExpandable() {
    try {
      return this.media.length > 0;
    } catch (e) {
      return false;
    }
  }

  getLatestCatalogMedia(dept?: string, res?: string): IMedia | null {
    try {
      if (dept && !res) {
        for (const media of this.catalog ?? []) {
          if (media.dept === dept) {
            return media;
          }
        }
      }

      if (dept && res) {
        for (const media of this.catalog ?? []) {
          if (media.dept === dept && media.res === res) {
            return media;
          }
        }
      }

      return null;
    } catch (e) {
      return null;
    }
  }

  getLatestMedia(dept?: string, res?: string): IMedia | null {
    try {
      if (!dept && !res) {
        const media = this.media[0];
        if (media["dept"] === "plt" && this.context !== "plates") {
          if (this.media && this.media.length === 1) {
            return this.media[0];
          }
          return this.media[1];
        } else {
          return media;
        }
      }

      if (dept && !res) {
        for (const media of this.media) {
          if (media.dept === dept) {
            return media;
          }
        }
      }

      if (dept && res) {
        for (const media of this.media) {
          if (media.dept === dept && media.res === res) {
            return media;
          }
        }
      }

      return null;
    } catch (e) {
      return null;
    }
  }

  findDefaultThumbnail() {
    if (!this["default_thumbnail"]) {
      return null;
    }

    try {
      for (const media of this.media) {
        if (media["_id"]["$oid"] === this["default_thumbnail"]["_id"]["$oid"]) {
          return media;
        }
      }
    } catch (e) {
      return null;
    }

    return null;
  }

  toString() {
    if (this.context === "plates" || this.context === "scripts" || this.context === "assets") {
      return this.entityCode;
    }
    if (this.context === "episodes") {
      return this.group;
    }

    return this.entityCode
      ? `${this.group ? this.group.concat("_") : ""}${this.entityCode}`
      : `${this.group}`;
  }

  toStringWithEpisodeName() {
    if (this.context !== "assets") {
      return `${this.group}_${this.entityCode} · ${this["episode_name"] || "<no name>"}`;
    }

    return "";
  }
}

export class ScriptEntity extends Entity {
  script_code: string;
  stages: { [key: string]: IStage[] }[];
  notesBuffer: BehaviorSubject<Note[]>;
  notesBuffer$: Observable<Note[]>;
}

// there is another typedef Status in utils.ts
export interface IStatus {
  text: string;
  icon: string;
  color?: string;
}

export function isIEntity(obj: IEntity): obj is IEntity {
  return (
    (obj as IEntity).asset_code !== undefined ||
    (obj as IEntity).shot_code !== undefined ||
    (obj as IEntity).plate_code !== undefined ||
    (obj as IEntity).entityCode !== undefined
  );
}
