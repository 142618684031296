import {
  Component,
  Input,
  OnInit,
  AfterContentChecked,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription, Observable, of, forkJoin } from "rxjs";
import { map, tap } from "rxjs/operators";

import { IEntity, Entity, Context, IMedia, IStatus } from "../entity";
import { EntityService, PlayMedia } from "../entity.service";
import { TableConfigColumnItem } from "../../types/table-config";
import { Filters } from "../../types/filters";
import { ProjectService } from "../project.service";
import { UserService } from "../user.service";
import { QueryParamService } from "../query-param.service";
import { NoteService } from "../note.service";
import { User, GroupByDept } from "../user";
import { createEmptyUser } from "../utils";
import { TypeaheadComponent } from "../typeahead/typeahead.component";

@Component({
  selector: "app-entities-card-view",
  templateUrl: "./entities-card-view.component.html",
  styleUrls: ["./entities-card-view.component.css"],
})
export class EntitiesCardViewComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() primarySoftware: string;
  @Input() allowUploadInFileBrowser: boolean;
  @Input() allowDownloadWorkfileInMediaPlayer: boolean;
  @Input() inChildView?: boolean;
  @Input() hasFilters?: boolean = false;

  projectCode: string;
  context: Context;
  entities$: Observable<Entity[]>;
  editMode: { [key: string]: Set<string> } = {};
  columns$: Observable<TableConfigColumnItem[]>;
  columns: TableConfigColumnItem[];
  statuses$: Observable<IStatus[]>;
  users$: Observable<{ [key: string]: User[] }>;
  obs$: Observable<{
    statuses: IStatus[];
    users: { [key: string]: User[] };
    usersInProject: User[];
    columns: TableConfigColumnItem[];
  }>;
  usersInProject$: Observable<User[]>;
  showApproved: boolean;

  filters$: Observable<Filters>;
  isFiltersEnabled: boolean;

  searchByOption: "tags" | "entityCode" | "";
  searching: boolean;

  openAddEntity: boolean = false;
  showFilters: boolean;
  viewMedia: PlayMedia;
  openMediaPlayer: boolean;
  subs: Subscription[] = [];
  selectedEntity: Entity | boolean;

  scrollPosition: { x: number; y: number };

  showStatusColors = true; // todo: control removed for this, so will always be true.

  cardsContainer: ElementRef;
  @ViewChild("cardsContainer") set setCardContainer(ref: ElementRef) {
    this.cardsContainer = ref;
  }

  searchTypeahead: TypeaheadComponent;
  @ViewChild("searchTypeahead") set setSearchTypeahead(ref: TypeaheadComponent) {
    this.searchTypeahead = ref;
  }

  constructor(
    private entityService: EntityService,
    private projectService: ProjectService,
    private userService: UserService,
    private queryParamService: QueryParamService,
    private noteService: NoteService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    const parentURL = this.activatedRoute.parent?.snapshot.url;
    const urlSegments = this.activatedRoute.snapshot.url;
    this.projectCode = parentURL ? parentURL[1].path : "";
    this.context = urlSegments[0].path as Context;
  }

  getEntities() {
    this.entities$ = this.entityService.getUnGroupedEntities(this.projectCode, this.context).pipe(
      map((entities) => {
        return entities.map((entity) => {
          entity.projectCode = this.projectCode;
          entity.context = this.context;
          const e = new Entity(entity);
          this.editMode[e.toString()] = new Set<string>();
          return e;
        });
      })
    );
  }

  ngOnInit() {
    const showApproved: string = window.localStorage.getItem("showApproved") ?? "";
    if (showApproved) {
      this.showApproved = showApproved === "true";
    }

    this.searchByOption = "entityCode";

    this.filters$ = this.projectService.getFilters(this.projectCode, this.context).pipe(
      tap((filters) => {
        this.isFiltersEnabled = filters["is_enabled"];
        this.getEntities();
      })
    );
    this.subs.push(this.filters$.subscribe());

    this.columns$ = this.projectService.getTableConfig(
      this.projectCode,
      this.context.slice(0, this.context.length - 1)
    );
    this.statuses$ = this.entityService.getStatuses(this.projectCode, this.context);
    this.users$ = (
      this.userService.getUsersByProject(this.projectCode, this.context) as Observable<{
        [key: string]: User[];
      }>
    ).pipe(
      map((users) => {
        for (const dept of Object.keys(users)) {
          users[dept] = [createEmptyUser()].concat(users[dept]);
        }
        return users;
      })
    );

    this.usersInProject$ = this.userService.getUsersByProject(
      this.projectCode,
      this.context,
      GroupByDept.no
    ) as Observable<User[]>;

    this.obs$ = forkJoin([this.statuses$, this.users$, this.usersInProject$, this.columns$]).pipe(
      map((values) => {
        return {
          statuses: values[0],
          users: values[1],
          usersInProject: values[2],
          columns: values[3],
        };
      })
    );

    this.subs.push(
      this.entityService.playMedia$.subscribe((viewMedia) => {
        this.scrollPosition = {
          x: this.cardsContainer.nativeElement.scrollLeft,
          y: this.cardsContainer.nativeElement.scrollTop,
        };

        // TODO: This gets the media twice. Once here and in media-player.component ngOnInit()
        viewMedia["media"].subscribe((media: IMedia[]) => {
          if (media.length > 0) {
            this.openMediaPlayer = true;
            this.viewMedia = viewMedia;
            this.cdr.markForCheck();
          } else {
            alert(
              `No items in ${viewMedia["src"].toLowerCase()} for ${viewMedia["entity"].toString()}.`
            );
          }
        });
      })
    );

    this.play();
  }

  ngAfterContentChecked(): void {}

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  // onTurnOffPlaySubscription($event: boolean) {
  //   if ($event) {
  //   }
  // }

  onToggledFilters($event: boolean) {
    this.isFiltersEnabled = $event;
    this.getEntities();
  }

  onSearch(searchTerm: string | string[] | undefined) {
    // we're expecting searchTerm to be string but typeahead component can emit string | string[] | undefined
    if (searchTerm === undefined || Array.isArray(searchTerm)) {
      // search is cancelled by pressing 'esc'
      this.searching = false;
      return;
    }

    this.queryParamService.updateQueryParams({
      expand: null,
      "show-all-notes": null,
      note: null,
      "show-completed-notes": null,
    });

    if (searchTerm === "") {
      this.searching = false;
      this.ngOnInit();
      return;
    }

    this.searching = true;
    let search = searchTerm;
    const regexp = /\+/g;
    search = search.replace(regexp, "%2B");
    this.entities$ = this.entityService
      .searchUnGroupedEntities(this.projectCode, this.context, {
        search: search,
        searchType: this.searchByOption,
        exactMatch: false,
      })
      .pipe(
        map((entities: IEntity[]) => {
          return entities.map((entity) => {
            entity.projectCode = this.projectCode;
            entity.context = this.context;
            const e = new Entity(entity);
            this.editMode[e.toString()] = new Set<string>();
            return e;
          });
        })
      );
  }

  play() {
    const play = this.activatedRoute.snapshot.queryParams["play"];
    const version = this.activatedRoute.snapshot.queryParams["version"];
    const context = this.activatedRoute.snapshot.queryParams["play-ctx"];
    const src = this.activatedRoute.snapshot.queryParams["play-src"];
    const compare = this.activatedRoute.snapshot.queryParams["compare"];
    const audio = this.activatedRoute.snapshot.queryParams["audio"] || "left";
    if (!play && !version && !context && !src) {
      return;
    }

    if (this.cardsContainer) {
      this.scrollPosition = {
        x: this.cardsContainer.nativeElement.scrollLeft,
        y: this.cardsContainer.nativeElement.scrollTop,
      };
    }

    const _entity = {
      projectCode: this.projectCode,
      context: context as Context,
      group: play.split("_")[0],
      entityCode: play.split("_").slice(1).join("_"),
    };
    this.entityService
      .findOne(
        this.projectCode,
        this.context as Context,
        _entity.group,
        _entity.entityCode,
        "fields=episode_code,asset_type,asset_code,shot_code,media,catalog,plate,default_thumbnail&exclude_fields=_id"
      )
      .subscribe((entity) => {
        let media: IMedia[] = [];
        const mediaOrCatalog = src.toLowerCase();

        if (entity && entity[mediaOrCatalog] && entity["plate"] && entity["plate"]["media"]) {
          // inject the plate media (if it exists) into the entity's media
          entity[mediaOrCatalog].splice(0, 0, entity["plate"]["media"]);
          media = entity[mediaOrCatalog];
        } else if (entity && entity[mediaOrCatalog] && !entity["plate"]) {
          media = entity[mediaOrCatalog];
        }

        entity = new Entity({ ...entity, projectCode: this.projectCode, context: context });
        this.noteService.getNotes(entity).subscribe((notes) => {
          this.entityService.getStatuses(this.projectCode, this.context).subscribe((statuses) => {
            // trigger for media component to open
            this.entityService.playMedia.next({
              notes: notes || [],
              media: of(media),
              selectedVersion: version,
              compare: compare,
              audio: audio,
              src: src,
              entity: entity as Entity,
              defaultThumbnail: entity["default_thumbnail"],
              statuses: statuses,
            });
          });
        });
      });
  }

  searchAndFilter() {
    this.scrollPosition = { x: 0, y: 0 };

    if (this.searchTypeahead.inputString !== "") {
      // if we are filtering and there is any text on the search box, search for that item using the filters
      this.entities$ = this.entityService
        .searchUnGroupedEntities(this.projectCode, this.context, {
          search: this.searchTypeahead.inputString,
          searchType: this.searchByOption,
          exactMatch: false,
        })
        .pipe(
          map((entities) => {
            return entities.map((entity) => {
              entity.projectCode = this.projectCode;
              entity.context = this.context;
              const e = new Entity(entity);
              this.editMode[e.toString()] = new Set<string>();
              return e;
            });
          })
        );
    } else {
      this.getEntities();
    }
  }

  onChangeShowApproved() {
    this.showApproved = !this.showApproved;
    window.localStorage.setItem("showApproved", String(this.showApproved));
  }
}
