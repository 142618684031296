import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Entity, IEntity, IMedia, IDocument } from "./entity";
import { environment } from "../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class ScriptService {
  constructor(private http: HttpClient) {}

  // Something like this should probably be in entity service?
  // TODO: Needs refactor to type properly.
  update(entity: Entity, itemToBeUpdated: object): Observable<any> {
    const url = `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${entity.entityCode}`;
    return this.http.put<any>(url, JSON.stringify(itemToBeUpdated), httpOptions);
  }

  // getAll(projectCode: string, filter=null): Observable<object[]> {
  //   let url = "";
  //   if (filter != null) {
  //     url = `${environment.projectsURL}/${projectCode}/scripts?fields=${filter}`;
  //   }
  //   else {
  //     url = `${environment.projectsURL}/${projectCode}/scripts`;
  //   }
  //
  //   return this.http.get<object[]>(url);
  // }
  // TODO: create dedicated script type.
  getAllEntities(projectCode: string): Observable<IEntity[]> {
    let url = `${environment.projectsURL}/${projectCode}/scripts`;

    return this.http.get<IEntity[]>(url);
  }

  getThumbnailURL(
    projectCode: string,
    scriptCode: string,
    propertyType: string,
    stage = "",
    version = ""
  ): string {
    if (!stage && !version) {
      return `${environment.projectsURL}/${projectCode}/scripts/${propertyType}/${scriptCode}/thumbnail`;
    } else {
      return `${environment.projectsURL}/${projectCode}/scripts/${propertyType}/${scriptCode}/thumbnail?stage=${stage}&version=${version}`;
    }
  }

  getMediaURL(entity: Entity, media: IMedia | IDocument, src?: string): string {
    if (media.res === "assembly") {
      return `${environment.projectsURL}/${entity.projectCode}/shots/${
        entity.episode_code ?? entity.group
      }/assembly/movie?id=${media._id.$oid}`;
    } else {
      return `${environment.projectsURL}/${entity.projectCode}/${entity.context}/${src}/${entity.entityCode}/movie?version=${media.version}&script-stage=${media.stage}`;
    }
  }
}
