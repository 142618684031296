import { Directive, ElementRef, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { IntersectionObserverService } from "./intersection-observer.service";

@Directive({
  selector: "[appIntersectionObserver]",
  exportAs: "intersection",
})
export class IntersectionObserverDirective implements OnInit, OnDestroy {
  isIntersecting: boolean = false;

  constructor(
    private iso: IntersectionObserverService,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.iso.addChild(this.elementRef.nativeElement, (isIntersecting: boolean) => {
      this.isIntersecting = isIntersecting;
      this.cdr.markForCheck();
      //if (this.isIntersecting && this.parentObserver.childObservers.get(this.elementRef.nativeElement)) {
      //  this.parentObserver.removeChild(this.elementRef.nativeElement);
      //}
    });
  }

  ngOnDestroy() {
    this.iso.removeChild(this.elementRef.nativeElement);
  }
}
