import { Component, OnInit, ViewChild, AfterViewChecked, OnDestroy } from "@angular/core";

import { Subscription, Observable, forkJoin } from "rxjs";
import { tap } from "rxjs/operators";
import { UserService } from "../user.service";
import { AuthService } from "../auth.service";
import { DepartmentService } from "../department.service";
import { ProjectService } from "../project.service";
import { User } from "../user";
import { Project } from "../project";
import { Role } from "../role";
import { TypeaheadComponent } from "../typeahead/typeahead.component";
import type {
  OneUserProjectsDeptsUpdate,
  UsersProjectsDeptsUpdate,
} from "../users/users.component";
import { Filters } from "../../types/filters";

@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.css"],
})
export class UserListComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild("searchTypeahead") searchTypeahead: TypeaheadComponent;

  projects: Project[];
  departments: string[];
  roles: Role[];
  accessRoles: string[];
  selectAll = false;
  showInactiveUsers = false;
  success: boolean;
  updatedUsers: string;
  updatedProjects: string;
  updatedDepts: string;
  updatedRoles: string;
  updatedAccountStatus: string;
  confirming: boolean;
  confirmChangesData: {
    projects: string[];
    projectsNiceNames: string[];
    roles: string;
    active: boolean;
    users: string[];
    depts: string[];
  };
  hasAccess: boolean;

  projectCode = "rt";
  context = "users";
  filters$: Observable<Filters>; // Todo: return {} from backend if user has no filters.
  search: string | undefined;
  searchByUsername: boolean;
  searchByFullname: boolean;
  hasFilters: boolean = false;
  showFilters: boolean;
  activeTypeAheadItems$: string[];

  // TODO: not actually used for anything. Strip out.
  iconClass: { "text-muted": boolean; "text-success": boolean } | undefined;

  users: User[];
  filteredUsers: User[];
  subs: Subscription[] = [];

  constructor(
    private userService: UserService,
    private projectService: ProjectService,
    private departmentService: DepartmentService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    const userRole = this.authService.getUserRole();

    this.accessRoles = ["Admin", "Production"];

    this.hasAccess = this.checkPermission(userRole);

    // this makes sure that we have the projects, depts and roles before displaying each individual user
    forkJoin({
      projects: this.projectService.getProjects(),
      depts: this.departmentService.getAllDepartments(),
      roles: this.userService.getRoles(),
    }).subscribe(({ projects, depts, roles }) => {
      this.projects = projects;
      this.departments = depts;
      this.roles = roles;
    });

    this.confirmChangesData = {
      users: [],
      projects: [],
      projectsNiceNames: [],
      depts: [],
      roles: "",
      active: false,
    };

    this.filters$ = this.projectService.getFilters(this.projectCode, this.context).pipe(
      // TODO: had to type filters as any here, since its type is unclear.
      tap((filters: Filters) => {
        const useSavedFilters =
          filters !== undefined && typeof filters === "object" && <boolean>filters["is_enabled"];
        this.userService.getUsers(useSavedFilters).subscribe((users) => {
          this.users = users;
          this.filteredUsers = [...users];
          this.getSearchItems();
        });
      })
    );

    this.subs.push(this.filters$.subscribe());
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  ngAfterViewChecked() {
    if (this.iconClass === undefined) {
      this.iconClass = { "text-muted": !this.hasFilters, "text-success": this.hasFilters };
    } else if (this.iconClass["text-success"] !== this.hasFilters) {
      this.iconClass = { "text-muted": !this.hasFilters, "text-success": this.hasFilters };
    }
  }

  checkPermission(userRole: string) {
    return this.accessRoles.includes(userRole);
  }

  getSelectedUsers(): string[] {
    const selectedUsers: string[] = [];
    const userCheckboxes = document.querySelectorAll(
      "input[type=checkbox].form-check-input.user-selected"
    );

    Array.prototype.forEach.call(userCheckboxes, (item) => {
      if (item.checked) {
        selectedUsers.push(item.value);
      }
    });

    return selectedUsers;
  }

  confirmChanges(projectsAndDepts: UsersProjectsDeptsUpdate | OneUserProjectsDeptsUpdate) {
    if (!this.hasAccess) {
      return;
    }
    const projects = projectsAndDepts["projects"];
    const depts = projectsAndDepts["depts"];
    const roles = projectsAndDepts["role"];
    const activated = projectsAndDepts["active"];

    if ("user" in projectsAndDepts) {
      // type is OneUserProjectsDeptsUpdate
      const user = projectsAndDepts["user"];
      // no need to confirm for single user update
      if (user) {
        this.success = true;
        this.updatedUsers = user;
        this.updatedProjects = projects.join(",  ");
        this.updatedDepts = depts.join(",  ");
        this.updatedRoles = roles;
        this.updatedAccountStatus = activated ? "Active" : "Deactivated";
        return;
      }
    } else {
      // type is UsersProjectsDeptsUpdate
      const projectsNiceNames = projectsAndDepts["projectsNiceNames"];
      // { projects: string[]; projectsNiceNames: string[]; roles: string; active: boolean; users: string[]; depts: string[] }
      this.confirmChangesData = {
        users: this.getSelectedUsers(),
        projects: projects,
        projectsNiceNames: projectsNiceNames,
        depts: depts,
        roles: roles,
        active: activated,
      };
      if (this.confirmChangesData["users"].length > 0) {
        this.confirming = true;
      }
    }
  }

  updateToSelectedUsers() {
    if (!this.hasAccess) {
      return;
    }

    // separating two different Observables array because there's an issue where
    // not all users will be updated if we use only one Observable array to pass to forkJoin
    // maybe a bug/issue on mongo since the network tab shows that we are indeed getting a 200 PUT
    // with the correct headers/request params etc.
    const updateProjectRequests: Observable<boolean>[] = [];
    const updateDeptsRequests: Observable<boolean>[] = [];
    const updateRolesRequests: Observable<boolean>[] = [];
    const toggleActivatedRequest: Observable<boolean>[] = [];

    const selectedUsers = this.confirmChangesData["users"];
    const selectedProjects = this.confirmChangesData["projects"];
    const selectedRoles = this.confirmChangesData["roles"];
    const selectedDepts = this.confirmChangesData["depts"];
    const activated = this.confirmChangesData["active"];

    selectedUsers.map((username) => {
      updateProjectRequests.push(this.userService.addProjectToUser(username, selectedProjects));
      updateDeptsRequests.push(
        this.userService.updateUser(username, { departments: selectedDepts })
      );
      updateRolesRequests.push(this.userService.updateUser(username, { role: selectedRoles }));
      toggleActivatedRequest.push(this.userService.toggleUserActivation(username, activated));
    });

    forkJoin(updateProjectRequests).subscribe(() => {
      forkJoin(updateDeptsRequests).subscribe(() => {
        forkJoin(updateRolesRequests).subscribe(() => {
          forkJoin(toggleActivatedRequest).subscribe(() => {
            this.userService.getUsers().subscribe((users) => {
              this.users = users;
              this.filteredUsers = [...users];
              this.getSearchItems();
            });
            this.selectAll = false;
            this.success = true;
            this.updatedUsers = selectedUsers.join(",  ");
            this.updatedProjects = selectedProjects.join(",  ");
            this.updatedDepts = selectedDepts.join(",  ");
            this.updatedRoles = selectedRoles;
            this.updatedAccountStatus = activated ? "Active" : "Deactivated";
            this.confirming = false;
            this.confirmChangesData = {
              users: [],
              projects: [],
              projectsNiceNames: [],
              depts: [],
              roles: "",
              active: false,
            };
          });
        });
      });
    });
  }

  getSearchItems() {
    if (this.searchByUsername) {
      this.activeTypeAheadItems$ = [...this.getAllUsernames()];
    } else {
      this.activeTypeAheadItems$ = [...this.getAllNames()];
    }
  }

  onToggledFilters($event: boolean) {
    if (!$event) {
      this.userService.getUsers().subscribe((users) => {
        this.users = users;
        this.filteredUsers = [...users];
        this.getSearchItems();
      });
    }
  }

  searchAndFilter() {
    if (this.searchTypeahead.inputString !== "") {
      this.onUpdatedSearch(this.searchTypeahead.inputString);
      this.getSearchItems();
    } else {
      this.userService.getUsers().subscribe((users) => {
        this.users = users;
        this.filteredUsers = [...users];
        this.getSearchItems();
      });
    }
  }

  onUpdatedSearch($event: string | string[] | undefined) {
    if ($event === undefined || Array.isArray($event)) {
      return;
    }
    this.search = $event;
    const regexp = /\+/g;
    this.search = this.search.replace(regexp, "%2B");
    if (this.searchByUsername) {
      this.searchUsername($event);
    } else {
      this.searchFullname($event);
    }
  }

  getAllUsernames(): string[] {
    if (!this.users) {
      return [];
    }
    let usernames: string[] = [];
    this.users.forEach((user) => {
      usernames.push(user.username);
    });
    return usernames;
  }

  getAllNames() {
    if (!this.users) {
      return [];
    }
    return this.users.map((user) => user.firstname + " " + user.lastname);
  }

  searchUsername($event: string) {
    let searchedUsers: User[] = [];

    if ($event.trim().length === 0) {
      this.filteredUsers = [...this.users];
    } else {
      this.filteredUsers = this.users.filter((user) => $event.trim() === user.username);
    }
  }

  searchFullname($event: string) {
    let searchedUsers = [];

    if ($event.trim().length === 0) {
      this.filteredUsers = [...this.users];
    } else {
      this.filteredUsers = this.users.filter(
        (user) => $event.trim() === user.firstname + " " + user.lastname
      );
    }
  }
}
