<ul class="nav nav-tabs" role="tablist">
  <li class="nav-item">
    <a
      #writeTab
      class="nav-link active hover-pointer"
      role="tab"
      (click)="toggleActiveTab(writeTab, previewTab)"
      >Write</a
    >
  </li>
  <li class="nav-item">
    <a
      #previewTab
      class="nav-link hover-pointer"
      role="tab"
      (click)="toggleActiveTab(previewTab, writeTab)"
      >Preview</a
    >
  </li>
  <li *ngIf="canvasComponent" class="nav-item" (click)="addFrameAnnotations()">
    <span class="nav-link hover-pointer" role="tab"
      ><i class="material-icons md-18 hover-pointer" title="Add frame annotations">gesture</i></span
    >
  </li>
  <li class="nav-item" (click)="wrapText('**', '**')">
    <span class="nav-link hover-pointer" role="tab"
      ><i class="material-icons md-18 hover-pointer" title="Bold">format_bold</i></span
    >
  </li>
  <li class="nav-item" (click)="wrapText('*', '*')">
    <span class="nav-link hover-pointer" role="tab"
      ><i class="material-icons md-18 hover-pointer" title="Italic">format_italic</i></span
    >
  </li>
  <li class="nav-item" (click)="wrapText('~~', '~~')">
    <span class="nav-link hover-pointer" role="tab"
      ><i class="material-icons md-18 hover-pointer" title="Strikethrough">strikethrough_s</i></span
    >
  </li>
  <li class="nav-item" (click)="prefixText('- [ ] ')">
    <span class="nav-link hover-pointer" role="tab"
      ><i class="material-icons md-18 hover-pointer" title="Checklist">rule</i></span
    >
  </li>
  <li class="nav-item" (click)="prefixText('- ')">
    <span class="nav-link hover-pointer" role="tab"
      ><i class="material-icons md-18 hover-pointer" title="List">format_list_bulleted</i></span
    >
  </li>
  <li class="nav-item" (click)="insertLink()">
    <span class="nav-link hover-pointer" role="tab"
      ><i class="material-icons md-18 hover-pointer" title="Add link">insert_link</i></span
    >
  </li>
  <li class="nav-item" (click)="prefixText('> ')">
    <span class="nav-link hover-pointer" role="tab"
      ><i class="material-icons md-18 hover-pointer" title="Add quote">format_quote</i></span
    >
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div
    class="tab-pane active show fade"
    id="dynamic-tab"
    role="tabpanel"
    aria-labelledby="dynamic-tab">
    <textarea
      #writeTextArea
      [ngClass]="{ 'd-none': !writeTab.classList.contains('active') }"
      class="form-control"
      id="note-body"
      name="note-body"
      rows="4"
      placeholder="Write a comment or drag images here"
      (drop)="onDropImage($event)"></textarea>
    <div
      #preview
      [ngClass]="{ 'd-none': !previewTab.classList.contains('active') }"
      class="form-control"
      style="height: auto"
      (click)="markdownLinkHandler($event)"
      [innerHTML]="writeTextArea.value | marked"></div>
  </div>
</div>
