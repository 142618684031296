<div
  class="fade show d-block modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="fileBrowser"
  aria-hidden="true"
  (exited)="shouldExit($event)"
  appModalExit>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <ng-container *ngIf="entity.hasOwnProperty('entityCode'); else general">
          <h5 class="modal-title">
            Browsing
            <span class="font-weight-bolder"
              >{{ $any(entity).group }} - {{ $any(entity).entityCode }}</span
            >
          </h5>
        </ng-container>

        <ng-template #general>
          <h5 class="modal-title">
            Browsing <span class="font-weight-bolder">{{ projectCode }}'s {{ entity }}</span>
          </h5>
        </ng-template>

        <ng-container *ngIf="entity.hasOwnProperty('entityCode')">
          <div class="ml-1 form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="dest"
              id="files"
              value="files"
              [checked]="true"
              (change)="rootDir = ''; init()" />
            <label class="form-check-label" for="files">Files</label>
          </div>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="dest"
              id="media"
              value="media"
              (change)="rootDir = 'media'; init()" />
            <label class="form-check-label" for="media">Media</label>
          </div>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="dest"
              id="transfer"
              value="transfer"
              (change)="rootDir = 'transfer/sent'; init()" />
            <label class="form-check-label" for="transfer">Transfer</label>
          </div>
        </ng-container>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body row">
        <nav aria-label="breadcrumb" class="w-100 mb-1">
          <ol class="breadcrumb align-items-baseline">
            <ng-container *ngFor="let dir of subDirs; last as isLast; index as i">
              <li class="breadcrumb-item" [ngClass]="{ active: isLast }" aria-current="page">
                <a
                  *ngIf="!isLast; else textOnly"
                  href="javascript:void(0);"
                  (click)="changeDir(i)"
                  >{{ dir }}</a
                >
                <ng-template #textOnly>{{ dir }}</ng-template>
              </li>
            </ng-container>
            <li *ngIf="allowUploadInFileBrowser" class="breadcrumb-item" aria-current="page">
              <input #folder type="text" class="d-inline-block" />
              <button type="button" class="btn btn-secondary btn-sm" (click)="createFolder(folder)">
                Create folder
              </button>
            </li>
          </ol>
        </nav>

        <div style="display: flex; justify-content: space-between" class="container mb-2">
          <div class="form-check">
            <input
              #checkboxInputAll
              class="form-check-input"
              id="file-browser"
              type="checkbox"
              [(ngModel)]="selectAll"
              (change)="
                updateSelectedFiles('/select_all/', checkboxInputAll.checked ? 'add' : 'remove')
              " />
            <label class="form-check-label" for="file-browser">Select all</label>
          </div>
          <div class="form-check">
            <input
              id="omit-versions-checkbox"
              class="form-check-input"
              type="checkbox"
              [(ngModel)]="omitVersions"
            />
            <label class="form-check-label" for="omit-versions-checkbox">Omit versions</label>
          </div>
        </div>

        <div class="container content-list" *ngFor="let content of contents">
          <div *ngIf="content['type'] === 'dir' && listDirResolved" class="form-check d-flex">
            <input
              #checkboxInputListItem
              class="form-check-input"
              type="checkbox"
              [id]="'chk-dir-' + content['name']"
              [checked]="selectAll"
              (change)="
                updateSelectedFiles(
                  content['name'],
                  checkboxInputListItem.checked ? 'add' : 'remove'
                )
              " />
            <label
              class="form-check-label flex-grow-1"
              (click)="subContents = undefined; listDir(content['name'])">
              <span class="hover-pointer highlight"
                ><i class="dir material-icons md-24 align-middle">folder</i
                ><span class="align-middle ml-1">{{ content["name"] }}</span></span
              >
            </label>
            <span *ngIf="entity | isIentity" class="zindex-tooltip m-auto"
              ><i
                class="material-icons md-24 align-middle hover-pointer text-danger"
                title="Delete"
                (click)="deleteSinglePath($event, content['name'])"
                >delete_forever</i
              ></span
            >
          </div>

          <div *ngIf="content['type'] === 'file'" class="form-check d-flex">
            <input
              class="form-check-input"
              type="checkbox"
              [id]="'chk-file-' + content['name']"
              [checked]="selectAll"
              (change)="onChangeFormCheckInput($event, content)" />
            <label
              class="form-check-label flex-grow-1"
              (click)="download(content['name'])"
              (mouseover)="updatePreview(content['name'])">
              <span class="hover-pointer highlight"
                ><i class="file material-icons-outlined md-24 align-middle">description</i
                ><span class="align-middle ml-1">{{ content["name"] }}</span></span
              >
            </label>
            <span *ngIf="entity | isIentity" class="zindex-tooltip m-auto"
              ><i
                class="material-icons md-24 align-middle hover-pointer text-danger"
                title="Delete"
                (click)="deleteSinglePath($event, content['name'])"
                >delete_forever</i
              ></span
            >
          </div>

          <ng-container
            *ngIf="
              entity.hasOwnProperty('context') &&
              $any(entity).context === 'shots' &&
              content['is_workfile'] &&
              content['type'] === 'file'
            ">
            <span
              class="align-middle ml-1 hover-pointer text-primary more"
              (click)="subContents = showFileSubContents(content['name'])"
              >more...</span
            >
            <form #subContentsForm>
              <div
                *ngFor="let s of subContents | async | keyvalue; last as isLast"
                class="container mx-2">
                <div class="container">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [id]="s.key"
                    [checked]="s.value === 'outdated' || s.value === 'new'"
                    [value]="s.key" />
                  <label class="form-check-label" [for]="s.key"
                    >{{ s.key }}
                    <i
                      [ngClass]="{
                        'text-danger': s.value === 'outdated',
                        'text-success': s.value === 'new',
                        'text-secondary': s.value === 'up to date'
                      }"
                      >{{ s.value }}</i
                    ></label
                  >
                </div>
                <div *ngIf="isLast" class="container mt-2">
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-sm"
                    (click)="download(content['name'], subContentsForm)">
                    Download
                  </button>
                </div>
              </div>
            </form>
          </ng-container>
        </div>

        <div class="container">
          <div
            *ngIf="addGenerateFileFromUpstreamOption"
            class="hover-pointer highlight"
            (click)="generateFileFromUpstreamDept()">
            <i class="file material-icons-outlined md-24 align-middle">alt_route</i>
            <span class="align-middle ml-1">Generate file from upstream department</span>
          </div>
          <div
            *ngIf="addGetTemplateFile"
            class="hover-pointer highlight"
            (click)="getTemplateFile()">
            <i class="file material-icons-outlined md-24 align-middle">alt_route</i>
            <span class="align-middle ml-1">Get Template File</span>
          </div>
        </div>

        <div class="container text-center" *ngIf="contents && contents.length === 0">
          This folder is empty
        </div>

        <div *ngIf="previewURL" class="my-3 container text-center">
          <div>Preview:</div>
          <img [src]="previewURL" class="img-thumbnail w-50" />
        </div>

        <div class="container text-center mt-3" *ngIf="contents && contents.length > 0">
          <button
            type="button"
            class="btn btn-outline-primary btn-sm"
            [attr.disabled]="selectedFiles.length === 0 ? true : null"
            (click)="downloadSelected()">
            Download selected
          </button>
          <button
            *ngIf="entity | isIentity"
            type="button"
            class="btn btn-outline-danger btn-sm"
            [attr.disabled]="selectedFiles.length === 0 ? true : null"
            (click)="deleteSelected()">
            Delete selected
          </button>
        </div>
      </div>

      <ng-container *ngIf="subDirs.length > 1 && allowUploadInFileBrowser">
        <div class="custom-file">
          <input
            #inputFiles
            type="file"
            class="custom-file-input"
            id="customFile"
            multiple="true"
            (change)="onChangeInputFiles(inputFiles)" />
          <label class="custom-file-label" for="customFile">Upload file(s)</label>
          <button
            type="button"
            class="w-100 hover-pointer mt-2 btn btn-outline-primary btn-sm"
            [attr.disabled]="
              attachedFiles.length === 0 || processStatus.status !== 'Idle' ? true : null
            "
            (click)="uploadFiles(inputFiles)">
            Upload
          </button>
        </div>

        <ul>
          <li *ngFor="let file of attachedFiles">{{ file.name }}</li>
        </ul>
      </ng-container>

      <div>
        <span *ngIf="processStatus.status !== 'Idle'">{{ processStatus.status }}</span
        ><span *ngIf="!['Submitted', 'Started', 'Idle'].includes(this.processStatus.status)"
          >. Please do not close this tab or your browser. {{ processStatus.progress }}</span
        >
        <div class="progress" style="height: 8px">
          <div #progressBar class="progress-bar" role="progressbar"></div>
        </div>
      </div>

      <div
        *ngIf="processStatus.status === 'Failed' || processStatus.status === 'Suspended'"
        class="container alert alert-danger alert-dismissible"
        role="alert">
        {{ processStatus.msg }}
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          (click)="processStatus.status = 'Idle'">
          <span class="font-small" aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="fade show d-block modal-backdrop"></div>
