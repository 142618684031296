import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "../environments/environment";
import MongoDocument from "./mongo-document";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

// TODO: these types could potentially be handled better/somewhere more centrally...
export interface DriveDocument extends MongoDocument {
  code: string;
  drive?: string;
  nice_name: string;
  out_of_picture?: boolean;
  tags: string[];
}

export interface ReelDocument extends MongoDocument {
  code: string;
  drive: string;
  nice_name: string;
  out_of_picture?: boolean;
  tags: string[];
}

@Injectable({
  providedIn: "root",
})
export class PlateService {
  constructor(private http: HttpClient) {}

  // getAll(projectCode: string): Observable<any> {
  //   const url = `${environment.projectsURL}/${projectCode}/plates?grouped=1&fields=plate_code,drive,reel,parent,children,out_of_picture`;
  //   return this.http.get<any>(url);
  // }

  // add(projectCode: string, plate: object) {
  //   const url = `${environment.projectsURL}/${projectCode}/plates`;
  //   return this.http.post(url, JSON.stringify(plate), httpOptions);
  // }

  // addAsFile(projectCode: string, plate: File) {
  //   let formData = new FormData();
  //   formData.append('file', plate, plate.name);
  //
  //   const url = `${environment.projectsURL}/${projectCode}/plates`;
  //   return this.http.post(url, formData, { headers: new HttpHeaders({ 'enctype': 'multipart/form-data' })}
  //   );
  // }

  // update(projectCode: string, drive: string, plateCode: string, plate: object) {
  //   const url = `${environment.projectsURL}/${projectCode}/plates/${drive}/${plateCode}`;
  //   return this.http.put(url, JSON.stringify(plate), httpOptions);
  // }

  updateDrive(
    projectCode: string,
    drive: string,
    update: object,
    updateChildren: boolean
  ): Observable<Partial<DriveDocument>> {
    const url = `${environment.projectsURL}/${projectCode}/drives/${drive}?update-children=${updateChildren}`;
    return this.http.put<Partial<DriveDocument>>(url, JSON.stringify(update), httpOptions);
  }

  updateReel(
    projectCode: string,
    drive: string,
    reel: string,
    update: object,
    updateChildren: boolean
  ): Observable<Partial<ReelDocument>> {
    const url = `${environment.projectsURL}/${projectCode}/reels/${drive}/${reel}?update-children=${updateChildren}`;
    return this.http.put<Partial<ReelDocument>>(url, JSON.stringify(update), httpOptions);
  }

  // getThumbnailURL(projectCode: string, plateCode: string): string {
  //   return `${environment.projectsURL}/${projectCode}/plates/${plateCode}/thumbnail`;
  // }

  // getAllTags(projectCode: string): Observable<string[]> {
  //   const url = `${environment.projectsURL}/${projectCode}/plates/tags/all`;
  //   return this.http.get<string[]>(url);
  // }
}
