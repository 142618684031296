<nav class="navbar navbar-expand-sm navbar-light bg-white p-1 mb-1">
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarToggler"
    aria-controls="navbarToggler"
    aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarToggler">
    <ul class="navbar-nav mr-auto">
      <ng-container *ngIf="!isLoggedIn">
        <li>
          <a class="nav-link" [class.active]="isActive('projects')" routerLink="/projects"
            >Projects<span class="sr-only">(current)</span></a
          >
        </li>
        <li>
          <a class="nav-link" [class.active]="isActive('users')" routerLink="/users"
            >Users<span class="sr-only">(current)</span></a
          >
        </li>
        <li>
          <a class="nav-link" [class.active]="isActive('login')" routerLink="/login"
            >Login<span class="sr-only">(current)</span></a
          >
        </li>
      </ng-container>
      <ng-container *ngIf="isLoggedIn">
        <li>
          <a class="nav-link" [class.active]="isActive('projects')" routerLink="/projects"
            >Projects<span class="sr-only">(current)</span></a
          >
        </li>
        <li>
          <a class="nav-link" [class.active]="isActive('users')" routerLink="/users"
            >Users<span class="sr-only">(current)</span></a
          >
        </li>
        <ng-container *ngIf="canViewDevDocs">
          <li>
            <a class="nav-link" [class.active]="isActive('swagger-api-reference')"
               routerLink="/swagger-api-reference">
              API Reference<span class="sr-only">(current)</span>
            </a>
          </li>
<!--          NOTE: sphinxdocs is served directly from nginx, this link won't work in dev mode. -->
<!--          <li>-->
<!--            <a class="nav-link" [href]="sphinxdocsURL">Sphinx Docs<span class="sr-only">(current)</span>-->
<!--            </a>-->
<!--          </li>-->
        </ng-container>
        <li>
          <a class="nav-link" [class.active]="isActive('projects/lts01/documentation-links')" routerLink="/projects/lts01/documentation-links"
            >Main Documentation<span class="sr-only">(current)</span></a>
        </li>
        <li>
          <a class="nav-link" [class.active]="isActive('logout')" (click)="logout()"
            >Logout<span class="sr-only">(current)</span></a>
        </li>
      </ng-container>
    </ul>
  </div>
</nav>

<router-outlet></router-outlet>
