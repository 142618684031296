import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { GroupByDept, User } from "./user";
import { environment } from "../environments/environment";
import { Role } from "./role";
import { Permission } from "./permission";
import type { Filters, FiltersFieldFromUser } from "../types/filters";
import type { TableConfigColumnItem } from "../types/table-config";

let httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

type _User = Omit<User, "projects"> & { projects: string[] };

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUsers(useSavedFilters: boolean = true): Observable<User[]> {
    if (useSavedFilters) {
      return this.http.get<User[]>(`${environment.usersURL}?exclude_fields=downloads,filters`);
    } else {
      return this.http.get<User[]>(
        `${environment.usersURL}?use_saved_filters=0&exclude_fields=downloads,filters`
      );
    }
  }

  getDevUsers(): Observable<User[]> {
    const url = `${environment.usersURL}?is_dev=is:true&fields=username&exclude_fields=_id`;
    return this.http.get<User[]>(url);
  }

  getUserByUsername(username: string): Observable<User> {
    const url = `${environment.usersURL}/${username}`;
    return this.http.get<User>(url);
  }

  addProjectToUser(username: string, projects: string[]): Observable<boolean> {
    const url = `${environment.usersURL}/${username}/add_project`;
    return this.http.put<boolean>(url, JSON.stringify(projects), httpOptions);
  }

  updateUser(username: string, fields: Object): Observable<boolean> {
    const url = `${environment.usersURL}/${username}`;
    return this.http.put<boolean>(url, JSON.stringify(fields), httpOptions);
  }

  toggleUserActivation(username: string, activate: boolean): Observable<boolean> {
    const endpoint = activate ? "activate" : "deactivate";
    const url = `${environment.usersURL}/${username}/${endpoint}`;
    return this.http.put<boolean>(url, "");
  }

  // This might not be the best here. This method is called with some fields of User defined, but not others
  addUser(user: Partial<_User>): Observable<User> {
    const url = `${environment.usersURL}/add`;
    return this.http.post<User>(url, user, httpOptions);
  }

  // Any should be ok here since it's private and has limited use.
  private _sendPasswordAndToken(url: string, password: string, token: string): Observable<any> {
    const encodedPayload = atob(token.split(".")[1]);
    const decodedPayload = JSON.parse(encodedPayload);
    const username = decodedPayload["username"];

    const toEncode = `${username}:${password}`;
    const encodedData = btoa(toEncode);

    const _token = { token: token };

    httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Basic ${encodedData}`,
        "Content-Type": "application/json",
      }),
    };
    return this.http.post<User>(url, _token, httpOptions);
  }

  activateAccount(password: string, token: string): Observable<boolean> {
    const url = `${environment.usersURL}/activate`;
    return this._sendPasswordAndToken(url, password, token);
  }

  resetPassword(password: string, token: string): Observable<boolean> {
    const url = `${environment.usersURL}/reset-password`;
    return this._sendPasswordAndToken(url, password, token);
  }

  getUsersByProject(
    projectCode: string,
    context: string | null | undefined | false,
    groupByDept = GroupByDept.yes
  ): Observable<User[] | { [dept: string]: User[] }> {
    const url = context
      ? `/api/projects/${projectCode}/users?group-by-dept=${groupByDept}&context=${context}&assignable=true&fields=username,departments,initials_colour`
      : `/api/projects/${projectCode}/users?group-by-dept=${groupByDept}&assignable=true&fields=username,departments,initials_colour`;

    return this.http.get<User[] | { [dept: string]: User[] }>(url);
  }

  saveFilters(projectCode: string, context: string, filters: string): Observable<Filters> {
    const url = `${environment.projectsURL}/${projectCode}/${context}/filters?${filters}`;
    return this.http.post<Filters>(url, JSON.stringify(""), httpOptions);
  }

  deleteFilter(projectCode: string, context: string, filterName: string): Observable<Filters> {
    const url = `${environment.projectsURL}/${projectCode}/${context}/filters?filtername=${filterName}`;
    return this.http.delete<Filters>(url);
  }

  setDefaultFilter(
    projectCode: string,
    context: string,
    filterName: string
  ): Observable<{ default: string }> {
    const url = `${environment.projectsURL}/${projectCode}/${context}/filters?filtername=${filterName}`;
    return this.http.put<{ default: string }>(url, JSON.stringify(""), httpOptions);
  }

  enableFilter(
    projectCode: string,
    context: string,
    enable: boolean
  ): Observable<{ is_enabled: boolean }> {
    const url = `${environment.projectsURL}/${projectCode}/${context}/filters?enable=${enable}`;
    return this.http.put<{ is_enabled: boolean }>(url, JSON.stringify(""), httpOptions);
  }

  addRole(rolename: Role | Omit<Role, "_id">): Observable<Role> {
    // might not be the right thing here...
    const url = `${environment.rolesURL}/add`;
    return this.http.post<Role>(url, rolename, httpOptions);
  }

  updateRole(rolename: string, fields: { permissions: string[] }): Observable<boolean> {
    const url = `${environment.rolesURL}/${rolename}`;
    return this.http.put<boolean>(url, JSON.stringify(fields), httpOptions);
  }

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(environment.rolesURL);
  }

  getRoleByRolename(rolename: string): Observable<Role> {
    const url = `${environment.rolesURL}/${rolename}`;
    return this.http.get<Role>(url);
  }

  getPermissionsList(): Observable<Permission[]> {
    return this.http.get<Permission[]>(environment.permissionsURL);
  }

  saveTableConfig(
    projectCode: string,
    context: string,
    config: TableConfigColumnItem[]
  ): Observable<boolean> {
    const url = `${environment.projectsURL}/${projectCode}/table_config/${context}`;
    return this.http.put<boolean>(url, JSON.stringify(config), httpOptions);
  }
}
