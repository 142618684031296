import { Injectable } from "@angular/core";

import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class HdriService {
  getThumbnailURL(projectCode: string, hdriCode: string): string {
    return `${environment.projectsURL}/${projectCode}/hdri/hdri/${hdriCode}/thumbnail`;
  }

  getImageURL(projectCode: string, hdriCode: string): string {
    return `${environment.projectsURL}/${projectCode}/hdri/hdri/${hdriCode}/image`;
  }
}
